@font-face {
    font-family: 'sap-icofonts';
    src: url('../../fonts/sap/sap-icofonts.eot');
    src: url('../../fonts/sap/sap-icofonts.eot?#iefix') format('embedded-opentype'), url('../../fonts/sap/sap-icofonts.ttf') format('truetype'), url('../../fonts/sap/sap-icofonts.woff') format('woff'), url('../../fonts/sap/sap-icofonts.svg#sap-icofonts') format('svg');
    font-weight: normal;
    font-style: normal;
}

$social-icon-popup-height: 320px;
$social-icon-popup-height--mobile: 230px;
$social-icon-popup-transition: all .4s;

.page--project-assets {
    .assets-grid {
        margin-top: 10px;
    }

    .public-page-title {
        font-size: 24px;
        color: $black;
        font-family: $BrandMedium;
        display: inline-block;
        padding-top: 20px;
    }
}

.page--asset-preview,
.public-html {
    .header-holder {
        .black_and_white_toggle {
            float: right;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;

            .input-pair__title-box {
                color: $white;
                min-height: 0;
                font-size: 12px;
                padding-top: 0;
            }
        }
    }
}

.public-html .asset-detail__actions-container {
    display: none;
}

.public-html .asset-modal .tabs__panel {
    margin-top: 5px;
}

.footer-navigation__items {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-navigation__item {
    a:hover {
        text-decoration: underline;
    }
}

.page--video-embed {
    .video-js {
        position: absolute;
    }
}

.asset-preview-widget.embedded {
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;

    .pdf-viewer__toolbar-zoom {
        .zoom-in {
            margin-right: 0;
        }
    }
    .pdf-viewer__modal {
        z-index: 1;
    }
    .button--action {
        z-index: 1;
        margin: 0 42px 0 auto;
    }
}

.content-preview-wrapper {
    .asset-preview-widget.embedded {
        .button--action {
            position: absolute;
            top: 92px;
            right: 0;
        }
    }
}

.asset-content-preview-page #main {
    background: rgba(18, 23, 28, 0.4);
}

.page--asset-preview {
    .asset-preview-container {
        flex-grow: 1;
        max-width: 1200px;
        margin: 0 auto;
    }
    #app:after {
        display: none;
    }
    .footer {
        display: block;
        border-top: 0;
        padding: 0;
    }
    .asset-preview-wrapper {
        max-width: 600px;
        margin: auto;
        background: $white;
        border-radius: 5px;
    }

	.asset-subtitle, .asset-title, .asset-preview-widget{
        color: $black;
        line-height: 100%;
    }

    .asset-subtitle, .asset-title{
        text-align: left;
    }
    .asset-preview-widget {
        text-align: center;

        .carousel {
            .slide {
                display: block;
            }
        }
        .carousel-slider {
            height: 405px;
            padding-bottom: 20px;
            box-sizing: border-box;
            .slider {
                padding: 0;
                margin: 0;
            }
            .slider-wrapper {
                height: 100%;
            }
        }
    }

    .asset-preview--content-holder {
        padding: 15px 30px 30px 30px;
    }

    .relational-buttons {
        text-align: center;

        .button--action {
            margin-left: calc(#{$indentBasis} * 2);
        }
    }

    .video-js {
        margin: auto;
    }

	.asset-title {
        font-size: 20px;
        word-break: break-word;
        font-family: $BrandMedium;
        font-weight: 500;
    }

    .asset-subtitle {
        font-size: 14px;
        padding-top: 9px;
        line-height: 20px;
        font-family: $BrandRegular;
	}

    .asset-more {

        a {
            position: relative;
            padding: 10px 23px 12px 24px;
            border-radius: 0;
            font-size: 13px;
            color: $white;
            background: $blue;

            &:hover {
                text-decoration: underline;
                background: $navy;
            }
        }
    }

    @media only screen and (max-width: 767px) {

        .asset-more a {
            font-size: 12px;
            font-family: $BrandRegular;
            padding: 7px 20px 6px 40px;
        }
    }


    @media only screen and (max-width: 767px) {
        .asset-title {
            font-size: 22px;
            line-height: 1.3;
            margin: 0px 0px 4px;
            padding: 0;
            text-align: left;
        }
        .asset-subtitle {
            font-size: 14px;
            line-height: 1.2;
            margin: 0;
            text-align: left;
        }
    }

    /* header styles */
    .header-holder {
        color: $greyLight5;
        padding: 13px 0;
    }
    .header-holder:after {
        content: '';
        display: block;
        clear: both;
    }

    #page {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .container:after {
        content: '';
        clear: both;
        display: block;
    }

    .content-holder {
        padding-top: 40px;
    }

    /* end of header styles */


    /* footer style */


    #footer a {
        color: $black;
    }
    @media only screen and (max-width: 767px) {
        #footer {
            padding: 0;
        }
    }
    @media only screen and (max-width: 767px) {
        .show-overlay #footer {
            position: static;
            z-index: 0;
        }
    }
    .footerNavGroup {
        height: 1%;
        margin-right: 400px;
        /* responsive tablet and lower */

    }
    @media only screen and (max-width: 1000px) {
        .footerNavGroup {
            margin: 0 auto;
            display: table;
        }
    }
    @media only screen and (max-width: 767px) {
        .footerNavGroup {
            margin: 0 -10px;
            display: block;
            position: relative;
        }
    }
    section.footer {
        position: relative;
        /* responsive tablet and lower */

    }
    section.footer.container {
        max-width: none;
        padding: 0;
    }
    section.footer .follow {
        float: right;
        margin-bottom: -12px;
    }
    @media only screen and (max-width: 1000px) {
        section.footer .follow {
            float: none;
            margin-bottom: 0;
        }
        section.footer .menu {
            float: none !important;
        }
    }
    section.footer .label {
        float: left;
    }
    section.footer .menu {
        float: left;
    }
    section.footer .menu > ul {
        float: left;
        list-style: none;
        margin: 0;
        padding: 2px 0 0;
    }
    section.footer .menu > ul a {
        display: inline-block;
        vertical-align: top;
    }
    section.footer .menu > ul > li {
        display: inline-block;
        margin: 0;
        padding: 0 8px;
        vertical-align: top;
        position: relative;
        /* responsive mobile */

    }

    @media only screen and (max-width: 767px) {
        .footerNavGroup {
            margin: 0 -10px !important;
        }
        .content-holder {
            padding: 28px 0 0 0 !important;
        }
        #footer {
            padding: 0 !important;
        }
        #footer .socialLinksGroup.extended {
            overflow: hidden;
            padding-top: 12px;
            margin-bottom: 13px;
        }
        section.footer {
            padding: 6px 0;
            position: relative;
            z-index: 100;
        }
        section.footer .menu {
            float: none;
            clear: both;
            border-bottom: 1px solid $white;
            padding: 0;
            position: relative;
            z-index: 101;
        }
        section.footer .menu > ul {
            float: none;
            padding: 0;
            margin: 0;
            list-style: none;
        }
        section.footer .menu > ul > li {
            white-space: nowrap;
            height: 44px;
            display: block;
            border-top: 1px solid $white;
            text-align: center;
            position: static;
        }
        section.footer .menu > ul > li:first-child {
            padding-left: 8px;
        }
        section.footer .menu > ul > li.odd {
            float: left;
            width: 45%;
            clear: none;
            border-right: 1px solid $white;
        }
        section.footer .menu > ul > li > a {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 14px 0 14px;
        }
        .asset-subtitle {
            margin: 0px 0px 5px;
        }
    }

    .social__items {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    @media only screen and (max-width: 1000px) {
        .extended .sapdx-follow {
            float: none;
            text-align: center;
            margin: 0;
        }
    }
    @media only screen and (max-width: 767px) {
        .extended .sapdx-follow {
            min-width: 1px;
        }
    }
    .extended .sapdx-social-links-group {
        overflow: visible;
        position: relative;
    }
    @media only screen and (max-width: 1000px) {
        .extended .sapdx-social-links-group {
            float: none;
            margin-left: 0;
        }
    }

    /* end of footer */


    // Media

    @media only screen and (max-width: 1000px) {
        .content-holder {
            padding: 40px 0 0;
        }
    }
}

.page--asset-preview,
.playlist-preview-page {
    #header,
    .region--public {
        background: $greyLight5;
        height: 60px;
        border: none;

        .sap-logo {
            width: 64px;
        }
    }

    .container,
    .page--width-wrapper {
        max-width: 1430px;
        padding: 0 24px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 640px) and (max-width: 979px) {
        .container,
        .page--width-wrapper {
            padding: 0 48px;
        }
    }

    @media only screen and (min-width: 980px) and (max-width: 1599px) {
        .container,
        .page--width-wrapper {
            padding: 0 72px;
        }
    }

    @media only screen and (min-width: 1600px) {
        .container,
        .page--width-wrapper {
            padding: 0 246px;
        }
    }
}

.footer-social {
    .social-icon {
        width: 32px;
        height: 32px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        svg {
            max-width: 16px;
            max-height: 16px;
        }
    }

    .social__item {
        margin-left: 12px;
        vertical-align: top;
        display: inline-block;

        &:first-child {
            margin-left: 0;
        }
    }

    .social__popup {
        position: absolute;
        bottom: 45px;
        right: 0;
        height: 0px;
        overflow-y: hidden;
        transition: $social-icon-popup-transition;

        &__content {
            box-sizing: border-box;
            position: relative;
            height: $social-icon-popup-height;
            width: 245px;
            bottom: -100%;
            padding: 34px 29px 5px 30px;
            background: $greyDarker4;
            transition: all .5s;
        }

        &__header {
            min-height: 125px;
            text-align: center;

            .social-icon {
                width: 72px;
                height: 72px;
                border-radius: 100%;
            }

            .social-icon:before {
                line-height: 72px;
                font-size: 40px;
            }

            .icon-facebook.social-icon:before,
            .icon-linked-in.social-icon:before,
            .icon-google.social-icon:before,
            .icon-twitter02.social-icon:before {
                font-size: 66px;
            }
        }

        &__title {
            font-size: 18px;
            line-height: 25px;
            padding: 7px 0 22px;
        }

        &__links {
            width: auto;
            display: block;
            cursor: pointer;

            a {
                display: block;
                padding: 5px 0;
            }

            a:hover {
                text-decoration: underline;
            }

            a:not(:last-of-type), a:only-of-type {
                border-bottom: 1px solid $greyDarker4;
            }
        }

        &--open {
            height: $social-icon-popup-height;
            z-index: 5;

            .social__popup__content {
                bottom: 0;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .sapdx-follow--popup-open {

            .social__title {
                margin-bottom: calc(#{$social-icon-popup-height--mobile} + 20px);
            }
        }

        .social__popup {
            width: 100%;
        }

        .social__popup--open,
        .social__popup__content {
            width: 100%;
            height: $social-icon-popup-height--mobile;
            padding-top: 5px;
        }
    }
}

.page--ownership-manager {

    /* header styles */
    #header {
    	background-color: $greyDefault;
    	width: 100%;
    	height: 60px;
    }
    .header-holder {
        color: $greyDarker4;
        padding: 12px 0;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        max-width: 1060px;
        margin: 0 auto;
    }
    .header-holder:after {
        content: ' ';
    }
    .header-holder .logo {
        font-size: 0;
        line-height: 0;
        float: left;
    }
    @media only screen and (max-width: 1059px) {
        .header-holder .logo {
            margin: 0 30px 0 0;
        }
    }
    @media only screen and (max-width: 1000px) {
        #header {
            min-height: 60px;
        }
        .header-holder {
            padding: 8px 0;
            margin: 0 -3px 0 0;
        }
        .header-holder .logo {
            margin: 0;
        }
    }
    .cabinet-header {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        font-weight: bold;
    }
    /* end of header styles */

    #main {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .container {
    	min-width: 320px;
        max-width: 1060px;
        padding: 0 270px;
        margin: 0 auto;
    }

    .content-holder {
        min-width: 320px;
        max-width: 1060px;
        margin: 0 auto;
        background-color: $white;
    }

    .ownership-manager-header {
        font-size: 14px;
        padding: 20px 0 20px;
        font-weight: bold;
        text-transform: uppercase;

        .rejected {
            color: red;
        }
    }

    .ownership-manager-panel {
        padding: 15px;
        background: $greyLight7
    }

    .ownership-manager-denied {
        padding: 15px;
        background: $redLight;
    }

    .base-textarea {
        width: 350px;
    }

    // Media
    @media only screen and (max-width: 1000px) {
        #header {
            min-height: 54px;
        }
    }

    @media only screen and (max-width: 1000px) {
        #header {
            min-height: 54px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .content-holder {
            padding: 60px 0 79px;
        }
    }
}

html.modal-open {
    .page--asset-preview {
        video {
            z-index: -1;
        }
    }
}

@media only screen and (max-width: 768px) {
    .page--asset-preview .asset-preview-widget .carousel-slider {
        max-height: 50vh;
    }
}

@media only screen and (max-width: 414px) {

    .page--asset-preview {
        .footer {
            margin: 0;
        }
        section.footer {
            margin: 0;
            .menu {
                > ul {
                    > li,  li.odd {
                        width: auto;
                        display: inline-block;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

.dummy .track-vertical {
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    z-index: 1;
}
