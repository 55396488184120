.confirm-modal {
    .modal__content {
        padding: 35px 20px;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
    }

    &.listening-modal {
        position: fixed;
        z-index: 1;
    }

    .published-assets--list {
        max-height: 300px;
        overflow-y: auto;
        li {
            margin-bottom: 5px;
            line-height: 24px;
            .publishedIn {
                &:after {
                    content: ', ';
                }
                &:last-child:after {
                    content: '';
                }
            }
        }
    }
}
