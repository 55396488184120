@media screen and (max-width: 1200px) {
    .cvp-preview-page__info {
        width: 50%;
    }

    .cvp-preview-page__assets-list {
        width: 50%;
    }
}

@media screen and (max-width: 720px) {
    .cvp-preview-page {
        &__info {
            width: auto;
        }
        &__assets-list {
            padding: 10px;
            width: auto;
        }
        .metadata-item__title {
            min-width: 0;
            width: 50%;
        }

        .actions__container {
            z-index: auto;
        }

        .metadata-item__data {
            width: 50%;
        }

        .cvp-preview-header__languages .language-tab--active {
            border: 0;
            background: $hoverBg;
        }

        .cvp-preview-page__content-wrapper {
            flex-direction: column-reverse;

            .related-asset__sequence-number {
                margin-right: 7px;
            }

            .related-asset__container {
                padding-left: 10px;
            }

            .related-asset__thumbnail {
                width: 130px;
            }

            .related-asset__info {
                width: calc(100% - 160px);
            }

            .related-asset__name {
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            .cvp-preview-page__info {
                width: 100%;

                .video-js {
                    width: 100% !important;
                }
            }

            .more-videos {
                float: right;
                line-height: 14px;

                .dropdown-title {
                    color: $blackLight;
                }

                &.dropdown--mobile {
                    .dropdown-body {

                        .dropdown-body__content {

                            .cvp-preview-page__subtitles {
                                margin-left: 0;
                            }

                            .related-asset__wrapper {
                                flex-grow: 0;
                                flex-shrink: 0;
                            }
                        }
                    }
                }

                &:not(.dropdown--mobile) {
                    .dropdown-body {
                        left: auto;
                        right: 0;
                        width: 600px;

                        .related-asset__wrapper {
                            width: calc(100% - 25px);
                            flex-grow: 0;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }

        .async-tabs--wrapper {
            width: 100%;
            margin: 0 !important;

            .language-tab {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .cvp-preview-page .general-description .description {
        font-size: 12px;
    }
}

@media #{$mobile} {
    .cvp-preview-page__container {
        padding: 0 24px;
    }

    .cvp-preview-page {
        .related-asset__info .general-description .content-type  {
            display: none;
        }

        .related-asset__container {
            .related-asset__thumbnail {
                width: 90px;
                .related-asset__img {
                    max-width: 90px;
                    min-width: 90px;
                }
            }
            .related-asset__info {
                width: 100%;
                overflow: hidden;
                .description.content-language {
                    flex-grow: 1;
                    overflow: hidden;
                    .content-language__title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 1365px) {
    .cvp-preview-page__container {
        padding: 0 32px;
    }
}
