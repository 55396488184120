.add-subtitle-modal {
    .info-tooltip {
        position: absolute;
        top: -12px;
        left: 70px;
    }

    .replace-thumbnail-modal__text {
        & > div {
            &:first-child {
                .input-pair {
                    padding-top: 0;
                }
            }
        }
    }

    .file-preview {
        margin-top: 2*$indentBasis;
        margin-bottom: 0;
    }

    .subtitle-error {
        font-size: 12px;
        line-height: 21px;
        color: $red;
    }

    .input-pair {
        .error {
            float: none;
        }
    }
}