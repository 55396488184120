.partner-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .export-dropdown{
        width: auto;
    }
    .search-input--full-text {
        .input-pair__input-box {
            width: 300px;
        }
    }
}

.pending-review__indicator {
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 12px;
    font-weight: bold;

    & > span {
        margin-right: 20px;
    }

    svg {
        height: 23px;
        width: 23px;
        margin-top: -3px;
    }

    path {
        fill: $redLight;
    }

    &__count {
        position: absolute;
        right: 5px;
        top: -8px;
        height: 15px;
        padding: 2px 5px;
        border-radius: 3px;
        background: $orangeLight;
        font-size: 11px;
    }
}

.button--action.review-link {
    font-size: 11px;
    margin-right: 10px;
    margin-top: -2px;
    min-width: 30px;
    max-width: 50px;
    text-transform: none;
    background: transparent;
    color: $blue;
    border: 1px solid $blue;

    &:hover {
        background: $navy;
        border-color: $navy;
        color: $white;
    }
}

.dt--partner-workspace {
    .dt-cell {
        // flex-basis: auto;
        // box-sizing: border-box;
        // flex-grow: 1;
        // flex-shrink: 0;
        // &--doi {
        //     width: $DT_ID;
        // }

        // &--name {
        //     flex-grow: 1;
        //     flex-shrink: 1;
        //     min-width: 160px;
        // }

        // &--partnerEdgeReviewDate {
        //     width: $DT_DATE;
        // }

        // &--partnerEdgeReviewedBy,
        // &--assetType,
        // &--partnerEdgeStatus {
        //     width: $DT_OTHER;
        // }


        &--actions {
            width: $DT_OTHER;
            display: flex;
            justify-content: flex-start;

            &__container {
                display: flex;
                align-items: center;
                .asset-actions{
                    display: flex;
                    align-items: center;
                    .asset-usage {
                        margin-right: 20px;
                        float: left;
                        font: bold 9px BentonSans, Arial, sans-serif;
                        padding: 5px 10px;
                        .asset-usage--container {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }

            .actions__container + .actions__container {
                margin-left: 8px;
            }
        }
    }

    .sort-controls__item--actions {
        border-right: 1px solid $greyDefault;
    }
}

@include media-ipad-portrait {
    .dt-cell--expirationDate {
        display: none !important;
    }
}

.cabinet-main-container{
    .asset-detail-container{
        margin: 30px;
        position: relative;
        &__info {
            min-height: 470px;
        }

        .tabs__panel {
            width: 58%;
            margin-top: 50px;
            max-height: 266px;
            overflow-y: auto;
        }

        .asset-detail__actions-container {
            top: 50px;
        }

        .asset-detail__preview {
            box-sizing: border-box;
            top: 50px;
            right: 0;
            position: absolute;
            max-width: 310px;
            max-height: 310px;

            .preview-container{
                background: $greyLight7;
                width: 310px;
                height: 310px;
                position: relative;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }

                .video-js {
                    margin: auto;
                }
                .video-tooltip {
                    display: none;
                }
                &.focused {
                    .video-tooltip {
                        display: block;
                        width: 225px;
                        background: $black;
                        font-size: 10px;
                        color: $white;
                        border-radius: 4px 0 4px 4px;
                        position: absolute;
                        padding: 5px;
                        left: -243px;
                        text-align: center;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: -10px;
                            width: 0;
                            height: 0;
                            border-top: 16px solid $black;
                            border-right: 16px solid transparent;
                        }
                    }
                }
            }
        }

        .buttons-panel {
            display: flex;

           .button--action {
                margin-right: 10px;
                cursor: pointer;
                flex-shrink: 0;
           }
        }

        .asset-button-actions{
            .pe-asset-reject{
                margin-left: 6px;
                color: $navy;
                background-color: $white;
                box-shadow: $grayShadow1;
            }
        }

        .reject-comment-title {
            padding: 10px 0 5px 0;
            display: block;
            font-size: 13px;
            color: $black;
            margin-top: 30px;
            svg {
                margin-right: 8px;
                path {
                    fill: $red;
                }
            }
        }

        .reject-comment-container{
            height: 195px;
            margin-top: 10px;
            box-shadow: $grayShadow1;

            .reject-comment{
                margin: 10px;
                width: 98%;
                border: none;
                font-size: 13px;
                line-height: 1.8;
            }

            .reject-comment-buttons{
                display: flex;
                position: absolute;
                bottom: 10px;
                right: 10px;

                .button--cancel{
                    margin-left: 5px;
                }
            }
        }
    }
}



.approving-confirmation-modal{
    .modal-positioner{
        height: 100%;
    }
    .modal{
        width: 440px;

        .modal__content{
            height: 128px;

            .approving-confirmation-text{
                height: 44px;
                width: 371px;
                color: $black;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                position: absolute;
            }

            .button--cancel{
                position: absolute;
                left: 38%;
                bottom: 30px;
            }
        }
    }
}
