.assets-grid__item__type,
.assets-list__item__type {
    display: inline-block;
    padding: 2px 5px;
    font: 9px Arial, sans-serif;
    background: $greyDarker4;
    color: $defaultColor;
    text-transform: uppercase;
}

.load-more {

    &:hover{
        color: $defaultColor;
        background-image: linear-gradient(to top, $greyDarker4 0%, $greyDarker4 100%);
    }

    &--container {
        width: 100%;
        padding: 10px 0px;
        box-sizing: border-box;
        position: relative;

        .load-more {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid $greyDarker4;
            color: $greyDarker;
            height: 50px;
            text-transform: uppercase;
            text-align: center;
            font: normal 18px $BrandMedium;
            background-image: linear-gradient(to top, $greyDarker4 0%, $greyLight7 100%);
        }

        .result-counter {
            position: absolute;
            top: -15px;
            width: 100%;
            text-align: center;
            color: $greyDarker;

            .span {
                color: $defaultColor;
            }
        }
    }

    &--container-vs-results {
        margin-top: 25px;
    }

    &--pagination {
        display: flex;
        justify-content: flex-end;

        .load-more--paging {
            background: transparent;
            border: none;
            color: $blue;
            box-sizing: border-box;
            min-width: 17px;
            width: auto;
            height: 25px;
            padding: 0 3px;
            font-size: 13px;
            line-height: 25px;
            font-weight: normal;
            font-family: Aarial,sans-serif;
            text-align: center;

            &.load-more--active {
                color: $navy;
                font-size: 15px;
                line-height: 25px;
                font-weight: bold;
                pointer-events: none;
            }

            &:hover {
                color: $navy;
            }
        }

        .load-more--hidden {
            display: none;

            & + .load-more--paging {
                text-align: right;

                &:before {
                    padding: 0 11px 0 6px;
                    display: inline-block;
                    content: '...';
                    height: inherit;
                    line-height: inherit;
                    pointer-events: none;
                    z-index: 1;
                }
            }
        }

        .load-more {
            background: none;
            border: 1px solid $greyDarker4;
            color: $blue;
            width: 32px;
            height: 25px;
            font: 13px/13px Arial, sans-serif;
            text-transform: none;
            text-align: center;
            box-sizing: border-box;

            &:hover {
                color: $navy;
            }

            &:first-child {
                margin-right: 20px;
            }

            &:last-child {
                margin-left: 20px;
            }
        }
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: default;
    }
}

.assets-region__nothing-found {
    text-align: center;
    color: $blackLight;
    display: block;
    margin: 0 auto;
    padding: 60px 0;
    font-size: 24px;
    line-height: 30px;
    svg {
        display: block;
        margin: 35px auto 0 auto;
        path {
            fill: $greyDarker4;
        }
    }
}

.picto-message__wrapper {
	height: 100%;
	width: 100%;
    font-size: 24px;
    line-height: 30px;

	td {
	    overflow: hidden;
	    vertical-align: middle;
	}

    margin: 0 auto;
    padding: 5% 0;

    .picto-message__picto {
        padding: 2%;
		width: 30%;

		div {
			max-height: 80%;
			min-width: 70px;
		}
        img {
            width: 100%;
            height: auto;
        }
    }

    .picto-message__line {
        width: 2px;
        height: 100%;

        span {
            display: block;
            height: 90%;
            min-height: 250px;
            width: 2px;
            background-color: $greyDarker4;
            margin: 4% 0;
        }
    }

    .picto-message__text {
        padding: 2% 6%;
        width: 70%;

        a {
            color: $orange;
        }
    }
}

.asset-list__item-lang.mac-dropdown {
    display: inline;
    width: auto;
    height: 14px;
    margin: 13px 0 0 5px;

    .mac-dropdown__wrapper {
        display: inline;
    }

    .mac-dropdown__trigger {
        border: none;
        margin: 0;
        height: inherit;
        width: auto;
        background: $orangeLight;
        color: $orange;
        display: inline-block;
        padding: 2px 5px;
        font: 9px bold Arial, sans-serif;
        text-transform: uppercase;

        &:after {
            display: none;
        }

        &:hover {
            background: $orangeLight;
        }
    }

    &.mac-dropdown--opened {
        .mac-dropdown__trigger {
            background: $orange;
            color: $white;

            &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                box-sizing: border-box;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                border-top: 4px solid $orange;
                border-bottom: none;
                position: absolute;
                top: 14px;
                right: 5px;
            }
        }

        .mac-dropdown__body {
            top: 20px;
            width: 130px;
            left: 50%;
            transform: translateX(-50%);
            padding-right: 11px;

            .asset-actions__item__text {
                clear: both;
                margin: 5px 0;
                line-height: 15px;
                white-space: pre-line;
                width: 100%;
                text-align: left;

                &:hover {
                    cursor: pointer;
                    color: $black;
                    text-decoration: underline;
                }
            }
        }
    }
}

