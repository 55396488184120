.cmps-publishing-form {
    max-width: calc(100vw - 20px);

    &.full-width {
        width: 100%;
        height: calc(100vh - 140px);

        .tabs {
            margin-top: 0;

            .carousel-panels {
                background: transparent;

                .carousel-panel {
                    height: calc(100vh - 220px) !important;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin: 20px 0;

        button {
            margin-right: 10px;
        }
    }
}
