.buttons-list {
    button,a {
        position: relative;
        overflow: visible;
        font-size: 11px;
        height: 30px;

        &:first-child {
            margin-right: 15px;
            padding: 0 10px;

            &:after {
                content: '';
                width: 10px;
                height: 28px;
                background: $blue;
                position: absolute;
                top: -1px;
                right: -6px;
                transform: skew(-20deg);
                border: 1px solid $blue;

            }
        }

        &:last-child {
            padding: 0 10px;

            &:after {
                content: '';
                width: 10px;
                background: $blue;
                position: absolute;
                top: -1px;
                left: -6px;
                transform: skew(-20deg);
                border: 1px solid $blue;
                height:28px;
            }
        }

        &:hover, &.active {
            background: $navy;
            border-color: $navy;

            &:after {
                background: $navy;
                border-color: $navy;
            }
        }
    }

    a {
        &:last-child {
            &:after {
                height:30px;
            }
        }
    }
}
