// every new style(.scss) file should be imported in - app/styles/main.scss
// like this: @import "../scripts/templates/style";

.monitoring_page {

    .action {
        padding: 15px 10px;

        button {
            display: inline-block;
            margin-right: 25px;
        }
    }

    .resource {
        .resource-links {
            .copy-link {
              display: inline-block;
              margin-right: 25px;
            }

            a {
                font-family: $BrandMedium;
                font-size: 14px;
                margin-right: 20px;
            }
        }
    }

    .copy-link__form {
        width: 600px;
    }

}
