.base-redux-modal {
    &.asset-statistic--modal {
        .base-modal {
            .base-modal--body {
                height: 393px;
            }
        }
        .apexChart {
            padding: 0 15px;
        }
        .apexcharts-xaxistooltip {
            padding: 6px 0px;
        }
    }

    .asset-statistic-description {
        margin-top: 40px;
        font-size: 16px;
        text-align: center;
    }
}