

.upload-type-modal {
    .upload-widget {
        padding: 0 0 10px 0;
        margin-bottom: 10px;
    }

    .upload-type {
        &--container {
            &.files-added {
                .upload-type-link {
                    display: none;
                }

                .upload-type--content {
                    top: 15px;
                    margin-top: 0;
                    width: 380px;
                    margin-left: -190px;

                    .upload-type--placeholder {
                        font-size: 13px;

                        svg, br {
                            display: none;
                        }
                    }
                }
            }

            &.link-focused {
                .upload-type--content {
                    .upload-type--placeholder {
                        opacity: 0.2;
                    }
                }
            }
        }

        &--content {
            position: absolute;
            width: 490px;
            height: 130px;
            top: 50%;
            left: 50%;
            margin-left: -245px;
            margin-top: -65px;
        }

        &--placeholder {
            display: flex;
            font-family: $BrandMedium;
            font-size: 16px;
            line-height: 26px;
            color: $black;
            margin-bottom: 40px;

            &-text {
                text-align: left;
            }

            svg {
                margin-right: 30px;
                width: 60px;
                height: 43px;
                path {
                    fill: $blue;
                }
            }

            .pseudo-link {
                color: $blue;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &--drop-area {
            width: 100%;
            border: 1px dashed $greyDarker;
            background: $greyLight7;
            padding: 15px;
            position: relative;
            box-sizing: border-box;
            margin-bottom: 30px;
            min-height: 240px;

            .added-files-container {
                margin-top: 30px;
                overflow: hidden;
                max-height: 400px;
                overflow-y: auto;

                .added-file-tile {
                    float: left;
                    width: 120px;
                    height: 80px;
                    margin: 18px;
                    position: relative;

                    .remove-file {
                        position: absolute;
                        top: -8px;
                        right: 30px;
                        cursor: pointer;

                        svg {
                            width: 15px;
                            height: 15px;

                            path {
                                fill: $greyDarker;
                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    fill: $defaultColor;
                                }
                            }
                        }
                    }

                    .file-preview {
                        width: 40px;
                        height: 60px;
                        display: block;
                        margin-left: 40px;

                        img {
                            width: 100%;
                        }
                    }

                    .file-name {
                        display: block;
                        text-align: center;
                        font-family: $BrandRegular;
                        font-size: 11px;
                        color: $black;
                    }
                }
            }
        }

        &--buttons {
            display: flex;
            justify-content: flex-end;

            button, .button--action {
                margin: 0 0 0 10px;
            }
        }

        &-link {
            .input-pair__title-box {
                font-size: 13px;
                text-transform: none;
                color: $black;
                font-weight: normal;

                &__text:after {
                    display: none;
                }
            }

            .input-pair__input-box {
                width: 340px;
            }

            .base-text-input {
                background: $white;
                width: 340px;
            }
        }
    }
}


