$layoutLightGrey: $greyLight7;

.partner-workspace-page{
    height: 100%;
    padding: 0 10px;

    .dt-cell--partnerEdgeStatus .filter-form-wrap {
        width: auto;
    }

    .base_block_select_dropdown {
        position: relative;
        &--selector {
            &:hover {
                background-color: $greyDarker2 !important;
            }

            .arrows {
                position: initial;
                display: inline-flex;
                margin-left: 5px;
                vertical-align: top;
                margin-top: 2px;
            }
        }

        &--options {
            padding: $indentBasis;
            min-width: 280px;
            right: -19px;
            left: auto;
            top: 37px;

            .base-checkbox {
                padding: 10px 0;
            }

            .base-checkbox__label {
                line-height: 16px;
                font-family: $BrandRegular;
                font-weight: normal;
                margin-left: $indentBasis;
            }
        }
    }

    .partner-workspace-header--wrapper {
        .partner-workspace-header {
            position: relative;
            box-shadow: none;

            .partner-workspace-header__buttons-wrapper {
                display: flex;
                position: relative;
                justify-content: flex-end;
                align-items: center;
                vertical-align: middle;
            }
        }
    }

    .partner-workspace-table-container {
        padding: 0;

        .partner-workspace-table-controls {
            .bulk-actions {
                margin-left: 15px;
                min-width: 99px;
            }
        }

        .dt--partner-workspace {
            .dt__item {
                .assets-list__item__details-link {
                    color: $blue;
                    font-weight: $BrandMedium;
                }

                .partner-workspace-status {
                    display: flex;

                    span {
                        margin-right: 5px;
                        height: 15px;
                        width: 10px;
                        border-radius: 3px;
                    }
                }
            }

            .base-checkbox__label {
                font-size: 14px;
            }

            .dt-cell--actions {
                width: 64px;
                max-width: 64px;
                min-width: 50px;
                padding-right: 0px;
                display: flex;
                flex-grow: 0;
                justify-content: flex-end;

                .asset_actions_dropdown {
                    margin-left: calc(#{$indentBasis} * 2);
                }
            }

            .button--action.review-link {
                border: none;
                box-shadow: none;
            }

            .sort-controls__item__label {
                font-family: $BrandMedium;
                color: $blackLight;
            }

            .dt-cell--name {
                width: 300px;
            }
        }
    }
}

.asset-approval-controls {
    background-color: #fff;
}

.asset-approval-controls--wrapper {
    position: relative;
}

.asset-approval-comment {
    height: 165px;
    justify-content: flex-end;
    border-top: 1px solid $greyDarker2;
    background-color: $greyLight7;

    .reject-comment-title {
        display: flex;
        margin: 20px 20px 2px 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
    }

    .reject-comment-container {
        textarea {
            display: flex;
            margin: 0px 20px 12px 20px;
            height: 65px;
            width: 548px;
            border: 1px solid $greyDarker2;
            border-radius: 3px;
            background-color: $white;
            padding: 5px;
        }
    }
}

@import './style_responsive';
