.semaphore-changes__controls-region {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin: 15px 0 30px;

    .right-side,
    .left-side {
        display: flex;
        align-items: center;
    }

   .left-side {
         margin-right: -23px;
    }

    .input-pair {
        width: 240px;
        margin: 0 15px 0 0;
        padding: 0;
        position: relative;

        &__input-box {

            width: 166px;
            flex: 1;
        }
    }

    .base-checkbox {
        margin: 0 7px 0 0;
        align-self: center;
    }

    .base-checkbox__label {
        white-space: nowrap;
    }

    .reload-button,
    .history-button,
    .clear-cache-button {
        color: $blue;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-transform: capitalize;
        text-decoration: underline;
        font-size: 12px;

        svg {
            margin-right: 8px;
        }

        path {
            fill: $blue;
        }

        &:hover {
            color: darken($blue, 20%);

            path {
                fill: darken($blue, 20%);
            }
        }
    }

    .reload-button svg {
        width: 20px;
        height: 16px;
    }

    .history-button svg {
        width: 19px;
        height: 19px;
    }

    .clear-cache-button svg {
        width: 17px;
        height: 17px;
    }
}

//HISTORY TABLE

.history-modal {
    .modal {
        min-height: 602px;
        height: auto;

        .modal__footer {
            display: none;
        }
    }
}

//END HISTORY TABLE

// SEMAPHORE REDESIGN
.semaphore-changes {
    &--lastUpdate {
        font-size: 1.4rem;
        line-height: 24px;
    }

    .cabinet-header h2 {
        padding-right: 20px;
        border-right: 1px solid $blackLight;
    }
    .semaphore-changes--dashboardSelector {
        margin-left: 10px;
        width: 220px;
        font-family: $BrandMedium;
        margin-top: 2px;

        .semaphore-dashboards-selection {
            padding-bottom: 0;
        }

        .Select-control {
            background: transparent;
            border: none;
            &:hover {
                box-shadow: none;
            }
        }
        .Select-clear-zone {
            display: none;
        }
    }
    .semaphore-changes--changeType {
        text-transform: capitalize;
        display: flex;
        align-items: center;
        line-height: 15px;
        svg {
            width: 16px;
            margin-right: 15px;
        }
    }
    .semaphore-changes--pageActions {
        margin-left: auto;
        display: flex;
        span {
            display: block;
        }
        .semaphore-changes--actionButton {
            margin-left: calc(#{$indentBasis} * 2);
        }
    }
    .dt-cell--nameInSemaphore, .dt-cell--nameInDgl {
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
        }
    }
}
.semaphore-changes--details {
    .semaphore-changes--detailsField {
        display: flex;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
        align-items: flex-start;
        .field-title {
            color: $greyDarker3;
            width: 170px;
            flex-shrink: 0;
        }
        .modal-link {
            text-transform: none;
        }
        .collapse {
            border: none;
        }
    }
    .semaphore-changes--levelDetails {
        padding: 12px 16px;
        background-color: $greyLight6;
        border: $classicBorder;
        margin-top: $indentBasis;
        margin-bottom: calc(#{$indentBasis} * 2);

        &:last-child {
            padding-bottom: 0;
        }
        .semaphore-changes--detailsField:last-child {
            font-family: $BrandMedium;
        }
    }
    .semaphore-changes--showAffected {
        padding: 0;
        height: 22px;
    }
}

.affected-assets-modal {
    .base-modal {
        width: 100vw;
        height: 100%;
    }
    .base-modal--body {
        height: 100%;
    }
    .dt-cell {
        >span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
        }
    }
    .affected-assets-modal--contentOwner {
        display: flex;
        align-items: center;
        svg {
            path {
                fill: $blue
            }
        }
        a {
            display: flex;
            align-items: center;
            margin-right: 15px;
            &:hover {
                text-decoration: none;
            }
        }
        span {
            line-height: 17px;
        }
    }
    &--heading {
        display: flex;
    }
    .assets-list__item__details-link {
        color: $blue;
    }
    .dt__sort-controls .dt-cell {
        padding-left: 0;
    }
}
