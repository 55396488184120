.generate-urls-modal {
    .base-modal {
        padding: 0;
    }

    &.base-modal--container {
        .base-modal--header-inner {
            padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 3);
            border-bottom: $classicBorder;
        }

        .base-modal {
            width: 718px;
            height: 592px;
            max-height: 100%;
        }

        .base-modal--body {
            max-height: 100%;
            height: calc(100% - 52px);
            padding: 0;
        }

        .base-modal--header {
            background-color: #fff;
        }

        .close-modal-button {
            right: 24px;
            top: 16px;
            .material-icons {
                color: $greyDefault;
            }
        }
    }

    .base-modal--header {
        padding: 0;
        h2 {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .tabs-list {
        width: 250px;
        background-color: $greyLight6;
        .tabs-list-scroll {
            margin-left: 1px;
        }
    }

    .tabs-list-scroll {
        .outer {
            height: 100%;
        }
    }

    .tabs-list--inner {
        height: 100%;
        padding: 12px calc(#{$indentBasis} * 3);
        box-sizing: border-box;
    }

    .form-container {
        width: auto;
        flex: 1;
        position: relative;
        border-left: none;
        background-color: #fff;
    }

    .generate-urls {
        &--list {
            .tab-item {
                width: calc(100% - 36px);
                margin-bottom: $indentBasis;
                padding: 12px calc(#{$indentBasis} * 2);
                background-color: $white;
                box-shadow: none;
                border-top: $classicBorder;
                border-right: $classicBorder;
                border-bottom: $classicBorder;
                box-sizing: border-box;

                &--selected {
                    padding-right: 42px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: $indentBasis;
                    path {
                        fill: $greyDarker3;
                    }
                }

                .material-icons {
                    font-size: 16px;
                    color: $greyDarker3;
                    margin-right: $indentBasis;
                }

                &:not(.tab-item--selected):hover {
                    background: $hoverBg;
                }
            }
            .tab-item-container {
                justify-content: flex-start;
            }
        }
        &--container {
            height: 100%;
        }
        &--form-wrapper {
            height: 100%;
            .copy-button-wrapper {
                button {
                    &:disabled {
                        color: $greyDarker3 !important;
                        border: none !important;

                        svg {
                            path {
                                fill: $greyDefault !important;
                            }
                        }
                    }
    
                    svg {
                        max-width: 16px;
                        max-height: 16px;
                        path {
                            fill: $saladGreenDark !important;
                        }
                    }
                }
            }
            .radio-group {
                display: block;
            }
            .base-radio {
                display: block;
                &--checked {
                    .base-radio__label {
                        font-family: $BrandMedium;
                    }
                }
                &__label {
                    font-family: $BrandRegular;
                }
                &__button {
                    display: flex;
                    align-items: center;
                }
            }
        }
        &--title {
            font-family: $BrandMedium;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: $indentBasis;
        }
        &--description {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 11px;
        }
        &--auth {
            display: flex;
            align-items: center;
            background-color: #fff;
            .green {
                color: #247230;
            }
            .divider {
                background-color: $greyLight2;
                width: 1px;
                height: 16px;
                margin: 0 $indentBasis;
            }
            span {
                font-family: $BrandMedium;
                color: $greyDarker3;
            }
        }
    }

    .form-container--scroll {
        height: calc(100% - 40px);
        padding: calc(#{$indentBasis} * 3);
        box-sizing: border-box;
    }

    .modal-notification {
        padding: 0 calc(#{$indentBasis} * 2) 0 0;
        position: static;
        left: 0;
        bottom: 0;
        border-top: none;
    }

    .generate-urls-bottom {
        width: 100%;
        padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 720px) {
    #app .base-redux-modal.generate-urls-modal .base-modal {
        max-height: 100%;
        height: 100%;
        max-width: none;
        display: flex;
        flex-direction: column;
        transition: all .3s;
        .base-modal--header-inner {
            padding-bottom: calc(#{$indentBasis} * 2);
        }
        .base-modal--body {
            padding: 0;
        }
        .mobile-profile--section {
            padding-top: 6px;
        }
    }
    #app .generate-urls-modal.base-modal--container .base-modal--header .close-modal-button {
        border-radius: 0;
        width: 24px;
        height: 24px;
        top: 15px;
        right: 17px;
        position: fixed;
    }
    #app .generate-urls-modal {
        .tabs-list,
        .form-container {
            width: 50%;
        }

        &.is-profiles-screen {
            .base-modal {
                transform: translate(-50%, 0px);
            }
        }
    }
}

.modal-notification {
    width: 100%;
    padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 3);
    position: absolute;
    left: 0;
    bottom: 40px;
    font-size: 12px;
    line-height: 18px;
    border-top: $classicBorder;
    color: $greyDarker3;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    svg {
        width: 16px;
        height: 16px;
        margin-right: calc(#{$indentBasis} / 2);
    }
    .asset-usage {
        span {
            color: $greyDefault;
        }
    }
    .icon-wrapper {
        height: 20px;
        margin-right: calc(#{$indentBasis} / 2);
        path {
            fill: $blue2;
        }
    }
    .warning,
    .info {
        svg {
            margin-right: calc(#{$indentBasis} / 2);
        }
    }
    .warning {
        svg {
            path {
                fill: $orange;
            }
        }
    }
    .info {
        svg {
            path {
                fill: #1B90FF;
            }
        }
    }
}