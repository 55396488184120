.exported-xls-page {

    .bulk-actions {
        height: 30px;
        margin: 18px 0 13px 0;

        .bulk-actions--dropdown {
            .dropdown-body {
                left: 0;
            }
        }
    }


    .xls-export-history {
        .dt-cell, .xls-export-item {
            &--actions, &__actions {
                float: none;
                display: flex;
                padding: 0;
                min-width: 170px;
                flex-basis: 170px;
                flex-wrap: wrap;

                .button--action {
                   margin-left: calc(#{$indentBasis} * 2);
                }
            }

        }
    }

    .export-xls-header {
        padding: 25px 30px 20px 30px;
        display: flex;
        justify-content: space-between;

        &--wrapper {
            margin: 0 -20px;
            background-color: $greyLight7;
            border-bottom: $classicBorder;
        }

        &--left {
            display: flex;
            justify-content: flex-start;
        }

        .export-xls-icon {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            background-color:$white;
            justify-content: center;
            border-radius: 25px;
            border: 1px solid $greyDarker2;

            img {
                width: 50px;
            }
        }

        h3 {
            color: $blackLight;
            font-family: $BrandMedium;
            font-size: 24px;
            font-weight: bold;
            line-height: 35px;
            padding-left: 15px;
            padding-top: 10px;
        }
    }
}

.button--action {
    &.selectAllBtn {
        width: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        border-radius: 4px 4px 0 0;
    }
}
