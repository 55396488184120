@media #{$mobile} {
    .region--top {
        padding: 0 15px;

        .aside-header-box {
            .notifications {
                margin-left: 10px;
            }

            &.aside-header-box--left {
                width: 100%;
            }

            &.aside-header-box--right {
                width: auto;
            }
        }
    }
}

@media #{$mobileSmall} {
    .region--top {
        .user-profile {
            margin-left: 10px;
        }

        .aside-header-box {
            width: auto;

            &.aside-header-box--left {
                width: 100%;
            }

            .notifications {
                margin-left: 5px;
            }

            h1 {
                margin-right: 10px;
                font-size: 13px;
            }

            .menu-trigger,
            .logout-button .user-profile--logout {
                margin-right: 10px;
            }
        }

        .sap-logo {
            width: 40px;
        }

        .search-input-mobile {
            &--closed {
                .button--action {
                    padding: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .region--top {
        .page--width-wrapper {
            &.wrapper--withSearch {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}