.agency_add_dropdown .asset-actions-popup,
.asset-actions-popup {
    .copy-url-tooltip {
        .copy-url-tooltip--unonimous--link,
        .copy-url-tooltip--Internal--link,
        .copy-url-tooltip--profile--link,
        .copy-url-tooltip--review--link {
            line-height: 30px;
            word-break: initial;
            word-wrap: initial;
        }
    }
    .template-content__copy-url {
        max-width: 100%;
        word-wrap: break-word;
        height: 0;
        overflow: hidden;
    }

    // .dropdown-body .button--action.with-icon,
    .button--action.with-icon {
        width: 100%;
        height: 37px;
        padding: 8px 25px 8px 40px;
        border-radius: 0;
        text-align: left;
        justify-content: flex-start;
        box-shadow: none;
        font-family: $BrandRegular;
        color: $blackLight;
        font-size: 14px;
        line-height: 21px;
        text-transform: none;
        position: relative;
        box-sizing: border-box;

        svg {
            margin-right: 10px;
            path {
                fill: $greyDarker3;
            }
        }

        .material-icons {
            width: 16px;
            font-size: 16px;
            text-align: center;
            color: $greyDarker3;
            position: absolute;
            left: calc(#{$indentBasis} * 2);
            top: 10px;
            display: block;
        }

        span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover,
        &.pressed {
            .button--action__toolTip {
                display: none !important;
            }

            &:not(.dropdown-title){
                background-color: $greyLight2;
            }
        }
    }
}

.asset-actions-popup {
    position: absolute;
    width: $dropdownMenuWidth;
    padding: 8px 0;
    right: 45px;
    top: 0;
    z-index: 11;
    background: $white;
    box-shadow: $dropdownShadow;
    border-radius: $dropdownBorderRadius;

    @media #{$IE} {
        width: auto;
        max-width: 390px;
    }

    .asset-actions-popup__close {
        text-align: right;
        margin: 0px 12px 5px auto;
        width: 56px;

        &:hover {
            svg {
                path {
                    fill: $greyDefault;
                }
            }
        }
    }

    .asset-actions-popup__close__icon {
        vertical-align: middle;
    }

    .asset-actions-popup__close__text {
        cursor: pointer;
        vertical-align: middle;
        padding-right: 5px;
        color: $greyDefault;
    }

    .asset-actions__item--popup-mode {
        display: block;
    }
    .asset-actions__item--icon-mode {
        display: none;
    }
    /* End if actions... */

    .hidden-action {
        display: none;
    }

    .actions__container.actions__container--links {
        padding: 0;
        .button--action.asset-links-trigger {
            svg {
                path {
                   fill: $blackLight;
                }
            }
            &:after {
                position: absolute;
                right: 10px;
                top: 8px;
                font-family: 'Material Icons', Arial, sans-serif;
                font-size: 19px;
                content: 'arrow_right';
                color: $greyDarker3;
                font-feature-settings: "liga" 0;
            }
        }
        .asset-actions-popup {
            right: calc(#{$dropdownMenuWidth} + 5px);
            width: $dropdownMenuWidth;
            box-sizing: border-box;
        }
    }

    .asset-actions__links--grid-view {
        & > .asset-actions-popup {
            right: 0;
            left: 100%;
        }
    }

    &.asset-actions-popup--nested-down {
        .asset-actions-popup {
            padding-left: 0;
            margin-bottom: 5px;
            box-shadow: none;
            border-bottom: $classicBorder;
            position: relative;
            width: 100% !important;
            left: 0 !important;
            right: auto !important;
            box-sizing: border-box;
        }

        .actions__container--links {
            margin: 0;
        }
    }
}

.asset-actions--divider {
    margin: 8px 0;
    border-top: $classicBorder;
    padding: 0 !important;
}

.asset_actions_dropdown {
    .dropdown {
        padding-bottom: 0;
    }
}

.dropdown {
    .dropdown-body__content__items {
        .button--action {
            .material-icons {
                margin-right: $indentBasis;
            }
        }
    }
    .dropdown {
        float: none;
    }
}

.search-results--container {
    .playlist-item-container {
        .actions__container--links {
            & > .asset-actions-popup {
                right: 0;
                left: 100%;
            }
        }
    }    
}

@import "./style_responsive";
