@mixin modal($width, $titleColumnWidth, $inputColumnWidth) {
    .modal {
        width: $width;
    }

    .input-pair--no-label {
        padding-left: $titleColumnWidth;
    }

    .input-pair__title-box {
        width: $titleColumnWidth;
    }

    .input-pair__input-box {
        width: $inputColumnWidth;
    }

    .modal__footer__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

%form-modal {
    @include modal(560px, 25%, 60%);

    .button--action {
        margin-right: 10px;
    }
}

%form-modal--small {
    @include modal(400px, 20%, 80%);
}

%form-modal--medium {
    @include modal(500px, 20%, 80%);

    .button--action {
        margin-right: 10px;
    }
}
