@media #{$mobile} {
    .aside-modal.manage-metadata-modal,
    .aside-modal.manage-presets-modal,
    .generate-urls-modal {
        width: calc(100% * 2);
        max-width: none;
        transition: all 0.5s ease;

        .upload-asset-modal__btn-group {
            position: fixed;
        }

        .aside-modal--body {
            height: calc(100% - 54px);

            .manage-metadata--container {
                height: 100%;

                .manage-metadata--metadata {
                    height: 100%;
                }
            }
        }

        .mobile-profile--section {
            display: flex;
            position: absolute;
            left: 50%;
            top: 15px;
            margin-right: -45px;
            width: 50%;
            box-sizing: border-box;
            padding: 0 60px 0 10px;
            align-items: center;
            vertical-align: middle;

            .back_to_files--button {
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 10px;
            }

            h2 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.is-profiles-screen {
            right: -100%;
        }

        .asset-preview {
            margin-top: 0;
        }
    }

    .metadata-profile-options .react-select-box-options--opened {
        padding-top: 30px;
        box-shadow: none;
        box-sizing: border-box;
    }

    .metadata-profile-options .react-select-box-options .button--action.close_select_options {
        top: 8px;
        right: -7px;
    }
}

@media #{$iPad} {
    .metadata-profile-options .react-select-box-container {
        width: 150px;
    }

    .tabs.wizard .tabs__panel,
    .tabs.wizard .wizard-tabs-footer {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .tabs.wizard .wizard-tabs-footer .tabs_wizard--progress {
        visibility: hidden;
        height: 30px;
        width: 0;
    }

    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container--header {
        padding-left: 10px !important;
    }
}
