$darkRed: $red;

html, body {
    height: auto !important;
    min-height: 100%;
}
.bmo-reports-page-wrapper {
    padding: 10px 0 0 0;

    .buttons-list {
        margin-bottom: 20px;
        position: relative;
        z-index: 4;
        display: inline-block;
    }
}

.charts-area.same-height {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .chart-container-wrapper {
        width: 49%;
        background: $greyLight7;
        display: flex;
        align-items: flex-end;
    }
}

.charts-area.names {
    display: flex;
    justify-content: space-between;
    margin: 0;
    clear: both;

    h4 {
        width: 49%;
    }
}

.bmo-report-initializer-wrapper {
    position: relative;
    margin-bottom: -20px;
}
