.material-icons {
    &.md-6 {font-size: 6px}
    &.md-7 {font-size: 7px}
    &.md-8 {font-size: 8px}
    &.md-9 {font-size: 9px}

    &.md-10 {font-size: 10px}
    &.md-11 {font-size: 11px}
    &.md-12 {font-size: 12px}
    &.md-13 {font-size: 13px}
    &.md-14 {font-size: 14px}
    &.md-15 {font-size: 15px}
    &.md-16 {font-size: 16px}
    &.md-17 {font-size: 17px}
    &.md-18 {font-size: 18px}
    &.md-19 {font-size: 19px}
    &.md-20 {font-size: 20px}

    &.md-21 {font-size: 21px}
    &.md-22 {font-size: 22px}
    &.md-23 {font-size: 23px}
    &.md-24 {font-size: 24px}
    &.md-25 {font-size: 25px}
    &.md-26 {font-size: 26px}
    &.md-27 {font-size: 27px}
    &.md-28 {font-size: 28px}
    &.md-29 {font-size: 29px}
    &.md-30 {font-size: 30px}

    &.md-31 {font-size: 31px}
    &.md-32 {font-size: 32px}
    &.md-33 {font-size: 33px}
    &.md-34 {font-size: 34px}
    &.md-35 {font-size: 35px}
    &.md-36 {font-size: 36px}
    &.md-37 {font-size: 37px}
    &.md-38 {font-size: 38px}
    &.md-39 {font-size: 39px}
    &.md-40 {font-size: 40px}

    &.md-60 {font-size: 60px}
}
