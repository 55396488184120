.tabs-container {
    display: flex;

    .tabs-list {
        width: 30%;
        background :$greyLight6;
        border-right: $classicBorder;

        &--inner {
            padding: 0 3*$indentBasis 2*$indentBasis 0;
        }
    }

    .form-container {
        width: 70%;
        background-color: #fff;
        // border-left: $classicBorder;

        &--scroll {
            width: 100%;
            height: calc(100vh - 128px);
            padding: 3*$indentBasis;
            box-sizing: border-box;
        }
    }
}

.tab-item {
    @extend %button-white;
    cursor: pointer;
    color: $blackLight;
    font-family: $BrandRegular;
    font-size: 14px;
    padding: 7px 7px 7px 15px;
    border: $classicBorder;
    border-radius: 4px;
    margin-bottom: $indentBasis;
    border-left: 4px solid $greyDarker2;
    position: relative;
    box-sizing: content-box !important;
    width: calc(100% - 25px);
    word-break: break-all;
    list-style: none;
    outline-offset: 2px;

    &.tab-item--selected {
        box-shadow: none;
        border: $classicBorder;
        border-left: 4px solid $blue;
        cursor: default;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-top: 6px; // border compensation
        padding-bottom: 6px; // border compensation
        padding-right: 30px;

        &:hover {
            background: $white;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
    }

    &--name {
        display: flex;
        justify-content: flex-start;
        word-break: break-word;
    }

    &--actions {
        min-height: 28px;
        margin-left: 10px;
        flex-shrink: 0;
        flex-grow: 0;
        max-width: 35px;
        display: flex;
        align-items: center;

        .curators-dropdown {
            width: auto;
            z-index: auto;
        }
    }

    svg {
        width: 16px;
        margin-right: $indentBasis;
        path {
            fill: $greyDarker3;
        }
    }

    &--primary__icon {
        margin-left: 6px;
        display: flex;
        align-items: center;
        vertical-align: middle;
        margin-top: -1px;

        svg {
            margin-right: 4px;
            path {
                fill: $orange;
            }
        }
    }
}

@media #{$mobile} {
    .tabs-container {    
        .tabs-list,
        .form-container {
            width: 50%;

            .form-container--scroll {
                width: auto;
                display: block;
                // height: calc(100% - 36px);
            }
        }
    }
}