$loginPanelWidth: 600px;

%login-form-background {
    background-color: $navy;
    background-position: 56px 98px;
    background-repeat: no-repeat;
    background-image: url("../../styles_images/login_bg.svg");
    background-size: cover;
}

.page--login {
    min-height: 100vh;
    padding-top: 0;
    display: flex;
    background: $white;
    &__description {
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        flex-grow: 1;
        word-break: break-word;

        .sap-logo {
            min-width: 60px;
            width: 64px;
            height: 32px;
            margin: 32px;
            position: absolute;
        }

        &__text {
            font-size: 14px;
            line-height: 22px;
            flex-grow: 1;
            display: flex;
            align-items: center;

            .description-wrapper {
                margin: auto;
                font-size: 16px;
                line-height: 24px;
                color: $blackLight;
                padding: 0 $indentBasis;
            }
            .description__title {
                font-size: 56px;
                line-height: 72px;
                font-family: $BrandBold;
                margin: 16px 0;
                &--blue {
                    color: $blue;
                }
            }
            .description__subtitle {
                line-height: 32px;
                font-size: 22px;
                font-family: $BrandBold;
            }
        }
    }
    &__form {
        box-sizing: border-box;
        position: relative;
        height: 100vh;
        overflow: hidden;
        width: calc(#{$loginPanelWidth} + 19px);
        min-width: calc(#{$loginPanelWidth} + 19px);
        @extend %login-form-background;

        &__internal {
            height: 100px;
            width: calc(#{$loginPanelWidth} + 18px);
            position: fixed!important;
            bottom: 0;
            right: 0;
            z-index: 101;
            background: $greyLight5;
        }

        &__login {
            width: calc(#{$loginPanelWidth} + 18px);
            max-width: calc(#{$loginPanelWidth} + 18px);
            position: fixed;
            height: 100vh;
            top: 0;
            right: 0;
            z-index: 102;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            @extend %login-form-background;
            
            &--logon-form {
                z-index: 100;
            }
        }
        &__loader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            
            .login-form-section {
                padding: 0;
            }
        }
    }
    &__dashboard-button-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .dashboard-button--image {
            display: flex;
            justify-content: center;
            width: 72px;
            margin: 0 auto $indentBasis;
        }

        .button--action {
            width: 100%;
            font-weight: bold;
            font-size: 15px;
            text-transform: none;
        }

        .dashboard-button--message {
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            margin-bottom: 2*$indentBasis;
            font-family: $BrandMedium;
        }
    }
}

.login-form-section {
    font-family: $BrandRegular, Arial, sans-serif;
    padding: 4*$indentBasis;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    max-width: 515px;
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    color: $blackLight;
    &__header {
        line-height: 32px;
        font-size: 22px;
        margin-bottom: 2*$indentBasis;
        font-family: $BrandMedium;
    }
    &__description {
        color: $greyDarker3;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 2*$indentBasis;
    }
    &__validation-message {
        font-size: 14px;
        color: $red;
    }
    .input-pair {
        display: block;
        margin-bottom: 0;
        &__title-box {
            font-family: $BrandMedium;
            font-weight: 400;
            font-size: 14px;
            display: block;
        }
        &__input-box {
            display: block;
        }
    }
    .button--action {
        font-size: 14px;
        line-height: 24px;
        width: 100%;
    }
    .internal-login-btn {
        margin-right: auto;
        margin-top: 2*$indentBasis;
        width: initial;
    }
    .admin-login-form__buttons {
        margin-top: 4*$indentBasis;
    }
}


.login-mmessege--failed {
    display: block;
    margin-top: 60px;
    font-size: 12px;
    padding: 10px;
    color: $red;
    text-align: center;
    font-family: $BrandRegular;
}

.showDashboardLinkOnly {
    .ids-wrap.ids-wrap--fixed {
        visibility: hidden !important;
    }
}

.showSsoLoginButtonOnly #ids_container {
    display: none;
}

.ids-overlay {
    display: none;
}

.ids-wrap {
    height: auto !important;
    position: absolute!important;
    top: 0;
    bottom: 100px!important;
    width: calc(#{$loginPanelWidth} + 20px) !important;
    z-index: 100!important;
    right: 0 !important;
    left: auto !important;
    overflow-y: auto!important;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box!important;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %login-form-background;
}

.not-admin-login {
    .ids-wrap {
        height: 100% !important;
    }
}

.ids-overlay-container {
    //box-shadow: none!important;
    //border-radius: 0 !important;
    //position: relative!important;
    //width: calc(#{$loginPanelWidth} - 1px) !important;
    //max-width: calc(#{$loginPanelWidth} - 1px) !important;
    margin: auto!important;
    box-shadow: none !important;
    width: 100%;

    &:after {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background: $white;
        position: absolute;
        z-index: 101;
        right: 0;
        top: 0;
    }
}

.ids-overlay-close {
    display: none!important;
}


.aside-modal-transition-enter {
    max-width: 0;
    overflow: hidden;
}

.aside-modal-transition-enter-active {
    max-width: $loginPanelWidth;
    transition: max-width 0.3s;
}

.aside-modal-transition-leave {
    max-width: $loginPanelWidth;
}

.aside-modal-transition-leave-active {
    max-width: 0;
    transition: max-width 0.3s;
    overflow: hidden;
}

.admin_button_wrapper {
    position: absolute;
    bottom: 4*$indentBasis;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1111;
}

@import "./login_page_responsive.scss";
