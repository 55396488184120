@media #{$mobile} {
    #app {
        .modal-root-node {
            .base-modal {
                width: 100%;
                margin: 0;
                box-sizing: border-box;
                position: absolute;
                bottom: auto;
                top: 50vh;
                transform: translate(0px, -50%);
                border-radius: 0;
                min-height: auto;
                &--body {
                    // max-height: 55vh;
                    max-height: calc(100% - 50px);
                    overflow-y: scroll;
                    padding: 20px;
                    .tooltip__container {
                        display: none;
                    }
                }
                &--header {
                    position: relative;
                    .modal__close {
                        position: absolute;
                        top: -45px;
                        right: 20px;
                        background: $white;
                        width: 34px;
                        height: 34px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        svg {
                            max-width: 12px;
                            max-height: 13px;
                        }
                    }
                }
            }
            .modal-positioner {
                overflow-y: visible;
                .modal {
                    width: 100%;
                    margin: 0;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: auto;
                    top: 50vh;
                    transform: translate(0px, -50%);
                    border-radius: 0;
                    min-height: auto;
                    &__content {
                        max-height: 55vh;
                        overflow-y: scroll;
                        padding: 20px;
                        .tooltip__container {
                            display: none;
                        }
                    }
                    &__header {
                        position: relative;
                        .modal__close {
                            position: absolute;
                            top: -45px;
                            right: 20px;
                            background: $white;
                            width: 34px;
                            height: 34px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            svg {
                                max-width: 20px;
                                max-height: 20px;
                            }
                        }
                    }
                }
            }
            .notification-modal {
                .modal__header {
                    height: 0;
                    padding: 0;
                    border: 0;
                    &__text {
                        display: none;
                    }
                }
                &--success {
                    .modal__footer {
                        display: none;
                    }
                }
                &--error {
                    .modal {
                        .modal__header {
                            .modal__close {
                                svg {
                                    path {
                                        fill: $blackLight;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Redux modal from coresap

        .base-modal--container {
            .base-modal {
                width: 100% ;
                bottom: 0!important;
                transform: none;
                left: 0!important;
                right: 0!important;
                top: auto!important;
                border-radius: 0;
            }
        }

        .base-redux-modal {
            .base-modal {
                max-height: 60vh;
                display: flex;
                flex-direction: column;

                .base-modal--header-inner {
                    padding-bottom: 0;
                }

                .base-modal--body {
                    flex: 1;
                }
            }
        }
    }

    html.modal--opened {
        .feedback-menu {
            z-index: -1;
        }
    }
}