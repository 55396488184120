.upload-wrapper__dnd {
    box-sizing: border-box;
    height: 100vh;
    padding-right: 0;

    &--content {
        padding: 2*$indentBasis 3*$indentBasis 0;
        min-height: 100vh;
        box-sizing: border-box;
        // border-right: $classicBorder;
    }

    &__title {
        font-family: $BrandMedium;
        color: $blackLight;
        font-size: 16px;
        text-transform: capitalize;
        line-height: 24px;
        flex-grow: 1;
        margin-bottom: 2*$indentBasis;
    }
    .upload-content {
        padding-bottom: 50px;
        // padding-right: 25px;

        &--holder {
            border: $classicBorder;
            background: $white;
            padding: 6px calc(#{$indentBasis} * 2) 3px;
            border-radius: 3px;
            margin-bottom: calc(#{$indentBasis} * 2);
        }

        .upload-type--drop-area {
            background: $white;
            height: 125px;
            min-height: 125px;
            display: flex;
            margin: 10px 0;
            border: 1px dashed $inputBorderColor;
            border-radius: 4px;
            vertical-align: middle;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                border-color: $blue;
            }

            &--disabled {
                opacity: 0.4;
                cursor: default;

                .pseudo-link {
                    &:hover {
                        text-decoration: none !important;
                        cursor: default !important;
                    }
                }

                &:hover {
                    border-color: $greyDarker2;
                }
            }
            .upload-type--content {
                display: flex;
                justify-content: center;
                position: static;
                flex-grow: 1;
                margin-left: 0;
                margin-top: 20px;
                .upload-type--placeholder {
                    display: block;
                    margin-bottom: 0;
                    padding: 10px 30px;

                    svg {
                        path {
                            fill: $greyDarker2
                        }
                    }

                    &-text {
                        font-family: $BrandRegular;
                        text-align: center;
                        font-size: 14px;
                        line-height: 20px;
                        margin: 10px 0;
                        color: $blackLight;
                        display: block;
                        .pseudo-link {
                            color: $blue;
                            cursor: pointer;
                            border: none;
                            box-shadow: none;
                            background: none;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                                color: $navy;
                                text-underline-offset: 4px;
                            }

                            &:focus {
                                outline-offset: 2px;
                            }
                        }
                    }
                    svg {
                        display: block;
                        height: 29px;
                        width: 40px;
                        margin: auto;
                    }
                }
            }
        }

        &--wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            &>div {
                width: 100%;
            }
            &.bigZone {
                .upload-type--drop-area {
                    height: 180px;
                }
            }
        }

        .one-drive-upload {
            position: absolute;
            bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            &--text {
                color: $greyDarker3;
                font-size: 12px;
            }
            svg {
                width: 25px;
            }
            &--btnWrapper {
                display: flex;
            }
            
            &--btn {
                padding-left: 5px;
                color: $blue;
                font-family: $BrandRegular;

                &:hover {
                    background-color: transparent;
                    color: $navy;
                    text-underline-offset: 4px;
                }

                span:hover {
                    text-decoration: underline;

                }
            }
        }

        .continue-button {
            display: flex;
            justify-content: flex-end;
            .white-btn {
                float: right;
                margin: 10px 0;
            }
        }

        .upload-type-link {
            .input-pair {
                display: block;
                margin-bottom: 0;
                &__title-box {
                    font-family: $BrandMedium;
                    font-size: 14px;
                    color: $blackLight;
                    width: 100%;
                    display: block;
                    &__text {
                        color: $blackLight;
                    }
                }
                &__input-box {
                    display: block;
                    width: 100%;
                    .base-text-input {
                        width: 100%;
                    }
                }
            }
            &__controls {
                display: flex;
                justify-content: flex-end;
                margin-bottom: $indentBasis;
                &__error-message {
                    color: $red;
                    flex-grow: 1;
                }
            }
        }
    }
    .pw_upload_compliance {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: $greyLight6;
        .compliance_info {
            margin: 8px 24px;
        }
    }

    .pw_upload_sumbit {
        display: flex;
        padding: 2*$indentBasis 3*$indentBasis;
        border-top: $classicBorder;
    }
}

.pw-upload {
    .upload-wrapper__dnd .upload-content {
        padding-bottom: 112px;
    }
}

.or-line {
    display: flex;
    justify-content: space-around;
    margin: calc(#{$indentBasis} * 2) 0 $indentBasis 0;
    border-top: $classicBorder;
    &--inner {
        background: $white;
        margin-top: -9px;
        padding: 0 calc(#{$indentBasis} * 2);
    }
}
