.fullscreen-modal {
    width: 100%;
    height: 100vh;
    background-color:$greyLight7;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;

    .fullscreen-modal-header {
        width: 100%;
        padding: 17px 32px;
        background-color: $white;
        position: relative;
        box-sizing: border-box;

        .curators-dropdown {
            margin-left: -20px;
            margin-top: 1px;
        }

        .assets-page--controls {
            padding-bottom: 0;
        }
    }

    .fullscreen-modal-title--wrap {
        display: flex;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;

        .fullscreen-modal-title {
            margin-right: 30px;
            white-space: nowrap;
        }
    }

    .fullscreen-modal-title {
        font-family: $BrandMedium;
        font-size: 14px;
        line-height: 21px;
        color: $blackLight;
    }

    .fullscreen-modal-container {
        padding: 23px 32px 38px;
        flex: 1;
        box-sizing: border-box;
    }

    .close-btn {
        position: absolute;
        top: 22px;
        right: 15px;

        &.button--action:not(.dropdown-title) {
            .material-icons {
                font-size: 20px;
                color: $greyDefault;
            }
        }
    }

    .header-btn {
        position: absolute;
        top: 22px;
        right: 50px;
        z-index: 2;

        &.button--action:not(.dropdown-title) {
            .material-icons {
                font-size: 20px;
            }
            &:disabled {
                .material-icons {
                    color: $greyDarker;
                }
            }
        }

        .button--action__toolTip {
            &.button--action__toolTip--bottom {
                margin-right: 0;
            }
        }
    }
}
