.page {
    padding-top: $topRegionHeight;
    box-sizing: border-box;

    & > header {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 100;
    }
}

.region--top {
    width: 100%;
    height: $topRegionHeight;
    background: $white;
    border-bottom: $classicBorder;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    padding: 8px 32px 8px 36px;

    .workspace-links {
        margin-top: 10px;
        padding-top: 10px;
        padding-left: 20px;

        .input-pair__title-box {
            font-size: 13px;
            color: $blackLight;
        }

        .input-pair__input-box {
            .dropdown {
                .dropdown-title {
                    font-size: 13px;
                    color: $blackLight;
                }

                .button--action {
                    &.active {
                        background: $greyLight7 !important;
                    }
                }
            }
        }

        .button--action {
            width: 100%;
        }
    }

    .page--width-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 3;

        @supports (display: grid) {
            &.wrapper--withSearch {
                display: grid;
                grid-template-columns: 1fr minmax(200px, 640px) 1fr;
            }
        }

        .logo {
            border-right: none;
            padding-left: 20px;
        }
    }

    .aside-header-box {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &--right {
            justify-content: flex-end;
            padding-left: 16px;
        }

        &--left {
            padding-right: 16px;
        }

        h1 {
            color: $blackLight;
            font-size: 14px;
            line-height: 21px;
            font-family: $BrandMedium;
            letter-spacing: -0.2px;
            margin-left: 4px;
        }

        .menu-trigger {
            margin-left: -7px;
            position: relative;
        }


        .menu-trigger,
        .notifications {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px;
        }

        .notifications-dropdown-title {
            width: 24px;
            height: 24px;
            margin-left: 18px;
            border-radius: 50%;

            .dropdown {
                padding: 0;
                height: 24px;
            }
        }

        .logout-button {
            .user-profile--logout {
                width: auto;
                padding: 5px;
                margin-right: -5px;
                margin-left: 15px;
                border-radius: 4px;

                .user-profile-item__icon {
                    margin-right: 0;

                    svg {
                        width: 18px;
                        height: 18px;
                    }

                    path:first-child {
                        fill: $greyDefault;
                    }
                }
            }
        }
        .outreach_close {
            background: $greyLight7;
            border-radius: 17.5px;
            box-shadow: none;
        }
    }

    .user-profile {
        margin-left: 18px;
    }

    .search-text-input--global {
        input {
            padding-right: 50px;
        }
    }
}

.select-opened {
    .region--top {
        & > .page--width-wrapper {
            z-index: 2;
        }
    }
}

.logo {
    flex-grow: 0;
    flex-shrink: 0;
    
    a {
        outline-offset: 2px;
    }

    .title {
        display: block;
        margin-bottom: 7px;
    }

    .logo-text {
        color: $greyDefault;
        position: absolute;
        bottom: 0px;
        cursor: default;
        font-size: 9px;
    }
}

.sap-logo {
    width: 48px;
}

.top-links {
    height: inherit;

    & > .top-links__item {
        padding: 0;
        margin: 0 20px 19px 0;
    }

    &.without-dropdown {
        margin-right: 60px;
        .top-links__item {
            margin: 0;
            padding: 0;
        }
        align-content: center;
        .black_and_white_toggle {
            margin-right: 30px;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .dropdown.top-links__item {
        height: 32px;
        margin-left: 0;
        &:hover {
            color: $black;
        }
    }
    .dropdown-title {
        display: flex;
        align-items: center;
        height: auto;
        font: 14px/20px $BrandRegular;
        border: none;
        color: $blackLight;
        background: transparent;
        &:after {
            content: '';
            position: absolute;
            top: 19px;
            right: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid;
            color: $blackLight;

        }
        &:hover {
            background: transparent;
        }
        svg {
            rect {
                fill: $blackLight;
            }
        }
    }
    .top-links__item {
        &.opened {
            .dropdown-title {
                &:after {
                    border-top: none;
                    border-bottom: 6px solid;
                }
            }
        }
        .edit-link {
            position: absolute;
            right: 12px;
            color: $blue2;
        }
    }
    .dropdown-body {
        width: $dropdownMenuWidth;
        top: 37px;
        min-width: fit-content;

        > :not(.logout):hover {
            background: transparent;
        }
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .cabinet-header__dropdown_group {
        border-bottom: 1px solid $greyDarker4;
        margin: 0;
        padding: 16px 10px 10px 14px;

        li {
            list-style: none;
            font-size: 13px;
            line-height: 20px;
            > span {
                width: 50px;
                display: inline-block;
                font-family: Tahoma, Arial, sans-serif;
                font-weight: bold;
                font-size: 9px;
                text-transform: uppercase;
                color: $greyDarker3
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
        &.logout {
            background: $greyLight7;
            padding: 0;
            .top-links__item--log {
                padding: 14px 10px 15px 23px;
                &:hover {
                    color: $black;
                    .top-dropdown-icon--logout {
                        svg {
                            path {
                                fill: $greyDefault;
                            }
                        }
                    }
                }
            }
        }
        .top-dropdown-icon--logout {
            margin: 3px 23px 0 1px;
            svg {
                path {
                    fill: $greyDarker4;
                }
            }
        }
        .top-links__item {
            margin-left: 0;
        }
    }
}

.top-links__item-invisible{
    color: $black !important;
    cursor: default !important;
}

.top-links__item {
    display: block;
    margin-left: 21px;
    padding-top: 6px;
    text-decoration: none;
    position: relative;
    color: $greyDarker;
    &:hover{
        color: $greyDarker4;
        text-decoration: underline;
    }
    &.active {
        color: $greyDarker4;
        &:hover {
            text-decoration: none;
        }
    }

    .modular {
        display: none;
    }
}

@import "./style_responsive";
