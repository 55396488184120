.reporting--page {
    .cabinet-main-container {
        max-width: 955px;
        width: 955px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.reports-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 115px 12.269979%;
}

.reports-menu__item {
    display: inline-block;
    margin: 5px;

    button {
        color: $defaultColor;
        text-transform: uppercase;
        font-weight: bold;
        padding: 29px 0 27px;
        background: $greyDarker4;
        width: 219px;
        display: inline-block;
        font-size: 14px;

        &:hover {
            color: $white;
            background: $blue;
        }
    }
}

.reports-menu__item--paragraph {
    width: 100%;
    font-size: 14px;
    color: $greyDefault;
    line-height: 21px;
    margin-top: 28px;
    text-shadow: 0px 0px 0px $greyDarker4;
    padding: 0 5.80696487%;

    &:first-of-type {
      margin-top: 54px;
    }
}


#abc {
    // Used campaign report for this size
    min-width: 944px;
    margin-left: -10px;
    background: $white;
    position: relative !important;
}

.xc-rootCanvas {
    position: relative;
}


#abc > div {
    margin-left: 10px;
}

.ui-datepicker-trigger {
    line-height: 21px !important;
    background: $greyDarker4;
    padding: 0 5px;
}

.xc-htmlComboBox-labelEl {
    border-radius: 0 !important;
    background: $white !important;
}

.xc-htmlCombox-labelFocus {
    box-shadow: none !important;
}

.xc-htmlComboBox-arrowBoxEl {
    color: transparent !important;
    text-shadow: none !important;

    &:after {
        color: $black !important;
        position: absolute !important;
        left: 0 !important;
        top: 4px !important;
        @extend %arrow-down;
    }
}

.xc-textinput {
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: 2px solid $greyBlueDark !important;

    img {
        display: none;
    }
}

.xc-ScoreCard {
    & div:first-child {
        & div:first-child {
            & div:nth-child(4) {
                div {
                    & div:first-child {
                        white-space: pre-wrap !important;
                    }
                    & div:last-child {
                        display: none !important;
                    }
                }
            }
        }
    }
}

// Increase datatable title height
.xc-ScoreCard > div + div {
    min-height: 20px;
}

// Fix cut-off edges of radio button
.xc-radiobutton-input-container {
    left: 0 !important;
    margin-right: 2px !important;
}
.xc-radiobutton-label {
    left: 14px !important;
}
