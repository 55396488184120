.files-for-upload {
    border-radius: 4px;
    position: relative;

    &__item {
        border: $classicBorder;
        position: relative;
        padding: 17px;
        display: flex;
        margin-bottom: 2*$indentBasis;
        background: $white;
        border-radius: 4px;

        @extend %hover-bg-annimation;

        &.is-ready-to-edit:not(.files-for-upload__item--active):hover {
            background: $hoverBg;
            cursor: pointer;

            .button--action.files-for-upload__item--remove {
                &:hover {
                    background: $white;
                }
            }
        }

        &--active {
            border-color: $greyDarker2;
            margin-right: -30px;
            padding-right: 47px;
            box-shadow: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .button--action.files-for-upload__item--remove {
                right: 35px;
            }
        }

        &__image {
            display: block;
            height: 61px;
            max-width: 45px;
            min-width: 45px;
            margin-right: 0.5*$indentBasis;
        }

        &__info {
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;

            .info-name {
                font-family: $BrandMedium;
                font-size: 14px;
                color: $blackLight;
                line-height: 16px;
                padding-right: 10px;
            }
            .info-size {
                font-size: 12px;
                color: $greyDarker3;
            }
        }
    }

    .files-for-upload__item--remove {
        width: 30px;
        position: absolute;
        top: 9px;
        right: 5px;
    }
}
