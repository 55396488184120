$bottomPanelHeigth: 64px;

.bottom-panel {
    position: fixed;
    bottom: 0;
    z-index: 10000;
    background: $white;
    width: 100%;
    box-shadow: $grayShadow3;
    height: $bottomPanelHeigth;

    @include bottomPanelAnimation($bottomPanelHeigth);

    &-actions {
        padding: 12px 4*$indentBasis;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bulk_mobile_actions--dropdown {
            &:not(.dropdown--mobile) {
                .dropdown-body {
                    bottom: 24px;
                    top: auto;
                    right: 0;

                    .dropdown-body__content__items > *:not(.button--action) {
                        padding: 0;
                    }
                    .bottom-panel-actions--manage:hover {
                        background: transparent;
                    }

                }
            }
        }

        .bulk_mobile_wrapper {
            padding: 4px 0 4px 4px;
            & > * {
                flex-grow: 0;
                flex-shrink: 1;
                min-width: 0;

                &.button--action,
                .button--action {
                    margin-right: $indentBasis;
                    text-transform: none;
                }
            }

        }

        .asset-actions__item--popup-mode {
            display: block;
        }

        .asset-actions__item--icon-mode {
            display: none;
        }

        .export_to_xls--container, &--manage-group, &--manage {
            display: flex;
        }
    }

    .coresap-loader {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
        .svg-loader {
            font-family: $BrandRegular;
            font-size: 14px;
        }
    }
}

.bulk_mobile_wrapper {
    display: flex;
    width: calc(100% - 200px);
    overflow: hidden;
}

.dropdown.bulk_mobile_actions--dropdown {
    position: absolute;
    top: 20px;
    right: 4*$indentBasis;
    .dropdown-body__content__close {
        width: 24px;
        height: 24px;
        padding: 2px;
    }
}
