.maintenance-warning-container, .maintenance-info-container {
    padding: 25px 15%;
    display: flex;
    position: relative;
    margin: 0;
    z-index: 0;

    svg {
        flex-grow: 0;
        flex-shrink: 0;
        width: 42px;
        margin-right: 20px;
    }
    .close-button {
        @extend %mac-close;
        position: absolute;
        top: 0;
        right: 0;
    }
    p {
        font-size: 13px;
        line-height: 16px;
        padding-top: 5px;
    }
}

.page--cabinet {
    background-color: $pageBgColor;
    .maintenance-warning-container, .maintenance-info-container {
        margin: -2px -20px 0 -20px;
    }
}

.page--login {
    .maintenance-warning-container, .maintenance-info-container {
        margin: -10px -20px 0 -20px;
    }
}

.maintenance-warning-container {
    border-top: 10px solid $red;
    background: $redLight;
}

.maintenance-info-container {
    border-top: 10px solid $saladGreen;
    background: $saladGreen;
}

.maintenance-warning-container-block + .maintenance-warning-container-block {
    .maintenance-warning-container, .maintenance-info-container {
        margin: 0;
    }
}

.maintenance-error-modal {
    .modal {
        width: 1074px;
    }
    .modal__content {
        padding: 0;

        .maintenance-error-container {
            border-top: 10px solid $red;
            display: flex;
            padding: 50px 15%;

            svg {
                flex-grow: 0;
                flex-shrink: 0;
                width: 42px;
                margin-right: 20px;
            }

            .maintenance-error-content {
                flex-grow: 1;
                flex-shrink: 1;

                h1 {
                    color: $red;
                    font: 18px/20px $BrandMedium;
                    margin-bottom: 15px;
                }

                dl {
                    font-size: 14px;
                    line-height: 22px;
                    color: $black;

                    dt {
                        font-weight: bold;
                        float: left;
                        clear: both;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
