@media #{$mobile} {
    .publish-form-modal {
        .publish-form-container {
            .tabs-publishing-steps.tabs.manage-asset-form {
                padding: 15px!important;
                padding-right: 0!important;

                .collapse-body {
                    padding: 0;
                }

                .publishing-steps__item__controls {
                    margin-right: 0;

                    .button-step {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}