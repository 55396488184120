@media #{$mobile} {
    .replace-asset-content-modal.base-modal--container {
        .base-modal {
            max-width: none;
            &--header {
                font-size: 0!important;
                line-height: 0;
                padding: 0;
            }
            &--body {
                padding: 10px 20px;
            }
        }

        .file-preview {
            .files-for-upload__item__image {
                margin-right: 10px;
                height: 40px;
                min-width: auto;
            }
            &__name {
                font-size: 13px;
            }
            &__size {
                font-size: 11px;
            }
        }
    }
}