@media screen and (max-width: 836px) {
    .ids-wrap {
        width: 100% !important;
    }
    .ids-overlay-container {
        width: 100% !important;
        padding-top: 0 !important;
    }
    .page--login__description {
        display: none;
    }
    .page--login__form__login {
        width: 100%;
        max-width: 100%;
    }
    .page--login__form {
        width: 100%;
        min-width: 100%;
        flex-grow: 1;
        &__internal {
            width: auto;
            left: 0;
        }
        .login-form-section {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .aside-modal-transition-enter {
        max-width: 0;
        overflow: hidden;
    }

    .aside-modal-transition-enter-active {
        max-width: 100%;
        transition: max-width 0.3s;
    }

    .aside-modal-transition-leave {
        max-width: 100%;
    }

    .aside-modal-transition-leave-active {
        max-width: 0;
        transition: max-width 0.3s;
        overflow: hidden;
    }
}

@media screen and (min-width: 836px) and (max-width: 1024px) {
    .ids-overlay-container {
        margin-top: 0 !important;
    }
}

@media only screen and (min-width: 544px) {
    body {
        .ids-overlay-container.ids-overlay-container--theme_fiori {
            max-width: 515px;
        }
    }
}