$firsMenuHoverColor: $greyDarker2;
$subMenuHoverColor: $greyDarker2;
$navigationBg: $greyLight7;

.no-page-header {
    .page-header {
        display: none;
    }
}

.page-header {
    background: $greyLight7;
    position: relative;

    &__inner {
        min-height: 68px;
        background: $white;
        box-sizing: border-box;
        position: relative;

        .page-header__content {
            border-bottom: 3px solid $orange;
            display: flex;
            height: 53px;
            box-sizing: content-box;
        }
    }

    &__left {
        width: 200px;
        z-index: 1;
        height: 60px;
        position: relative;
    }

    &__middle {
        width: 240px;
    }

    &__right {
        margin: 0 0 0 20px;
        flex: 1;
        position: relative;
        display: flex;
        justify-content: flex-end;

        .search {
            top: 7px;
            width: 550px;
            right: 0;
            background: $white;
            padding-top: 10px;
            padding-left: 5px;

            &.search--ambassador {
                width: 540px;
            }

            .spinner--search {
                right: 90px;
                height: 28px;
            }

            .search-controls-wrapper {
                height: 34px;
            }

            .quick-search__input-wrapper {

                .base-text-input {
                    &.quick-search__input {
                        background: $white;
                        font-size: 14px !important;
                        width: calc(100% - 74px);
                        border: 1px solid $greyDarker4 !important;
                    }
                }
            }

            .search__controls__button {
                background: none;
                color: $blue;
                box-shadow: none;
                font-size: 12px;
                text-align: right;
                top: auto;
                height: 25px;
                bottom: 5px;
                width: 78px;
                text-indent: 0;

                &:after {
                    display: none;
                }
            }

            &.search--active {
                .search-controls__clear-input {
                    background: $white;
                    height: 34px;
                    right: 78px;
                }
            }
        }

        .quick-search__dropdown {
            top: 42px;
            left: 5px;
        }
    }

    &__permissions-message {
        display: flex;
        padding-left: 10px;
        margin-top: 26px;

        .permissions-message__role {
            font-weight: bold;
        }

        .icon {
            margin: 0 7px 0 0;

            svg {
                width: 17px;
                height: 15px;
            }

            path {
                fill: $greyDefault;
                fill-rule: evenodd;
            }
        }

        .text {
            line-height: 16px;
            font-family: Arial, sans-serif;
            font-size: 13px;
        }
    }

    &__text {
        font-size: 18px;
        color: $black;
        font-family: $BrandMedium;
        line-height: 22px;
    }
}

.dashboard-link {
    font-family: $BrandRegular;
    text-transform: capitalize;
    display: inline-block;
    font-size: 15px;
    line-height: 14px;
    padding: 10px 10px 10px 20px;
    color: $black;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    @extend %hover-bg-annimation;

    &:hover {
        background: $firsMenuHoverColor;
    }
}

.navigation-main-container {
    position: relative;
    z-index: 10;
}

.assets-page-header {
    padding: 12px 0px 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.workspaces-wrapper {
        display: block;
    }

    & &--left {
        margin-right: 20px;
        flex-grow: 0;
        flex-shrink: 0;

        h3 {
            display: block;
            padding-top: 0;
            font-family: $BrandMedium;
            font-size: 20px;
            font-weight: bold;
            line-height: 34px;
            color: $blackLight;
            align-self: center;
        }
    }
}

.assets-page--controls {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .items-counts {
        font-family: $BrandRegular;
        font-size: 14px;
        line-height: 32px;
        color: $greyDefault;
        padding: 0px;
    }
}

@media screen and (max-width: 425px) {
    .assets-page--controls {
        flex-direction: column;
        align-items: flex-end;

        .search-text-input--wrapper {
            width: 100%;

            .search-text-input {
                width: 100%;
                min-width: 100px;
            }
        }

        .items-counts {
            display: none;
        }
    }
}

@include media-ipad {
    .header_navigation {
        .sales-dashboard-navigation__inner {
            padding-right: 10px;
        }
        .sales-dashboard-navigation__item {
            flex-grow: 1;
            width: auto;
            min-width: 125px;
        }
    }
}
