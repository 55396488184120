@media #{$mobile} {
    .partner-workspace-page {
        padding: 0;

        .sort-controls__item.dt-cell.dt-cell--actions {
            .sort-controls__item__label {
                text-indent: -9999px;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .dt--partner-workspace {
        &.assets-table {
            .dt-cell--src {
                display: none;
            }
        }
    }
}
