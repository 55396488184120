.video_preview_container {
    .video_preview_title {
        font-size: 18px;
        color: $white;
        line-height: 27px;
        position: absolute;
        z-index: 1;
        top: 40px;
        left: 40px;
    }
    .image_preview_body {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image_preview_container {
        width: initial;
        height: initial;
    }
    .button--action.video-preview-close-btn {
        position: absolute;
        top: 36px;
        right: 36px;
    }
    .playlist {
        min-width: 80vw;
        min-height: 60vh;
    }
    &.playlist_preview {
        .image_preview_container {
            padding: 0;
            overflow: hidden;
        }
        .video_preview_title {
            top: 15px;
            left: 15px;
        }
        .playlist_videos--dropdown-wrapper {
            right: 45px;
        }
        .button--action.video-preview-close-btn {
            top: 7px;
            right: 10px;
        }
        .playlist_videos--dropdown .dropdown-body {
            height: 60vh;
            position: absolute;
            top: -10px;
            right: -40px;
        }
    }
    .video-js {
        max-width: 100vw;
        max-height: 100vh;
    }
    @media screen and (max-width: 1280px) {
        .video-js {
            width: 640px;
            height: 360px;
        }
    }

    @media screen and (max-width: 640px) {
        .video-js {
            min-width: auto;
            min-height: auto;
        }
    }

    @media screen and (max-width: 1280px) and (min-width: 640px) {
        .video-js, .playlist {
            min-width: 640px;
            min-height: 360px;
        }
    }

    @media screen and (min-width: 1280px) {
        .video-js, .playlist {
            min-width: 1280px;
            min-height: 720px;
        }
    }
}