.notification-modal-list {

    &__header-box {
        margin: 0 0 6px 0;
    }

    &__list-box {
        padding: 0 0 0 20px;
        color: $greyDefault;
        font-size: 14px;
        line-height: 21px;
    }

    .modal__content {
        display: flex;
        padding: 36px 50px 23px 58px
    }

    &-item {
        &__value {
            max-width: 100%;
            display: inline-block;
            color: $black;
            padding: 0 5px;
            text-transform: uppercase;
            font-family: Tahoma, sans-serif;
            font-weight: bold;
            font-size: 11px;
            line-height: 20px;
            margin: 0 5px 6px 0;

            &--ERROR {
                background: $redLight;
            }

            &--INFO {
                background: $saladGreenLight;
            }

            &--FIELDS {
                background: none;
                font-family: Arial, sans-serif;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                padding: 0;
                border-bottom: 1px dashed $blue;
                display: inline;
                text-transform: none;
            }
        }
    }

    .modal {
        width: 496px;
    }

    .modal__footer {
        padding: 0 20px 41px 110px;
        text-align: left;
    }
}
