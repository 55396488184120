.content-store-container,
.manage_users_modal,
.rejection_reason-tooltip{
    .users-list--wrapper {
        height: 100%;
    }

    .users-list {
        &-item {
            display: flex;
            align-items: center;
            padding: 8px 0 8px 30px;
            position: relative;

            &--select {
                width: 220px;
                flex-grow: 0;
                flex-shrink: 0;
                margin-bottom: 0;
            }

            &:hover {
                background: $greyLight5;
                padding-right: 70px;
                transition: .5s;
            }

            &--icon {
                @extend %avatar;
                margin-right: 16px;
            }

            &--remove {
                position: absolute;
                top: 50%;
                margin-top: -16px;
                right: 18px;
                display: none;
                opacity: 0;
                height: 32px;
            }

            .user-data__name {
                font-size: 12px;
                line-height: 21px;
                color: $greyDefault;
            }
        }
    }

    .user-avatar {
        margin-right: $indentBasis;
    }
}

.content-store-container {
    .users-list--wrapper {
        // height: 300px;
        margin-bottom: 15px;
    }

    .users-list-item {
        padding-left: 10px;
    }

    .users-list-item--name {
        flex-grow: 0;
        flex-shrink: 0;
        width: 170px;
        padding-right: 70px;
    }
}

.manage_users_modal {
    .aside-modal__body {
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
    }

    .users-list--search {
        padding: 0 30px 10px 30px;
        margin-bottom: 8px;
    }

    .users-list {
        height: 100%;
        padding: 0 24px;
    }

    .outer {
        height: 100%;

        .coresap-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -30px;
        }
    }
}