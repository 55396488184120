$panelWidth: 264px;
$headerHeight: 64px;

.folders-collapse-wrapper {
    .folders_tree_collapse_title, .folder-button-wrapper {
        & > svg,
        & > div > svg {
            max-width: 18px;
            max-height: 18px;
            flex-grow: 0;
            flex-shrink: 0;

            path {
                fill: $greyDarker;
            }
        }
    }

    .collapse-button{
        position: relative;
        word-break: break-all;
        text-align: left;
    }

    .folders_tree_collapse_title {
        span {
            line-height: 23px;
            padding: 0 $indentBasis;
        }
    }
}

.folders-modal {
    .collapse-button {
        padding: 5px 0;
    }
    .collapse.collapse--with-arrow .collapse-toggle {
        .collapse-toggle__title {
            font-family: $BrandRegular;
        }
    }
    .collapse.collapse--with-arrow .collapse-toggle .collapse-toggle__icon .collapse-toggle__icon__arrow {
        top: 2px;
    }
    .collapse.collapse--with-arrow.collapse--opened {
        padding-bottom: 0;
    }

    .collapse.collapse--with-arrow .collapse-toggle .collapse-toggle__icon {
        margin-top: 3px;
        margin-left: 5px;
    }
    .folders-collapse-wrapper {
        height: 100%;
    }
    .folders-modal__input{
        margin-bottom: 15px;
        position: relative;
        .base-text-input {
            border-radius: 25px;
            padding-left: 16px;
            padding-right: 40px;
        }
    }
    .folders-modal__inputIcon {
        position: absolute;
        right: 16px;
        top: 10px;
        color: $greyDarker3;
        font-size: 22px;
    }
    .preview-modal.content-store .content-store-step-container--scrollable {
        height: calc(100vh - 200px);
    }
    .folder-for-move {
        background: none;
        box-shadow: none;
        min-width: 0;
        color: $greyDefault;
        font-family: $BrandRegular;
        font-size: 14px;
        margin: 0;
        padding: 0;
        width: 100%;

        & > svg {margin-left: $indentBasis}

        &.button--action{
            margin: 0;
        }
        &.indent-{
            &0 {
                margin-left: 0;
            }
            &1 {
                margin-left: 20px;
            }
            &2 {
                margin-left: 40px;
            }
            &3 {
                margin-left: 60px;
            }
            &4 {
                margin-left: 80px;
            }
            &5 {
                margin-left: 100px;
            }
            &6 {
                margin-left: 120px;
            }
            &7 {
                margin-left: 140px;
            }
        }
        .collapse-button {
            &:hover {
                background-color: $hoverBg;
            }
        }
    }
    .collapse-toggle__title {
        padding-right: 10px;
    }
    .folders_tree_collapse_title {
        display: flex;
        align-items: center;
    }
    .folder-button-wrapper, .folder-for-move--wrapper {
        display: flex;
        align-items: flex-start;
        .item-image-icon {
            width: 24px;
            height: 24px;
        }
    }
    .folder-for-move--wrapper {
        .base-checkbox {
            margin-top: 9px;
            margin-right: 8px;

            .base-checkbox__icon {
                margin-right: 0;
            }
        }
    }
    .folder-for-move--wrapper .checkbox {
        .collapse-button:after {
            left: 9px;
        }
    }
    .collapse--tree-view.collapse--opened {
        > .collapse-button:after {
            border-color: $white;
        }
        .tree-collapse-childrens .folder-button-wrapper:last-child {
            margin-bottom: 0;
        }
    }
    .collapse--tree-view.collapse--opened .collapse--tree-view {
        margin-left: -2px;
    }
    .folder-button-wrapper{
        position: initial;
        display: flex;
        align-items: flex-start;
        padding: $indentBasis 0;
        border-radius: 2px;

        &:hover,
        &.is-selected {
            background: $hoverBg;
        }

        .folder-for-move{
            padding: 0 10px;
            justify-content: flex-start;
            width: 100%;
            word-break: break-all;
            white-space: normal;
            height: auto;
            text-align: left;
            line-height: 23px;
            display: flex;
            align-items: center;
        }
        .base-radio {
            margin-top: 0px;
        }
        .base-checkbox {
            margin-top: 1px;
        }
    }
    .button--action > span, .collapse-toggle__title {
        z-index: 2;
    }

    .content-store-step-container--scrollable {
        .folder-for-move--wrapper:last-child {
            >.collapse >.collapse-button:after {
                border-color: $white;
            }
        }
    }
    .collapse .tree-collapse-childrens > div > *:not(.collapse) {
        padding-left: 0;
    }
    .collapse .tree-collapse-childrens {
        margin-left: 0;
        padding-left: calc(#{$indentBasis} * 2 + 11px);
        position: relative;
        padding-top: 0;
        padding-bottom: 0;

        .folder-for-move--wrapper > .folder-for-move {
            line-height: 16px;
        }
        > div > *:not(.collapse),
        > div .collapse-button {
            line-height: 14px;
        }

        .collapse-toggle__icon__arrow {
            line-height: 14px;
        }
    }
    .base-radio, .base-radio__icon, .base-radio__label {
        margin: 0;
    }
    .base-radio {
        margin-right: 10px;
        margin-top: 9px;
        &--checked .base-radio__icon {
            border-color: $blue;
            &:after {
                background: $blue;
            }
        }
    }
    .base-radio, .base-checkbox {
        .base-radio__label, .base-checkbox__label {
            display: none;
        }
    }
}

.content-store-page {
    .new-search-page--wrapper {
        transition: padding-left 0.5s ease;
    }
}

.tree-panel {
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: row;

    .resizable-container {
        &--inner {
            position: fixed;
            height: calc(100% - 49px);
        }
    }

    .folders-tree-title {
        height: 49px;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;

        &--inner {
            padding: 0 calc(#{$indentBasis} * 3);

            h2 {
                font-size: 16px;
                font-family: $BrandMedium;
            }

            p {
                font-size: 12px;
            }
        }
    }

    &.auto-hide--hidden {
        .folders-collapse-wrapper {
            width: 0;
            border: 0;
        }
    }

    .folders-collapse-wrapper .folders_tree_collapse_title > .material-icons {
        margin-top: 1px;
    }

    .folder-for-move--wrapper {
        padding-left: calc(#{$indentBasis} * 2);
    }

    .collapse .tree-collapse-childrens {
        padding: 0 0 0 22px;
    }

    .collapse.collapse--with-arrow .collapse-toggle, .folder-button-wrapper {
        padding: calc(#{$indentBasis}/2) #{$indentBasis};
        border-radius: 2px;

        .collapse-toggle__title {
            font-family: $BrandRegular;
        }
        .collapse-toggle__icon {
            margin-top: 2px;
        }
    }

    .collapse.collapse--with-arrow.collapse--opened {
        padding-bottom: 0;
    }

    .collapse.collapse--with-arrow .collapse-button {
        display: flex;
        padding-top: 1px;
        padding-bottom: 1px;
        &:hover {
            background: $hoverBg;
        }
    }

    .folders-collapse-wrapper {
        transition: width 0.5s ease;
        height: 100%;
        width: 100%;
        padding: calc(#{$indentBasis} * 2 - 3px) 0 calc(#{$indentBasis} * 2) 0;
        box-sizing: border-box;
        border-right: $classicBorder;
        border-left: $classicBorder;
        background: $white;

        .content-store-step-container--scrollable {
            box-sizing: border-box;
            padding-bottom: 36px;
        }
    }
    .collapse.collapse--tree-view {
        .collapse-toggle {
            .collapse-toggle__icon + .collapse-toggle__title {
                margin-left: 0;
            }
            .collapse-toggle__icon {
                .collapse-toggle__icon__arrow {
                    top: 0;
                    svg {
                        path {
                            fill: $greyDarker3;
                        }
                    }
                }
            }
        }
        .collapse-toggle__title {
            line-height: 2rem;
            margin-left: 17px;
        }
    }

    .item-image-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        border: $classicBorder;
        background: $white;
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: -2px;
    }

    .folder-button-wrapper {
        display: flex;
        align-items: center;
        vertical-align: middle;
        font-family: $BrandRegular;
        padding-left: 10px !important;
        margin-left: 22px;
        &.is-selected {
            background: $hoverBg;
            font-family: $BrandMedium;
        }
        &:hover {
            background: $hoverBg;
            cursor: pointer;
        }
        .folder-for-move {
            padding: 0 $indentBasis;
            line-height: 23px;
            font-size: 1.4rem;
        }
    }

    .folders_tree_collapse_title {
        display: flex;
    }

    .is-selected,
    .is-active-top-parent,
    .is-active-parent {
        & > .collapse-button {
            padding-top: 0;
            padding-bottom: 0;

            .collapse-toggle {
                .collapse-toggle__title {
                    font-family: $BrandMedium;
                }
            }
        }
    }

    .is-selected {
        & > .collapse-button .collapse-toggle {
            background: $hoverBg;
            width: 100%;
        }
    }
}

.virtualized-list-container {
    height: calc(100% - 50px);
}