.tags-select {
    .base-input {
        margin: 10px 0;
    }
    &__options {
        &__option {
            .option-tag {
                border-radius: 10.5px;
                background: $greyDefault;
                display: inline-flex;
                height: 21px;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                margin-bottom: 10px;
                font-size: 13px;
                &__user-name {
                    color: $white;
                    margin-right: 10px;
                }
                &__user-action {
                    transform: rotate(45deg);
                    color: $white;
                    font-size: 21px;
                }
            }
        }
    }
}