.generate-anon-link-modal {
    .copy_anon_url--modal-content {
        display: block;
    }

    .base-modal {
        .base-modal--body {
            overflow-y: unset;
        }
        .base-modal--footer {
            padding-top: 0;
        }
    }

    .get-public-link-modal--content {
        .ordering-select {
            margin-bottom: 10px;
        }
        .asset-field--toolTip .info-tooltip {
            top: 2px;
        }
        .include-hierarchy-field {
            display: flex;
            align-items: center;
            .info-tooltip {
                .material-icons {
                    font-size: 16px;
                    color: $greyDarker3;
                }
                .tooltip__open-btn {
                    padding: 0 8px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: 1px;
                }
            }
            .base-checkbox__label {
                margin-right: 0;
            }
        }
    }
    .base-modal--footer {
        margin-top: 1px;
    }
    .generate-anon-link--title {
        display: flex;
        align-items: center;
        .button--action {
            margin-right: 10px;
        }
    }
}

@import "./style_responsive";