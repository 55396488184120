.upload-progress {
    &__line {
        width: 100%;
        border-radius: 2px;
        background-color: $greyDarker2;
        height: 3px;
        margin-top: 10px;
        margin-bottom: 8px;
        &__percentage {
            height: 100%;
            border-radius: 2px;
            background: $blue2;
        }
    }
    &__status {
        font-family: $BrandRegular;
        color: $blue;
        font-size: 12px;
        line-height: 18px;
        &--error {
            cursor: pointer;
            color: $red;
            .error-item {
                margin-bottom: 8px;
                display: flex;
            }
            .error-description {
                padding-left: 20px;
                margin-bottom: 8px;
                color: $greyDefault;
            }
            .exclamation-mark {
                margin-top: 1px;
                color: $white;
                background: $red;
                display: inline-block;
                border-radius: 50%;
                text-align: center;
                line-height: 14px;
                width: 14px;
                height: 14px;
                margin-right: 5px;
                flex-shrink: 0;
            }
        }
        &--complete {
            color:$saladGreenDark;
            svg {
                margin-right: 5px;
                height: 10px;
                width: 14px;
                path {
                    fill: $saladGreenDark;
                }
            }
        }
        .collapse-error {
            position: relative;
            &:after {
                @extend %arrow-down;
                transition: transform .25s;
                top: 4px;
                right: -12px;
              }
              
            &.opened:after {
                transform: rotate(180deg);
                transition: transform .25s;
            }
        }
    }

    .details-btn {
        position: absolute;
        right: 17px;
        top: 11px;
        color: $blackLight;
        border: none;
        background: $white;
        box-shadow: $grayShadow1;
        &:hover {
            background: $greyDarker2;
        }
    }
}


