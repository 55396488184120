.folder-preview {
    .content-stores {
        padding: 12px 48px;

        .cabinet-main-container {
            padding-bottom: 40px;
        }
    }
    .folder-title {
        color: $blackLight;
        background-color: $white;
        padding: 28px 48px 20px 48px;

        h2 {
            font-size: 24px;
            line-height: 35px;
            font-family: $BrandMedium;
            margin-bottom: 5px;
        }
    }
    .cabinet-main-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 38px 0 36px;

        .grid-view__item {
            width: 300px;
            height: auto;
            margin: 15px 15px 15px 15px;
        }
    }

    .region--top {
        background: $greyLight5;
        height: 60px;
        border: none;

        .sap-logo {
            width: 64px;
        }
    }

    .search-item {
        position: relative;
        display: block;

        .bulk-actions-checkbox {
            position: absolute;
            top: calc(#{$indentBasis} * 2);
            left: calc(#{$indentBasis} * 2);
            z-index: 2;
        }

        &--thumbnail {
            text-align: center;
            width: 100%;
            border-bottom: $classicBorder;
            border-radius: 12px 12px 0 0;
            box-sizing: border-box;
            z-index: 1;
        }

        .search-item-content {
            min-height: 95px;
            width: 100%;
            padding: 8px 0 0 0;

            .name {
                max-height: 30px;
                overflow: hidden;
                padding: 9px calc(#{$indentBasis} * 2) 3px calc(#{$indentBasis} * 2);
                font-size: 16px;
                text-overflow: ellipsis;
                display: block;
                line-height: 24px;
            }

            span.name {
                padding-bottom: 4px;
                padding-top: 4px;
                min-height: 24px;
                line-height: 24px;
            }

            .description {
                line-height: 18px;
                padding: 4px 2*$indentBasis 0px 2*$indentBasis;
                font-size: 14px;
                display: block;
                max-height: 36px;
                overflow: hidden;
                color: $greyDefault;
            }
        }

        .search-item-info {
            display: flex;
            padding: 5px 12px 12px calc(#{$indentBasis} * 2);
            justify-content: space-between;
            height: 44px;
            box-sizing: border-box;
            align-items: center;

            .search-item-content--other-info .other-info-box.type:before {display: none}
        }
    }

    article {
        flex-direction: column;
    }

    .empty-folder {
        margin-top: 150px;
        color: $greyDarker;
        font-size: 34px;
    }
    .search-item-content--title {
        .search-item-content--name {
            margin: 0;
            padding: 0;
            &:hover {
                background-color: transparent;
            }
        }
    }

    .custom_folder--preview {
        .description {
            min-height: 131px;
        }
        .info {
            display: none;
        }
    } 

    .custom_asset--preview {
        height: auto;
        .search-item-info {
            & > div:first-of-type {
                flex: 1;
            }
        }
    }

    @extend %custom_folder--thumbnail;
    @extend %custom-folder-preview;
}
