@media screen and (max-width: 1350px) {
    .curators-dashboard-page {
        .curators-dashboard-grid-col {
            &.col-full-width,
            &.col-fixed-width {
                width: 50%;
            }
        }
    }

    .playlist-page .curators-dashboard-page {
        .curators-dashboard-grid-col {
            &.col-full-width,
            &.col-fixed-width {
                width: 600px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .curators-dashboard-page {
        .dashboard-widget.my-feed-widget {
            min-height: 330px;
        }
        .curators-dashboard--holder {
            right: 20px;
        }
        .curators-dashboard-grid {
            flex-direction: column;
    
            &-col {
                margin-bottom: 16px;
                width: 100%;

                &.col-expiring,
                &.col-full-width {
                    width: 100%;
                }
            }
        }

        .curators-dashboard-widget {
            width: 100%;
            &.full-height {
                height: 70vh;
            }
        }
    }
}

@media #{$IE} {
    .curators-dashboard-page {
        .curators-dashboard-grid-col {
            height: calc(100vh - 100px);
        }
    }
}