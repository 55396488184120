.cs_field {
    & > .button--action.white {
        color: $blackLight;
    }
    &--value {
        padding-top: 5px;
        overflow: hidden;

        &-item {
            position: relative;
            background: $greyLight2;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            margin: 0 2px 5px 2px;
            padding: 2px 3px 2px $indentBasis;
            border-radius: 4px;
            font-family: $BrandRegular;
            font-size: 14px;
            line-height: 16px;
            align-self: flex-start;
            float: left;
            clear: both;
            word-break: break-all;

            &:hover {
                background: $greyDarker2;
            }

            .button--action {
                flex-shrink: 0;
                flex-grow: 0;
                margin-left: 10px;

                svg {
                    max-width: 10px !important;
                    max-height: 10px !important;

                    path {
                        fill: $greyDarker3;
                    }
                }
            }
        }
    }
}

.folder-for-move.selected > .collapse-button,
.button--action.folder-for-move.selected,
span.folder-for-move.selected {
    background: $blueLight;
}

.folder-for-move.chosen.selected > .collapse-button > .collapse-toggle,
.button--action.folder-for-move.chosen.selected,
span.folder-for-move.selected {
    &:before {
        background: $hoverBg;
    }
}
