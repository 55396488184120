.aside-modal {
    width: 100%;
    max-width: 600px;
    height: 100%;
    background: $greyLight6;
    box-shadow: $grayShadow4;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;

    .aside-modal__container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .aside-modal__header {
        padding: 2*$indentBasis 3*$indentBasis;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        min-height: 64px;

        &-controls {
            display: flex;
            gap: $indentBasis;
        }

        .aside-modal__title, .aside-modal__title h2 {
            font-family: $BrandMedium;
            font-size: 16px;
            color: $blackLight;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: $indentBasis;
        }
        .assets-list__item__details-link {
            &:focus {
                outline-offset: 2px;
            }
        }
    }

    .aside-modal__body {
        height: calc(100vh - 56px);
        padding: 0 3*$indentBasis 3*$indentBasis;
        flex: 1;
        box-sizing: border-box;
    }

    &.with-footer {
        .aside-modal__body {
            height: calc(100vh - 129px);
        }
    }

    &.header-border {
        .aside-modal__header {
            border-bottom: $classicBorder;
        }
    }

    .aside-modal__footer {
        display: flex;
        padding: 2*$indentBasis 3*$indentBasis;
        gap: $indentBasis;
        border-top: $classicBorder;
        .button--action {
            min-width: 60px;
        }
    }

    &.minimized .aside-modal__header{
        min-height: initial;
    }

}
