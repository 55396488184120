.copy-asset-modal {
    .copy-asset-select {
        .input-pair,
        .input-pair__title-box {
            padding-top: 0;
        }
    }
    .userName-dropdown {
        max-height: 205px;
        margin-top: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &.base-redux-modal {
        .base-modal {
            .base-modal--body {
                overflow-y: unset;
            }
        }
    }
}