/*
Example of usage:
.your-class {
    @extend %clearfix;
}
*/

%clearfix {
    &:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
        font-size: 0;
    }
}

%ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

%button--close-modal {
    background: none;
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 14px;
    right: 20px;
}

@mixin colorSvg($color) {
    svg {
        rect {
            fill: $color
        }
    }
}

%usage--internal {
    color: $white;
    background: $orange;
    @include colorSvg($orange);

}

%usage--direct {
    color: $white;
    background: $saladGreen;
    @include colorSvg($saladGreen);
}

%usage--public {
    color: $white;
    background: $saladGreen;
    @include colorSvg($saladGreen);
}

%usage--partner {
    color: $white;
    background: $blue;
    @include colorSvg($blue);
}

%usage--customer {
    color: $white;
    background: $blue;
    @include colorSvg($blue);
}

%usage--brand {
    color: $white;
    background: $orange;
    @include colorSvg($orange);
}

%usage--jam {
    color: $white;
    background: $violet;
    @include colorSvg($violet);
}

%arrow-left,
%arrow-right,
%arrow-up,
%arrow-down {
    position: absolute;
    content: ' ';
    display: inline-block;
    border-top-color: $defaultColor;
}

%arrow-left,
%arrow-right {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}

%arrow-right {
    margin-left: -4px;
    border-left: 5px solid;
}

%arrow-up,
%arrow-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}

%arrow-left {
    border-right: 5px solid;
}

%arrow-up {
    border-top: none !important;
    border-bottom: 5px solid;
}

%arrow-down {
    border-top: 5px solid;
}

%mac-scrollbar {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $navy;
    }
}

%mac-close {
    width: 25px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
    &:after {
        content: '×';
        display: inline-block;
        font-size: 22px;
        font-weight: bold;
        line-height: 25px;
        color: $defaultColor;
    }
}

%tahoma-font {
    font-family: Tahoma, Arial, sans-serif;
    font-weight: bold;
}

%custom-folder-preview {
    .custom_folder--preview {
        border-radius: 12px;
        box-shadow: $grayShadow1;
        border: $folderItemBorder;
        position: relative;

        &:hover {
            box-shadow: $grayShadow2;
            border-color: $blue;
        }

        &.no-image {
            .custom_folder--thumbnail {
                border-bottom: 0;
            }
        }
        .react-contextmenu-wrapper {
            height: 100%;
        }
        .custom_forder--container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .asset-preview__title {
                width: auto;
            }

            .asset-preview__data {
                padding-left: 12px;
            }
        }
        &--is-over {
            opacity: 0;
        }
        &--is-test {
            opacity: 0.4;
        }
        &--draggable {
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;

            &:active {
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
        }

        .description {
            padding: #{$indentBasis} calc(#{$indentBasis} * 2) 0;
            word-break: break-word;
            flex: 1;
            min-height: 30px;

            &__title {
                @extend %ellipsis;
                font-family: $BrandRegular;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                padding: 4px 0;
                text-transform: none;
                transition: color .2s;

                span {
                    @extend %ellipsis;
                }

                &.not-link {
                    color: $greyDarker3;

                    &:hover {
                        background: none;
                    }
                }
            }
            &__text {
                color: $greyDefault;
                font-family: $BrandRegular;
                font-size: 14px;
                line-height: 18px;
                margin-top: 5px;
                display: block;
            }
        }
        .info {
            display: flex;
            color: $blackLight;
            font-family: $BrandRegular;
            align-items: flex-end;
            font-size: 14px;
            padding: calc(#{$indentBasis} * 2);
            padding-right: 40px;
            justify-content: space-between;

            &--inner {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: center;

                .counter {
                    padding: 0 0 0 0;

                    .material-icons {
                        color: $greyDarker3;
                    }
                    svg {
                        width: 15px;
                        height: 15px;
                        margin-top: -1px;
                        path {
                            fill: $greyDarker;
                        }
                    }
                }

                .asset-preview-metadata__pair {
                    width: 100%;
                    flex-grow: 0;
                    flex-shrink: 0;
                    align-items: center;
                    margin-bottom: calc(#{$indentBasis} * 2);

                    .counter_name {
                        color: $greyDefault;
                        font-family: $BrandMedium;
                        font-size: 12px;
                    }

                    .asset-preview__data {
                        line-height: 16px;
                        font-family: $BrandRegular;
                        font-size: 12px;
                    }

                    .material-icons {
                        font-size: 14px;
                        color: $greyDefault;
                    }
                }

                & > *:not(.asset-preview-metadata__pair) {
                    margin-right: calc(#{$indentBasis} * 2);
                }
            }

            &--actions {
                flex-grow: 0;
                flex-shrink: 0;
                position: absolute;
                height: 25px;
                bottom: 10px;
                right: 12px;

                .dropdown {
                    height: 25px;
                }

            }

            .folder_grid_item--actions-pop-up {
                display: flex;
                flex-grow: 2;
                justify-content: flex-end;

                .actions__container {
                    .asset-actions-trigger {
                        position: relative;
                        z-index: 10;

                        svg {
                            path {
                                fill: $blackLight;
                            }
                        }
                    }
                }
            }
            &__data {
                display: flex;
                white-space: nowrap;
                max-width: 45%;
                font-size: 12px;

                &:first-child {
                    margin-right: 20px;
                }
                svg {
                    margin-right: 6px;
                    path {
                        fill: rgba(34,34,34,0.5);
                    }
                }
                &--folders {
                    svg {
                        margin-top: 2px;
                        height: 11px;
                        width: 14px;
                    }
                }
                &--assets {
                    svg {
                        height: 15px;
                        width: 12px;
                    }
                }
                &--agency {
                    font-family: $BrandMedium;
                }
            }
        }
        .sub-folders-amount-count {
            margin-right: 4px;
            max-width: 80px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 12px;
        }
    }
}

%custom_folder--thumbnail {
    .custom_folder--thumbnail {
        position: relative;
        border-bottom: $classicBorder;
        background: $greyLight6;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .content-preview--image {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }

        .item-label {
            padding: 4px 8px 4px 23px;
            font-size: 14px;
            line-height: 24px;
            background-color: $white;
            border-left: 3px solid $orange;
            box-shadow: $grayShadow1;
            opacity: 0.98;
            position: absolute;
            top: 8px;
            right: 0;
            z-index: 2;

            .bold {
                font-family: $BrandMedium;
            }

            &:after {
                content: '';
                width: 7px;
                height: 7px;
                background: $orange;
                border-radius: 50%;
                position: absolute;
                left: 8px;
                top: 12px;
                z-index: 3;
                display: block;
            }

            &.rejected {
                border-left-color: $red;
                &:after {
                    background: $red;
                }
            }
        }

        .bulk-actions-checkbox {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            @media #{$IE} {
                top: 0px;
            }
        }
    }
}
