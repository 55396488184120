.view-relations-modal {
    .modal {
        width: 690px;
    }
    .button--action {
        width: 75px;
    }
}

.view-relations-modal__title {
    font-family: $BrandMedium;
    text-transform: uppercase;
    margin-bottom: 14px;
}

.view-relations-modal__item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .sap-doi {
        display: inline-block;
    }

    &__usage {
        width: 62px;
        flex-grow: 0;
        padding-right: 10px;
    }

    &__name {
        flex: 1 0;
        padding-right: 20px;
        line-height: 10px;
        word-break: break-all;
    }

    &__actions {
        width: 100px;
        display: flex;
        align-self: stretch;
        align-items: center;
        flex-grow: 0;
    }

    .asset-usage {
        font: 10px/20px Arial, sans-serif;
        width: 62px;
    }
}
.view-relations-modal__items {
    max-height: 415px;
    padding: 30px 0 5px;
    overflow-y: auto;
    margin-top: -10px;
}

.sap-doi {
    span {
        font-size: 14px;
        font-family: $BrandMedium;
        color: $blue;
    }
}
