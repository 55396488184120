.customer-video-workspace {
    .search-in-store {
        padding-top: 0;
        padding-left: 15px;
        margin-bottom: 5px;
    }

    .content-store-header {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .search-results-info--content-actions {
        padding-left: 0;

        .dropdown-body {
            min-width: 185px;
        }
    }
}