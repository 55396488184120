#progress-component {
    box-shadow: $dropdownShadow;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 0;
    height: auto;
    max-height: calc(100vh - 58px);

    .progress-window {
        width: 583px;
        background: $white;
        opacity: 1;
        text-align: left;
        position: relative;

        .modal__header {
            padding: 10px 60px 10px 20px;
            position: relative;
            background: $greyDefault;
            color: $greyDarker4;
            border: none;

            &__text {
                text-transform: none;
                color: $greyDarker4;
                font-size: 20px;
                line-height: 30px;
            }
        }

        .modal__close {
            position: absolute;
            top: 18px;
            right: 20px;

            &:hover {
                .asset-actions__item__tooltip {
                    visibility: visible;
                }
            }

            svg {
                width: 16px;
                height: 16px;

                path {
                    fill: $greyDarker4;
                }
            }

            .asset-actions__item__tooltip {
                right: 50px;
                width: 120px;
                top: auto;
                bottom: 35px;
                white-space: normal;
                text-align: center;

                &:after {
                    left: auto;
                    right: 25px;
                }
            }
        }

        .minimize-upload-icon {
            position: absolute;
            top: 20px;
            right: 60px;
            display: block;
            width: 25px;
            height: 17px;
            text-align: center;
            cursor: pointer;
            opacity: 0.85;

            svg {
                path: {
                    fill: $greyDarker4;
                }
            }

            &:hover {
                opacity: 1;
            }
        }



        &.minimized {
            .minimize-upload-icon {
                transform: rotate(-180deg);
            }

            .progress-window--body {
                height: 0;
                transition: 1s;
            }
        }

        &-description {
            display: block;
            margin-bottom: 20px;
        }

        &--body {
            height: auto;
            max-height: calc(100vh - 58px);
            overflow: auto;
            &-inner {
                padding: 25px 20px;
            }
        }

        &--files {
            .file-progress-item.error {
                &:first-child {
                    .file-progress-item--tooltip {
                        top: 20px;
                        &:after {
                            border-bottom: 4px solid $redLight;
                            top: -4px;
                        }
                    }
                }
                &:not(:first-child) {
                    .file-progress-item--tooltip {
                        bottom: 20px;
                        &:after {
                            border-top: 4px solid $redLight;
                            bottom: -4px;
                        }
                    }
                }
            }

            .upload-progress__status--error {
                position: relative;
                top: 4px;
            }
        }

        .file-progress-item {
            display: flex;
            margin-bottom: 20px;

            &--error {
                float: none;
                background: none;
                border: none;
                padding: 0;
                font-size: 12px;
                .progress {
                    background: $greyLight7;
                }
                .progress-value {
                    background: $red;
                }
                .error-message {
                    color: red;
                    float: right;
                }
            }

            &--processing {
                .progress-value {
                    background: $greyDarker4;
                }
                &.file-progress-item--validated {
                    .progress-value {
                        background: $saladGreen;
                    }
                }
            }

            &--uploaded {
                .progress-value {
                    background: $saladGreen;
                }
            }

            &--preview {
                width: 32px;
                height: 42px;
                margin-right: 10px;
                flex-grow: 0;
                flex-shrink: 0;

                img {
                    height: 100%;
                }
            }

            &-content {
                flex-grow: 1;
                flex-shrink: 1;
                overflow: hidden;
                padding-bottom: 5px;
            }

            &--name {
                margin-bottom: 3px;
                position: relative;
                height: 36px;
                line-height: 36px;

                .file-uploaded,
                .file-progress-item--status {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 10px;
                    max-width: 70px;
                }
                .file-progress-item--error {
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    position: relative;
                    top: 3px;
                    right: 5px;
                    color: red;
                    &:hover + .file-progress-item--tooltip {
                        visibility: visible;
                    }
                    svg {
                        path {
                            fill: $red;
                        }
                    }
                }


                .file-progress-item--status {
                    img {
                        width: 15px;
                        height: 15px;
                    }
                }

                .file-uploaded {
                    svg {
                        width: 15px;
                        height: 12px;

                        path {
                            fill: $saladGreen;
                        }
                    }
                }
                .status-button {
                    text-align: center;
                    width: 114px;
                    height: 27px;
                    border: 1px solid $blue;
                    color: $blue;
                    background: $white;
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                    line-height: 18px;
                    -webkit-user-select: none;
                    user-select: none;
                    z-index: 2;
                    cursor: pointer;
                    &:hover {
                        color: $white;
                        background: $blue;
                    }
                }

                .file-progress-item--tooltip {
                    visibility: hidden;
                    position: absolute;
                    right: -12px;
                    font-size: 11px;
                    z-index: 5;
                    padding: 4px 10px;
                    background: $redLight;
                    text-align: center;
                    font-weight: normal;
                    color: $greyDefault;
                    line-height: 14px;
                    &:after {
                        position: absolute;
                        z-index: 1;
                        content: ' ';
                        display: inline-block;
                        right: 15px;
                        margin-left: -5px;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                    }
                }
                .file-error {
                    float: right;
                    margin-right: 130px;
                }
            }
            &--nameWrapper {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

@import "./progress-component_responsive";
