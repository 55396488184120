.asset-history {
    .asset-sapId {
        display: inline-block;
    
        .assets-list__item__details-link {
            color: $blue;
        }
    
        .other-info-box {
            display: none;
        }
    }
}

.history-filters {
    padding-bottom: $indentBasis;
    border-bottom: $classicBorder;
    margin: $indentBasis 0 0 0.5*$indentBasis;

    &-empty {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        svg {
            margin-bottom: calc(#{$indentBasis} / 2);
            width: 32px;
            height: 32px;
            path {
                fill: $greyDarker
            }
        }

        &-title {
            font-size: 18px;
            font-family: $BrandMedium;
            line-height: 28px;
        }
    }
}

.history-record {
    padding: calc(#{$indentBasis} * 2) 0px;
    border-bottom: $classicBorder;

    &-without-divider {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: -16px;

        .history-record-user {
            display: none;
        }
    }

    .bold {
        font-family: $BrandMedium;
    }

    .link, button {
        font-family: $BrandMedium;
        color: $blue;
        &:hover {
            color: $navy;
        }
    }

    .history-comments {
        p {
            display: inline;
        }
    }

    &-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-text {
        font-size: 14px;
        line-height: 24px;
        color: $greyDefault;
    }

    &-date {
        margin-top: 5px;
        font-size: 10px;
        line-height: 16px;
        color: $greyDarker3;
        margin-left: calc(#{$indentBasis} * 2);
        flex-shrink: 0;
    }

    &-user {
        margin-top: $indentBasis;
        display: flex;
        align-items: center;

        .user-avatar {
            margin-right: $indentBasis;
            flex-shrink: 0;
            font-size: 10px;
            line-height: 22px;
        }

        .user-data__name {
            font-size: 12px;
        }
    }
    .multy-value-wrapper {
        .multy-value-metadata {
            margin-top: 0;
            margin-bottom: $indentBasis;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .metadata-label {
            font-family: $BrandMedium;
            font-size: 14px;
            line-height: 24px;
        }
        &.metadata {
            display: block;
        }
    }
    .metadata-label,
    .section-label {
        width: 200px;
        padding-right: $indentBasis;
    }
    .metadata,
    .multy-value-metadata {
        margin-top: $indentBasis;
        display: flex;
        font-size: 14px;
        line-height: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        .metadata-label {
            font-family: $BrandMedium;
        }

        .metadata-value {
            flex: 1;
        }

        .collapse-body {
            padding: 0;
        }

        .metadata-block {
            padding: 0 4px;
            width: fit-content;
        }

        .multy-metadata-block {
            padding: 0 4px;
            font-family: $BrandRegular;

            &:last-child {
                margin-bottom: 0;
            }

            .title {
                font-family: $BrandMedium;
            }

            .metadata-value-item {
                margin-bottom: 2px;
            }
        }

        .new-value {
            color: $saladGreenDark;
            background-color: $greyLight2;          
            border-radius: 2px;
            font-family: $BrandMedium;
        }

        .collapse {
            display: flex;
            flex-direction: column-reverse;

            .collapse-title-opened {
                display: none;
            }

            &.collapse--with-arrow {
                padding: 0;
                border-top: none;

                .collapse-button {
                    padding: 0;
                    color: $blue;
                }

                .collapse-toggle {
                    padding-right: 20px;
                    display: inline-block;
                    position: relative;
                }

                .collapse-toggle__icon {
                    position: absolute;
                    top: 0;
                    right: -3px;

                    .collapse-toggle__icon__arrow {
                        transform: rotate(90deg);
                        svg {
                            path {
                                fill: $blue;
                            }
                        }
                    }
                }

                .collapse-toggle__title {
                    font-family: $BrandRegular;
                    color: $blue;
                }
            }

            &.collapse--opened {
                .collapse-title-opened {
                    display: inline;
                }

                .collapse-title-closed {
                    display: none;
                }

                & > .collapse-button {
                    .collapse-toggle__icon__arrow {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}