.aside-modal {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    background: $white;
    box-shadow: $grayShadow4;
    @include asideModalAnimation($modalDefaultWidth);

    &.view-relations-container {
        z-index: 995;
    }

    &.preview-asset-modal-wrapper {
        margin-right: -$modalDefaultWidth;
        transition-duration: 0.5s;

        &.opened {
            margin-right: 0;
        }
    }

    &--inner {
        width: 100%;
        height: 100%;
        position: relative;

        .mobile-overlay {
            display: block;
            position: absolute;
            right: 100%;
            width: 100vw;
        }
    }
    &.classic-header {
        .preview-modal__header {
            color: $blackLight;
            font-family: $BrandMedium;
            font-size: 18px;
            padding: 5px 20px 0 20px;
            min-height: 45px;
            line-height: 45px;
        }

        .preview-modal__header__close-modal {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            z-index: 5;

            svg {
                width: 17px;
                height: 17px;
                path {
                    fill: $greyDarker3;
                }
            }
        }

        .preview-modal__body {
            width: 600px;
        }
    }

    /*panels animation*/


    &--entire {
        max-width: 100%;
    }

    &--narrowed {
        top: auto;
        bottom: 0;
        right: 0;
        height: 30%;
        max-width: $modalDefaultWidth;
    }
}

.saved-searches {
    .preview-modal__header__close-modal {
        cursor: pointer;
    }
}

html.modal-opened--aside {
    overflow: hidden;
}

html.has-dark-overlay {
    height: 100vh!important;
    overflow: hidden;
    .feedback-menu {
        z-index: -1;
    }

    header {
        z-index: 0;
    }

    &--profile {
        header {
            z-index: 100;
        }

        .page .full-layout--container {
            z-index: -1;
        }
    }
}


