.workspace-projects-list {
    margin: 20px 0 0 0;

    @extend %custom_folder--thumbnail;
    @extend %custom-folder-preview;

    .asset-preview__title {
        &.persons {
            .counter_icon {
                svg {
                    width: 11px;
                    path {
                        fill: $greyDefault;
                    }
                }
            }
        }
    }

    .custom_folder--preview .description__text {
        display: block;
        min-height: 35px;
    }
}