%text-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-profile {
    .dropdown-body button.user-profile-item:not(.base-checkbox__icon):not(.search-input-actions__button):not(.anime-toggle--button):not(.dropdown-body__content__close):not(.user-profile--button) {
        padding: $indentBasis calc(#{$indentBasis} * 2);
    }

    &--dropdown {
        &.dropdown {
            padding-bottom: 0;
            height: auto;
            z-index: 112;

            & > div {
                display: flex;
            }
        }

        .dropdown-title {
            @extend %avatar;

            &:after {
                display: none;
            }

            &:hover {
                @extend %avatar-hover;
            }
        }

        .dropdown-body {
            & > *:hover {
                background: inherit;
            }
        }

        .button--action {
            height: 40px;
        }

        .button--action.user-profile-item svg,
        .user-profile-item__icon {
            margin-right: $indentBasis !important;
            width: 16px !important;
            height: auto !important;
            margin-left: 0 !important;
        }
    }

    & &-group {
        width: 100%;
        padding: 7px 0;
        margin: 0;
        border-bottom: $classicBorder;
        cursor: default;
        box-sizing: border-box;

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        &:hover {
            background: $white;
        }
    }

    & &-info {
        padding: $indentBasis calc(#{$indentBasis} * 2);
        display: flex;

        &--wrapper {
            width: calc(100% - 55px);
            line-height: 1;
        }

        &--name {
            padding-top: 2px;
            font-family: $BrandMedium;
            font-size: 14px;
            line-height: 24px;
            color: $greyDefault;
            @extend %text-ellipsis;
        }

        &--email {
            font-family: $BrandRegular;
            font-size: 12px;
            color: $defaultColor;
            display: block;
            @extend %text-ellipsis;
        }
    }

    & &-item {
        padding: $indentBasis calc(#{$indentBasis} * 2);
    }

    .user-avatar {
        margin-right: 10px;
    }

    .dropdown-body {
        .user-profile-item {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .user-profile-item__icon { 
        svg {
            width: 18px;
            height: 18px;
        }
    }

    .user-profile--logout {
        .user-profile-item__icon { 
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
