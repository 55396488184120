.saved-searches {
    width: 100%;
    margin-top: 0;

    .content-fixer {
        z-index: 1;
    }

    .sort-controls__item,
    .saved-searches-item {
        display: flex;
        align-items: center;
        padding: 0;
        box-sizing: border-box;

        &__checkbox {
            width: 36px;
            padding: 0 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

        &--name,
        &__name {
            flex-grow: 1;
            flex-shrink: 1;
            width: 0;
        }

        &--name a {
            border-left: 1px solid $greyDefault;
        }

        &--placeholder,
        &__actions {
            width: 89px;
        }

        &--dateUpdated,
        &--dateCreated,
        &__date-uploaded,
        &__date-updated {
            width: 120px;
        }

        &--results,
        &__results {
            width: 80px;
        }

        &--ownerName,
        &__owner {
            width: 105px;
        }
    }
}

/******   MODAL RELATED (preferences)   ******/

.saved-searches-modal {
    .dt__items {
        height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .dt__item {
        width: 686px;

        &--disabled {
            opacity: 0.5;

            .dt-cell--name {
                cursor:auto;
                a:hover {
                    border-bottom: none;
                    cursor:default;
                }
            }

            .dt-cell--checkbox {
                input[type='checkbox']:hover {
                    cursor: default;
                }

                label:hover {
                    cursor: default;
                }
            }
        }
    }

    .dt-cell--name {
        flex-grow: 1;
        flex-shrink: 1;
        width: 0;
    }

    .dt-cell--result {
        width: 70px;
        text-align: center;
    }

    .saved-searches-item__results {
        width: 100%;
    }

    .dt-cell--dateUpdated,
    .dt-cell--dateCreated,
    .dt-cell--ownerName {
        width: 110px;
    }
}

/******   END MODAL RELATED (preferences)   ******/

// Special styling for <th>-based sorting controls
.saved-searches__head {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 25px;

    a {
        padding: 0 20px 0 10px !important;
        display: flex;
        align-items: center;
        color: $greyDarker4;
        box-sizing: border-box;
        height: 24px;
        border-right: 1px solid $greyDefault;
    }
}

.saved-searches__body {
    background-color: $white;
    color: $defaultColor;
    font-size: 10px;
}

.saved-searches-item {
    font: 12px Arial, sans-serif;
    border: 1px solid $greyDarker4;
    display: flex;
    border-bottom: 0;

    > div {
        box-sizing: border-box;
        padding: 10px;
    }

    &:last-child {
        border-bottom: 1px solid $greyDarker4;
    }

    &:first-child {
        margin-top: 10px;
    }
}

.saved-searches-item--selected {
    background-color: $greyLight7;
}

.empty-table-row {
    height: 10px;
    background-color: $white;
}

.saved-searches-item__checkbox {
    width: 16px;
    padding-left: 12px;
    line-height: 0;
}
.saved-searches-item__name {
    color: $blue;
    width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    cursor: pointer;
    padding-right: 40px !important;

    a:hover {
        border-bottom: 1px solid $blue;
    }
}

.saved-searches-item__results {
    column-span: 2;
    color: $black;
    width: 70px;
    font-weight: bold;
    text-align: center;

    .saved-searches-item__delta {
        margin-left: 4px;
        font-weight: normal;

    	&--same {
   		    color: $white;
    	    display: none;
    	}

        &--increase {
            color: $saladGreen;

            &:before {
                content: '+';
                color: inherit;
                display: inline;
            }
        }

        &--decrease {
            color: $greyDefault;
        }
    }
}
