.no-result-container {
    display: flex;
    align-items: flex-start;
    .centered-container {
        margin: 0 auto;
    }

    &__title {
        color: $blackLight;
        font-size: 22px;
        line-height: 32px;
        font-family: $BrandMedium;
        margin-bottom: calc(#{$indentBasis} * 3);
    }

    ol.top-searches-list {
        overflow: hidden;
        padding-left: 20px;
        margin-bottom: 0;

        li {
            width: 50%;
            float: left;
            margin-bottom: 15px;

            .synonym {
                button {
                    color: #0870C3;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                svg {
                    display: none;
                }
            }
        }
    }
    .error_image {
        width: 240px;
        margin-left: auto;
    }
    .no-result-container__body {
        max-width: 656px;
    }
}

@import "./no_results_responsive";
