@media #{$mobile} {
    .filters-mobile-open .search-results-filter{
        height: 100vh !important;
        position: fixed !important;
        top: 0 !important;
    }

    .grid-view {
        .search-results {
            &-info {
                padding-bottom: 10px;
                &--content {
                    align-items: flex-start;
                    padding: 10px 50px 10px 15px;

                    .new-search-results--actions--left {
                        display: block;
                    }
                }

                .search-results-info__count {
                    padding-top: 10px;
                }

            }

            &--container, &-info {
                padding: 15px;
                .filter-tags {
                    margin: auto;
                    padding: 10px 50px 10px 15px;
                    max-width: calc(#{$griItemWidth} * 2 + #{$gridItemLeftIndent});
                }
            }
        }

        .suggestions {
            margin: 0;
            padding: 0 20px;
            &--inner {
                .exact-suggestion,
                .other-suggestion {
                    padding-left: 15px!important;
                }
            }
        }
    }

    .search-results-filter .auto-hide--opener {
        .button--action {
            right: 15px;
        }
    }

    .search-results--options {
        .page-controls {
            flex-direction: column;
            align-items: center;
            &__load-more {
                .load-more--pagination {
                    justify-content: center;
                }
            }
            &__change-size {
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .search-item-content--actions--global {
        top: auto;
        bottom: 10px;
        right: 12px;
        height: 25px;
        margin-right: 0;
    }
    .other-info-box.asset-id {
        display: none;
    }
    .search-results-sticky .search-results-controls .search-results-info--content-actions .base-checkbox {
        margin: calc(#{$indentBasis} * 2);
    }
    .search-results--container {
        .custom_asset--preview {
            height: auto;

            .search-item {
                display: block;

                &--thumbnail {
                    width: 100%;
                    border-top-right-radius: 12px;
                    border-bottom-left-radius: 0;
                }

                .bulk-actions-checkbox {
                    .base-checkbox {
                        margin: calc(#{$indentBasis} * 2);
                    }
                }

                &-content {
                    width: 100%;
                    padding: calc(#{$indentBasis} * 2);
                }
            }
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 1365px) {
    .cabinet-main-container {
        padding-left: 17px;
        padding-right: 17px;
    }

    .new-search-page {
        .new-search-page--wrapper {
            padding: 0 17px;
        }
    }

    .search-results-info,
    .search-results--container-list,
    .page.folder-preview .folder-title,
    .page.folder-preview .content-stores {
        padding-left: calc(#{$indentBasis} * 4);
        padding-right: calc(#{$indentBasis} * 4);
    }
}

@media #{$mobile} {
    .cabinet-main-container {
        padding-left: calc(#{$indentBasis} * 3);
        padding-right: calc(#{$indentBasis} * 3);
    }

    .new-search-page {
        .new-search-page--wrapper {
            padding: 0 calc(#{$indentBasis} * 3);
        }
    }

    .search-results-info,
    .search-results--container-list,
    .page.folder-preview .folder-title,
    .page.folder-preview .content-stores {
        padding-left: calc(#{$indentBasis} * 3);
        padding-right: calc(#{$indentBasis} * 3);
    }

    body .playlist-page--content,
    body .search-result-companies--wrapper,
    body .cvp-page .search-sortby-field--container {
        padding-left: 0;
        padding-right: 0;
    }
}



/* No need more, because SAP ID in search-item from search page
    migration to bottom of thumbnail - need to double check
*/
// @media #{$iPhone5s_P} {
//     .search-item-content--other-info .other-info-box.type span {display: none}
// }
