.empty-table-row {
    height: 10px;
    background-color: $white;
}

.xls-export-item__image img {
    width: 25px;
    margin: 0;
    padding: 0;
}

.dt-cell--dateUpdated,
.dt-cell--dateCreated {
    width: 100px;
}

.xls-export-item__image {
    color: $black;
    width: 7%;
}

.xls-export-item__delta {
    &--same {
        visibility: hidden;
    }
    &--increase {
        color: $saladGreen;
    }
    &--decrease {
        color: $saladGreen;
    }
}

.xls-export-item__results {
    column-span: 2;
    color: $black;
    width: 60px;
    font-weight: bold;
}

.export-to-xls-modal__options {
    label {
        margin-top: 10px;
    }

    input {
        margin-left: 0 !important;
        margin-right: 10px;
    }
}
