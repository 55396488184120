.manage-cvp-project {
    .manage-asset-form {
        height: calc(100vh - 130px);
        margin-top: 0;

        .input-pair {
            &.authorization-date,
            &.expiration-date {
                .input-pair__input-box {
                    width: 170px;
                }
            }
        }
    }

    .tabs.wizard {
        .carousel-panels .carousel-panel .carousel-scroll .carousel-scroll--inner {
            padding: 0
        }
        .wizard-tabs-footer {
            padding-left: 0;
        }
    }

    .carousel-scroll--inner {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.asset_subtitles_section {
    display: flex;
    align-items: center;

    .dropdown {
        padding-bottom: 0;
        height: 24px;
        margin-left: $indentBasis;
    }

    .subtitle {
        
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $greyDefault;
        margin-bottom: 0;
        padding: 12px calc(#{$indentBasis} * 2);

        &:hover {
            background-color: transparent;
            cursor: default;
        }

        svg {
            margin-right: 10px;
            width: 13px;
            height: 13px;
        }
    }
}

.cvp_assets_table_heading {
    display: flex;
    align-items: center;

    .dropdown-title {
        margin-top: 1px;
    }

    .dropdown-body__content__items .button--action {
        width: 100%;
    }
}

.cvp_agencies_project_page {
    .__react_component_tooltip.info-tooltip {
        width: 390px;
    }
}

.dt-cell--reason {
    .tooltip__open-btn i {
        color: $greyDarker;
    }
}

.rejection-reason-dropdown {
    &.dropdown {
        float: none;
        white-space: normal;
        .dropdown-body__arrow {
            left: 50%;
        }
    }
    .dropdown-body {
        width: 340px;
        border-radius: 8px;
    }

    .dropdown-body__content__close.button--action {
        position: absolute;
        top: 8px;
        right: 14px;
        color: $greyDarker;
    }
}

.rejection_reason-tooltip {
    font-size: 14px;
    line-height: 24px;
    padding: $indentBasis calc(#{$indentBasis} * 2) !important;

    &:hover {
        background: $white !important;
    }

    .rejection_reason-title {
        margin-bottom: calc(#{$indentBasis} * 2);
        color: $blackLight;
        font-family: $BrandMedium;
        font-size: 16px;
        text-align: left;
    }

    .rejection_reason-body {
        text-align: left;
        color: $defaultTextColor;

        p {
            margin-bottom: $indentBasis;
        }

        .outer {
            padding-right: calc(#{$indentBasis} * 2);
        }

        a.withIcon {
            display: flex;
            align-items: center;
            
            svg {
                width: 13px;
                margin-left: 5px;
            }
        }
    }

    .rejection_reason-footer {
        margin-top: calc(#{$indentBasis} * 2);
        padding-top: calc(#{$indentBasis} * 2);
        border-top: $classicBorder;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .users-list-item {
            padding: 0;

            &:hover {
                padding-right: 0;
                background: transparent;
            }

            .users-list-item--name {
                font-family: $BrandMedium;
                font-size: 12px;
                line-height: 19px;
                margin-right: auto;
            }
        }

        .user-avatar {
            font-size: 10px;
        }

        .rejection_date {
            color: $greyDarker3;
            font-size: 10px;
            line-height: 16px;
        }
    }
}

.agent-list {
    padding-left: 0;
}

.agent-credentials-container {
    display: flex;
    flex-direction: column;
}

.cvp-agency-table {
    .dt-cell--actions__container {
        display: flex;

        .review-btn {
            margin-right: 16px;
        }
    }

    .asset_actions_dropdown {
        .dropdown-title {
            margin-top: 0;
        }
    }

    .custom_asset--preview {
        max-width: 100%;
    }
}

.asset-preview__sub-heading {
    margin-bottom: 20px;
}

.project-page-actions {
    display: flex;
    align-items: center;

    .dropdown {
        margin-left: calc(#{$indentBasis} * 2);
    }

    .agency_add_dropdown {
        height: 24px;
    }
}

.agencies-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .folder-section__title {
        margin-bottom: 0;
    }
}

.get-project-info-modal {
    .asset-preview__first {
        display: none;
    }

    .asset-preview__tab {
        border-top: 0;
    }
}

.asset-history-modal {
    .aside-modal__header {
        background-color: $greyLight5;
        border-bottom: $classicBorder;
    }

    .asset-history {
        &-items {
            padding: 24px 0;
        }

        &-item {
            margin-bottom: 16px;
            color: $greyDefault;
            display: flex;
        }

        &-data {
            margin-left: 8px;
            padding-bottom: 16px;
            border-bottom: $classicBorder;
            flex: 1;
        }

        &-data-wrapper {
            margin: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-user {
            font-family: $BrandMedium;
            font-size: 14px;
            line-height: 22px;
        }

        &-date {
            font-size: 12px;
            line-height: 18px;
            color: $greyDarker3;
            white-space: nowrap;
        }

        &-action {
            font-size: 10px;
            line-height: 16px;

            .action {
                color: $blue;
            }
        }

        &-comment {
            margin-top: 8px;
            font-size: 14px;
            line-height: 18px;
            color: $blackLight;
        }
    }
}

.cvp-agencies-project-page {
    .agency_upload_dropdown {
        &.opened {
            .dropdown-title {
                color: $blue;
                border-color: $blue;
                background-color: $blueLight5;
            }
        }
    }
    
    .dt-cell--actions__container {
        display: flex;
    }

    .dt-cell:last-child {
        width: 100px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
    }

    .content-store-header {
        padding-left: 0;
        padding-right: 0;
        margin-left: -10px;
    }

    .content-store-header--bottom {
        align-items: flex-start;
    }

    .dt--assets-table {
        .dt-cell--reason {
            width: 75px;
            flex-basis: 75px;
            min-width: 75px;
            flex-grow: 0;
            flex-shrink: 0;
            text-align: center;
        }

        .dt-cell--stateMachineStatus {
            width: 120px;
            flex-basis: 120px;
            min-width: 120px;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &.isContentOwnerWorkspace {
        .approval-form-table {
            .dt-cell--stateMachineStatus {
                width: 195px;
                flex-basis: 195px;
                min-width: 195px;
            }
        }
    }
}

.cvp-approve-buttons {
    display: flex;
    padding: 2*$indentBasis 3*$indentBasis;
    background-color: #fff;
    gap: $indentBasis;
    border-top: $classicBorder;
}

.upload-asset-modal.AGENCY_PROJECT_ASSET {
    .tabs_wizard--progress {
        visibility: hidden;
    }
}

.review_approval_pdf_modal {
    .top-panel-container .top-panel-container--left .asset-usage {
        display: none;
    }

    .top-panel-container .top-panel-container--left > * {
        margin-left: 0;
        border-left: 0;
        padding-left: 0;
    }
}

.cvp-review-asset-modal {
    &.aside-modal {
        max-width: 985px;
        @include asideModalAnimation(985px);

        .aside-modal__header {
            border-bottom: $classicBorder;
        }

        .aside-modal__body {
            padding: 0;

            & > div {
                height: 100%;
            }
        }

        .aside-modal__footer {
            justify-content: flex-end;
        }
    }

    .asset-preview__heading {
        font-size: 16px;
    }

    .cvp-review-asset-wrapper {
        height: 100%;
        display: flex;

        .cvp-review-asset-column {
            width: 50%;
            padding: 24px 32px;
            box-sizing: border-box;

            &.cvp-review-asset-form {
                padding: 0;
                position: relative;
            }
        }

        .cvp-video-container {
            border-right: $classicBorder;

            .asset-detail__preview > div {
                .video-js {
                    max-height: calc(100vh - 215px);
                }
            }
        }

        .cvp-approval-form {
            margin-top: 16px;
            padding: 16px;
            color: $greyDefault;
            border: $classicBorder;
            border-radius: 4px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .files-for-upload__item__image {
                margin-right: 16px;
                height: 56px;
                min-width: 42px;
            }

            .cvp-approval-form-name {
                font-family: $BrandMedium;
                font-size: 14px;
                line-height: 22px;
            }

            .cvp-approval-form-type {
                font-family: $BrandRegular;
                font-size: 12px;
                line-height: 18px;
                color: $greyDarker;
            }
        }
    }

    .tabs.wizard {
        .wizard-tabs-footer {
            padding: 2*$indentBasis 3*$indentBasis 2*$indentBasis;

            .tabs_wizard--progress {
                display: flex;
                flex-direction: column-reverse;
                font-size: 12px;
                line-height: 18px;
            }

            .tabs_wizard--progress--line {
                height: 3px;
                margin-top: 3px;
                border-radius: 4px;
            }
        }

        .carousel-panels {
            padding: 10px 3*$indentBasis 3*$indentBasis;
        }

        &.manage-asset-form {
            height: calc(100vh - 225px);
        }
    }

    .cvp-review-asset-btns {
        width: 100%;
        display: flex;
        position: absolute;
        bottom: -45px;
        right: 0;

        .button--action {
            width: 50%;
            border-radius: 0;
        }
    }
}

.cvp_assets_filter {
    .dropdown-body {
        width: 280px;
    }

    .filter_item {
        width: 100%;
        position: relative;
        margin-bottom: 0;
    }

    .collapse {
        border: none;
        padding: 0;

        .collapse-button {
            position: absolute;
            transition: .3s;
            width: 40px;
            height: 40px;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            padding: 0;
            border-radius: 3px;

            svg {
                path {
                    fill: $greyDarker !important;
                }
            }
        }

        .link-box__description {
            white-space: break-spaces;
            margin-bottom: 8px;
            margin-left: 40px;
            color: $greyDarker3;
        }
    }
}

.member-rights-radiogroup {
    .tooltip__open-btn {
        padding-top: 0;
        padding-left: 0;
    }

    .base-radio {
        .base-radio__label {
            margin-top: -3px;
        }
    }
}

.cvp-agency-page {
    .content-store-description {
        margin-top: -30px;
    }
}

.agency-actions-popup {
    .asset-actions-popup {
        max-width: 220px;

        .tether-container {
            margin-top: 0;
        }
    }
}

.create-agency {
    .button--file, .button--action {
        margin-left: 2px;
    }
}

.input-pair--invalid .add-member-button {
    border-color: $red;
}

.reject-asset-modal.base-redux-modal .base-modal .base-modal--body {
    overflow: initial;
}