.error_page {
    background-color: $white;
    height: 100%;
    .homePageLink {
        margin-right: auto;
    }
}

.error_page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 960px;
    padding: $indentBasis;
    margin: 0 auto;
}

.read-only-message {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: calc(#{$indentBasis} * 3);
    b {
        font-family: $BrandMedium;
    }
}