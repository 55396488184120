.ai-suggestion-wrapper {
    .open-suggestion-btn {
        margin-left: 8px;
    }

    .base-input--wrapper {
        display: flex;
        align-items: center;

        .black_undefined + .simple-tooltip-wrapper {
            margin-top: -35px;
        }

        > div {
            width: 100%;
        }
    }

    .ai-suggestion-content {
        border-radius: 8px;
        box-shadow: $grayShadow1;
        border: 1px solid $inputBorderColor;
        padding: $indentBasis $indentBasis $indentBasis 0;
        width: calc(100% - 40px);
        &--header {
            display: flex;
            align-items: center;
            margin-bottom: $indentBasis;
            svg {
                width: 14px;
                height: 14px;
            }
            .ai-suggestion-content--title {
                font-family: $BrandMedium;
                font-size: 14px;
                line-height: 24px;
                margin: 0 $indentBasis 0 2*$indentBasis;
            }
            .close-ai-suggestions {
                margin-left: auto;
            }
            .tooltip__open-btn {
                padding: 0;
                margin-top: 2px;
            }
        }
        &--body {
            min-height: 48px;
            .svg-loader {
                justify-content: center;
            }
            .ai-suggestion-list {
                .radio-group, .checkbox-group {
                    display: flex;
                    flex-direction: column;
                }
                .base-radio, .base-checkbox {
                    margin: 0;
                    padding: $indentBasis 2*$indentBasis;
                    &:hover, &.base-checkbox--checked {
                        background-color: $blueLight5;
                    }
                }
                .base-radio__button {
                    display: flex;
                    align-items: center;
                }
                .base-radio__label, .base-checkbox__label {
                    line-height: 24px;
                    margin-top: 0;
                    font-family: $BrandRegular;
                }
            }
            .ai-suggestion--empty, .ai-suggestion--single {
                margin-top: $indentBasis;
                padding: 0 2*$indentBasis;
                font-size: 14px;
                line-height: 24px;
            }
            .ai-suggestion--empty {
                color: $greyDarker;
            }
        }
        &--footer {
            display: flex;
            justify-content: flex-end;
            gap: 0 $indentBasis;
            margin-top: $indentBasis;
            .ai-suggestion--salectButtons {
                display: flex;
                gap: 0 $indentBasis;
                margin-right: auto;
            }
        }
    }
}

.suggestion_warning {
    display: flex;
    align-items: center;
    color: $greyDarker3;
    margin-top: -4px;
    margin-bottom: 4px;

    b {
        font-family: $BrandMedium;
    }

    svg {
        max-width: 13px;
        max-height: 13px;
        margin-right: calc(#{$indentBasis}/2);

        path {
            fill: $orange
        }
    }
}