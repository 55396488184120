$preview-column-width: 40%;
$text-column-width: 58%;

.asset-modal {
    .modal {
        max-width: 800px;
        width: 80%;
        min-height: 450px;
    }

    .modal__content {
        position: relative;
        padding: 20px;

        .tabs {
            width: 100%;
        }
    }

    .asset-actions {
        float: left;

        &__links {
            float: left;
            padding-left: 30px;
        }
        &.disabled {
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 30px;
                z-index: 4;
            }
        }
        div {
            float: left;
        }
    }

    .asset-detail__preview {
        box-sizing: border-box;
        top: 65px;
        right: 20px;
        position: absolute;
        max-width: 300px;
        width: $preview-column-width;

        .preview-container {
            background: $greyLight7;
            position: relative;
            width: 100%;
            max-width: 100%;
            height: 311px;
            text-align: center;
            img {
                max-height: 100%;
                max-width: 100%;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
            .video-js {
                overflow: hidden;
            }
            .video-tooltip {
                display: none;
            }
            &.focused {
                > div:not(.video-tooltip) {
                    outline: 2px solid $blue2 !important;
                    box-shadow: $whiteShadow;
                }
                .video-tooltip {
                    display: block;
                    width: 225px;
                    background: $black;
                    color: $white;
                    border-radius: 4px 0 4px 4px;
                    position: absolute;
                    padding: 5px;
                    left: -245px;
                    height: auto;
                    word-break: break-word;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: -10px;
                        width: 0;
                        height: 0;
                        border-top: 16px solid $black;
                        border-right: 16px solid transparent;
                    }
                }
            }
        }
        .buttons-panel {
            display: flex;
            justify-content: center;
        }
    }

    .modal__header {
        padding: 12px 10px 10px 23px;
        min-height: 52px;
        box-sizing: border-box;

        &__text {
            text-transform: capitalize;
            font-size: 20px;
        }
    }

    .modal__header--internal {
        @extend %usage--internal;
    }

    .modal__header--direct {
        @extend %usage--direct;
    }

    .modal__header--public {
        @extend %usage--public;
    }

    .modal__header--partner {
        @extend %usage--partner;
    }

    .modal__header--customer {
        @extend %usage--customer;
    }

    .modal__header--brand {
        @extend %usage--brand;
    }
    .modal__header--jam {
        @extend %usage--jam;
    }

    .modal__close {
        @extend %button--close-modal;

        svg {
            path {
                fill: $white;
            }
        }

        &:hover {
            path {
                fill: $greyDarker4;
            }
        }
    }

    .tabs__panel {
        width: $text-column-width;
        margin-top: 50px;
        max-height: 266px;
        overflow-y: auto;

        .data-pair {
            word-wrap: break-word;
        }
        .data-pair:last-child {
            padding-bottom: 0;
        }
    }

    .asset-preview--language-select {
        float: left;
        padding-left: 10px;
        background: $blue;
        height: 25px;
        color: $white;

        .input-pair__title-box__text {
            color: $white;
        }

        .input-pair__input-box {
            width: 120px;
            height: 25px;

            .react-select-box {
                height: 25px;
                border-color: transparent;
                background: transparent;

                &:after {
                    border-top-color: $white;
                    top: 10px;
                }

                &--opened {
                    border-color: transparent;
                    background: transparent;

                    .select__arrow-up-container:after {
                        position: absolute;
                        top: 10px;
                        border-bottom-color: $white;
                    }
                }

                &-label {
                    color: $white;
                    padding: 0 25px 0 10px;
                }
            }
        }
    }

    .hidden-button {
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .request-brand-feedback, .add-to-project-workspace {
        position: absolute;
        top: 20px;
        right: 20px;
        background: $white;
        padding: 0 5px;

        .button {
            color: $blue;
            font-family: Tahoma, sans-serif;
            text-transform: uppercase;
            font-weight: bold;
            height: 28px;
            padding: 0 15px;
            border-bottom: 3px solid $greyDarker4;
        }

        &:hover .button {
            border-color: $blue;
        }
    }
    &.focused {
        .modal {
            height: 480px;
        }
    }
}


.asset-detail-container--aem-legacy {
    .tabs__panel {
        margin-top: 90px;
    }

    .asset-detail__notice-container {
        font: 12px Arial, sans-serif;
        top: 100px;
        left: 20px;
        bottom: inherit;
        position: absolute;
        width: 400px;
        background: $redLight;
        padding: 3px 10px;
        line-height: 15px;
    }
}


.asset-detail__roadmap-message {
    position: absolute;
    top: 48px;
    width: 94%;
    display: flex;
    justify-content: flex-end;
    color: $red;
    font-style: italic;

    a {
        color: $greyDefault;
        text-decoration: underline;
    }
}

.asset-detail__actions-container {
    position: absolute;
    top: 70px;
}

.asset-modal--gallery {

    .modal {
        min-height: 495px;
    }

    .tabs__panel {
        max-height: 325px;
    }

    .asset-detail__preview {
        .preview-list {
            position: relative;
            width: 100%;
            height: 50px;
            margin-top: 10px;
            box-sizing: border-box;
            transition: .2s ease-in-out;

            .preview-list__item {
                display: block;
                float: left;
                box-sizing: border-box;
                width: 50px;
                height: 50px;
                margin-right: 4px;
                border: 1px solid $greyDarker4;

                a {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $greyLight7;

                    img {
                        flex-basis: 90%;
                        max-width: 90%;
                        max-height: 90%;
                    }
                }
            }

            .preview-list__item--active {
                border: 2px solid $defaultColor;
            }
        }
    }

    .swipe-container {
        overflow: hidden;
    }

    .preview-list-container {
        transition: .2s ease-in-out;
        position: relative;
    }
}

.preview-list__show-next-btn,
.preview-list__show-prev-btn {
    top: 0;
    z-index: 2;
    position: absolute;
    height: 50px;
    width: 24px;
    background-color: $greyDarker4;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 19px;
    }
}

.preview-list__show-next-btn {
    right: -1px;
    &:before {
        right: 9px;
        border-top: 6px solid transparent;
        border-left: 5px solid $white;
        border-bottom: 6px solid transparent;
    }
    &:hover {
        background-color: $defaultColor;
        &:before {
            border-left-color: $greyDarker4;
        }
    }
}

.preview-list__show-prev-btn {
    left: -1px;
    &:before {
        left: 9px;
        border-top: 6px solid transparent;
        border-right: 5px solid $white;
        border-bottom: 6px solid transparent;
    }

    &:hover {
        background-color: $greyDefault;
        &:before {
            border-left-color: $greyDarker4;
        }
    }
}

.spinner--preview {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -10px;
    margin-top: -15px;
}

.tabs__panel__asset-versions {
    &--header {
        border-bottom: 1px solid $greyDarker4;
        display: flex;

        &-item {
            padding: 5px 10px;
            font-weight: bold;
            font-size: 11px;
            color: $greyDefault;

            &.version {
                padding-left: 20px;
                width: 100px;
            }
        }
    }

    &--tr {
        border-left: 10px solid $greyDarker4;

        &:first-child {
            border-left-color: $saladGreen;
        }

        &-inner {
            border-bottom: 1px solid $greyDarker4;
            padding: 0 0 0 10px;
        }
    }

    &--body {
        height: 236px;
        overflow: hidden;
        overflow-y: auto;

        &-item {
            display: flex;
            align-items: center;

            &-link {
                background: $greyLight7;
                padding: 15px;
                margin-bottom: 7px;
                min-height: 17px;

                &.link--hidden {
                    display: none;
                }

                .asset-actions__item__tooltip {
                    transform: none;
                    right: -115px;

                    &:after {
                        left: 16px;
                    }
                }

                &.to-generate {
                    .asset-actions__item__tooltip {
                        right: -177px;
                    }
                }
            }

            &-cell {
                padding: 7px 10px 7px 0;
                height: 22px;
                line-height: 12px;
                color: $black;
                display: flex;
                align-items: center;

                &.version {
                    width: 110px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                &.date {
                    width: 130px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                &.action {
                    flex-grow: 1;
                    flex-shrink: 1;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 0;
                }
            }

            .button--action--green {
                width: 110px;
                height: 22px;
                line-height: 22px;
                margin: 0;
                padding: 0 12px;
                color: $white;
                border-color: $saladGreen;
                font-family: $BrandMedium;

                &:hover {
                    border-color: $saladGreenDark;
                }
            }
        }
    }
}

@include media-ipad-portrait {
    .asset-modal {
        .asset-detail__preview {
            .preview-container {
                .video-js {
                    width: 245px !important;
                    height: 245px !important;
                }
            }
        }
    }
}
