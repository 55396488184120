.scroll-to-top {
    &.button--action:not(.dropdown-title) {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 3px solid $greyDarker4;
        position: fixed;
        bottom: 10px;
        right: 10px;
        opacity: 0;
        visibility: hidden;
        transition: opacity, visibility, color, border-color 0.2s;

        svg {
            width: 32px;
            height: 32px;
            path {
                fill: $greyDarker4;
            }
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }

        &:hover {
            background-color: transparent;
            border-color: $greyDarker;

            svg {
                path {
                    fill: $greyDarker
                }
            }
        }
    }
}