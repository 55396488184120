.upload-asset-btn.button--action {
    margin-left: calc(#{$indentBasis} * 2);
}

.actions-wrapper {
    .upload-asset-btn.button--action {
        margin-left: 0;
    }
}

@media screen and (max-width: 425px) {
    .upload-asset-btn.button--action {
        background: transparent;
        border: none;

        &.upload-btn-rounded {
            height: 24px;
            width: 24px;
            line-height: 24px;
        }
    }
}

@import "../asset_upload_modal/styles/asset_upload_modal";
@import "../files_upload/styles/files_upload";
@import "../files_upload/styles/file_preview";
@import "../items_upload/styles/upload_progress";
@import "../items_upload/styles/duplicate_preview";
@import '../manage_asset_metadata/styles/manage_asset_metadata';
@import './style_responsive';
