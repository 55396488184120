.publishing-steps {
    &__item {
        padding-bottom: 5px;
        &__controls {
            display: flex;
            margin-right: 30px;
            .button--action.button-step {
                font-family: $BrandMedium;
                flex-grow: 1;
                background: $greyLight2;
                border-radius: 0;
                display: flex;
                justify-content: flex-start;
                box-shadow: none;
                font-size: 15px;
                color: $blackLight;
                padding-left: 5px;
                position: relative;
                &:hover {
                    background: $greyLight2;
                }
                &:disabled {
                    background: $greyLight2;
                }
                .button-step--expanded {
                    span {
                        &:before {
                            position: absolute;
                            left: 16px;
                            top: 13px;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 6px 4.5px 0 4.5px;
                            border-color: $blackLight transparent transparent transparent;
                        }
                    }
                }
            }
            .button-next {
                margin-left: 5px;
                min-width: 60px;
            }
        }
    }

    .asset-published-place {
        position: relative;

        & > .tooltip__container  {
            position: absolute;
            top: 3px;
            left: 305px;
        }
    }
}

.cmps-publishing-form  {
    .button--action__toolTip {
        color: $greyBlueDark;
        background-color: $white;

        &::after {
            background-color: $white;
        }
    }

    .asset-authorization-is {
        .button--action__toolTip {
            font-size: 13px;
            text-align: left;
            white-space: normal;
            width: 150px;
            top: auto;
            bottom: 140%;

            &::after {
                bottom: -6px;
                top: auto;
            }
        }
    }
}
