@media #{$mobile} {
    button {
        &.button--action {
            .button--action__toolTip {
                display: none!important;
            }
        }
    }
}

@mixin increaseClickableArea() {
    .modal__close,
    .base-radio button,
    .panel-header,
    .group-item--action .add,
    .base-checkbox__icon,
    .region--top .aside-header-box .logo a,
    .asset-actions-popup__close,
    .modal-minimize,
    .anime-toggle--button,
    .assets-list__item__details-link,
    .menu-trigger,
    .button--cancel,
    .button--action {
        position:relative;

        &:before {
            position:absolute;
            content:'';
            top:-5px;
            right:-5px;
            left:-5px;
            bottom:-5px;
            //border: 1px solid red;
        }
    }

    .modal__close {
        position: absolute;
    }
}

@media #{$mobile} {
    @include increaseClickableArea();

    .button--action__toolTip {
        display: none !important;
    }
}

@media #{$iPad} {
    @include increaseClickableArea();

    .button--action__toolTip {
        display: none !important;
    }
}
