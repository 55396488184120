@import "../../../node_modules/coresap/lib/scss/_colors.scss";

//fonts
$BrandMedium: BrandMedium, Arial, sans-serif;
$BrandRegular: BrandRegular, Arial, sans-serif;
$BrandLight: BrandLight, Arial, sans-serif;
$BrandBold: BrandBold, Arial, sans-serif;
$BrandBlack: BrandBlack, Arial, sans-serif;
$BrandBook: BrandBook, Arial, sans-serif;

$defaultColor: $greyDarker3;
$pageBgColor: $greyLight7;
$inputBorderColor: $greyDarker4;

$sectionBorder: 1px solid $greyLight2;
$folderItemBorder: 1px solid $greyLight2;

$previewRatio: calc(600 / 338);

/* Dimentions */
$mobileSmall: 'screen and (max-width: 375px)';
$mobile: 'screen and (max-width: 640px)';
$laptopSmall: 'screen and (max-width: 1024px)';

$maxLayoutWidth: 1700px;
$topRegionHeight: 48px;
$cabinetHeaderHeight: 65px;

/* Colors, Fonts variables */
$blueInsetShadow: 0 0 8px $blue inset;
$whiteShadow: 0 0 1em $white;


/* DATATABLES */
$DT_TINY: 40px;
$DT_SMALL: 100px;
$DT_LONG: 210px;
$DT_ID: 140px;
$DT_DATE: 140px;
$DT_TEXT: 320px;
$DT_OTHER: 140px;

/* Fonts */
$small: 11px;
$normal: 12px;
$middle: 14px;
$normalLineHeight: 1.3;

$buttonsFont: bold 11px $BrandBold;

/* Dimensions of blocks */
$ASSET_LIST_ITEM_HEIGHT: 60px;
$ASSET_LIST_ITEM_HEIGHT-STICKER: $ASSET_LIST_ITEM_HEIGHT;

$defaultColorText: $greyDefault;

$modalDefaultWidth: 600px;

@mixin asideModalAnimation($width) {
    max-width: $width;

    &.aside-modal-transition-enter {
        max-width: 0;
        overflow: hidden;
    }

    &.aside-modal-transition-enter-active {
        max-width: $width;
        transition: max-width 0.5s;
    }

    &.aside-modal-transition-leave {
        max-width: $width;
    }

    &.aside-modal-transition-leave-active {
        max-width: 0;
        transition: max-width 0.5s;
        overflow: hidden;
    }
}

@mixin bottomPanelAnimation($height) {
    height: $height;

    &.bottom-panel-transition-enter {
        height: 0;
        overflow: hidden;
    }

    &.bottom-panel-transition-enter-active {
        height: $height;
        transition: height 0.5s;
    }

    &.bottom-panel-transition-leave {
        height: $height;
    }

    &.bottom-panel-transition-leave-active {
        height: 0;
        transition: height 0.5s;
        overflow: hidden;
    }
}

@mixin centeredSmallThumbnail() {
    .asset-image-wrapper {
        width: 100%;
        //height: calc(#{100%} / #{$previewRatio});
        overflow: hidden;
        transition: height 0.05s ease-out;

        &.is-smallIcon--wrapper {
            display: flex;
            justify-content: space-around;
            vertical-align: middle;
            align-content: center;
            flex-wrap: wrap;

            img.content-preview--image.is-smallIcon {
                height: auto;
                width: auto;
                max-width: none;
                max-height: none;
                min-width: 0;
                min-height: 0;
                flex-grow: 0;
                flex-shrink: 0;
                margin: auto;
            }
        }
        &.default-thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.noTextTransform {
    text-transform: none !important;
}
