.config-service-page {
    .dt-cell--actions__container {
        display: flex;

        & > span {
            margin-right: calc(#{$indentBasis} * 2);

            &:last-child {
                margin-right: 0;
            }
        }
    }
}