.user-profile__info__item__label,
.user-profile__info__item__value {
    box-sizing: border-box;
    display: inline-block;
}

.user-profile__short-info {
    margin-top: $indentBasis;
}

.user-profile__short-info__name {
    font-family: $BrandMedium, Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: $blackLight;
}

.user-profile {
    &__links {
        &__you {
            padding-right: 10px;
            color: $navy;
            font-family: $BrandMedium;
            font-size: 16px;
        }
    }

    &__image {
        width: 96px;
    }

    &__border-holder {
        border-bottom: 1px solid $greyDarker4;
    }

    &__info{

        margin-top: calc(#{$indentBasis} * 2);

        &__ownership {
            margin-top: 23px;
        }

        &__item {
            margin-bottom: $indentBasis;
            overflow: hidden;
            display: flex;
            align-items: center;

            &__label {
                font-family: $BrandRegular, Arial, sans-serif;
                line-height: 18px;
                color: $greyDarker3;
                white-space: nowrap;
                width: 80px;
                margin-right: calc(#{$indentBasis} * 2);
            }
            &__value {
                color: $blackLight;
                @extend %ellipsis;
                vertical-align: text-bottom;
            }
        }
    }
    &__top-row {
        display: flex;
        flex-direction: column;
    }
}
