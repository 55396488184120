/*Material icons*/
@import 'material-icons/iconfont/material-icons.css';

.material-icons {
    font-size: 1.4rem;
}

/*Brand-Light*/
@font-face {
    font-family: 'BrandLight';
    src: local('BrandLight'),
    url('../fonts/72Brand-Light.woff?v=1') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandLight';
    src: local('BrandLight'),
    url('../fonts/72Brand-Light.woff?v=1') format('woff');
    font-weight: bold;
    font-style: normal;
}

/** Brand-Book **/
@font-face {
    font-family: 'BrandBook';
    src: local('BrandBook'),
    url('../fonts/72Brand-Book.woff?v=1') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandBook';
    src: local('BrandBook'),
    url('../fonts/72Brand-Book.woff?v=1') format('woff');
    font-weight: bold;
    font-style: normal;
}

/** Brand-Regular **/
@font-face {
    font-family: 'BrandRegular';
    src: local('BrandRegular'),
    url('../fonts/72Brand-Regular.woff?v=1') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandRegular';
    src: local('BrandRegular'),
    url('../fonts/72Brand-Regular.woff?v=1') format('woff');
    font-weight: bold;
    font-style: normal;
}

/** Brand-Medium **/
@font-face {
    font-family: 'BrandMedium';
    src: local('BrandMedium'),
    url('../fonts/72Brand-Medium.woff?v=1') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandMedium';
    src: local('BrandMedium'),
    url('../fonts/72Brand-Medium.woff?v=1') format('woff');
    font-weight: bold;
    font-style: normal;
}

/** Brand-Bold **/
@font-face {
    font-family: 'BrandBold';
    src: local('BrandBold'),
    url('../fonts/72Brand-Bold.woff?v=1') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BrandBold';
    src: local('BrandBold'),
    url('../fonts/72Brand-Bold.woff?v=1') format('woff');
    font-weight: bold;
    font-style: normal;
}

/** Brand-Black **/
@font-face {
    font-family: 'BrandBlack';
    src: local('BrandBlack'),
    url('../fonts/72Brand-Black.woff?v=1') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BrandBlack';
    src: local('BrandBlack'),
    url('../fonts/72Brand-Black.woff?v=1') format('woff');
    font-weight: bold;
    font-style: normal;
}

/*use differing font-weight and font-style for correct displaying*/
