@media #{$mobile} {
    .asset-actions-popup .actions__container.actions__container--links.reuse_popup .asset-actions-popup {
        width: auto;
        box-shadow: none;

        .button--action {
            box-shadow: none;
            border: 0;
        }
    }
}
