$load-more-width: 90px;

.project-workspace-controls {
    justify-content: space-between;
    padding: 15px 0;

    .search-input--keyword {
        margin-right: 0;

        .input-pair__input-box {
            width: 300px;
        }
    }
   button.search-input__button {
       background: transparent;
       border: 0;
       box-shadow: none;
       &:hover {
           border-color: transparent;
           background: transparent;
       }
   }

    .search-input--project-id {
        .input-pair__input-box__error,
        .error {
            position: absolute;
            left: 0;
            top: 100%;
        }
    }
}

.dt--project-workspace {
    .dt-cell--projectNumber {
        width: 90px;
    }

    .dt-cell--projectName {
        flex-grow: 1;
        max-width: 635px;
    }

    .dt-cell--projectCoordinator {
        width: 200px;
    }

    .project-id--link {
        font-weight: bold;
        font-size: 12px;
    }
}


.project_identification_label {
    padding-left: 20px;
    font-family: $BrandRegular;
    font-size: 14px;

    a, b {
        font-family: $BrandMedium;
    }
}

.assets_search_control {
    display: flex;

    .search-input {
        .input-pair {
            padding: 0;

            &__input-box {
                width: 300px;
            }
        }
    }

    .input-pair__input-box {
        width: 265px;
    }
}

.searched_assets_container {
    padding-top: 43px;
    background: url("../../../../styles_images/bulk-bg.png");
    position: relative;

    .searched_assets-info {
        position: absolute;
        left: 0;
        top: 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        button {
            padding: 0 5px;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .searched_assets_scrollable {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        height: 184px;
        padding-bottom: 30px;

        .load-more--container {
            width: $load-more-width;
            display: inline-block;
            height: 186px;
            position: relative;
            // reset default "load more" styles
            button.load-more {
                width: $load-more-width;
                font: $buttonsFont;
                font-weight: normal;
                font-size: 14px;
                text-transform: capitalize;
                color: $blackLight;
                background: $white;
                border: none;
                position: absolute;
                top: 55px;


                &:after {
                    content: "\2026"; // three dots
                    display: block;
                    line-height: 6px;
                }
            }
        }
    }
}
.cabinet-controls.project_assets {
    display: flex;
    padding: 0;
    justify-content: space-between;
    background: none;

    .bulk-actions {
        &--dropdown {
            .dropdown-body {
                left: 0;
                right: auto;
            }
        }
    }
    .bulk-actions .bulk-actions--dropdown .dropdown-body
    .project-assets-title {
        font: 16px $BrandMedium;
        color: $blackLight;
    }
}

.project-page {
    .sort-controls__item {
        .sort-controls__item__label {
            color: $blackLight;
        }
    }
    .search-input {
        .base-text-input {
            border: 1px solid $greyDarker2;
        }
    }
    .project-workspace-controls {
        .input-pair__title-box {
            display: block;
        }
    }

}


.project-workspace-page {
    height: 100%;
    margin: 0 -20px;

    &__header {
        .dropdown-body__content__items > *:hover {
            background: none;
        }
    }

    &__content {
        padding: 20px 30px;
    }

    .search-input {
        .base-text-input {
            border: 1px solid $greyDarker2;
        }
    }

    .bulk-actions {
        .dropdown-title {
            background: $white;
            &:hover {
                background: $greyLight7;
            }
        }
        .dropdown.opened {
            .dropdown-title {
                background: $greyLight7;
            }
        }
        .dropdown-body {
            a, button {
                color: $blackLight;
                font-family: $BrandRegular;
                &:hover {
                    background: $greyLight7;
                }
            }
        }
    }
    .dt--project-assets {
        .sort-controls__item {
            .sort-controls__item__label {
                color: $blackLight;
            }
        }
        .dt__sort-controls {
            height: 42px;
            background: $greyLight7;
        }
        .dt-cell {
            > span, .clipped-text__full-description__text {
                color: $greyDefault;
            }
            .assets-list__item__details-link {
                color: $blue;
            }
            .asset-actions__item--icon-mode {
                margin-right: 0;
                svg {
                    path {
                        fill: $blue;
                    }
                }
            }
            .other-info-box {
                svg, img {
                    margin-right: 5px;
                    flex-shrink: 0;
                    flex-grow: 0;
                }
                svg {
                    width: 14px;
                    height: 14px;
                }
                &.type {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    font-family: $BrandMedium;
                    margin: 0 0 0 8px;
                    &:after {
                        right: -8px;
                    }
                    &:before {
                        left: -8px;
                    }
                }
            }
        }
        .actions__container {
            > button {
                svg {
                    path {
                        fill: $blue;
                    }
                }
            }
            svg {
                path {
                    fill: $blackLight;
                }
            }
            .asset-actions__item--popup-mode {
                .asset-actions__item__text {
                    color: $blackLight;
                }
            }
            &--links {
                .asset-actions-popup {
                    .button--action.with-icon {
                        box-shadow: none;
                        font-family: $BrandRegular;
                        color: $blackLight;
                    }
                }
            }

        }
    }
    .bookmark-link-container {
        .bookmark-link, .publishing_form--link {
            color: $blackLight;
        }
    }
    .cabinet-header__text.visible {
        color: $blackLight;
    }
    .asset-actions-popup__close__text {
        color: $greyDefault
    }
    .asset-actions-popup {
        width: 275px;
        @media #{$mobile} {
            width: 100%;
        }
    }
}

.dt--project-assets.assets-table {
    .ui-recently-uploaded {
        box-shadow: $blueInsetShadow;
        border: 2px solid $greyLight7;
    }

    // Transition for box-shadow of recently added item
    .dt__item {
        transition: box-shadow .5s ease-in-out;
    }

    .dt-cell--actions {
        min-width: auto;
        max-width: 20px;
        flex-basis: 0;
        padding: 0;
    }
    .dt-cell--src {
        max-width: 70px;
    }
    .dt-cell--fileSize {
        flex-basis: 120px;
        min-width: 120px;
    }
    .dt-cell--dateModified {
        flex-basis: 130px;
        min-width: 130px;
    }
    .dt-cell--doi {
        flex-basis: 120px;
        min-width: 120px;
    }
    .dt-cell--fileName {
        flex-grow: 5;
        flex-shrink: 5;
        min-width: 110px;
    }
    .dt-cell--name {
        flex-grow: 4;
        flex-shrink: 4;
    }
    .dt-cell--doi,
    .dt-cell--fileSize,
    .dt-cell--dateModified {
        flex-shrink: 0;
        flex-grow: 0;
    }
    .sort-controls__item--actions {
        border-right: 1px solid $blackLight;
    }

    .asset-final {
        .anime-toggle {
            visibility: visible;
        }
    }

    button.assets-list__item__details-link {
        font-family: $BrandMedium;
    }

    .dnd-set-relations {
        box-shadow: none;
    }
}

.mark-as-final__buttons {
    padding-left: 0 !important;
}

.publishing_form--link {
    font: 14px/16px $BrandBold;
    margin-left: 20px;
    text-decoration: underline;

    &:hover {text-decoration: none}
}

@include media-ipad-portrait {
    .project-workspace-page {
        .cabinet-header__text {
            width: 150px;
        }

        .assets_search_control {
            display: block;
        }

        .dt-cell--fileName {
            display: none !important;
        }
    }

    .bookmark-link-container {
        .bookmark-link {
            margin-left: 0;
        }
        .publishing_form--link {
            margin-left: 0;
        }

    }

    .project_identification_label {
        padding-left: 5px;
        width: 120px;
        display: inline-block;
    }

    .cabinet-controls {
        align-items: flex-start;
    }
}

.dt-grid__item {
    .dt-cell--src {
        .dt-grid--column_name {
            display: none;
        }
    }

    .clipped-text__full-description {
        right: 0;
        top: 18px;
    }
}

@import "./project_workspace_responsive";
