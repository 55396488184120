.scroll-up button {
    box-sizing: border-box;
    padding: 0;
    width: 37px;
    height: 37px;
    right: auto !important;
    left: 50% !important;
    bottom: 25px !important;
    display: flex;
    align-items: flex-start;
    margin-left: -50%;

    //Do not remove the code below, it's magic
    //will be bug with cutting the select dropdown in advanced search
    visibility: visible !important;
    transition: none !important;

    span {
        display: inline-block;
    }
}

@media (min-width: 1400px) {
    .scroll-up button {
        margin-left: -700px;
    }
}

.back-cabinet {
    background-color: $navy;
    border-bottom: none;
    padding: 14px 9px 15px 9px;

    &.active {
        background-color: $navy;
        &:hover {
            background-color: $navy;
        }
    }
}
