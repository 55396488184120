.api-workspace-page {
    .api-workspace-wrapper {
        padding: 0 15px;
    }

    .assets-page-header {
        .description {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .preload-projects {
        margin-top: 60px;
        
        .workspace-projects-list__item {
            margin: 0 8px 16px;
        }
    }

    @extend %custom_folder--thumbnail;
    @extend %custom-folder-preview;
}

.api-workspace-manage-page {
    .dt-cell--id {
        padding-left: 0;

        &.sort-controls__item {
            padding-left: 10px;
        }
    }
}

.import-config-btn {
    &.button--action {
        margin-right: calc(#{$indentBasis} * 2);
    }
}

.profile-import-config-modal {
    .file-preview {
        margin-top: 16px;
    }
}

.profile-field-wrapper {
    .asset-preview__data {
        .asset-preview__title {
            width: 150px;
        }
    }
}