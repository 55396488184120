.selection-container {
    display: flex;
    align-items: center;
    .selection-counter {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #ffffff;
        background-color: $blue;
        font-family: $BrandMedium;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
    }
    .dropdown {
        margin-right: 12px;
        
        &-body {
            bottom: 32px !important;
            top: auto;
            right: 15px;
            left: 0;
        }
    }

    &.no-selection {
        .dropdown-body {
            display: none !important;
        }

        .dropdown-title {
            cursor: default !important;

            &:hover {
                background: none !important;
            }
        }
    }
}
