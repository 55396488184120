.curators-dashboard-page {
    height: 100%;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .curators-dashboard-header {
        font-family: $BrandMedium;
        font-size: 22px;
        line-height: 32px;
        color: $blackLight;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .separator {
            width: 1px;
            height: 16px;
            margin-left: calc(#{$indentBasis} * 2);
            margin-right: $indentBasis;
            background: $greyDarker4;
        }
        h3 {
            font-size: 1.4rem;
            font-family: $BrandRegular;
        }
    }

    .curators-dashboard-folder {
        padding-top: $indentBasis;
        width: fit-content;
        display: flex;
        font-family: $BrandMedium;
        font-size: 2.2rem;
        margin-bottom: 3*$indentBasis;

        .set_default {
            margin-top: 3px;
            margin-left: $indentBasis;
            svg {
                path {
                    fill: $greyDarker
                }
            }
            &.is_active {
                svg {
                    path {
                        fill: $orange
                    }
                }
            }
        }
    }

    .manage-authorized-groups-dropdown {
        .dropdown-body {
            z-index: 3;
            padding: 8px 0;
        }
        .authorized-group-item {
            width: 100%;
        }
    }

    .curators-dashboard-grid {
        padding-bottom: 25px;
        margin: 0 -8px;
        flex: 1;
        display: flex;
        box-sizing: border-box;

        &-col {
            margin: 0 8px;
            display: flex;
            flex-direction: column;

            &.col-full-width {
                flex: 1;
            }

            &.col-fixed-width {
                flex-grow: 0;
                flex-shrink: 0;
            }

            &.col-expiring {
                width: 529px;
            }
        }
    }

    .dashboard-widget {
        &.my-feed-widget,
        &.widget-teams {
            padding-left: 0;
            padding-right: 0;
            display: flex;
            flex-direction: column;

            .widget-content {
                flex: 1;

                & > div {
                    min-height: 330px;
                }
            }
        }

        &.my-feed-widget {
            min-height: 628px;

            .widget-header {
                padding-left: 24px;
                padding-right: 105px;
                margin-top: -8px;
                margin-bottom: 9px;
            }
        }

        &.widget-expiration {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            margin-bottom: calc(#{$indentBasis} * 2);

            .widget-header {
                margin-bottom: 0;
            }

            .coresap-loader {
                margin: 24px;
            }
        }

        &.widget-teams,
        &.widget-expiration {
            .widget-header {
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        &.widget-teams {
            min-height: 392px;
            padding-bottom: 15px;
        }

        .preload-datatable {
            .dt__item,
            .dt__sort-controls {
                height: 48px;
            }
        }
    }

    .set-up-overlay {
        width: 100%;
        height: 100%;
        padding: 20px;
        background: rgba(18, 23, 28, 0.6);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        box-sizing: border-box;

        .set-up-modal {
            max-width: 470px;
            background: $white;
            border-radius: 0;
            font-family: $BrandRegular;
            font-size: 16px;
            line-height: 24px;
            color: $greyDefault;
            box-sizing: border-box;
        }

        .set-up-modal-header {
            padding: 2*$indentBasis 3*$indentBasis;
            font-family: $BrandMedium;
        }

        .set-up-wrapper {
            padding: 3*$indentBasis;
            display: flex;
            align-items: flex-start;
        }

        .set-up-title {
            font-family: $BrandMedium;
        }

        .set-up-description {
            b {
                font-family: $BrandMedium;
            }
        }

        .set-up-modal-buttons {
            padding: 2*$indentBasis 3*$indentBasis;
            display: flex;
            justify-content: flex-end;

            .button--action {
                text-transform: none;
            }
        }

        .set-up-img {
            width: 96px;
            height: auto;
            margin-right: 3*$indentBasis;
            flex-shrink: 0;
        }
    }

    .set-up-wrapper {
        height: 100%;
    }

    .set-up-placeholder {
        padding: 0 24px;

        .set-up-placeholder-item {
            height: 48px;
            border-bottom: $classicBorder;
            display: flex;
            align-items: center;

            & > div, img {
                margin: 0 10px;
            }

            .set-up-checkbox {
                width: 18px;
                height: 18px;
                margin-left: 3px;
                border: 1px solid $greyDarker;
                border-radius: 2px;
                box-sizing: border-box;
            }

            .set-up-id {
                width: 81px;
            }

            .set-up-name {
                width: 174px;
            }

            .set-up-empty {
                flex: 1;
            }

            .set-up-date {
                width: 64px;
                margin-right: 0;
            }

            .set-up-id,
            .set-up-name,
            .set-up-date {
                height: 8px;
                background: $greyDarker2;
            }
        }
    }
}

.curators-dashboard--holder {
    position: absolute;
    right: 36px;
    margin-top: -43px;
    display: flex;

    & > * {
        margin-left: $indentBasis*2;
    }
}

.curators-dropdown {
    max-width: 200px;
    height: 35px;
    position: relative;
    flex: 1;
    margin-right: auto;
    z-index: 2;
    width: 200px;

    .base-input {
        height: 100%;

        .base-select {
            height: 100%;

            .react-select__input {
                margin-top: -5px;
            }

            .react-select__clear-indicator {
                display: none;
            }

            .react-select__control {
                height: 100%;

                .react-select__value-container {
                    height: 100%;
                }
            }
        }
    }
}

.presets-info {
    padding: 16px 16px 24px;
    background: $white;
    border: $classicBorder;
    border-radius: 4px;
    color: $greyDefault;

    .presets-info-img {
        width: 72px;
    }

    .presets-info-title {
        margin-bottom: 8px;
        font-family: $BrandMedium;
        font-size: 14px;
        line-height: 21px;
    }

    .presets-info-description {
        font-family: $BrandRegular;
        font-size: 12px;
        line-height: 18px;
    }
}

.curators-dropdown-item {
    width: 100%;
    height: 40px !important;
    box-shadow: none !important;
    border-radius: $dropdownBorderRadius $dropdownBorderRadius 0 0 !important;
    justify-content: flex-start !important;

    &:hover {
        background-color: $greyLight5 !important;
        border-color: $greyLight5 !important;
    }

    svg, .material-icons {
        margin-right: 8px;
    }
    svg + span {
        margin-left: 0;
    }
}

.react-select__menu .react-select__option .selected-option-icon {
    position: relative;
    top: auto;
    right: auto;
}

.manage-presets-modal {
    .manage-preset--items {
        .presets--list {
            padding: 0;
        }
    }

    .tab-item {
        background: $white;

        &:not(.tab-item--selected):hover {
            background: $hoverBg;
        }
    }

    .presets-form-wrapper {
        height: 100%;
        display: flex;
        margin-top: 3*$indentBasis;
    }

    .tabs-container  {
        .form-container--scroll {
            padding: 0;
            height: calc(100vh - 150px);
            .outer {
                height: 100%;
            }

            .manage-preset-form {
                width: 100%;
                padding: 5px 24px;
                box-sizing: border-box;

                .tooltip__open-btn {
                    vertical-align: sub;
                }
            }
        }

        .tabs-list--inner {
            padding-bottom: 95px;
            margin-top: 3*$indentBasis;
        }
    }

    .react-tagsinput-tag,
    .react-tagsinput-input input {
        font-size: 14px;
    }

    .preset-form-filters {
        .auto-hide--container {
            background: $white;
            border-left: $classicBorder;
        }

        .filters {
            padding-top: 0;
        }

        .filters-group {
            border-bottom: $classicBorder;
        }
    }

    .input-pair {
        width: 100%;
        margin-bottom: 9px;
    }

    .preset-date-picker {
        max-width: 238px;
    }

    .preset-actions {
        padding-bottom: 0;
        height: 24px;

        .button--action {
            min-width: 24px;
            svg {
                path {
                    fill: $blackLight;
                }
            }
        }

        .dropdown-title {
            box-shadow: none;
        }

        .primary {
            svg {
                path {
                    fill: $orange !important;
                }
            }
        }

        .dropdown-body {
            width: 204px;
            top: 42px;
            right: 0;
            left: auto;
        }

        .dropdown-container {
            height: auto;
        }


    }

    .filters-wrapper {
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;

        .filter-item {
            margin-top: 0.5*$indentBasis;
        }
    }
}

.my-feed-table {
    &.dt {
        .dt__sort-controls {
            background-color: $greyLight5;
        }

        &.dt--assets-table {
            .dt__items {
                margin-top: 0;
            }

            .dt-cell {
                padding: 9px 8px;
            }

            .dt-cell--actions {
                flex-basis: 120px;
                min-width: 120px;
                max-width: 135px;
            }

            .dt__item {
                height: 48px;
                max-height: 48px;
                margin-top: 0;
            }
        }
    }
    .dt__item {
        .dt-cell--actions {
            .hovered--action {
                display: flex;
                opacity: 0;
                transition: opacity .2s;
            }
        }
        &:hover,
        &.dt__item--active {
            background: $greyLight5;

            .dt-cell--actions {
                .hovered--action {
                    opacity: 1;
                }
            }
        }

        .asset-actions-popup {
            top: 0;
            right: 40px;
        }
    }

    .dt__item--active,
    .dt-disabled .dt__item {
        background-color: $greyDarker2;
        opacity: 1;
    }

    &--small {
        &.dt {
            border: none;
            &.dt--assets-table {
                .dt-cell--checkbox {
                    padding-left: 12px;
                }
                .dt-cell--dateImported {
                    padding-right: 12px;
                }
                .dt__item {
                    padding: 0 45px 1px 12px;
                }
            }
        }
        .dt-cell--checkbox {
            width: 35px;
            min-width: 35px;
            max-width: 35px;
        }

        &.dt--assets-table {
            .dt__sort-controls {
                background: transparent;
                padding: 0 12px;

                .dt-cell {
                    visibility: hidden;

                    &.dt-cell--dateImported {
                        flex-grow: 0;
                        flex-shrink: 0;
                        min-width: 0;
                    }

                    &.dt-cell--dateImported,
                    &.dt-cell--checkbox {
                        visibility: visible;
                    }
                }
            }
        }

        .dt-cell--src {
            max-width: 50px;
        }

        .dt-cell--doi {
            flex-shrink: 0;
            flex-grow: 0;
        }

        .dt-cell--actions {
            position: absolute;
            top: 3px;
            right: 15px;

            .hovered--action {
                display: none;
            }
        }

        .dt__item {
            padding-left: 24px;
            padding-right: 24px;

            &:hover,
            &.dt__item--active {
                .my-feed-upload-date {
                    color: transparent;
                }
                .dt-cell--actions {
                    .hovered--action {
                        opacity: 1;
                        display: flex;
                    }
                }
            }
        }
    }

    .assets-list__item__details-link {
        color: $blue;
    }

    .my-feed-upload-date {
        font-family: $BrandRegular;
        font-size: 12px;
        line-height: 18px;
        color: $greyDarker3;
        text-align: right;
    }

    .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: -6px;

        .button--action.my-feed-action-btn {
            margin: 0 $indentBasis;

            &.asset-actions-trigger {
                padding: 0 10px;
            }
        }

        .simple-tooltip-wrapper {
            width: 24px;
            margin: 0 15px;
            display: block;
        }
    }
}

.fullscreen-modal {
    .dt.expiring {
        .dt__sort-controls {
            .dt-cell--checkbox {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .dt-cell--actions {
            flex-basis: 70px;
            min-width: 70px;

            .dt-cell--actions__container {
                width: 34px;
            }
        }
    }
    .fullscreen-modal-title--wrap {
        min-height: 34px;
        &.fullscreen-modal-title--expiring,
        &.fullscreen-modal-title--team {
            padding-right: 45px;
            justify-content: space-between;
        }
        &.folder-title {
            padding-right: 80px;
        }
    }
    .fullscreen-modal-title--flex {
        display: flex;
        align-items: center;
    }
}

.refresh-btn {
    &.button--action:not(.dropdown-title) {
        font-size: 12px;

        &.active {
            color: $blue;
            background: rgba(0, 162, 225, 0.1) !important;

            .material-icons {
                color: $blue;
            }
        }
    }
}

.dashboard--select {
    .folder-for-move {
        cursor: pointer;
    }
    .folder-for-move:hover,
    .folder-for-move.is-selected {
        color: $greyDefault;
    }

    .collapse.folder-for-move > .collapse-button:hover {
        background: $hoverBg;
    }

    .collapse.folder-for-move.is-selected {
        & > .collapse-button {
            background: $hoverBg;

            .collapse-toggle {
                .collapse-toggle__title {
                    font-family: $BrandMedium;
                }
            }
        }
    }

    .folder-for-move.is-selected {
        font-family: $BrandMedium;
    }

    h4 {
        font-size: 14px;
        display: flex;
        align-items: center;
        border-bottom: $classicBorder;
        margin-bottom: calc(#{$indentBasis} * 2);
        cursor: pointer;
        padding: $indentBasis;
        margin-left: -$indentBasis;

        &:hover {
            background: $greyLight2;
        }

        svg {
            margin-right: $indentBasis;
        }
    }

    &.folders-modal {
        h4 + .folders-collapse-wrapper {
            height: calc(100% - 46px);
        }
    }

}

@import "./style_responsive";
@import "./components/donut_chart/style";
@import "./components/my_team/style";
