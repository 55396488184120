.aside-modal__body .add-workspace-assets {
    width: 100%;
    height: 100%;
    .search-results--container {
        height: calc(100% - 60px);
        padding: 45px 0 0 0;
    }
    .search-mode{
        float: left;
        position: absolute;
    }
    .search-text-input--wrapper{
        width: calc(100% - 165px);
        margin-bottom: 20px;
        position: absolute;
        float: right;
        margin-left: 105px;
        input {
            border-radius: 0 3px 3px 0;
        }
    }
    .is-focused {
        .Select-control {
            border-color: $greyDarker2 !important;
        }
    }
    .search-text-input-button {
        border-radius: 0 3px 3px 0;
    }
    .react-select__control {
        width: 105px;
        min-height: 32px;
        height: 32px;

        & > div {
            height: 32px;
        }

        .react-select__indicator {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
}
.project-workspace-add-file-modal {
    .file-preview .file-preview__button {
        min-width: 32px;
    }
    .aside-modal__header {
        .modal-minimize {
            margin-right: $indentBasis;
        }
        button.minimized {
            margin-right: 10px;
        }

        .with-icon.button--action {
            padding: 0 6px;
        }

        .asset-sapId {
            display: inline-block;

            .assets-list__item__details-link {
                color: $blue;
            }

            .other-info-box {
                display: none;
            }
        }
    }

    &.minimized {
        transition: top 0.5s;
        right: 10px;
        top: calc(100% - 55px);
        height: 44px;
        width: 450px;
        max-width: calc(100vw - 20px);
        border: $classicBorder;
        box-shadow: $grayShadow3;
        border-radius: 4px;

        .aside-modal__header {
            border-radius: 4px;
            height: 100%;
            padding: 5px 10px;
        }

        .aside-modal__body {
            display: none;
        }
    }
    .preview-modal__body {
        .upload-wrapper__dnd--content {
            min-height: calc(100vh - 90px);
        }
    }
}
