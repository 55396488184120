.dgl-test-label {
    display: inline-block;
    height: 20px;
    font-family: $BrandRegular;
    background: $red;
    color: $white;
    line-height: 23px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: 1.5px;
    padding: 0 5px;
    margin-left: 7px;
}