.copy-video-link, .associate-asset-with-campaign, .gco-reuse, .relational-preview-link {
    .video_url {
        position: relative;
        height: 70px;
        padding-top: 6px;

        .button--action-light {
            position: absolute;
            right: 0;
            top: 50px;
        }
    }

    .copy-url-tooltip {
        position: relative;
        top: 0;
        left: 0;
        width: 93%;
        float: left;
        box-shadow: none;
        border: 0;
        border-bottom: 2px solid $greyDarker;
        background: $greyLight7;
        height: 30px;
        line-height: 28px;
        padding: 0 10px;
        white-space: nowrap;
        overflow: hidden;
    }
}
