.manage-ux-hints {
    padding-top: 25px;

    .input-pair__title-box {
        text-transform: none;
        border-top: $classicBorder;
        padding-top: 20px;
    }

    .ux_hint {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        vertical-align: middle;
        border-top: 0;
        padding: 5px;

        .base-input {
            margin-right: 20px;
        }

        &--title {
            font-size: 14px;
            color: $blackLight;
            font-family: $BrandRegular;
            line-height: 30px;
        }
    }
}

.manage_user_form--updateOwnership {
    margin: 10px 0;
}
