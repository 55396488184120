@media #{$mobile} {
    .api-workspace-page .api-workspace-wrapper,
    .playlists-page {
        padding: 0 !important;
    }

    .playlist-page--content .curators-dashboard-grid .curators-dashboard-grid-col:first-child {
        width: 100%;
    }

    .playlist-page .content-store-actions {
        margin: 12px 0;
    }

    .playlist-page .asset-detail__preview .video-js {
        width: 100% !important;
        height: 100% !important;
    }
}

@media screen and (max-width: 900px) {
    .curators-dashboard-page .curators-dashboard-grid {
        flex-direction: column;

        .curators-dashboard-grid-col {
            margin: 0;
        }

        .curators-dashboard-grid-col:first-child {
            width: 100%;
            margin-bottom: 24px;
        }

        .curators-dashboard-grid-col:last-child {
            width: 100%;
        }

        .playlist-list--wrapper {
            max-width: none;
        }
    }
}