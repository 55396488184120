
.pdf-container {
    height: 680px;
    width: 100%;
    border: 1px solid $greyDarker2;
    background: rgba(34, 34, 34, 0.8);
}

.pdf-toolbar {
    height: 28px;
    width: 100%;
    background-color: #fff;
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    .container {
        display: flex;
        justify-content: flex-end;
    }
    button {
        padding: 5px;
        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.pdf-container.fullscreen {
    .pdf-content {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        background: black;
        width: 100vw;
        height: 100vh;
        z-index: 99;
    }
    .pdf-toolbar {
        display: none;
    }
}


.pdf-tooltip {
    opacity: 0;
    height: 0;
}

.pdf-tooltip.activate {
    position: absolute;
    z-index: 101;
    height: auto;
    background: $black;
    padding: 15px 30px;
    font-size: 18px;
    color: $white;
    opacity: 0.6;
    left: 45%;
    top: 0;

    span:nth-child(2) {
        border: 1px solid $white;
        padding: 5px 2px;
    }
}

.pdf-content {
    height: 647px;
    width: 100%;
    overflow: auto;
}

.embedded {
    .pdf-container {
        height: 100%;
    }

    .pdf-content {
       height: 100%;
       .restricted{
            position: relative;
            &:before {
                content: '';
                display: block;
                background: url('../../../styles_images/restricted.svg') no-repeat;
                width: 100%;
                height: 100%;
                background-position: center;
                position: absolute;
           }
       }
    }

    .pdf-viewer__modal-body {
        .pdfViewer {
            padding-top: 28px;
        }
    }
}

.scale-buttons {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    button {
        margin: 0 2px;
    }
}

@media only screen and (max-width: 414px) {
    .pdf-container {
        width: 95%;
    }

}

/** **************
*   NEW DESIGN
************** **/


.asset-content-preview-page {
    .asset-preview-widget {
        height: 338px;
        width: 600px;
    }

    .pdf-preview-container-new {
        width: 100%;
        overflow: hidden;

        .default_preview_icon {
            width: 25%;
        }

         img {
             width: 100%;
         }
    }

    .asset-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .asset-more--download {
            margin-right: -15px;
            margin-left: $indentBasis;
        }
    }

    .logo {
        border-right: none;
    }

    #footer {
        &.footer {
            color: $greyDarker;
            margin: 0;

            a {
                color: $white;
                font-size: 14px;
                border-radius: 2px;
            }
        }
    }

    .relational-buttons {
        display: flex;
        justify-content: flex-end;
    }
}

.restrictedDownload:before,
.restrictedDownload .page:before,
.restrictedContent:before,
.restricted .page:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-position: center !important;
    position: absolute;
}

.restrictedContent:before,
.restricted .page:before {
    background: url('../../../styles_images/restricted.svg') no-repeat;
}

.restrictedDownload:before,
.restrictedDownload .page:before {
    background: url('../../../styles_images/restricted-download.svg') no-repeat;
}

.pdf-preview-container-new {
    height: 100%;
    position: relative;
    cursor: pointer;

    .preview-mode {
        display: flex;
        align-items: center;
        background: linear-gradient(0deg, rgba(51, 51, 51, 0.6), rgba(51, 51, 51, 0.6));
        color: $greyLight7;
        font-family: $BrandMedium, Arial, sans-serif;
        font-size: 16px;
        line-height: 24px;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 0.3s opacity ease-in-out;
        text-transform: none;

        .preview-icon {
            height: 17px;
            margin-bottom: 10px;

            svg {
                width: 32px;
                height: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -16px;
                margin-top: -30px;
 
                path {
                    fill: $white;
                }
            }
        }
    }

    &:hover .preview-mode {
        opacity: 1;
    }

    .loading-asset {
        @extend %preload;
        height: 550px;
        border-radius: 0;
    }
}

.pdf-viewer,
.image-viewer {
    &__modal {
        background-color: rgba(18, 23, 28, 0.4);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1010;

        &-body {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .Viewer {
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: auto;
        }

        .pdf-viewer-close-btn {
            position: absolute;
            z-index: 1000;
            top: 16px;
            right: 32px; //scrollbar for long document present, so +20px needs
            width: 32px;
            height: 32px;
            svg {
                path {
                    fill: $greyBlueDark;
                }
            }
        }
    }

    &__toolbar {
        align-items: center;
        background-color: rgba(34,34,34,0.8);
        bottom: 50px;
        border-radius: 3px;
        color: $white;
        display: flex;
        justify-content: space-between;
        position: fixed;
        transform: translateX(50%);
        right: 50%;
        z-index: 10;

        &.pdf-loading {
            position: absolute;
            height: 45px;
            @extend %preload;

            &::after {
                background: linear-gradient(to top, $greyDarker 0%, $greyDarker 52%, $greyDarker2 100%);
            }
        }

        &-navigation {
            border-right: 1px solid $white;
            padding: 4px 20px;

            input[type=number].page-navigation {
                color: $blackLight;
                border: 1px solid $inputBorderColor;
                border-radius: 3px;
                box-shadow: $grayShadow1;
                font-family: $BrandRegular;
                font-size: 14px;
                line-height: 26px;
                margin: 0 15px;
                text-align: center;
                height: 35px;
                width: 35px;
            }

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            .number-of-pages {
                margin-left: 20px;
            }
        }

        &-zoom {
            align-items: center;
            display: flex;
            padding: 4px 20px;

            .zoom-in {
                cursor: pointer;
                margin-right: 25px;
            }
            .zoom-out {
                cursor: pointer;
                margin-right: 25px;
            }
            .exit-full-screen {
                cursor: pointer;
            }

            svg {
                g, path {
                    fill: $white;
                }
            }
        }
    }
}

/** ***********************
*   STYLES FROM pdfjs LIB
*********************** **/
.pdfViewer .page {
    padding-top: 0;
    box-sizing: initial;
}

.pdf-viewer__modal {
    .pdfViewer .canvasWrapper {
        overflow: hidden;
    }

    .pdfViewer .page {
        direction: ltr;
        width: 816px;
        height: 1056px;
        margin: 1px auto -8px auto;
        position: relative;
        overflow: visible;
        border: 9px solid transparent;
        border-left: 0px;
        border-right: 0px;
        background-clip: content-box;
        background-color: white;
    }

    .pdfViewer.removePageBorders .page {
        margin: 0px auto 10px auto;
        border: none;
    }

    .pdfViewer.singlePageView {
        display: inline-block;
    }

    .pdfViewer.singlePageView .page {
        margin: 0;
        border: none;
    }

    .pdfViewer.scrollHorizontal, .pdfViewer.scrollWrapped, .spread {
        margin-left: 3.5px;
        margin-right: 3.5px;
        text-align: center;
    }

    .pdfViewer.scrollHorizontal, .spread {
        white-space: nowrap;
    }

    .pdfViewer.removePageBorders,
    .pdfViewer.scrollHorizontal .spread,
    .pdfViewer.scrollWrapped .spread {
        margin-left: 0;
        margin-right: 0;
    }

    .spread .page,
    .pdfViewer.scrollHorizontal .page,
    .pdfViewer.scrollWrapped .page,
    .pdfViewer.scrollHorizontal .spread,
    .pdfViewer.scrollWrapped .spread {
        display: inline-block;
        vertical-align: middle;
    }

    .spread .page,
    .pdfViewer.scrollHorizontal .page,
    .pdfViewer.scrollWrapped .page {
        margin-left: -3.5px;
        margin-right: -3.5px;
    }

    .pdfViewer.removePageBorders .spread .page,
    .pdfViewer.removePageBorders.scrollHorizontal .page,
    .pdfViewer.removePageBorders.scrollWrapped .page {
        margin-left: 5px;
        margin-right: 5px;
    }

    .pdfViewer .page canvas {
        margin: 0;
        display: block;
    }

    .pdfViewer .page canvas[hidden] {
        display: none;
    }

    .pdfViewer .page .loadingIcon {
        display: none;
    }

    .pdfPresentationMode .pdfViewer {
        margin-left: 0;
        margin-right: 0;
    }

    .pdfPresentationMode .pdfViewer .page,
    .pdfPresentationMode .pdfViewer .spread {
        display: block;
    }

    .pdfPresentationMode .pdfViewer .page,
    .pdfPresentationMode .pdfViewer.removePageBorders .page {
        margin-left: auto;
        margin-right: auto;
    }

    .pdfPresentationMode:-ms-fullscreen .pdfViewer .page {
        margin-bottom: 100% !important;
    }

    .pdfPresentationMode:fullscreen .pdfViewer .page {
        margin-bottom: 100%;
        border: 0;
    }

    .textLayer {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        opacity: 0.2;
    }

    .textLayer > div {
        color: transparent;
        position: absolute;
        white-space: pre;
        cursor: text;
        -webkit-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
    }

    .textLayer .highlight {
        margin: -1px;
        padding: 1px;
        background-color: rgb(180, 0, 170);
        border-radius: 4px;
    }

    .textLayer .highlight.begin {
        border-radius: 4px 0px 0px 4px;
    }

    .textLayer .highlight.end {
        border-radius: 0px 4px 4px 0px;
    }

    .textLayer .highlight.middle {
        border-radius: 0px;
    }

    .textLayer .highlight.selected {
        background-color: $saladGreenDark;
    }

    .textLayer ::selection {
        background: $blue;
    }
    .textLayer ::-moz-selection {
        background: $blue;
    }

    .image_preview_body {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    .image_preview_container {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 30px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;

        .image_preview_container_inner {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100%;

            img {
                display: block;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACLSURBVHgB7dgxDoAwDARBG7nO/5+ZHwCmgvq2sMRNRRUtiogc8rwFaO8d1JKZGdUPZGOvRa53xHAOVDlQ5UCVA1UOVDlQ5UBFz4OjA3uuHL/FFaB+494WyjPy03eItRYaWfQdgoxrPmYU47/i8edgc6DKgSoHqhyocqDqH4Hwf/gPJJAeUt+KvkPQLiHvKyCX4yAXAAAAAElFTkSuQmCC');
                background-size: 30px 30px;
                transition: background-image .3s;
            }
        }

        .image-preview-close-btn {
            position: fixed;
            top: 16px;
            right: 32px;
            border-radius: 50%;
        }
    }
    .statistics-chart {
        background-color: $white;
    }
}

.content-preview-wrapper {
    .pdf-viewer__modal {
        top: 60px;
        bottom: 184px;
    }
    article {
        min-height: calc(100vh - 244px);
    }
}

@media screen and (max-width: 639px) {
    .content-preview-wrapper {
        .pdf-viewer__modal {
            bottom: 260px;
        }
        article {
            min-height: calc(100vh - 320px);
        }
    }
}

@media screen and (max-height: 590px) {
    .content-preview-wrapper {
        &.isVideoEmbeded {
            article {
                min-height: 300px !important;
            }
        }
    }
}

@media #{$IE} {
    .pdf-viewer__modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .pdf-viewer__modal {
        position: absolute;
        height: 100vh;
    }
}
