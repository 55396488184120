// every new style(.scss) file should be imported in - app/styles/main.scss
// like this: @import "../scripts/templates/style";

.db_config_page {
    .tabs__panel {
        overflow-x: visible;
    }

    &--info {
        padding-top: 20px;

        .action-title {
            display: flex;
            justify-content: flex-end;
            padding: 0 0 10px 0;
            font-size: 14px;
            font-family: $BrandRegular;
            color: $greyDarker3;

            b {
                font-family: $BrandRegular;
                color: $blackLight;
                font-weight: normal;
            }
        }
    }
}

.db-config-page-actions {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px 10px 0;
    align-items: center;
    vertical-align: middle;
    margin-left: -5px;

    span {
        display: block;
    }

    .button--action {
        margin-right: calc(#{$indentBasis} * 2);
    }

    &--separator {
        width: 1px;
        background: $greyDarker2;
        margin-right: calc(#{$indentBasis} * 2);
        height: 20px;
    }
}

.config-table {
    .config-table-rows {
        display: block;
        overflow-x: auto;
        overflow-y: scroll;
        height: 560px;

        .config-table-row {
            display: flex;
            flex-wrap: nowrap;
            background: $white;

            .config-table-item {
                flex: 0 0 auto;
                align-items: stretch;
                border: 1px solid $greyDarker4;
                padding: 0px 5px;

                input {
                    width: 100%;
                    height: 100%;
                    color: $greyDarker;
                    background: $white;
                    border: none;
                }

                &--changed {
                    input {
                        color: $blackLight;
                    }
                }

                &--invalid {
                    color: $red !important;
                    border-color: $red !important;
                }

                button {
                    display: inline-block;
                    box-shadow: none;
                    border-radius: 0;
                    width: 60px;
                    height: 30px;
                    font-weight: bold;
                    color: $orange;
                    background: $white;
                    padding: 0px;
                    border: none;
                    font-family: $BrandMedium;

                    &:disabled {
                        color: $greyDarker4 !important;
                    }

                    &.safe {
                        color: $saladGreen;
                    }

                    &.unsafe {
                        color: $red;
                    }

                    &:focus {
                        outline-offset: -2px;
                        border-radius: 4px;
                    }
                }

                &--actions {
                    .header {
                        width: 180px;
                    }
                }
            }

            &--headers {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 100;

                .config-table-item {
                    background: $greyLight7;
                    border-color: $greyDarker;
                    padding: 3px 5px;

                    input {
                        background: $greyLight7;
                        text-align: center;
                        color: $greyDefault;
                    }
                }
            }

            &--editing {
                .config-table-item {
                    border-style: dashed;
                    border-color: $greyDefault;

                    &--disabled {
                        input {
                            font-weight: bolder;
                            color: $greyDarker4
                        }
                    }
                }
            }
        }
    }
}
