/* original http://codepen.io/Tournevis/pen/JYwogV */
// Spinner top
$color1 : $blue;
$color2 : $blue;
$color3 : $blue;
$color4 : $blue;

@for $i from 1 through 51{
    .pixels:nth-child(#{$i}){
        animation: magic 2.5s linear infinite;
        animation-delay:$i*.02s;
    }
}

@keyframes magic {
    0%{
        opacity: 0;
    }
    20% {
        opacity: 0;
        background: $color1;
        box-shadow: 0 -1px 0 $color1;
    }
    28%{
        opacity:1;
    }
    30% {
        background: $color2;
        box-shadow: 0 -1px 0 $color2;
    }
    50%{
        background: $color3;
        box-shadow: 0 -1px 0 $color3;
    }
    65%{
        background: $color4;
        box-shadow: 0 -1px 0 $color4;
    }
    80%{
        background: $color3;
        box-shadow: 0 -1px 0 $color3;
    }
    90%{
        opacity:1;
        box-shadow: 0 -1px 0 $color2;
        background: $color2;
    }
    100%{
        opacity:0;
        background: $color1;

    }
}

.loader-container {
    z-index: 100000;
    height: 10px;
    width : 100% ;
    overflow: hidden;

    // Handle incorrect .innerWidth when scrollbar visible in Chrome (resulted in incorrect .pixels widths)
    white-space: nowrap;

    position: fixed;
    top: -1px;
    .loaderPixel{
        margin:auto;
        position: relative;
        width:100%;
        h1{
            text-align: center;
        }
        .pixels{
            display: inline-block;
            box-shadow: 0 -1px 0px $color1;
            margin-top:0;
            margin-bottom:0;
            opacity:0;
            height: 10px ;
            width : calc(100vw / 50);
        }
    }
}

.react-lazy-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}

.manage-asset-form.with-loader {
    position: relative;
}

// End Spinner top

// Backdrop
.ajax-progress-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    // Place it above .modal-positioner
    z-index: 10001 !important;
    background: $black;
    opacity: 0.7;

    &__body {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
// End Backdrop


// Spinner square
.loader-square {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid $greyLight7;
    top: 50%;
    animation: loader-square 4s infinite ease;
}

.loader-square-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $greyLight7;
    animation: loader-square-inner 4s infinite ease-in;
}

@keyframes loader-square {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-square-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}
// End spinner square

