.sso-user-page {
    background-color: $white;
}

.sso-user {
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    
    //clearfix
    &:after {
        content: '';
        display: block;
        width: 100%;
        clear: both;
    }

    .cabinet-header__text {
        display: block;
        font-family: $BrandMedium, Arial, sans-serif;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: calc(#{$indentBasis} * 3);
    }

    .cabinet-header {
        margin: 0;
    }

    .sso-user__right {
        margin-left: calc(#{$indentBasis} * 12);
    }

    .sso-user-logout {
        margin-top: calc(#{$indentBasis} * 5)
    }

    .user-profile__info__item__value {
        line-height: 18px;
    }
}

.sso-user-info {
    display: flex;
}

.sso-user__title {
    font-family: $BrandMedium, Arial, sans-serif;
    margin-bottom: $indentBasis;
    font-size: 14px;
    line-height: 24px;
}

.sso-user__general-info {
    font-size: 14px;
    line-height: 24px;
    .button-link {
        display: inline;
    }
}

.upgrade-form {
    background: $greyLight7;
    padding: 28px;
    margin-bottom: 16px;

    //leave default color for titles of nonemty inputs
    .input-pair--nonempty .input-pair__title-box {
        color: $black;
    }

    .input-pair__title-box {
        font-size: 11px;
        color: $greyDefault;
        width: 35%;
    }

    .input-pair__input-box {
        width: 65%;
    }

    .upgrade-form__title {
        font-size: 14px;
        margin-bottom: -8px;
        color: $black;
        font-weight: bold;
    }

    .upgrade-form__label, .upgrade-form__item--vertical .input-pair__title-box {
        padding: 0 0 9px 0;
        font-size: 14px;
        line-height: 22px;
    }

    .upgrade-form__item {
        padding: 32px 0 0 0;

        &--vertical {
            .input-pair {
                flex-direction: column;
                align-items: flex-start;
            }

            .input-pair__title-box {
                font-weight: normal;
                text-transform: capitalize;
                color: $defaultColor;
                width: 100%;

                &__text {
                    width: 100%;
                }
            }
        }
    }

    .upgrade-form__footer {
        padding: 25px 0 35px 0;

        .button--action {
            float: right;
        }
    }
}
