.crop-from-video--modal {
    .base-modal {
        width: 650px;
        @include previewResolution(600px);

        .base-modal--body {
            height: 380px;
        }

        p.description {
            margin-bottom: calc(#{$indentBasis} * 2);
        }
    }
}