.authorized-members-page {
    width: 100%;

    .dt--authorized-group {
        .dt-cell--id {
            width: 50px;
        }
        .dt-cell--groupName {
            width: 80%;
            & > span {
                display: block;
                word-wrap: break-word;
            }
        }
        .dt-cell--doi {
            width: 11.5%;
        }
    }
}

.authorized-group-preview-modal {
    .dt--users {
        .dt-cell--id {
            width: 10%;
        }
        .dt-cell--nm {
            width: 45%;
        }
        .dt-cell--email {
            width: 45%;
        }
    }
}

.create-authorized-group-modal {
    .modal {
        width: 600px;

        .modal__footer__buttons {
            width: calc(100% - 56px);
        }
    }
    .input-pair__title-box {
        width: 30%;
    }
    .input-pair__input-box {
        width: 60%;
    }
}
