@media #{$mobile} {
    .bulk-actions {
        .dropdown.bulk-actions--dropdown.dropdown--mobile {
            .dropdown-body {
                top: auto;
                bottom: 0;
                left: 0;
                right: 0;
                width: auto;

                &__content {
                    &__close {
                        width: 34px;
                        display: flex;
                    }
                }
            }
        }
    }

    .search-item-content .with-translations .dropdown-body {
        width: auto;
        top: auto;
    }

    .search-mask-filter-panel {
        .auto-hide--container {
            border-left: 1px solid $greyDarker2;
        }
    }
}

@import "./search_results_responsive";