@media #{$iPad_P} {
    .dt-grid {
        .dt-grid__item {
            width: 325px;
        }
    }
}

@media #{$screenSuperSmall} {
    .project-workspace-page {
        .dt-cell {
            &--approvalStatus {
                flex-basis: 105px;
                min-width: 105px;
            }
        }
    }
}

@media #{$iPhone678Plus_P} {
    .dt-grid {
        .dt-grid__item {
            width: 384px;
        }
    }

    .dummy.isMobileOnly .my-assets-page .my-assets-table-controls .search-text-input {
        width: 382px;
    }
}

@media #{$iPhone678_P} {
    .dummy.isMobileOnly .my-assets-page .my-assets-table-controls .search-text-input {
        width: 345px;
    }

    .dt-grid {
        .dt-grid__item {
            width: 345px;
        }
    }
}

@media #{$iPhone5s_P} {
    .dummy.isMobileOnly .my-assets-page .my-assets-table-controls .search-text-input {
        width: 290px;
    }

    .dt-grid {
        .dt-grid__item {
            width: 290px;
        }
    }
}

@media #{$mobile} {
    .my-assets-page {
        padding: 0;
    }

    .my-assets-header {
        &__buttons-wrapper {
            .bulk-actions {
                .dropdown-body__content__items {
                    .button--action:last-child {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .my-assets-header {
        .bulk-actions {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 350px) {
    .my-assets-page {
        .dt__items {
            .dt-cell--src {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 400px) {
    .assets-table {
        .dt-cell {
            &--approvalStatus {
                flex-basis: 105px;
                min-width: 105px;
            }
        }
    }
}
