.subscriptions-workspace-page {
    height: 100%;
    padding: 0 10px;

    .subscriptions-workspace-header--wrapper {
        .subscriptions-workspace-header {
            position: relative;
            box-shadow: none;
        }
    }

    .subscriptions-workspace-table {
        &-container {
            padding: 0;

            .dt--subscriptions-workspace {
                .dt-cell--actions {
                    min-width: 25px;
                    flex-basis: 0;
                    padding: 0;
                }
                
                .dt__item {
                    .assets-list__item__details-link {
                        color: $blue;
                        font-weight: $BrandMedium;
                    }

                    .subscriptions-workspace-status {
                        display: flex;

                        span {
                            margin-right: 5px;
                            height: 15px;
                            width: 10px;
                            border-radius: 3px;
                        }
                    }

                    .assets-list__item__subscription, .other-info-box.type {
                        display: none;
                    }
                }

                .button--action.review-link {
                    border: none;
                    box-shadow: none;
                }

                .sort-controls__item__label {
                    font-weight: bold;
                    color: $blackLight;
                }
            }
        }

        &-controls {
            .bulk-actions {
                margin-left: 15px;
                min-width: 99px;
            }
        }
    }
}

@import './style_responsive';
