.notification-modal-list.archiving-notification {
    display: flex;
    align-items: center;
    width: 100%;

    .notification-modal-list__list-box {
        width: 230px;
        font-size: 16px;
    }
}
