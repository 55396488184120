@media #{$iPhone5s_P} {
    .search-result-companies--wrapper {
        width: 250px;
    }
}

@media screen and (max-height: 600px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                padding-top: 1px;
                padding-bottom: 1px;
            }
        }
    }
}


@media screen and (min-height: 625px) and (max-height: 960px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                margin-bottom: 1px;
            }
        }
    }
}

@media screen and (min-height: 601px) and (max-height: 680px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }
}

@media screen and (min-height: 681px) and (max-height: 730px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }
    }
}

@media screen and (min-height: 731px) and (max-height: 780px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
    }
}

@media screen and (min-height: 781px) and (max-height: 835px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}

@media screen and (min-height: 836px) and (max-height: 885px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}

@media screen and (min-height: 900px) {
    .cvp-page {
        .alphabetical-navigation {
            top: 70px;
        }
    }
}

@media screen and (min-height: 961px) and (max-height: 1000px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                margin-bottom: 3px;
            }
        }
    }
}

@media screen and (min-height: 1001px) and (max-height: 1080px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                margin-bottom: 5px;
            }
        }
    }
}

@media screen and (min-height: 1081px) {
    .cvp-page {
        .alphabetical-navigation {
            .navigation-item {
                margin-bottom: 8px;
            }
        }
    }
}