@media #{$mobile} {
    .saved-searches-table {
        .dt-cell {
            flex-basis: 80px;
            min-width: 80px;

        }
    }

    .saved-searches-table .view-details {
        .dropdown-body {
            right: -104px;
            width: 338px;
        }
    }

    .saved-searches-table .saved-search--actions {
        margin-left: -10px;
    }


    .saved-searches-table .input-container {
        width: 100%;
    }
}

@media #{$iPad_P} {
    .new-search-page.content-store-page {
        .saved-searches-dropdown {
            .dropdown-body {
                right: auto;
                left: 0;
            }
        }
    }

    .new-search-page:not(.content-store-page) {
        .search-results {
            padding-top: 15px;
        }
    }
}

@media #{$iPhone5s_P} {
    .saved-searches-table .input-container {
        width: 280px;
    }

    .saved-searches-table .view-details {
        .dropdown-body {
            right: -100px;
            width: 286px;
        }
    }

    .saved-searches-table .dt-cell {
        &.dt-cell--name {
            flex-basis: 100px;
            min-width: 100px;

            &:not(.sort-controls__item) {
                flex-basis: 120px;
                min-width: 120px;
            }
        }
    }

    .saved-searches-table .view-details--content .asset-preview-metadata__pair .asset-preview__data {
        width: 120px;
    }
}

@media #{$iPhone678_P} {
    .saved-searches-table .dt-cell {
        &.dt-cell--name {
            flex-basis: 150px;
            min-width: 150px;

            &:not(.sort-controls__item) {
                flex-basis: 170px;
                min-width: 170px;
            }
        }
    }
}
