.replace-content-file-modal {
    @include modal(470px, 179px, 100%);

    .input-pair--file {
        padding-bottom: 20px;
    }

    .radio-group {
        display: flex;
        flex-direction: column;
    }

    .modal__footer__buttons {
        box-sizing: content-box;
    }
}
