.carousel:not(.tabs) {
    position: relative;
    width: 100%;

    .control-arrow, &.carousel-slider .control-arrow {
        -webkit-transition: all 0.25s ease-in;
        -moz-transition: all 0.25s ease-in;
        -ms-transition: all 0.25s ease-in;
        -o-transition: all 0.25s ease-in;
        transition: all 0.25s ease-in;
        opacity: 0.4;
        filter: alpha(opacity=40);
        position: absolute;
        z-index: 2;
        top: 20px;
        background: none;
        border: 0;
        font-size: 32px;
        cursor: pointer;
    }
    .control-arrow {
        top: 50%;
        margin-top: -13px;
        font-size: 18px;
        &:hover {
            opacity: 1;
            filter: alpha(opacity=100);
        }
        &:before {
            margin: 0 5px;
            display: inline-block;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            content: '';
        }
    }
    &.carousel-slider .control-arrow:before {
        margin: 0 5px;
        display: inline-block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        content: '';
    }
    .control-disabled.control-arrow {
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: inherit;
        display: none;
    }
    .control-prev.control-arrow {
        left: 0;
        &:before {
            border-right: 8px solid $white;
        }
    }
    .control-next.control-arrow {
        right: 0;
        &:before {
            border-left: 8px solid $white;
        }
    }

    button {
        border: 0;
        background: none;
    }
    img {
        display: inline-block;
        pointer-events: none;
    }
    .carousel {
        position: relative;
    }
    .thumbs-wrapper {
        margin: 20px;
        overflow: hidden;
    }
    .thumbs {
        -webkit-transition: all 0.15s ease-in;
        -moz-transition: all 0.15s ease-in;
        -ms-transition: all 0.15s ease-in;
        -o-transition: all 0.15s ease-in;
        transition: all 0.15s ease-in;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        position: relative;
        list-style: none;
        white-space: nowrap;
    }
    .thumb {
        -webkit-transition: border 0.15s ease-in;
        -moz-transition: border 0.15s ease-in;
        -ms-transition: border 0.15s ease-in;
        -o-transition: border 0.15s ease-in;
        transition: border 0.15s ease-in;
        display: inline-block;
        width: 80px;
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        border: 3px solid $white;
        padding: 2px;
        &.selected, &:hover {
            border: 3px solid $greyDefault;
            padding: 2px;
        }
        img {
            vertical-align: top;
        }
    }
    &.carousel-slider {
        position: relative;
        margin: 0;
        .control-arrow {
            color: $white;
            font-size: 41px;
            bottom: 0;
            top: 0;
            padding: 15px;
            background: $black;
            &:hover {
                background: $greyDarker;
            }
        }
    }
    .slider-wrapper {
        overflow: hidden;
        margin: auto;
        width: 100%;
        -webkit-transition: height 0.15s ease-in;
        -moz-transition: height 0.15s ease-in;
        -ms-transition: height 0.15s ease-in;
        -o-transition: height 0.15s ease-in;
        transition: height 0.15s ease-in;
        &.axis-horizontal .slider {
            -ms-box-orient: horizontal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            .slide {
                flex-direction: column;
            }
        }
        &.axis-vertical {
            -ms-box-orient: horizontal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            .slider {
                -webkit-flex-direction: column;
                flex-direction: column;
            }
        }
    }
    .slider {
        position: relative;
        list-style: none;
        width: 100%;
        &.animated {
            -webkit-transition: all 0.35s ease-in-out;
            -moz-transition: all 0.35s ease-in-out;
            -ms-transition: all 0.35s ease-in-out;
            -o-transition: all 0.35s ease-in-out;
            transition: all 0.35s ease-in-out;
        }
    }
    .slide {
        min-width: 100%;
        margin: 0;
        position: relative;
        text-align: center;
        background: $white;
        display: flex;
        justify-content: center;
        img {
            max-height: 600px;
            vertical-align: top;
            border: 0;
        }
        iframe {
            display: inline-block;
            width: calc(100% - 80px);
            margin: 0 40px 40px;
            border: 0;
        }
        .legend {
            position: absolute;
            bottom: 40px;
            left: 50%;
            margin-left: -45%;
            width: 90%;
            border-radius: 10px;
            background: $black;
            color: $white;
            padding: 10px;
            font-size: 12px;
            text-align: center;
            opacity: 0.25;
            -webkit-transition: opacity 0.35s ease-in-out;
            -moz-transition: opacity 0.35s ease-in-out;
            -ms-transition: opacity 0.35s ease-in-out;
            -o-transition: opacity 0.35s ease-in-out;
            transition: opacity 0.35s ease-in-out;
        }
    }
    .control-dots {
        position: absolute;
        bottom: 0;
        margin: 10px 0;
        text-align: center;
        width: 100%;
        .dot {
            -webkit-transition: opacity 0.25s ease-in;
            -moz-transition: opacity 0.25s ease-in;
            -ms-transition: opacity 0.25s ease-in;
            -o-transition: opacity 0.25s ease-in;
            transition: opacity 0.25s ease-in;
            opacity: 0.3;
            filter: alpha(opacity=30);
            box-shadow: $grayShadow1;
            background: $white;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            cursor: pointer;
            display: inline-block;
            margin: 0 8px;
            &.selected, &:hover {
                opacity: 1;
                filter: alpha(opacity=100);
            }
        }
    }
    .carousel-status {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 10px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
        color: $white;
    }
    &:hover .slide .legend {
        opacity: 1;
    }
}

@media (min-width: 960px) {
    .carousel .control-dots {
        bottom: 0;
    }
}
