.upload-asset-modal .upload-wrapper {
    &__metadata {
        box-sizing: border-box;
        .edit_asset_container {
            height: calc(100% - 50px);

            .wizard-tabs-footer--buttons {
                .button--action {
                    width: 80px;
                }
            }

            .input-pair__input-box__error {
                background: $white;
            }

            &--header {
                border-bottom: $classicBorder;
                padding: 13px 160px 13px 3*$indentBasis;
                display: flex;
                justify-content: space-between;
                height: 30px;
                position: relative;
                z-index: 2;

                .apply-metadata {
                    align-items: center;
                    display: flex;
                    width: auto;
                    margin-top: -2px;
                    margin-left: 2*$indentBasis;
                    .react-select__control {
                        padding-left: 40px;
                    }
                }

                h2 {
                    color: $blackLight;
                    font-size: 16px;
                    font-family: $BrandMedium;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .asset-role-field {
                .radio-group {
                    display: flex;
                    justify-content: space-between;

                }
                .base-radio {
                    border: 1px solid $blueLight5;
                    padding: 8px 20px;
                    text-align: center;
                    flex-grow: 1;
                    background: $white;

                    &__label {
                        margin-right: 0;
                        color: $blackLight;
                    }
                    &--checked {
                        background-color: $greyDarker2;
                        box-shadow: none;
                        span {
                            color: $greyDefault;
                        }
                        &:hover {
                            background: $greyDarker2;
                        }
                    }
                    &__icon {
                        display: none;
                    }

                    &:hover {
                        background: $greyLight7 !important;
                    }
                }
            }
            &--progress {
                .input-pair,
                .base-input,
                .asset-preview__heading,
                .wizard-tabs--buttons,
                .form-list_field,
                .content-owner-btn,
                .compliance_info,
                .compliance_requirements,
                .third_party_approve_error {
                    min-height: 50px;
                    @extend %preload;
                    * {
                        visibility: hidden;
                    }
                }

                .content-owner-btn {
                    width: 100%;
                    box-shadow: none;
                    border: none;
                }

                .nav-link {
                    visibility: hidden;
                }

                .edit_asset_container--buttons,
                .base-checkbox,
                .tooltip__container {
                    display: none;
                }
            }
            .tabs.wizard {
                box-sizing: border-box;
                margin-top: 0;
                height: calc(100% - 73px);
                overflow: visible;
                padding-top: 0;

                .tabs__panel {
                    height: 100%;
                    box-sizing: border-box;
                }
            }
        }
        height: 100%;
        .asset-preview {
            width: auto;
        }
    }

    .add-metadata-profile {
        position: absolute;
        z-index: 5;
        left: 1px;
        
        .button--action {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: 1px solid $inputBorderColor;
            height: 36px;
            padding: $indentBasis;
            width: fit-content;
            svg {
                width: 16px;
                height: 16px;
            }
        }
        
        @media #{$IE} {
            margin-top: -17px;
        }
    }
}

.upload-asset-modal {
    &.full-screen {
        .upload-wrapper__metadata {
            .edit_asset_container--header {
                padding-right: 205px;
            }
        }
        .upload-content--holder {
            display: none;
        }
    }
}

.edit_asset_container {
    .apply-metadata {
        position: relative;
        width: 150px;
        margin-top: -2px;

        .react-select-box {
            padding-left: 40px;
        }
    }
}

.aside-modal--entire {
    .edit_asset_container--header {
        &--left {
            .files-for-upload__item__image {
                margin-left: -10px;
            }
        }
    }
}

.edit_asset_container--header {
    display: flex;

    &--left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        vertical-align: middle;

        .files-for-upload__item__image {
            height: 33px;
            width: auto;
            margin-left: -6px;
            @media #{$IE} {
                width: 45px;
            }
        }
    }

    h2 {
        color: $blackLight;
        font-size: 14px;
        font-family: $BrandMedium;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media #{$IE} {
    .edit_asset_container--header {
        justify-content: flex-start;
        .button--action__toolTip.button--action__toolTip--bottom{
            right: -70%;
        }
    }
    .full-screen .edit_asset_container--header {
        .button--action__toolTip.button--action__toolTip--bottom{
            right: -55%;
            @media #{$IE} {
                right: -117%;
            }
        }
    }
}

.base_block_select {
    .input-pair {
        label.base-radio{
            &--checked {
                button {
                    background-color: $greyDarker2;
                }
                border: none;
                box-shadow: none;
                span {
                    color: $greyDefault;
                }
                &:hover {
                    background: $greyLight7;
                }
            }
            &:not(.base-radio--checked):not(.base-checkbox--checked) {
                &:hover {
                    background: $greyLight7 !important;
                }
            }
        }
    }
}

.input-pair--required {
    span.input-pair__title-box__text {
        text-transform: none;
    }
}

.edit-upload-item-modal {
    .modal__content {
        padding: 0;
    }

    .tabs.wizard {
        .tabs__panel {
            padding: 10px 20px;
            margin: 0;

            .input-pair {
                display: flex;
                flex-direction: column;

                & > * {
                    width: 100%;
                }
            }
        }

        .wizard-tabs--buttons {
            padding-right: 30px;
        }
    }
}

.input-pair__title-box__text {
    color: $blackLight;
}

.input-pair__title-box__descr {
    color: $blackLight;
    line-height: 24px;
    font-family: $BrandRegular;
    margin-top: 8px;
    text-transform: none;
}

.react-select-box-label__item {
    color: $greyDefault;
}

.changed-field {
    .add-container {
        display: flex;
        width: 100%;

        & > div {
            width: 100%;
        }

        .add-button {
            width: 34px;
            height: 34px;
            min-width: 34px;
            margin-top: 28px;
            margin-left: 15px;
            font-size: 30px;
        }

        .form-field-wrapper {
            width: 100%;
        }

        .double-field {
            .second {
                margin-right: -49px;
                padding-top: 0;
            }
        }
    }
}

.form-field-wrapper {
    .input-pair--disabled {
        .input-pair__title-box {
            color: $greyDarker3;
        }
    }
}

.form-field-radio {
    padding: 15px 0 5px;
}

.mac-monthpicker {
    position: relative;

    .react-select__clear-indicator {
        display: none;
    }

    &--container {
        display: flex;
        justify-content: flex-start;

        .mac-monthpicker--select-holder {
            width: 150px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 10px;
            position: relative;
        }
    }
}

@import "./manage_asset_metadata_responsive";
