.search-text-input--container {
    height: 32px;
    width: 100%;
    max-width: 640px;
    flex-grow: 1;
    flex-shrink: 1;

    .search-text-input--wrapper {
        .search-text-input {
            &-autocomplete {
                .button--action:not(.settingsBtn) {
                    font-family: $BrandRegular;
                    font-size: 14px;
                    text-transform: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $navy;
                    padding: 0 calc(#{$indentBasis} * 2);

                    svg {
                        margin-right: $indentBasis;

                        path {
                            fill: $navy;
                        }
                    }

                    &.userAutocomplete {
                        span {
                            color: $violet;
                        }

                        svg {
                            path {
                                fill: $violet;
                            }
                        }
                    }
                    b {
                        font-family: $BrandRegular;
                    }
                }
                .search-text-action-btn {
                    position: absolute;
                    top: 1px;
                    right: 12px;
                    z-index: 2;
                }
            }
        }

        .search-text-input-autocomplete-title {
            padding-top: 12px;
        }
    }
}

.search-text-input:not(.search-text-input--global) {
    .search-text-input-button.noTitle {
        svg {
            max-height: 13px;
            max-width: 13px;
        }
    }
}

@import "./style_responsive";
