.spinner {
    height: 30px;
    font-size: 10px;
    text-align: center;

    &--medium {
        height: 50px;
    }
}

.spinner > div, .spinner--medium > div  {
    background-color: $blue;
    height: 100%;
    width: 2px;
    display: inline-block;
    margin: 0 1px;

    -webkit-animation: stretchdelay 2s infinite ease-in-out;
    animation: stretchdelay 2s infinite ease-in-out;
}

.spinner--medium > div {
    width: 3px;
    margin: 0 1.5px;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.8s;
    animation-delay: -1.8s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.6s;
    animation-delay: -1.6s;
}

.spinner .rect4 {
    -webkit-animation-delay: -1.4s;
    animation-delay: -1.4s;
}

.spinner .rect5 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}

.spinner .rect6 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.spinner .rect7 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}
