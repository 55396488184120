.dt-accordion {
    background: $greyLight7;
    padding: 5px 10px 10px;

    &__header {
        display: flex;
        text-transform: uppercase;
        align-items: stretch;
        font: bold 9px/9px Tahoma, sans-serif;

        .dt-accordion-cell {
            padding: 9px 18px;
        }
    }

    &__items {
        background: $white;
    }

    &__item {
        border-bottom: 1px solid  $greyDarker4;
        padding: 0 1px;

        &--header {
            display: flex;
            align-items: stretch;

            .dt-accordion-cell {
                padding: 7px 18px;
            }

            .dt-accordion--toggle {
                cursor: pointer;

                svg {
                    fill: $greyDarker;
                }
            }

            .dt-accordion-cell--toggle {
                text-align: center;
            }
        }

        &--opened {
            padding: 0;
            border-color: $greyDarker4;
            border-left: 1px solid  $greyDarker4;
            border-right: 1px solid  $greyDarker4;
            border-top: 0 !important;
            margin-top: -1px;

            .dt-accordion__item--header {
                background: $blueLight;
            }

            .dt-accordion-cell {
                border-top: 1px solid  $greyDarker4;
            }

            .dt-accordion-cell--toggle {
                background: $white;
                border-top: 1px solid  $greyDarker4;
            }

            .dt-accordion--toggle {
                svg {
                    fill: $defaultColor;
                }
            }
        }

        &--body {
            padding: 10px;
        }
    }
}
