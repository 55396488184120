.image-cropper-modal {
    z-index: 1999;
    // fix for onboarding tooltips
    position: relative;
    .base-modal--overlay {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 0;
    }
    .base-modal {
        padding: 0;
        .base-modal--header {
            padding: 0;
            .base-modal--header-inner {
                padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 3);
            }
        }
        .base-modal--body {
            padding: 0 0 24px;
        }
        .close-modal-button {
            right: 24px;
        }
    }
    .base-modal--footer {
        padding: 0;
    }
    .crop-image-modal-header {
        display: flex;
        align-items: flex-start;
        h4 {
            font-size: 16px;
            line-height: 24px;
        }
        #thumbnail-cropper-onboarding-1 {
            display: inline;
        }
        .crop-image-modal-title {
            h4 {
                display: inline;
            }
        }
        .crop-image-modal-descr {
            margin-top: calc(#{$indentBasis} * 2);
            font-family: $BrandRegular;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .show-thumb-onboarding {
        position: absolute;
        right: 62px;
        top: 16px;
    }
    .image-crop-wrapper {
        padding: 0 24px;
        border-bottom: $classicBorder;
        .controls {
            .material-icons {
                font-size: 2.2rem;
            }
        }
    }
    .crop-image-modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 0 24px;
        &-wrapper {
            display: flex;
            justify-content: space-between;
        }
        .crop-from-video-btn {
            margin-left: 5px;
        }
    }
    .thumb-error {
        margin-top: 16px;
        padding: 0 24px;
    }
}

.single-file-preview {
    display: flex;
    align-items: center;
    height: 34px;
    border-radius: 4px;
    border: 1px solid $greyDarker2;
    &--name {
        max-width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    img {
        width: 24px;
        height: auto;
        margin: 0 8px;
    }
}

.update-thumbnail-wrapper {
    .preview-thumb, .preload-generated-thumb, .preview-thumb-icon {
        width: 100%;
    }
    .button--action.update-thumb-btn {
        margin-bottom: 16px;
    }
    .error {
        margin-top: 16px;
    }
}
