.base-redux-modal.reuse--modal {
    .base-modal {
        height: 600px;
    }

    .edit_metadata_header {
        margin-top: 0;
    }

    .add-modal__fieldset {
        & > div {
            &:first-child {
                .input-pair {
                    padding-top: 0;
                    .input-pair__title-box {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .notice-container {
        margin-bottom: 10px;
    }

    .base-modal--body {
        padding-top: 0;
        height: calc(100% - 120px);
        position: relative;

        .sales_reuse_footer {
            width: 100%;
            height: 34px;
            position: absolute;
            bottom: 0;
            left: 0;

            button {
                width: 100%;
            }
        }

        .tabs__menu {
            display: none;
        }

        .tabs__panel {
            padding-top: 0;
        }

        .sales-reuse-submit {
            display: flex;
            justify-content: flex-end;
            padding-top: 5px;

            .button--action {
                margin-right: 0;
            }
        }

        .manage-asset-form {
            height: 100%;
        }

        .asset-preview {
            width: auto;
        }

        .edit_sales_reuse {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 15px 10px;
            box-sizing: border-box;
        }
    }

    .manage-asset-form.sale .form-field-wrapper > .with-pseudo-title.sales_role:before {
        content: '';
        padding: 0;
    }

    .preview-modal__header__nav-tabs {
        visibility: hidden;
        height: 10px;
        margin: 0;
    }

    .asset-preview {
        border-bottom: $classicBorder;
    }

    .online-asset-production {
        position: relative;

        .form-field-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }
}

.reuse_edit_close--container {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: flex-start;
}

.asset-actions-popup .actions__container.actions__container--links.reuse_popup .asset-actions-popup {
    padding-right: 5px;
    left: 100%;
    width: 200px;
}

.sales_reuse_preview_container {
    .collapse, .collapse.collapse--with-arrow {
        border-top: 0;

        .collapse-toggle .collapse-toggle__title {
            font-family: $BrandRegular;
        }

        .collapse-body {
            padding-left: 17px;
        }
    }
}

@import './style_responsive';
