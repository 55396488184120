.publish-form-modal {
    width: 100% !important;
    max-width: 100% !important;

    .cmps-publishing-form--switcher {
        right: 34px !important;
        top: 10px;
    }

    .manage-asset-form {
        padding-top: 0 !important;
    }

    &__title {
        font-size: 20px;
        padding-left: 30px;
        padding-top: 5px;
        font-family: $BrandMedium;
    }

    .cmps-publishing-form__buttons {
        margin: 0;
        padding: 2*$indentBasis 0 0;
        position: relative;
        z-index: 2;
        display: flex;
        gap: $indentBasis;
        border-top: $classicBorder;
        justify-content: flex-end;
        .button--action {
            min-width: 60px;
        }
    }

    .carousel-navigation--wrapper {
        bottom: 0 !important;
    }

    .with-pseudo-title:before { //For sales only
        display: none !important;
    }

    .asset-preview__heading {
        text-indent: 0 !important;
        margin: -15px -15px 0 -15px;
    }
}

.publish-form-container {
    box-sizing: border-box;

    .step-container {
        padding: 10px 0 26px 0;
        display: flex;

        &__left, &__right {
            flex-grow: 1;
            width: 45%;
            padding-right: 40px;
        }
    }

    .publishing-steps__item {
        &.invalid {
            .publishing-steps__item__controls .button-step {
                color: $red !important;
            }
        }
    }

}

.cmps-publishing-form--switcher {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1000;

    .button--action {
        width: 36px;
    }
}

.tabs.manage-asset-form.tabs-publishing-steps {

    .asset-preview__heading {
        visibility: hidden;
    }

    .collapse-body {
       padding: 0 20px 0 20px;
    }
}

.page.page--cmps-publishing {
    .cmps-publishing-form {
        max-width: 100%;
        position: relative;
    }

    .publish-form-container {
        margin-top: 60px;
        height: calc(100% - 75px);
    }

    .cmps-publishing-form__buttons {
        padding: 2*$indentBasis 3*$indentBasis 0;
    }
}

@import './components/publishing_form_steps/publishing_steps';
@import './styles_responsive';
