@media #{$mobile} {
    .replace-thumbnail-modal {
        .file-preview {
            .files-for-upload__item__image {
                margin-right: 15px;
                height: 40px;
                min-width: auto;
            }
            &__name {
                font-size: 13px;
            }
            &__size {
                font-size: 11px;
            }
            margin-bottom: 0;
        }

        .modal__header {
            // background: $white;
            border: none;
            padding-bottom: 0;
            &__text {
                font-size: 14px;
                font-family: $BrandMedium;
            }
        }

        .modal__footer {
            padding-top: 0;
        }

        &__tip {
            padding-bottom: 0;
            padding-top: 0;
        }
    }
}