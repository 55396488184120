.replace-thumbnail-modal {
    .file-preview {
        padding: 0;
        border: none;
        background: $greyLight6;

        &__info {
            padding-top: 5px;
        }

        &__name {
            font-family: $BrandRegular;
        }

        &__size {
            color: $greyDarker3;
        }

        .file-preview__button {
            margin-top: -3px;
            svg {
                path {
                    fill: $greyDarker3;
                }
            }
        }
    }

    .modal__content {
        padding: 15px 20px 0 20px;
    }

    &__tip {
        color: $greyDarker3;
        line-height: 24px;
        padding: 5px 0 20px;
    }

    &--with-files {
        .replace-thumbnail--wrapper {
            .mac-file__flex-container .button--file,
            .replace-thumbnail-modal__tip {
                display: none;
            }
        }

        .modal__footer {
            display: block;
        }
    }
}


.asset-actions-popup .actions__container.actions__container--links.thumbnail_popup {
    .asset-actions-popup {
        padding-right: 5px;
        left: 100%;
        width: 200px;
    }

    .button--action {
        width: 100%;
        border-radius: 0px;
        color: $blackLight;
        text-align: left;
        justify-content: flex-start;
    }
}

@import "./replace_thumbnail_style_responsive";