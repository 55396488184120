@media #{$mobile} {
    .project-workspace-page {
        &__header {
            padding: 15px;
            &__top {
                .project-workspace__breadcrumps {
                    display: none;
                }
                .project-workspace__actions {
                    margin-left: 0;
                }
                padding-bottom: 15px;
            }
            &__details {
                display: block;

                .project-details__name {
                    padding-right: 0;
                }
                .project-details__coordinator {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
}