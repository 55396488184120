.export_metadata_modal {
    .metadata-values--container-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        &-value {
            margin-top: 4px;
        }
    }
    .metadata-values--container {
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
    }
    .modal__content {
        min-height: 250px;
    }
}

.metadata-classes {
    .cabinet-header {
        position: relative;
        .export-metadata-btn {
            position: absolute;
            top: 5px;
            right: 0;
        }
    }
}
