@media screen and (max-width: 550px) {
    .widget-teams {    
        .team-item {
            width: 50%;

            &:nth-child(2n) {
                &:after {
                    display: none;
                }
            }
    
            &:nth-child(3) {
                .divider {
                    display: none;
                }
            }

            &:nth-child(3n) {
                &:after {
                    display: block;
                }
            }
        }
    }
    
    .my-team-table {
        .dt-cell--avatar {
            max-width: 50px;
            flex-basis: 50px;
            min-width: 50px;
        }
    }
}

@media screen and (max-width: 410px) {
    .widget-teams {    
        .team-item {
            width: 100%;
    
            &:nth-child(2) {
                .divider {
                    display: none;
                }
            }
        }
    }
}