@media #{$mobile} {
    .content-store-page {
        .search-in-store {
            padding: 0;
            .search-text-input--container {
                max-width: 100%!important;
                width: 100%;
                .search-text-input--wrapper {
                    width: 100%;
                }
            }
        }
    }

    .content-stores {
        .search-results--container {
            padding: 15px 0;
            .filter-tags {
                padding-left: 15px;
            }
        }
    }
}

@media #{$iPad} {
    .full-layout--right.content-stores-filter,
    .full-layout--right.search-results-filter {
        position: relative !important;

        & > .auto-hide {
            position: fixed !important;
            top: 50px !important;
            height: calc(100% - 50px);
        }
    }
}
