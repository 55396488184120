// Style for tether-generated className
.actions__container {
    position: relative;

    &__icon:hover + .asset-actions__item__tooltip {
        visibility: visible;
    }

    &.button--action:not(.dropdown-title) {
        width: 32px;
        height: 32px;
        line-height: 32px;
    }
    svg {
        width: 13px;
        height: 16px;

        path {
            fill: $greyDarker4;
        }
    }
}

.dt-grid__item {
    .asset-actions-popup {
        .copy-url-tooltip {
            .copy-url-tooltip--unonimous--link,
            .copy-url-tooltip--Internal--link,
            .copy-url-tooltip--profile--link,
            .copy-url-tooltip--review--link {
                line-height: 15px;
                word-break: break-word;
                word-wrap: break-word;
            }
        }
    }
}

.asset-actions__item--container, .generate-course-preview-url {
    position: relative;


    &-campaigns {
        float: none;
        margin-top: 20px;
        margin-bottom: 40px;

         .copy-url-tooltip {
            padding: 10px !important;
            box-shadow: none !important;
            border: 1px solid $greyDarker4 !important;
            top: -10px !important;
            left: 35px !important;
            width: 70% !important;
         }
    }

    .copy-url-tooltip, .copy-url-tooltip--Internal--link {
        position: absolute;
        display: flex;
        color: $defaultColor;
        background: $greyLight7;
        box-shadow: $grayShadow1;
        padding: 15px;
        top: 0;
        right: 120px;
        z-index: 2;

        &:hover .asset-actions__item__text{
            left: -20px;
            text-decoration: none !important;
        }

        .asset-actions__item__text{
            margin-left: -1px;
            position: absolute;
            left: -99999px;
            padding: 4px 10px;
            background: $orange;
            font-weight: normal;
            color: $greyDefault;
            z-index: 5;
            top: -10px;
            line-height: 14px;
            white-space: nowrap;
            text-decoration: none;
        }

        svg {
            margin-right: 15px;
            cursor: pointer;
            fill: $defaultColor;

            &:hover {
                fill: $greyDefault;
            }
        }

        &--vertical {
            display: block;
        }

        &--hidden {
            display: none;
        }
    }

    &-featured {

        svg {
            height: 17px;
        }
    }
}

.associate_urls .copy-url-tooltip,
.copy-url-tooltip--video {
    float: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.asset-actions__item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    min-height: 17px;
}

.asset-actions__item__icon svg {
    width: 15px;
    height: 15px;
    fill-rule: evenodd;
}

.asset-actions__item--wrapper {
    display: flex;
    justify-content: space-between;

    &.generate-course-preview--wrapper {
        flex-direction: column;
    }

    .generate-course-preview-url {
        display: flex;
        justify-content: space-between;
        min-width: $dropdownMenuWidth;
    }

    .button--action-light {
        min-width: 85px;
        max-width: 85px;
    }
}


.link-box__wrapper {
    .pseudo-button {
        cursor: default;
        font-family: $BrandMedium !important;

        &:hover {
            background: $white !important;
        }
    }
}

.actions-icon__unsubscribed  svg {
    width: 19px !important;
}

/* By default Actions are displayed in icon-mode  */
.asset-actions__item--popup-mode {
    overflow: hidden;
    line-height: 17px;
    display: none; 

    &:hover {
        .asset-actions__item__text {
            background: $greyLight2;
        }
    }

    .asset-actions__item__text,
    .asset-actions__item__icon {
        display: block;
        float: left;
    }
    .asset-actions__item__text {
        text-align: left;
        white-space: normal;
        width: 100%;
        padding: 6px 12px 6px 20%;
        color: $defaultColor;
    }
    .asset-actions__item__icon {
        position: absolute;
        padding-left: 7%;
        padding-top: 6px;
    }
}

.asset-actions__item--remove-container {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

// Zeroclipboard generated classname
.zeroclipboard-is-hover {
    .asset-actions__item__text {
        text-decoration: underline !important;
        color: $black !important;
    }
    cursor: pointer !important;
}

.asset-actions__links {
    padding-left: 30px;
}
/* end by default ... */

.dt__item {
    .actions__container {
        z-index: auto;
    }
}

.react-contextmenu {
    .asset-actions__item--popup-mode {
        display: block;
    }

    .content-store-folder-item__actions,
    .single-asset-actions-dropdown {
        .button--action {
            background: none;
            color: $greyDefault;
            box-shadow: none;
            display: flex;
            justify-content: flex-start;
            border-radius: 0;

            svg, .material-icons {
                margin-bottom: 3px;
                margin-right: 10px;
                path {
                    fill: $greyDarker3;
                }
            }
        }
    }

    .asset-actions__item--icon-mode {
        display: none;
    }

    svg {
        path {
            fill: $greyDarker3;
        }
    }
}

.single-asset-actions-dropdown {
    .button--action {
        font-family: $BrandRegular;
        padding-left: 15px;
        color: $greyDefault;
        font-size: 14px;
        height: 100%;
        text-transform: none;
        svg {
            margin-bottom: 3px;
        }
        > span {
            display: block;
        }
        &:hover {
            border: none;
        }
    }
    .asset-actions__item, .button--action {
        height: 35px;
    }
}

.actions__container.actions__container--links {
    .asset-actions__item--popup-mode {
        &:hover {
            background: $white;
        }
    }
    .link-box__title {
        padding: 11px 11px 8px 16px;
        color: $greyDarker3;
    }
    .link-box__wrapper {
        .info-tooltip {
            position: absolute;
            right: -45px;
            top: 5px;
            .material-icons {
                font-size: 18px;
                color: $greyDarker3;
            }
        }
    }
    .asset-actions-popup--grid {
        .actions__container__set {
            padding: 0;
        }
        .asset-actions__item--popup-mode {
            padding: 0;
            .asset-actions__item__text {
                padding-left: 18%;
            }
            &:hover {
                .asset-actions__item__text {
                    background: $hoverBg;
                }
            }
        }
    }
}

.dropdown-body  .dropdown-body__content__items {
    .link-box__title {
        padding: 12px 16px 8px 16px;
        color: $greyDarker3;
        font-size: 10px;
        cursor: default;
        line-height: 16px;
    }
}


.actions__container--links {

    .asset-actions__item--popup-mode {
        &:hover {
            .asset-actions__item__text {
                background: $white;
            }
        }
    }

    .asset-actions-popup {
        width: 320px;
        padding: 0;
        padding: 8px 50px 8px 0px;
        &__close {
            padding: 9px;
            border-bottom: 1px solid $greyDarker4;
        }
    }

    .actions__container__set {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .collapse {
        border: none;
        padding: 0;
    }

    .link-box__wrapper, .asset-actions__item--wrapper{
        width: 100%;
        position: relative;
        .button--action{
            padding: 2px 5px 2px 10px;
            font-size: 14px;
            font-family: $BrandRegular !important;
            line-height: 28px !important;
            color: $greyDefault;
            box-shadow: none;
            svg {
                flex-grow: 0;
                flex-shrink: 0;
                margin-bottom: 3px;
                path {
                    fill: $greyDarker3 !important;
                }
            }
            & > span {
                max-width: 270px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .collapse-button{
            position: absolute;
            transition: .3s;
            width: 40px;
            height: 32px;
            right: -45px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            border-radius: 3px;
            &:hover {
                background: $greyDarker2;
            }
            svg {
                width: 13px;
                height: 13px;
                path {
                    fill: $greyDarker;
                }
            }

            .button--action{
                width: 100%;
                padding-left: 13px;
                border-radius: 3px;
                svg{
                    margin-bottom: 1px;
                }
            }
            @media #{$IE} {
                .button--action {
                    padding-left: 13px;
                    padding-top: 4px;
                }
            }
        }
    }

    .link-box__description {
        font-size: 12px;
        line-height: 18px;
        font-family: $BrandRegular;
        padding: 0;
        margin-left: 40px;
        color: $greyDarker3;
    }

    &.actions__container--vertical {

        .actions__container__set {
            flex-direction: column;
        }

        .link-box__wrapper + .link-box__wrapper {
            margin-left: 0;
        }
    }
}

.asset-actions__item--icon-mode {
    position: relative;
    margin-right: 8px;

    &:hover .asset-actions__item__tooltip {
        visibility: visible;
    }
}

.asset-actions__item--hidden {
    visibility: hidden;
}


.asset-actions__item__tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 5;
    top: -35px;
    right: 50%;
    transform: translateX(50%);
    padding: 5px 10px;
    background: $black;
    font-weight: normal;
    color: $white;
    line-height: 14px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 14px;
    font-family: $BrandRegular;
    box-shadow: $grayShadow3;

    &:first-letter {
        text-transform: uppercase;
    }

    &:after {
        position: absolute;
        z-index: 1;
        content: ' ';
        display: inline-block;
        border-top: 4px solid;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top-color: $black;
        top: 100%;
        left: 50%;
        margin-left: -5px;
    }
}

.asset_preview_page--content {
    .download-hacky-parent {
        .button--action:not(.dropdown-title) {
            i {
                color: white;
            }
        }
    }
}

.dropdown-body__content__items {
    .download-hacky-parent {
        padding: 0;
    }
}

.asset-links-trigger:hover,
.asset-links-trigger--open,
.asset-actions-trigger:hover,
.asset-actions-trigger--open {
    svg {
        path {
            fill: $greyDefault;
        }
    }
}




.assets-list {
    .asset-actions-trigger {
        width: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

.assets-grid {
    .asset-actions-trigger {
        float: right;
        width: 20px;
        height: 20px;
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {

    // IMPORTANT: width should be half of _sprite-retina.png width
    .asset-actions__item__icon,
    .saved-searches-item__actions__delete-button {
        background-size: 22px !important;
    }
}

.associate-asset-with-campaign,
.copy-video-link,
.relational-preview-link {
    @include modal(470px, 100%, 100%);

    .modal__footer__buttons {
        box-sizing: content-box;
    }

    .copy-video-link_title {
        .base-textarea {
            height: 75px !important;
            max-height: 75px;
        }
    }

    .copy-video-link_description {
        .base-textarea {
            height: 100px !important;
            max-height: 100px;
        }
    }
}

.associate-asset-with-campaign .tooltip__container {
    margin-left: 5px;
}

.asset-folders-modal {
    .asset-folders__kit-link {
        color: $blue;
    }
}

.actions-icon__generate-pdf:after {
    content: 'PDF';
}

.actions-icon__generate-pptx:after {
    content: 'PPTX';
}

.request-brand-feedback {

    &--disabled {
        pointer-events: none;
        opacity: 0.7;
    }
}

@include media-ipad {
    .link-box__wrapper {
        margin: 0 !important;
    }

    .actions__container--links .asset-actions-popup__close {
        border: 0;
    }
}

.dummy.isIE {
    .asset-actions__item--container .copy-url-tooltip {
        width: 360px;
        word-break: break-all;
    }
}
