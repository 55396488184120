@media #{$mobile} {
    .associate-asset-with-campaign {
        .modal-positioner {
            .modal {
                &__header {
                    background: $white;
                    border: none;
                    &__text {
                        font-family: $BrandMedium;
                        font-size: 14px;
                    }
                }
                &__content {
                    padding-top: 0;
                }
                &__footer {
                    padding-right: 40px;
                }
            }
        }
    }
}