.caption_edit_modal {
    .base-modal {
        width: 856px;
    }
}

.captions_edit_container {
    width: 400px;
    margin-left: calc(#{$indentBasis} * 2);
    height: 400px;
}

.caption_preview_container {
    width: 400px;

    .asset-detail__preview {
        height: 225px;
    }
}

.caption_edit {
    display: flex;

    .preview-container {
        border-radius: 4px;
        overflow: hidden;
    }
}
.primary_caption_field {
    display: flex;
    justify-content: space-between;
}

.captions_editor {
    border: $classicBorder;
    height: calc(100% - 24px);
    border-radius: 3px;
    background: $white;

    &.captions_editor_error {
        border-color: $red;
    }
}

.captions_list {
    padding: #{$indentBasis} calc(#{$indentBasis} * 2);
}

.caption_item {
    margin-bottom: calc(#{$indentBasis} * 2);

    .base-textarea {
        border: 0;
        padding-right: 0;
        padding-left: 0;
        min-height: 90px;
    }

    &--time {
        color: $greyDarker;
    }

    &.caption_item--active {
        .caption_item--time {
            color: $blue;
        }
    }
}

.captions_edit_header {
    margin-bottom: $indentBasis;
    font-family: $BrandMedium;
}

.caption_edit_footer {
    display: flex;
    justify-content: space-between;
    border-top: $classicBorder;
    padding: 16px 20px 0 20px;
    margin: 0 -20px;
}

.caption_edit_footer--left {
    display: flex;

    .button--action {
        margin-right: $indentBasis;
    }
}



.caption_editor-scroll {
    margin-right: 10px;
}