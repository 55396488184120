.search-item-content--other-info {
    width: 100%;
    display: flex;
    font-family: $BrandRegular;
    font-size: 12px;
    line-height: 20px;
    color: $greyDefault;

    .asset-info-box {
        height: 24px;
        display: flex;
        justify-content: flex-start;
    }
}

.asset-preview-content .search-item-content--other-info {
    justify-content: flex-start;
}

.asset-info-box {
    .asset-preview__new-data {
        margin-right: 8px;

        .other-info-box {
            padding-right: 0;
            color: $saladGreenDark !important;
        }
    }
}

.other-info-box {
    padding-right: calc(#{$indentBasis} * 2);
    color: $greyDefault !important;
    background: transparent;
    font-family: $BrandMedium;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;

    &.html_link_wrapper {
        position: relative;
        padding-right: 5px;

        .html_link {
            margin-top: 2px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 16px;
            background: $greyDarker2;
            left: -8px;
        }
    }

    &.asset-id {
        padding-right: 0;
        margin-left: auto;
        margin-top: 3px;
    }

    &.shrink {
        flex-shrink: initial;
        > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            overflow: hidden;
        }
    }

    svg, img {
        margin-right: calc(#{$indentBasis} / 2);
        flex-shrink: 0;
        flex-grow: 0;
    }

    svg {
        width: 14px;
        height: 14px;
    }

    b {
        font-family: $BrandBold;
    }

    &.type,
    &.asset-usage,
    &.html_link_wrapper,
    &.language {
        margin-top: 3px;
    }

    &.type,
    &.language {
        position: relative;
        overflow: visible;
        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 16px;
            background: $greyDarker2;
            left: -8px;
        }
        @media #{$IE} {
            &:after {
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &.language.with-translations {
        margin-top: 2px;
        padding-right: 11px;
        display: block;
        max-width: 160px;
        min-width: 60px;
        &:before {
            left: -3px;
            top: 3px
        }
        svg {
            max-width: none;
            max-height: none;
            path {
                color: $greyDefault;
            }
        }
        .dropdown {
            float: none;
            &-title {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                overflow: hidden;

                &:hover {
                    background-color: $greyLight2;
                }

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &-body {
                &--top {
                    top: auto;
                    bottom: 30px;
                }
            }
        }
    }
    &.language:not(.with-translations) {
        svg {
            rect {
                fill: $white;
            }
            path {
                fill: $greyDefault;
            }
        }
    }
    .material-icons {
        font-size: 16px;
        margin-right: calc(#{$indentBasis} / 2);
        color: $greyDefault;
    }

    span {
        margin-top: 1px;
    }
}

.asset-usage {
    text-transform: none;
    svg {
        width: 17px;
        height: 17px;
        margin-top: -2px;
    }
    &--public,
    &--direct {
        svg path {
            fill: $saladGreenDark;
        }
    }
    &--internal {
        svg path {
            fill: $orange;
        }
    }
    &--partner {
        svg {
            width: 15px;
            height: 15px;
            path {
                fill: $blue;
            }
        }
    }
    &--customer {
        svg {
            width: 15px;
            height: 15px;
        }
    }
}