$menuTransitionTme: 0.2s;
$iconColor: $greyDarker3;
$sectionLeftSpace: 32px;
$itemLeftSpace: 29px;
$collapseLeftSpace: 67px;

.navigation {
    width: inherit;
    height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
    position: fixed;
    left: 0;
    overflow: auto;
    z-index: 6;
    border-right: $classicBorder;

    .mobile-overlay {
        z-index: 1;
        display: none;
    }

    &--opened {
        .page-header__text {
            padding-left: 20px;
        }

        .mobile-overlay {
            display: block;
        }
    }

    &__body {
        height: 100%;
        background-color: $white;
        position: relative;
        z-index: 1;
        box-sizing: border-box;

        .section__items {
            transition: all $menuTransitionTme;
            visibility: visible;
            max-height: none;
        }
    }

    &__section {
        padding: 8px 0;
        border-bottom: $classicBorder;
        box-sizing: border-box;

        &-label {
            padding: 12px $sectionLeftSpace 8px;
            font-family: $BrandMedium;
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            color: $greyDarker3;
            text-transform: uppercase;
            box-sizing: border-box;
        }

        .navigation-nested-wrapper {
            position: relative;

            .navigation-item__icon {
                position: absolute;
                top: 8px;
                left: $itemLeftSpace;
            }

            .navigation-item__counter {
                right: 34px;
                top: 10px;
            }
        }

        .collapse {
            padding: 0;
            border-top: none;

            .collapse-body {
                padding: 0;
            }

            &.collapse--opened {
                padding-bottom: 0;

                .collapse-toggle__icon__arrow {
                    transform: rotate(180deg);
                }

                .collapse-toggle {
                    .collapse-toggle__icon {
                        .collapse-toggle__icon__arrow {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            .collapse-button {
                border: 0;
                height: 38px;
                padding: 13px 10px 8px $collapseLeftSpace;
                box-sizing: border-box;
                &:focus {
                    outline-offset: -2px;
                }

                &:hover {
                    background: $hoverBg;
                }
            }

            .collapse-toggle {
                flex-direction: row-reverse;
                justify-content: space-between;

                .collapse-toggle__title {
                    font-family: $BrandRegular;
                }

                .collapse-toggle__icon {
                    width: 16px;
                    height: 16px;
                    .collapse-toggle__icon__arrow {
                        transform: rotate(90deg);
                        top: -1px;
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            .collapse-body {
                .navigation-item {
                    padding-left: $collapseLeftSpace;
                }
            }
        }
    }

    &__footer {
        padding: 18px $sectionLeftSpace;
        font-size: 12px;
        line-height: 20px;
        color: $greyDarker3;
        box-sizing: border-box;

        &-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: inline;
            }
        }

        &-item {
            margin-right: 9px;
            font-family: $BrandMedium;
            color: inherit;
            transition: all 0.25s;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .user-info {
        padding: 8px 15px 0;
        text-align: center;
        box-sizing: border-box;

        .user-avatar {
            margin: 0 auto 8px;
        }

        .user--name {
            font-family: $BrandMedium;
            font-size: 14px;
            line-height: 24px;
        }

        .user--email {
            width: 100%;
            font-size: 12px;
            line-height: 18px;
            color: $defaultColor;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .user--id {
            font-family: $BrandMedium;
            font-size: 12px;
            line-height: 18px;
            color: $blue;
        }
    }
}

.navigation-item,
.user-profile-item,
.navigation-item.button--action:not(.dropdown-title),
.user-profile-item.button--action:not(.dropdown-title),
.dropdown-body button.user-profile-item:not(.base-checkbox__icon):not(.search-input-actions__button):not(.anime-toggle--button):not(.dropdown-body__content__close):not(.user-profile--button) {
    width: 100%;
    height: auto;
    padding: 8px 10px 8px $itemLeftSpace;
    font-family: $BrandRegular;
    font-size: 14px;
    line-height: 24px;
    color: $greyDefault;
    text-align: left;
    text-decoration: none;
    background-color: $white;
    border: none;
    box-shadow: none;
    border-radius: 0;
    display: block;
    box-sizing: border-box;

    &.active {
        font-family: $BrandMedium;
        background: $hoverBg;
    }

    &:hover {
        background: $hoverBg;
        text-decoration: none;
    }

    &:focus {
        border-radius: 4px;
        outline-offset: -2px;
    }

    &:disabled,
    &.disabled {
        color: $greyDarker3;

        .navigation-item__icon,
        .user-profile-item__icon {    
            svg {
                path:first-child {
                    fill: $greyDarker3;
                }
            }
    
            .material-icons {
                color: $greyDarker3;
            }
        }

        svg {    
            path:first-child {
                fill: $greyDarker3;
            }
        }
    }
}

.navigation-item.button--action:not(.dropdown-title),
.user-profile-item.button--action:not(.dropdown-title),
.dropdown-body button.user-profile-item:not(.base-checkbox__icon):not(.search-input-actions__button):not(.anime-toggle--button):not(.dropdown-body__content__close):not(.user-profile--button) {
    display: flex;
    justify-content: flex-start;

    svg {
        width: 20px;
        height: 20px;
        max-width: none;
        max-height: none;
        margin-right: 17px;
        margin-left: 4px;

        path {
            fill: transparent;
        }

        path:first-child {
            fill: $iconColor;
        }
    }
}

.navigation-item,
.user-profile-item {
    & &__content {
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left;
        color: $greyDefault;
        position: relative;

        .navigation-item__counter {
            top: 1px;
            right: 0;
        }
    }

    &__counter {
        width: 20px;
        height: 20px;
        padding: 1px;
        border-radius: 50%;
        background: $blue;
        font-family: $BrandRegular;
        font-size: 9px;
        line-height: 18px;
        text-align: center;
        color: $white;
        position: absolute;
        box-sizing: border-box;
    }

    &__text {
        width: 147px;
        padding-top: 2px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;

        img {
            width: 21px;
            height: 21px;
        }

        svg {
            width: 17px;
            height: 17px;

            path {
                fill: $iconColor;
            }
        }

        .material-icons {
            font-size: 20px;
            color: $iconColor;
        }
    }

    &.button--action {
        svg {
            width: 17px !important;
            height: 17px !important;

            path {
                fill: $iconColor;
            }
        }
    }
}

.navigation-item.content_stores_item {
    .navigation-item__icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: $blueLight5;
        svg {
            width: 14px;
            height: 14px;
            path {
                fill: $blue;
            }
        }
    }
}

.dummy.isMobile, .dummy.isIOS {
    .fixed-navigation {
        box-sizing: border-box;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .navigation-main-container {
        width: 0;

        .navigation--opened {
            width: 230px;
        }
    }
}
