.new-search-page.content-store-page.simple-view {
    .content-store-header {
        .content-store-description {
            align-items: center;

            .info {
                align-items: center;

                .title {
                    font-family: $BrandBold;
                }

                .folder-description {
                    margin-top: 0;
                    font-size: 16px;
                }
            }

            .content-store__thumbnail__icon {
                width: 48px;
                height: auto;
                padding: 0;
            }
        }
    }
    .carousel-tree .content-store-header .content-store-description .content-store__thumbnail__icon {
        width: 32px;
    }
    .search-in-store {
        margin-bottom: calc(#{$indentBasis} * 3);
        justify-content: center;
    }
    .content-store-header--bottom {
        justify-content: center;

        .content-store-description {
            flex-grow: 0;
            flex-direction: column;
            align-items: center;
        }
    }

    .content-store__thumbnail__icon {
        width: 40px;
        height: 22px;
        background: none;
        border: none;
        padding: 0;
    }

    .saved_search_details .content-store__thumbnail__icon {
        background: white;
        width: 35px;
        height: 35px;
        border: $classicBorder;
        padding: 5px;
        svg {
            width: 15px;
        }
    }
}