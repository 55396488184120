
.cabinet-header {
    .create_toast, .export_metadata_link {
        margin-left: 10px;
    }
}
.aside-modal.create_toast-modal {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 470px;
    .close_modal {
        padding: 0;
    }
    .user-change-requests-manage-modal--header {
        padding: 20px 20px 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-change-requests-manage-modal--title {
            font-size: 18px;
            font-family: $BrandMedium;
        }
        .button--action .material-icons {
            font-size: 22px;
        }
    }
    .toast_example_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .create_toast-modal--body {
        height: 100%;
        padding: 0 30px;
        .closePreview {
            width: fit-content;
        }
        @media #{$IE} {
            .closePreview {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
        .toast {
            margin-left: auto;
        }
        .warningMessage {
            margin-top: auto;
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 17px;
            span {
                color: $red;
            }
        }
    }
    .manage_user_form--controls {
        .button--action{
            width: 100%;
            border-radius: 0;
        }
    }
    .url-input-container {
        left: 0;
        top: 30px;
    }
}

.block-modal {
    position: fixed;
    z-index: 100000000;
    width: 100%;
    height: 100%;

    .overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.5;
    }

    .block-modal--container {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .block-modal--container-inner {
        width: 70%;
        max-width: 800px;
        background: white;
        padding: 60px 100px;
        font-size: 1.6rem;
        line-height: 2rem;

        .block-modal--icon {
            margin-right: 40px;

            svg {
                width: 100px;
                height: 100px;
            }

            .material-icons {
                font-size: 10rem;
            }
        }
    }

    &.ERROR {
        .block-modal--container-inner {
            border-top: 10px $red solid;
        }

        .block-modal--icon {
            .material-icons {
                color: $red;
            }
            svg {
                path {
                    fill: $red;
                }
            }
        }
    }

    &.INFO {
        .block-modal--container-inner {
            border-top: 10px $blue solid;
        }

        .block-modal--icon {
            .material-icons {
                color: $blue;
            }
            svg {
                path {
                    fill: $blue;
                }
            }
        }
    }

    &.WARNING {
        .block-modal--container-inner {
            border-top: 10px $orange solid;
        }

        .block-modal--icon {
            .material-icons {
                color: $orange;
            }
            svg {
                path {
                    fill: $orange;
                }
            }
        }
    }

    .block-modal--container-content {
        display: flex;
    }

    .block-modal--container-footer {
        display: flex;
        justify-content: flex-end;
    }
}
