/* **********************************
****   STYLES FOR SORT FIELD - START
************************************* */
.search-sortby-field--container {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin: 0 20px;
    position: relative;
    width: 100%;

    & > .dropdown {
        display: flex;
        align-items: center;
    }

    & > .base-input {
        width: 150px;
    }

    .search-sortby-field--wrapper {
        display: flex;
        align-items: center;
    }

    .search-sortby-field--title {
        margin-right: 10px;
        color: $greyDarker3;
    }
}

.sortby-actions--dropdown {
    display: flex;
    align-items: center;

    .sortby-dropdown-body {
        list-style: none;
    }

    &.opened {
        & .dropdown-title {
            &::after {
                border-top: none;
                border-bottom: 6px solid $greyDarker3;
            }
        }
    }

    & > .dropdown-body {
        left: 0;

        & > .sortby-dropdown-body {
            padding: 0;
            margin: 0;

            &:hover {
                background: none;
            }
        }

        & .sortby-button-action {
            list-style: none;
            padding: 5px 10px;
            color: $blackLight;

            &:hover {
                background-color: $greyLight7;
            }
        }
    }
}

.companies-amount {
    font-family: $BrandMedium;
}
/* ********************************
****   STYLES FOR SORT FIELD - END
*********************************** */

.cvp-preview-page {

    &__container {
        width: 100%;
        padding: 0 calc(#{$indentBasis} * 6);
        box-sizing: border-box;

        .usage--Direct svg path {
            fill: #398C2B;
        }

        .usage--Internal svg path {
            fill: $orange;
        }
    }

    &__info {
        width: 60%;
        margin-right: 24px;

        .preview-container {
            padding-top: 56%;
            position: relative;
            box-sizing: border-box;
            .asset-image-wrapper {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__assets-list {
        width: 40%;

        .dt-grid--search {
            display: none;
        }
    }
    &__subtitles {
        margin-left: 35px;

        &__title {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 16px;
            font-family: $BrandMedium;
        }
        &__item {
            background-color: $white;
            display: flex;
            font-size: 16px;
            border-radius: 3px;
            box-shadow: $grayShadow1;
            margin-bottom: 15px;
        }

        &__info {
            overflow: hidden;

            &.related-asset__info {
                .related-asset__name {
                    color: $blackLight;
                    margin-bottom: 10px;
                }
            }
        }

        &__name {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 5px;
        }

        .subtitles__item-image {
            box-sizing: border-box;
            border-right: 1px solid $hoverBg;
        }

        .general-description {
            font-size: 12px;
        }
    }
    &__assets-amount {
        display: flex;
        margin-bottom: 15px;
        font-size: 16px;
    }

    .cvp-preview-header {
        &.content-store-header {
            flex-direction: column;
            padding-bottom: 0;
        }

        &__languages {
            display: flex;
            margin-top: 16px;
            margin-bottom: 24px;

            .language-tab {
                font-family: $BrandMedium;
                font-size: 16px;
                padding: 10px 25px;
                color: $blackLight;
                cursor: pointer;

                &--active {
                    border-bottom: 3px solid $orange;
                }
            }
        }
    }

    .content-store-header {
        padding-left: 0;
        padding-right: 0;

        &--breadcrumbs {
            padding-left: 0;
            margin-left: -5px;
        }
    }

    .content-store-description {
        color: $blackLight;
        font-family: $BrandMedium;
        font-size: 24px;
        line-height: 35px;
        margin-top: 15px;
    }

    /* CVP preview page content view */
    &__content-wrapper {
        display: flex;
        max-width: $maxLayoutWidth;
        margin: 0 auto;

        .video-js {
            height: 100%;
        }

        .assets-amount__title {
            font-family: $BrandMedium;
            margin-right: 5px;
        }
        .assets-amount__quantity {
            color: $greyDarker;
            font-family: Arial, sans-serif;
        }

        .other-info-box {
            &.type span {
                text-transform: capitalize;
            }
        }

        .related-asset {
            &__container {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .grid-view__item {
                    width: calc(100% - 35px);
                }
            }

            &__sequence {
                width: 35px;
            }

            &__sequence-number {
                color: $greyDarker;
                font-family: Arial, sans-serif;
                font-size: 14px;
                font-weight: 700;
                margin-right: 15px;
                position: relative;
                text-align: right;
                width: 20px;
                flex-shrink: 0;

                &--active {
                    margin-right: 15px;
                    position: relative;
                    left: 6px;

                    svg {
                        width: 20px;
                        height: 20px;

                        path {
                            fill: $blue;
                        }
                    }
                }
            }

            &__thumbnail {
                position: relative;
                height: 85px;
            }

            &__img {
                max-width: 130px;
                min-width: 130px;
                height: 85px;
            }

            &__duration {
                padding: 3px;
                color: $white;
                background-color: rgba(34, 34, 34, 0.8);
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
            }

            &__info {
                background-color: $white;
                padding: 10px 15px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &__name {
                color: $navy;
                cursor: pointer;
                font-size: 16px;
                line-height: 20px;

                display: -webkit-box;
                display: -moz-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 5px;
            }
        }

        .dropdown.more-videos {
            .dropdown-body {
                width: 90vw;

                &__content {
                    &__items > * {
                        padding: 0;
                        cursor: auto;
                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }

    .general-description {
        width: 100%;
    }

    .cvp-asset-preview {
        &-actions {
            margin: 8px 0 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bulk-actions--dropdown {
                min-width: 0;
                margin-left: 10px;
                &.opened {
                    .dropdown-title > span {
                        position: relative;
                        &:after {
                            content: '';
                            right: -15px;
                            top: 50%;
                            margin-top: -2px;
                            @extend %arrow-up;
                        }
                    }
                }

                .dropdown-title {
                    color: $blackLight;
                    height: 32px;
                    background: $white;
                    padding-right:30px;
                    padding-left: 15px;
                    &:after {
                        border-top-color: $greyDarker3;
                    }
                    &:hover {
                        background: $hoverBg;
                    }

                    span {
                        position: relative;
                        &:after {
                            content: '';
                            right: -15px;
                            top: 50%;
                            margin-top: -2px;
                            @extend %arrow-down;
                        }
                    }
                }

                .dropdown-body {
                    svg {
                        margin-right: 10px;
                    }
                    .asset-actions__item--icon-mode {
                        display: none;
                    }
                    .asset-actions__item--popup-mode {
                        display: block;
                    }
                }
            }
        }

        &__title {
            font-size: 20px;
            font-family: $BrandMedium;
            line-height: 25px;
        }

        &__description {
            font-size: 14px;
            margin: 10px 0 20px;
            line-height: 20px;
            color: $greyDefault;
        }
    }

    .button--action {
        box-shadow: none;
    }

    .video-actions {
        .asset-actions-trigger {
            background-color: transparent;
        }

        .actions__container--links {
            .asset-actions-popup {
                top: 0;
            }
        }

        .asset-actions-popup {
            top: 40px;
            right: 0;

            svg path {
                fill: $blackLight;
            }
        }
    }

    .metadata-container {
        margin-bottom: 20px;
    }

    .metadata-title {
        border: 1px solid $hoverBg;
        background-color: $greyLight2;
        padding: 10px;
        font-size: 15px;
        font-family: $BrandMedium;
        margin-bottom: 20px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .metadata-items {
        padding: 0 10px;

        .data-pair {
            display: flex;
            margin: 10px 0;

            &__title {
                min-width: 220px;
                color: $greyDarker3;
                font-size: 14px;
                font-family: $BrandRegular;
                text-transform: none;
            }

            &__value {
                line-height: 20px;
                color: $blackLight;
                font-size: 14px;
                font-family: $BrandRegular;
                word-break: break-word;
            }
        }
        .approve-status .data-pair {
            align-items: center;
        }
    }
    
    .subtitles-container .data-pair {
        justify-content: space-between;
        align-items: center;
    }
}

@import './style_responsive';


