.new-reports-page--html {
    .cabinet-main-container {
        padding-bottom: 0;
    }
}

.new-reports-page {
    .reporting-dashboard-page .folder-section__title {
        padding-bottom: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-start;
        vertical-align: middle;
        align-items: center;

        .report-page-icon {
            border: none;
            padding: 0;
            margin: 0;
            padding-right: 10px;
            margin-bottom: -5px;
            display: inline-block;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.reporting-dashboard-page {
    width: 100%;

    .breadcrumbs {
        margin: 15px 0;
    }

    .lumira-report-iframe {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
    }

    .yui-skin-sam .yui-layout .yui-layout-unit div.yui-layout-bd-nohd {
        border: none;
    }

    .reporting-dashboard-page--header {
        padding: 16px 2px 0;
    }

    .reporting-dashboard-container {
        margin-left: -14px;
        overflow: hidden;
        height: 2500px; // no inner scroll, max of reports
        width: calc(100% + 42px);

        & > div {
            height: 100%;
        }
    }

    .folder-section__description {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
}

.campaign_statistic {
    .reporting-dashboard-page .reporting-dashboard-container {
        height: 2130px;
    }
}

.download_statistic {
    .reporting-dashboard-page .reporting-dashboard-container {
        height: 1800px;
    }
}

.gco_management {
    .reporting-dashboard-page .reporting-dashboard-container {
        height: 2450px;
    }
}

.gbcc_reports {
    .reporting-dashboard-page .reporting-dashboard-container {
        height: 1600px;
    }
}

.new-reports-page.content_usage {
    .reporting-dashboard-page .reporting-dashboard-container {
        height: 2800px;
    }
}

.new-reports-page.content_catalogue {
    .reporting-dashboard-page .reporting-dashboard-container {
        height: 1100px;
    }
}

.yui-skin-sam .yui-layout .yui-layout-unit div.yui-layout-bd-nohd,
.yui-skin-sam .yui-layout .yui-layout-unit div.yui-layout-bd {
    border: none !important;
}
