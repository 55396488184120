$widgetHeigth: 426px;

%widget-title {
    font-size: 16px;
    font-family: $BrandMedium;
    color: $greyDefault;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
}

%zoom-icon {
    .material-icons {
        font-size: 22px;
        color: $greyDarker;
    }
}

.cs-reporting-container {
    .cs-reporting--header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        vertical-align: middle;
        margin-bottom: 15px;
    }
    .date-period {
        .dropdown-body {
            .base-input {
                &:hover {
                    background: transparent;
                }
            }

            .dropdown-body__content .dropdown-body__content__items .button--action:disabled {
                background: $greyLight2!important;

                span {
                    font-family: $BrandMedium;
                    color: $greyDefault !important;
                }
            }
        }

        .to-container,
        .from-container {
            background: $white;
        }
    }

    .cs-reporting-widgets {
        display: flex;
        flex-flow: column wrap;
        max-height: 1395px;
        margin-left: -2%;
        width: 100%;

        &--title {
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            & > h2 {
                @extend %widget-title;
            }

            .button--action {
                margin: 0 -13px 0 10px;
                visibility: hidden;
                opacity: 0;
                transition: all .25s;

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &--actions {
            font-size: 12px;
            &-btn {
                &.button--action {
                    padding: 0 2px;
                    display: inline;
                }
            }
            &-label {
                text-transform: lowercase;
            }
            b {
                font-family: $BrandMedium;
            }
        }

        &--item {
            border: $classicBorder;
            background: $white;
            border-radius: 5px;
            padding: 2% 2.4% 2.4%;
            margin-bottom: 2%;
            margin-left: 2%;
            width: 44%;
            flex-grow: 0;
            flex-shrink: 0;
            min-height: $widgetHeigth;

            & > h2 {
                @extend %widget-title;
            }

            &.no-map-tooltip {
                .mouse-map-tooltop {
                    display: none !important;
                }
            }

            &:hover {
                .cs-reporting-widgets--title {
                    .button--action {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

        .dropdown-body {
            .base_block_select_dropdown--title {
                font-family: $BrandMedium;
                padding: 0 10px;
                font-size: 10px;
            }
            label.base-checkbox {
                padding: 10px 10px 11px;
            }
        }
    }

    @media #{$IE} {
        .cs-reporting-widgets {
            flex-flow: inherit;
        }
    }

    .cs-reporting-map {
        min-height: 300px;
        .map-zoom {
            margin-left: -10px;
        }
        .chart-controls {
            margin-left: 30px;
            >div {
                width: 100%;
            }
        }
    }

    .cs-reporting-chart {
        padding-bottom: 10px;

        button {
            background: $white;
            box-shadow: none;

            &:hover {
                background: $hoverBg;
            }
        }
    }

    .chart-controls {
        display: flex;
        align-items: center;
        vertical-align: middle;

        &.map-controls {
            margin-top: -30px;
        }

        .chart-controls-item {
            max-width: 180px;
            width: 100%;
            margin-left: 10px;
        }

        .base-input {
            width: 100%;

            .base-select {
                font-size: 12px;

                .Select-control {
                    height: 26px;

                    .Select-input {
                        height: 26px;
                    }

                    .Select-value {
                        padding-right: 30px;
                    }
                }
            }

            .Select-clear-zone {
                display: none;
            }
        }
    }
}
.new-reports-page {
    .reporting-dashboard-page {
        padding: 0 10px;
    }

    .content-store-header {
        border-bottom: none;
        background: transparent;
        padding-left: 0;
        padding-right: 0;

        &--bottom {
            margin-top: 18px;
        }

        .breadcrumbs {
            margin: 0;
        }
    }

    .reporting-dashboard-page--header {
        .folder-section__title {
            margin-bottom: 20px;
        }
    }
}

.apexChart {

    .apexcharts-legend-series {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .apexcharts-legend.position-bottom.apexcharts-align-left {
        bottom: -5px !important;
    }

    .dropdown {
        bottom: -2px;
    }
}

.folder-tree-map {
    height: $widgetHeigth;

    &--empty {
        margin-top: 20px;
        text-align: center;
        color: $greyDarker;
    }

    .cs-reporting-widgets--actions {
        margin-bottom: 20px;
    }

    .chart-controls {
        margin-top: 20px;
    }

    & > div {
        height: 100%;
    }

    &--folder .recharts-wrapper path {
        cursor: default;
        background: $orangeLight;
    }

    &--folders {
        margin-left: -15px;
    }

    &--folder {
        margin-left: 15px;
        margin-bottom: 15px;

        path {
            cursor: default !important;
        }
    }
}

.cs_report_details--modal {
    .modal {
        min-height: 300px;
    }

    &.base-redux-modal {
        .base-modal {
            width: 900px;
        }

        .base-modal--footer {
            padding-top: 0;
        }
    }
    &.base-redux-modal .main-modal-style {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;

        .modal-positioner {
            min-height: 100%;
            position: static;
            overflow-y: hidden;
            box-sizing: border-box;

            .modal  {
                width: 900px;

                .cs_report_details--data-group {
                    label.base-checkbox {
                        display: block;
                    }
                }
            }

            .modal__content {
                padding-bottom: 15px;
            }
        }
    }

    .apexChart {
        .dropdown {
            display: none;
        }
    }

    .cs_report_details--data-group {
        .base-checkbox {
            margin-bottom: 10px;
        }


        .base-checkbox__label {
            text-transform: none;
            color: $greyDefault;

            .compare_value {
                color: $greyDefault;
                margin-left: 25px;

                &.positive {
                    color: $saladGreen;

                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid $saladGreen;
                        position: relative;
                        top: -12px;
                        left: -5px;
                    }
                }

                &.negative {
                    color: $red;

                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid $red;
                        position: relative;
                        top: 10px;
                        left: -5px;
                    }
                }
            }

            i {
                font-size: 13px;
                font-style: normal;
                color: $greyDarker3;
            }

            b {
                font-family: $BrandMedium;
                color: $greyDefault;
            }

        }
    }

}

.apexcharts-legend.position-bottom.apexcharts-align-left {
    height: auto !important;
    padding-bottom: 2px;
    padding-right: 60px;
}

.cs_report_details--modal-actions {
    display: flex;
    border-bottom: $classicBorder;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .button--action {
        margin-right: 0;
    }
}

.cs_report_full-screen--modal {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;

    .cs-reporting-container {
        height: 100%;

        .cs-reporting-widgets {
            width: 100%;
            height: 100%;
            max-height: none;
            margin: 0;

            .cs-reporting-widgets--item {
                width: 100%;
                height: 100%;
                margin: 0;
                max-width: none;
                box-sizing: border-box;
            }
        }
    }

    .cs-reporting-chart {
        button {
            margin-top: 0;
        }

        .apexChart {
            height: 100%;
            max-height: 600px;
        }

        .dropdown-body {
            top: auto;
            bottom: 45px;
        }
    }

    .Select {
        .is-open > .Select-control {
            border-radius: 0 0 4px 4px;
        }

        .Select-menu-outer {
            top: auto;
            bottom: 27px;
            border-radius: 4px 4px 0 0;
        }
    }

    .react-contextmenu-wrapper {
        height: 100%;
    }

    .rsm-svg {
        max-height: calc(100vh - 145px);
    }

    @media #{$IE} {
        .svgMap-wrapper {
            max-height: calc(100vh - 145px) !important;
        }
    }
}

@import "./style_responsive";
