.feedback--modal {
    &.base-modal--container {
        .base-modal--header-inner {
            padding-right: 2*$indentBasis;
        }
    }

    .feedback-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tooltip__open-btn {
            padding: 3px 4px;
            margin-right: calc(#{$indentBasis} * 2);
        }
    }

    .feedback-form {
        .input-pair__title-box__text {
            line-height: 24px;

            &:after {
                margin-top: 3px;
            }
        }

        .input-pair__title-box {
            margin-bottom: calc(#{$indentBasis} / 2);
        }

        .tooltip__open-btn {
            padding: 4px;
            margin-left: calc(#{$indentBasis} / 2);
        }

        .base-textarea {
            height: 120px;
            max-height: 120px;
            padding: $indentBasis calc(#{$indentBasis} * 2);
        }

        &-checkbox {
            margin-top: 10px;
            display: flex;
            align-items: center;
        }

        .base-checkbox__label {
            padding: 0;
            margin: 0;
            text-transform: initial;
            font-family: $BrandRegular;
        }

        .base-checkbox--checked {
            .base-checkbox__label {
                font-family: $BrandMedium;
            }
        }

        &-rate-wrapper {
            position: relative;

            &.input-pair,
            .input-pair__title-box {
                padding: 0;
            }

            .error {
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

        &-rate {
            display: flex;
            margin-left: -4px;
            margin-bottom: 4px;
    
            &-item {
                padding: calc(#{$indentBasis} / 2);
                height: 26px;
    
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        fill: $greyDarker4;
                    }
                }
    
                &.favorite {
                    svg {
                        path {
                            fill: $orange;
                        }
                    }
                }
                &:focus {
                    outline-offset: 2px;
                }
            }
        }
    }

    .feedback-modal-notice {
        margin-top: 14px;
        font-size: 10px;
        line-height: 16px;
        color: $greyDarker3;
    }

    .feedback-success {
        height: 263px;
        padding: 22px 24px;
        text-align: center;
        box-sizing: border-box;

        &-img {
            width: 160px;
            height: auto;
        }

        &-title {
            margin-bottom: calc(#{$indentBasis} / 2);
            font-family: $BrandMedium;
            font-size: 16px;
            line-height: 24px; 
        }

        &-description {
            font-size: 14px;
            line-height: 24px; 
        }
    }

    &.specific-feedback-modal {
        width: 360px;
        padding: calc(#{$indentBasis} * 2) calc(#{$indentBasis} * 3) calc(#{$indentBasis} * 8);
        position: fixed;
        bottom: 0px;
        right: calc(#{$indentBasis} * 3);
        background-color: #fff;
        border-radius: 8px 8px 0px 0px;
        box-shadow: $grayShadow4;
        box-sizing: border-box;

        .feedback-modal-title {
            margin-bottom: calc(#{$indentBasis} * 2);
            font-family: $BrandMedium;
            font-size: 16px;
            line-height: 24px;
        }

        .feedback-success-img {
            width: 106px;
        }

        .feedback-success {
            height: auto;
            padding: 0;
        }
    }
}


.specific-feedback-modal {
    position: relative;

    &-title {
        display: flex;

        .feedback-modal-title {
            flex: 1;
        }
    }

    &-button {
        width: 100%;
        padding: 12px $indentBasis*3 $indentBasis*2;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
    }

    &-transition-enter {
        transition: 1s;
        transform: translateY(200%);
    }

    &-transition-enter-active {
        transform: translateY(0);
    }
    
    &-transition-leave-active {
        transition: 1s;
        transform: translateY(200%);
    }
}

// #app .chatbot-calendar-modal.base-redux-modal .base-modal .base-modal--body {
//     overflow: initial;
// }

