@media #{$mobile} {
    .page--asset-preview {
        .asset-content-preview-page {
            .asset-preview-widget {
                width: auto;
                height: auto;
            }
        }

        .asset-content {
            padding-bottom: 50px
        }

        .video-js {
            width: 100%!important;
            height: 300px!important;
        }
    }
}

@media screen and (max-width: 979px) {
    .content-preview-footer {
        &__divider {
            width: 100%;
            height: 1px;
            margin: calc(#{$indentBasis} * 3) 0;
            background-color: $greyDarker2;
            display: block;
        }
        &__content {
            display: block;
        }
    }
}

@media screen and (max-width: 639px) {
    .content-preview-footer {
        &__content {
            &__links {
                &__items {
                    display: block;
                }
                .footer-link-item {
                    padding: $indentBasis 0;
                }
            }
        }
    }
}
