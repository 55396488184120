.language-switcher-container {
    margin-bottom: 20px;

    .language-switcher {
        display: inline-block;
        text-transform: uppercase;
        background: $blue;
        color: $white;
        font-weight: bold;
        font-size: 9px;
        position: relative;
        min-height: 10px;
        line-height: 10px;
        padding: 3px 4px 2px 19px;
        margin-right: 10px;
        cursor: pointer;

        &.active {
            background: $navy;

            .language-switcher--icon {
                svg {
                    top: 7px;
                }
            }
        }

        &--icon {
            background: $greyLight7;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;

            svg {
                fill: $defaultColor;
                position: absolute;
                top: 4px;
                left: 4px;
            }
        }
    }
}
