$maxAsideModal: 1400px;

.upload-asset-modal {
    background: $greyLight6;
    box-sizing: border-box;

    &__btn-group {
        position: absolute;
        right: 25px;
        top: 15px;
        z-index: 10;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        vertical-align: middle;

        .button--action {
            margin-left: $indentBasis;

            @media #{$IE} {
                &.upload-view-switcher {
                    padding-left: 6px;
                    .button--action__toolTip {
                        right: -70%;
                    }
                }
            }
        }
    }

    &__body {
        height: calc(100% - 60px);
    }

    &.aside-modal--entire {
        width: 100%;
        transition: max-width .5s;
        max-width: $maxAsideModal;

        @media #{$IE} {
            transition: none;
        }

        &.aside-modal-transition-enter {
            max-width: 0;
            overflow: hidden;
        }
        &.aside-modal-transition-enter-active {
            max-width: 100%;
            transition: max-width 0.5s;
        }
        &.aside-modal-transition-leave {
            max-width: 100%;
        }
        &.aside-modal-transition-leave-active {
            max-width: 0;
            transition: max-width 0.5s;
            overflow: hidden;
        }

        .upload-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            &__dnd {
                flex-grow: 1;
                flex-shrink: 1;
                max-width: 50%;
                overflow: hidden;
            }

            &__metadata {
                flex-grow: 1;
                flex-shrink: 1;
                max-width: 50%;
                background: $white;
            }

            .duplicate-preview {
                flex-grow: 1;
                flex-shrink: 1;
                background: $white;
            }

            .mobile-overlay {
                position: absolute;
                right: 100%;
            }
        }
    }

    &.minimized {
        transition: top 0.5s;
        right: 10px;
        top: calc(100% - 55px);
        height: 44px;
        width: 450px;
        max-width: calc(100vw - 20px);
        border: $classicBorder;
        box-shadow: $grayShadow3;
        border-radius: 4px;


        .upload-wrapper__dnd--content {
            padding-top: 15px;
        }

        .duplicate-preview,
        .upload-wrapper__metadata,
        .mobile-overlay {
            display: none;
        }

        .upload-wrapper {
            display: none;
        }

        .upload-wrapper__dnd {
            width: 100%;
        }

        .upload-wrapper__dnd__title {
            padding: 8px 85px 8px 20px;
            font-size: 14px;
            display: flex;
            align-items: center;
            vertical-align: middle;

            svg {
                width: 30px;
                height: 22px;
                margin-right: 10px;
            }
        }

        .upload-asset-modal__btn-group {
            top: 9px;
            right: 6px;
        }
    }

    &.full-screen{
        &__btn-group {
            .button--action {
                @media #{$IE} {
                    &.upload-view-switcher {
                        .button--action__toolTip {
                            right: -52%;
                        }
                    }
                }
            }
        }
    }
    .upload-wrapper__dnd--content {
        border-right: $classicBorder;
    }
}

.aside-modal {
    .online-asset-production {
        .form-field-wrapper {
            position: relative;

            & > .info-tooltip {
                position: absolute;
                top: 5px;
                left: 250px;
        
                @media #{$IE} {
                    top: 10px;
                }
            }
        }
    }
    &.full-screen {
        width: 100vw !important;
        max-width: 100vw !important;

        .notice-container {
           display: none;
        }

        .tabs.carousel {
            position: relative;
            z-index: 1;
            & > .button--action {
                float: right;
                margin-right: 20px;
                margin-top: -50px;
            }
        }
        .carousel-panels {
            padding: 20px 0 130px 0 !important;
            width: calc(100vw - 100px);
            background-color: transparent;
        }

        .upload-wrapper__dnd {
            max-width: 60px;
            width: 60px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .upload-wrapper__metadata {
            width: calc(100% - 60px);
            max-width: none;
        }

        .upload-wrapper__dnd__title,
        .upload-type--drop-area,
        .upload-type-link {
            display: none !important;
        }

        .upload-wrapper__dnd--content {
            padding: 56px 0 0 0 !important;

            .files-for-upload__item {
                padding: 10px 7px;
                box-shadow: none;
                background: $greyLight6;
                margin-bottom: 0;
                border-radius: 0;
                border: 0;
                border-top: $classicBorder;
                display: block;

                &--duplicated {
                    display: none;
                }
            }

            .files-for-upload__item__info {
                display: block;

                .upload-progress,
                .files-for-upload__item--remove,
                .info-size {
                    display: none;
                }

                .info-name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $greyDarker3;
                    font-size: 12px;
                    font-family: $BrandMedium;
                    padding: 0;
                    width: 40px;
                }
            }

            .files-for-upload__item__image {
                margin: 0;
            }

            .files-for-upload__item--active {
                background: $greyLight5;
                padding-right: 35px;

                &:last-child {
                    border-bottom: $classicBorder !important;
                }
            }

            .files-for-upload__item.is-ready-to-edit:not(.files-for-upload__item--active):hover {
                background: $greyLight5;
            }
        }

        .upload-wrapper__dnd .upload-content {
            padding-right: 0;
        }

        &.edit-mode {
            .carousel-panels {
                width: auto;
            }

            .preview-modal__body {
                .edit_asset_container {
                    height: calc(100% - 74px) !important;

                    &--buttons {
                        position: relative;
                        z-index: 1;
                    }
                }
            }

            .tabs.carousel {
                .carousel-navigation--wrapper {
                    bottom: 20px;
                }
            }

            .manage-asset-form {
                padding-top: 0px;
                overflow: visible;
                margin-top: 0;
            }
            .notice-container,.message {
                width: 100%;
            }
            .message {
                width: 100% !important;
                height: auto;
            }
        }

        .edit_asset_container--header {
            padding-right: 180px;

            &--left {
                width: auto;
            }
        }
    }

}

.edit-mode {
    .edit_asset_container--header--left {
        width: 265px;
    }
    .message {
        width: 486px !important;
    }
}

.preview-asset-modal.edit-mode {
    .edit_from_cmps_project {
        .asset-preview__heading {
            text-indent: 0;
        }

        .asset-preview {
            min-height: 0;
            &__section:last-child {
                min-height: 0;
            }

            .asset-preview__section{
                &:first-child h2 {
                    display: block !important;
                }
            }
        }
    }
    .preview-modal__header__nav-tabs {
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .manage-asset-form {
        padding-top: 46px;
    }

    .asset-preview__heading {
        text-indent: -9999px;
    }

    .asset-preview {
        padding-bottom: 24px;
    }

    .input-pair {
        display: block;

        &__title-box {
            display: block;
            float: none;
        }

        &__input-box {
            display: block;
        }
    }
}

.hidden-wizard-buttons {
    .wizard-tabs-footer {
        display: none;
    }

    .asset-preview {
        .asset-preview__section:first-child {
            h2 {
                display: none;
            }
        }
    }
}

.white-btn {
    padding: 0 13px;
    height: 34px;
    line-height: 32px;
    background: linear-gradient(180deg, $white 0%, $greyDarker2 100%);
    box-shadow: $grayShadow1;
    color: $greyBlueDark;
    border: 1px solid $greyLight7;
    min-width: 10px;
    font-size: 14px;
    font-family: Arial, sans-serif;

    &:hover {background: $greyLight7}
}

.dummy.isMobileOnly {
    .upload-asset-modal.aside-modal--entire .upload-wrapper {
        display: block;
        overflow-y: auto;

        &__metadata,
        &__dnd {
            max-width: none;
        }

        &__dnd {
            height: calc(100% - 45px);
        }
    }
}

.aside-edit-modal-container {
    .asset-preview {
        width: 100%;
        box-sizing: border-box;
    }
    &.preview-asset-modal.edit-mode {
        .preview-modal {
            .asset-preview {
                height: 100%;
                overflow: hidden;
                padding: 0;
            }
        }
    }
}

@media screen and(min-width: $maxAsideModal) {
    .upload-asset-modal.aside-modal--entire:not(.minimized) {
        width: $maxAsideModal;
    }
}

@media screen and(max-width: 1150px) {
    .tabs.wizard .wizard-tabs-footer .tabs_wizard--progress--line {
        width: auto;
    }
}
