.saved-searches {
    &.aside-modal {

        .aside-modal__body {
            padding: 0;
        }
        .aside-modal__header {
            padding: 16px 24px;
        }
    }

    .add-new-search {
        display: flex;
        margin-bottom: calc(#{$indentBasis} * 2);
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        vertical-align: middle;

        .base-input {
            flex-grow: 1;
            flex-shrink: 1;
            margin-right: calc(#{$indentBasis} * 2);
            position: relative;
        }

        .base-text-input {
            height: 34px;
        }
    }

    .preview-modal {
        &__header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px 15px;
            border-bottom: 1px solid $inputBorderColor;
            background-color: $greyLight7;

            svg {
                width: 14px;
                height: 14px;
            }
        }

        &__title {
            font-family: $BrandMedium;
            font-size: 18px;
            line-height: 26px
        }
    }
}

.saved-searches-dropdown {
    background: none;
    margin-left: 3px;
}

.rename-saved-search-descr {
    margin-bottom: $indentBasis;
}

.saved-searches-table {

    height: 100%;

    .saved-searches-table--row {
        display: flex;
        justify-content: space-between;
        padding: $indentBasis 3*$indentBasis;
        align-items: center;
        svg {
            path {
                fill: $greyDarker3;
            }
        }
        &:hover {
            cursor: pointer;
            background-color: $hoverBg;
        }
        &:focus {
            outline-offset: -2px;
        }
    }

    &--listTitle {
        padding: 13px 2*$indentBasis 9px 3*$indentBasis;
        color: $greyDarker3;
        font-family: $BrandMedium;
        margin-bottom: 0;
    }

    .saved-search--nameWrapper {
        display: flex;
        align-items: center;
        svg {
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
        .saved-search--name {
            font-size: 14px;
            line-height: 24px;
        }
    }

    .dropdown.view-details {
        padding-bottom: 0;

        .dropdown-title {
            color: $blue;
            margin-top: 2px;
        }

        .dropdown-body {
            padding: 0;
        }
        .view-details--content {
            padding: 2*$indentBasis;
        }
    }

    .saved-search--actions {
        display: flex;
        align-items: center;

        .copy-url{
            position: absolute;
            bottom: -10px;
            right: 10px;
            background: $white;
            box-shadow: $grayShadow1;
            padding: 6px 8px;
            border-radius: 3px;
            display: none;

            &.active {
                display: flex;
            }
        }

        .simple-tooltip-wrapper {
            margin: 0 0.5*$indentBasis;
        }

        .button--action {
            svg {
                width: 15px;
                height: 15px;
            }
        }
        .dropdown.opened {
            .dropdown-title {
                color: $blue;
                border-color: $blue;
                background-color: $blueLight5;
            }
        }
    }

    .view-details--content {
        &:hover {
            background: transparent;
        }

        .asset-preview__title {
            width: 50%;
            text-transform: capitalize;
        }

        .asset-preview-metadata__pair {
            &:last-child {
                border-top: $classicBorder;
                margin: 10px -10px 0;
                padding: 10px 10px 0 10px;
            }

            .asset-preview__title {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .asset-preview__data {
                width: 50%;
                flex-grow: 0;
                flex-shrink: 0;
                white-space: normal;
                .asset-preview__data {
                    padding: 0;
                    width: 100%;
                }
                .collapse {
                    padding: 0;
                    .collapse-button {
                        margin-top: 0;
                        padding: 0 !important;
                        .collapse-toggle {
                            padding: 0;
                            .collapse-toggle__icon {
                                top: 0;
                                margin-top: 0;
                                right:-15px;
                                .collapse-toggle__icon__arrow {
                                    border-color: transparent transparent transparent $greyDefault !important;
                                }
                            }
                            .collapse-toggle__title {
                                font-size: 12px;
                                padding: 0;
                                color: $greyDefault;
                            }
                        }
                    }
                }
            }
        }
    }
}

.saved-search--actions,
.saved_search_details,
.requestor-email {
    .button--action.link-copied {
        span {
            color: $greyDarker !important;
        }
        svg {
            path {
                fill: $saladGreenDark !important;
            }
        }
    }
}

.saved-search-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    svg {
        margin-bottom: 8px;
        width: 20px;
        height: 20px;
        path {
            fill: $greyDarker;
        }
    }
    &--message {
        line-height: 24px;
        font-size: 14px;
        color: $greyDarker3;
    }
}

.saved_search_details {
    display: flex;
    justify-content: space-between;
    margin-top: $indentBasis;
    &--title {
        h3 {
            font-size: 22px;
            line-height: 32px;
            font-family: $BrandMedium;
            margin-bottom: 0.5*$indentBasis;
        }
    }
    .saved_search_details--description {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 2*$indentBasis;
        color: $greyDarker3
    }

    &--actions {
        display: flex;
        align-items: flex-start;
        gap: 0 2*$indentBasis;
        .button--action.noTitle {
            width: 24px;
            height: 24px;
        }
    }

    .dropdown .dropdown-body {
        width: 200px;
    }
}

.saved-search-controls {
    display: flex;
    gap: 0 2*$indentBasis;
    flex-wrap: wrap;
    .button--action {
        text-transform: none;
    }
}

.saved-search-page {
    .filter-button .auto-hide--hidden .auto-hide--opener{
        top: 30px;
    }
}

@media screen and (max-width: 740px) {
    .saved_search_details {
        margin-right: 5*$indentBasis;
    }
}


@import "./_style_responsive.scss";
