@media #{$mobile} {
    .tabs.wizard .wizard-tabs-footer .tabs_wizard--progress,
    .button--action.upload-view-switcher {
        display: none;
    }

    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container .tabs.wizard .tabs__panel {
        padding: 20px 15px 20px 15px;
    }

    .upload-asset-modal .tabs.wizard .wizard-tabs-footer {
        justify-content: flex-end;
        padding: 13px 15px 13px 15px;
    }

    .upload-asset-modal.aside-modal--entire {
        width: calc(100% * 2);
        transition: all 0.5s ease;
    }

    .metadata-profile-options .react-select-box-container {
        width: 150px;
    }

    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container--header {
        justify-content: flex-start;
        padding: 10px 15px 10px 15px;
    }

    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container--header .apply-metadata {
        margin-left: 10px;
    }

    .upload-asset-modal__btn-group {
        right: 10px;
    }

    .back_to_files--button {
        flex-shrink: 0;
        flex-grow: 0;
    }

    .upload-wrapper__dnd--content {
        padding: 15px 0 15px 15px;
    }

    .upload-wrapper__dnd .upload-content {
        padding-bottom: 20px;
        padding-right: 15px;
    }

    .upload-asset-modal.aside-modal--entire.is-files-screen {
        right: -100%;
    }

    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container--header h2 {
        flex-grow: 0;
        flex-shrink: 0;
        width: 150px;
    }

    .files-for-upload__item__image {
        margin-right: 5px;
    }

    .edit_asset_container .apply-metadata {
        margin: 10px 15px 10px 15px;
    }

    .tabs.wizard .wizard-tabs-footer .wizard-tabs-footer--buttons {
        margin-top: -2px;
    }

    .preview-asset-modal.edit-mode .preview-modal .asset-preview {
        margin-top: 0;
        height: 100%;
    }
}

@media #{$iPhone5s_P} {
    .upload-asset-modal .upload-wrapper__metadata .edit_asset_container--header h2 {
        width: 130px;
    }

    .metadata-profile-options .react-select-box-container {
        width: 129px;
    }
}
