.duplication-modal {

    &__message {
        word-break: break-word;
        padding: 0;
        font-family: $BrandRegular;
        font-size: 13px;
        text-align: center;
        line-height: 1.57;
        color: $black;

        &--light {
            color: $greyDarker4;
        }
        b {
            font-family: $BrandMedium;
        }
        h3 {
            color: $blue;
        }
    }
    &__message-header {
        border-bottom: 1px solid $greyDarker4;
        padding-bottom: 10px;
        span {
            font-size: 12px;
        }
    }


    .modal {
        width: 439px;
        min-height: 234px;
        max-height: 620px;
        display: flex;
        flex-direction: column;

        .modal__header {
            box-sizing: border-box;
            border-bottom: 1px solid $greyDarker4;
            padding: 10px 0 10px 17px;
            height: 50px;
            font-family: $BrandRegular;
            font-size: 20px;
            letter-spacing: -0.5px;
            text-align: left;
            color: $navy;
            background: $red;
            border: 0;

            &__text {
                font-size: 20px;
                font-family: $BrandBook;
                padding-left: 40px;
            }
            .modal__close {
                top: 15px;
                right: 15px;
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
        &__content {
            padding: 0px !important;
            &--text {
                overflow: hidden;
                min-height: 50px;
                h2, a {
                    color: $navy;
                }
                h2 {
                    font-family: $BrandBold;
                }
            }
            &__container {
                padding: 16px 0 26px 0;
            }
            &__body {
                padding: 0 10px;
                border-top: 2px solid $greyLight7;
                max-height: 376px;
                overflow-y: auto;
                &--list {
                    list-style: none;
                    .doi-item {
                        span {
                            color: $blue;
                            float: left;
                        }
                        .asset-icon, .assets-list__item__type {
                            display: none;
                        }
                        .assets-list__item__details-link {
                            text-align: left;
                            span {
                                width: auto;
                                color: $blue;
                                font-size: 12px;
                                font-family: $BrandRegular;
                            }
                        }
                        .unavalible-doi {
                            float: none;
                            color: $defaultColor;
                        }
                    }
                    li {
                        line-height: 20px;
                        span:first-child {
                            text-transform: uppercase;
                            font-size: 10px;
                            display: inline-block;
                            width: 108px;
                            color: $black;
                            font-family: $BrandBold;
                            padding: 0;
                        }
                        &:first-child {
                            font-size: 14px;
                            line-height: 20px;
                            font-family: $BrandRegular;
                            margin-bottom: 10px;
                            font-weight: bold;
                            color: $black;
                            word-break: break-word;
                        }
                    }

                }
                p {
                    margin-left: 40px;
                    padding-bottom: 15px;
                    border-bottom: 2px solid $greyLight7;
                }
                .file--preview {
                    width: 32px;
                    height: 42px;
                    margin-right: 8px;
                    float: left;
                }
            }
            .button-link {
                color: $blue;
            }
            &__inner {
                padding: 13px 34px;
            }
        }

        &__footer {
            padding: 10px 20px 20px;
        }

        .modal__footer__buttons {
            text-align: center;
            .button--cancel {
                font-size: 12px;
            }
        }

        &__icon {
            position: absolute;
            top: 5px;
            left: 10px;
            height: 30px;
            float: left;
            width: 35px;
            path {
                fill: $greyDarker4;
            }
        }
    }
}
