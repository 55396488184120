$add-asset-to-modal__label-width: 30%;
$add-asset-to-modal__input__width: 65%;

.add-asset--modal {
    @include modal(569px, $add-asset-to-modal__label-width, $add-asset-to-modal__input__width);
    .dt__items {
        height: 250px;
        overflow-y: auto;
    }

    &.base-modal--container {
        .base-modal {
            width: 569px;
        }
    }

    .modal__content {
        padding: 0;
    }

    .load-more--container {
        margin: 20px auto 0 auto;
        width: 93%;
    }

    .add-asset-to__inputs {
        padding-top: 10px;
    }

    .add-asset-to__search-buttons {
        display: flex;
        justify-content: flex-end;
        margin-right: auto;
        padding: 25px 0 10px;

        button {
            margin-left: 15px;
        }
    }

    .kit-assets {
        padding: 29px 20px 0;
        border-top: 1px solid $greyDarker4;
    }

    .dt-cell--name {
        max-width: 111px;
        word-break: break-all;
        flex-grow: 1;
    }

    .dt-cell--dateImported, .dt-cell--dateModified {
        flex: 1;
    }

    .assets-region__nothing-found {
        border-top: 1px solid $greyDarker4;
        height: 226px;
        margin: 0;
        padding-top: 85px;
    }

    .modal__footer {
        padding-bottom: 0;
    }
    .modal__footer__buttons {
        text-align: left;
    }
}
