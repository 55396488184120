@mixin mobileHeader() {
    .content-store-header--bottom .contact-person-link .button--action {
        width: auto;
    }

    .content-store-header {
        &--breadcrumbs {
            display: none;
        }

        padding: 15px 0 0;
        display: flex;
        flex-direction: column-reverse;

        &--top {
            .content-store-header--action-buttons {
                width: 100%;
                justify-content: flex-start;
                margin-right: 0;
                margin-top: 7px;
                margin-bottom: 7px;
                margin-left: -12px;
                .button--action {
                    margin-right: 10px;
                }

                .carousel-view-toggle {
                    display: none;
                }

                .lucy-toggle {
                    position: relative;
                    background: none;
                    box-shadow: none;
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-right: 5px;
                    margin-left: 5px;
                    bottom: auto;
                    right: auto;
                    display: block;
                    color: $greyBlueDark;
                    svg {
                        max-width: 18px;
                        max-height: 18px;
                        padding-top: 8px;
                        margin-right: 0;
                        g {
                            stroke: $blackLight;
                        }
                    }
                    & > span {
                        display: none;
                    }
                }
            }
        }

        &--bottom {
            display: block;

            .contact-person-link {
                justify-content: flex-start;
                margin-top: 5px;
            }
        }
    }
}

@media #{$mobile} {
    @include mobileHeader();

    .auto-hide {
        .auto-hide--closer {
            position: absolute;
            top: 13px;
            right: 26px;
            z-index: 3;
        }
    }

    .content-stores-filter {
        height: 100vh!important;
        position: fixed!important;
        top: 0!important;
    }
}

html.filters-mobile-open {
    overflow: hidden;
    header {
        z-index: 0;
    }
}

@media #{$iPad_P} {
    @include mobileHeader();

    .content-store-header--bottom {
        padding-right: 80px;
    }

    .add_dropdown {
        .dropdown-body {
            right: auto;
            left: 0;
        }
    }
}

@media screen and (max-width: 420px) {
    .content-store-header--top {
        .content-store-header--action-buttons {
            & > div {
                margin-left: 0;
            }
            .button--action {
                margin-right: 5px;
            }
            .filter-toggle {
                margin-left: 0;
            }
        }
    }
}
