.profile-settings-page {
    &.page {
        .new-layout--container {
            background-color: $greyLight5;

            .new-layout--middle {
                width: 100%;
            }
        }
    }

    .profile-settings {
        &--container {
            max-width: 660px;
            margin: 0 auto;
            padding: 25px 30px;
            box-sizing: border-box;
        }

        &--title {
            margin-bottom: 17px;
            font-family: $BrandMedium;
            font-size: 22px;
            color: $blackLight;
        }

        &--block {
            margin-bottom: 20px;
            padding: 24px 30px;
            background: $white;
            border: $classicBorder;
            border-radius: 3px;
            position: relative;
        }

        &--block-title {
            margin-bottom: 10px;
            font-family: $BrandMedium;
            font-size: 16px;
        }

        &--btn-wrapper {
            padding-top: 12px;
            display: flex;
            justify-content: flex-end;
        }

        &--button {
            margin-left: -8px;
        }

        &--wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &--subtitle {
            margin-top: 10px;
            font-family: $BrandMedium;
            font-size: 14px;
            line-height: 31px;
        }

        &--notification {
            & .profile-settings--wrapper {
                align-items: center;
            }
        }

        &--preferences-wrapper {
            display: flex;
            flex-direction: column;

            .radio-group {
                display: flex;
            }
        }

        &--col {
            width: 50%;
        }

        .anime-toggle {
            margin-top: calc(#{$indentBasis} * 2);
        }
    }

    .user-profile {
        &--name {
            margin-right: 20px;
            font-family: $BrandMedium;
            font-size: 16px;
            line-height: 1.4;
        }

        &--email {
            padding: 5px 0;
            margin: 2px 0 5px;
            font-family: $BrandRegular;
            display: inline-block;
        }

        &-data {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style-type: none;
            font-family: $BrandMedium;
            font-size: 12px;

            li {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            svg {
                width: 13px;
                height: 13px;
            }

            span, svg {
                vertical-align: middle;
            }

            &--label {
                margin-left: 7px;
            }

            &--icons {
                font-size: 13px;
            }
        }
    }

    .base-radio .base-radio__label.with-description {
        .base-radio__label--title {
            font-size: 14px;
        }

        .base-radio__label--description {
            font-size: 12px;
        }
    }

    .input-pair {
        display: block;
    }

    .asset-preview {
        height: calc(100vh - 180px);
        width: calc(100% - 260px);
        margin-left: 260px;
        padding-top: 53px;
        padding-left: 50px;

        &__heading {
            background: none;
            margin: 0;
            padding: 20px 0;
            font-size: 24px;
            line-height: 26px;
            color: $blackLight;
            font-family: $BrandMedium;
        }

        &__section {
            max-width: 600px;
            min-height: auto;
        }

        .input-pair {
            display: block;

            &__title-box {
                float: none;
            }

            &__input-box {
                width: 100%;
            }
        }

        .hidden-field {
            display: none;
        }
    }

    .ownership-form {
        &--progress {
            .input-pair {
                &__title-box, &__input-box {
                    @extend %preload;
                }
            }
            .Select-control,
            .button--action,
            .base-tags-input {
                visibility: hidden;
            }
        }
        .input-pair--invalid {
            .base-tags-input {
                border-color: $red;
            }
        }
    }

    .ownership-description {
        font-size: 12px;
        line-height: 18px;
    }

    .notifications-radio {
        margin-top: 10px;
        .radio-group {
            flex-direction: column;

            .base-radio {
                &__label {
                    color: $blackLight;
                }

                button {
                    display: flex;

                    .base-radio__icon {
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                }
            }

            .base-radio__label--title {
                font-size: 14px;
            }

            .base-radio__label--description {
                margin-top: 5px;
                font-size: 12px;
                display: block;
            }
        }
    }

    .base-radio {
        .base-radio__label--description {
            text-transform: none;
        }
    }
}

.content-ownership-notification {
    font-size: 14px;
    line-height: 24px;
    &__message {
        .message--red {
            color: $red;
        }
        .message__dois {
            font-family: $BrandMedium;
        }
    }
}

.radio-default-view {
    .base-radio {
        .base-radio__icon {
            margin-top: 3px;
        }
    }
}
