.asset-relations,
.content-approval {
    &-container {
        &.aside-modal {
            z-index: 1001;
        }
    }

    &__modal {
        &-body {
            width: 100%;
            .relation-title {
                font-size: 14px;
                margin-bottom: 10px;
                text-transform: capitalize;
            }

            .search-text-input--wrapper {
                margin: 10px 2px 15px;

                & input {
                    box-sizing: border-box;
                    box-shadow: none;
                    padding-right: 35px;
                    width: 100%;
                }


                .search-text-input {
                    background-color: transparent;

                    &-button {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &__search {
        &-description {
            font-size: 12px;
            line-height: 18px;
        }

        &-result {
            .assets-count {
                font-size: 13px;
                margin-bottom: 10px;
            }
        }

        &-nothing {
            color: $defaultColor;
            font-size: 20px;
            margin-top: 50px;
            line-height: 35px;
            text-align: center;
        }
    }
}

.for-set-relation--selected {
    .search-item {
        justify-content: space-between;
        height: 100%;
        flex-direction: row-reverse;
        & > .search-item-content {
            padding-left: 35px;
            min-width: calc(100% - 130px);
        }

        .relations-types-definitions {
            border-left: 1px solid $inputBorderColor;
            border-top-right-radius: $indentBasis;
            border-bottom-right-radius: $indentBasis;
            box-sizing: border-box;
            cursor: pointer;
            height: 124px;
            order: 2;
            padding-bottom: 0;

            & > div:first-child {
                width: 100%;
                height: 100%;
            }
        }
    }

    .relations-types-definitions.dropdown {
        max-width: 130px;
        .relations-grops-wrapper {
            padding: 0 !important;

            .dropdown--divider {
                padding: 0;
                margin: 0;
            }
        }

        .dropdown-title {
            color: $blackLight;
            height: 100%;
            line-height: 20px;
            padding: 0 $indentBasis*2;
            text-align: left;
            text-transform: none;
            white-space: normal;
            width: 100%;
            border-radius: 0 12px 12px 0;

            &:hover {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        &.dropdown{

            .dropdown-body {
                height: auto;
                top: calc(100% + 8px);
                padding-top: 12px;
                bottom: auto;
                padding-bottom: 0;

                .relations-grops-wrapper:hover {
                    background: none;
                }

                &__content .dropdown-body__content__items button.button--action.submit-relation {
                    min-width: auto;
                    margin: 12px 2*$indentBasis;
                }
            }
        }
        .dropdown-body__content__items {
            & > .base-input {
                padding: 0;
                margin: 0;
            }
            .base-radio {
                margin: 0;
                padding: 5px 10px;
                &:hover {
                    background: $hoverBg;
                }

                button {
                    width: 100%;
                    &:hover {
                        background: none !important;
                    }
                }
                &__label {
                    color: #3E3E3E;
                }
            }
        }

        .radio-group {
            flex-direction: column;
        }

        .base-radio__label--title {
            white-space: nowrap
        }
        &.opened {
            .dropdown-title {
                background-color: $hoverBg;
            }
        }
    }
}

.content-approval__modal {
    .search-results--container {
        padding: 15px 0;
    }
}

.asset-set-relations-container {
    .grid-view__item {
        margin-bottom: 24px;

        .base-checkbox {
            margin: 12px;
        }
    }
    &.aside-modal {
        z-index: 999;
    }
    .search-results--container {
        padding: 0;
    }

    .search-item-content--other-info .other-info-box {
        b {
            font-family: $BrandMedium;
        }
    }

    .aside-modal__header {
        .modal-minimize {
            & > div {
                width: 100%;
                height: 3px;
                background-color: $greyDefault;
                margin-top: 8px;
            }
        }
        .modal-maximaze {
            margin-right: 10px;
            svg {
                path {
                    fill: $greyDefault;
                }
            }
        }
        button.minimized {
            margin-right: 10px;
            & > svg {
                margin-top: 10px;
            }
        }

        .asset-sapId {
            display: inline-block;

            .assets-list__item__details-link {
                color: $blue;
            }

            .other-info-box {
                display: none;
            }
        }
    }

    &.minimized {
        transition: top 0.5s;
        right: 10px;
        top: calc(100% - 55px);
        height: 44px;
        width: 450px;
        max-width: calc(100vw - 20px);
        border: $classicBorder;
        box-shadow: $grayShadow3;
        border-radius: 4px;

        .aside-modal__header {
            border-radius: 4px;
            height: 100%;
            padding: 5px 10px;
        }

        .aside-modal__body {
            display: none;
        }
    }
}

.related-asset {
    &__wrapper {
        background-color: $white;
        border: $folderItemBorder;
        border-radius: 12px;
        box-shadow: $grayShadow1;
        display: flex;
        margin-bottom: 24px;
        position: relative;
        height: 124px;

        &:hover {
            box-shadow: $grayShadow2;
            border-color: $blue;
        }

        .search-item--thumbnail {
            overflow: hidden;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-right: $classicBorder;
            width: 220px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .search-item-content {
            width: calc(100% - 225px);

            &--title {
                padding-right: 60px;
            }
        }
        &--actions {
            display: flex;
            align-items: flex-start;
            width: 70px;
            margin: 5px 5px 0 0;
            position: absolute;
            right: 0;
            top: 0;

            .button--action {
                margin-left: $indentBasis;
            }
        }
        .remove-relation {
            &.button--action {
                svg path {
                    fill: $greyDefault;
                }
            }

            .asset-actions__item--icon-mode {
                margin-right: 0;
            }
        }
        .base-checkbox--checked .base-checkbox__icon svg path {
            fill: $blackLight;
        }
        &.content-preview {
            height: 56px;
            margin-bottom: 10px;
            .search-item-content {
                display: flex;
                width: calc(100% - 85px);
                &--title {
                    width: 100%;
                }
            }
            .search-item--thumbnail {
                width: 100px;
                max-width: 100px;
                overflow: hidden;
            }
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        padding: 3px 5px 10px 0;
        width: 100%;

        .general-description__icons {
            display: flex;
            font-size: 12px;
            max-width: 280px
        }

        .assets-list__item__details-link {
            color: $navy;
            cursor: pointer;
            font-size: 16px;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-top: 10px;
            margin-bottom: 10px;
        }

        .description {
            display: flex;
            align-items: center;
            padding-right: 10px;

            &.content-language {
                overflow: hidden;
            }

            &:not(:last-child) {
                border-right: 1px solid $blackLight;
                margin-right: 10px;
            }

            & > svg {
                margin-right: 5px;
                min-width: 14px;
                max-width: 14px;
            }

            .content-language--value {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .usage--Direct {
            svg path {
                fill: #398C2B
            }
        }
        .usage--Internal {
            svg path {
                fill: $orange;
            }
        }
        .usage--Partner {
            svg path {
                fill: $blue;
            }
        }
        .usage--Customer {
            svg path {
                fill: $blue;
            }
        }

        .file-type-icon {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }

    &__info-general {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .dots {
            position: relative;
            top: -4px;
        }
    }

    &__description {
        font-size: 14px;
    }

    &__footer {
        font-family: $BrandMedium;
        display: flex;
        justify-content: space-between;

        .related-asset__id {

            &-label {
                font-size: 14px;
            }

            &-value {
                margin-right: 10px;
                margin-left: 5px;
            }
        }
    }
}

.confirm-removing-relation-modal {
    &.approve-error-modal {
        .modal-positioner {
            .modal {
                width: 440px;
            }
        }

        .modal__header {
            .modal__close {
                background-color: transparent;
            }
        }

        .modal__footer {
            margin: initial;
            position: static;
            background-color: $white;
            display: flex;
            justify-content: center;
        }
    }
}
