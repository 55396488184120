$layoutLightGrey: $greyLight7;
$griItemWidth: 360px;
$gridItemLeftIndent: 40px;
$superBigScreen: 'screen and (min-width: 2000px)';

@import "./components/search_item/style";
@import "./components/search_item_content/style";

@media #{$IE} {
    #app {
        overflow: hidden;
    }
}

.new-search-page {
    .scroll-up {
        display: none;
    }

    .new-search-page--wrapper {
        //max-width: 1370px;
        margin: 0 auto;
        padding: 0 33px;
        box-sizing: border-box;
    }
}

.infinite_scroll {
    font-size: 20px;
    line-height: 30px;
}

.page {
    .full-layout--container {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        max-width: 100vw;
        min-width: auto;

        .full-layout--middle {
            .data-not-loaded {
                .load-more--container.load-more--pagination,
                .synonyms-container h4,
                .synonyms-container button,
                .search-results-info p,
                .other-info-box,
                .search-item-content--highlightedContent,
                .search-item-content--name,
                .page-controls,
                .page_size_dropdown,
                .statistics-button,
                .search-item--thumbnail,
                .custom_folder--preview {
                    @extend %preload;
                }

                .bulk-actions-checkbox,
                .suggestions,
                .closeable-box,
                .preview--placeholder,
                .search-results-info--content,
                .new-search-results--actions,
                .search-item--button,
                .base-select,
                .custom_folder--preview img,
                .search-item--thumbnail img,
                .restrictedDownload,
                .restrictedContent {
                    display: none;
                }

                .other-info-box {
                    width: 50px;
                    display: inline-block;
                    height: 20px;

                    button {
                        display: none;
                    }

                    &.language {
                        button {
                            display: none;
                        }
                    }
                }
                .synonyms-container button {
                    width: 100px;
                    display: inline-block;
                }

                .load-more--container.load-more--pagination {
                    width: 100%;

                    button {
                        display: none;
                    }
                }

                .custom_asset--preview {
                    box-shadow: none;
                }

                .search-item-content--name {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                }
            }
            .no-search-results {
                width: 100%;
                height: 100%;
                .error_page-container {
                    height: 100%;
                }
                .error-message-container {
                    .centered-container{
                        width: 640px;
                        margin: 0 auto;
                        h1 {
                            color: $blackLight;
                            font-size: 24px;
                            line-height: 35px;
                            font-family: $BrandMedium;
                            background: $greyLight7;
                            padding: 31px 0 33px;
                        }
                    }
                    border-bottom: 1px solid $inputBorderColor;
                }
                &.content-stores .error_page-container {
                    margin-top: calc(#{$indentBasis} * 3);
                }
            }
        }

        .full-layout--right {
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;
            width: 0;
            z-index: 4;
            right: 0;

            .auto-hide {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
            }
        }

        .full-layout--left {
            position: relative;
            z-index: 4;
            width: 0;
            right: auto;

            &--overlay {
                width: 0 !important;
            }

            .navigation {
                height: 100%;

                &.navigation--opened {
                    width: 230px;
                }
            }
        }
    }
}

.search-results, .cs-search {
    margin: 0 0 40px 0;

    &.search-results--list {
        .grid-view__item {
            margin-bottom: calc(#{$indentBasis} * 3);
        }
    }

    &-item-wrapper {
        padding-bottom: 15px;
    }

    &-info {
        max-width: 640px;
        height: 86px;
        padding: 15px 15px 0;
        margin: -14px auto 10px;
        position: relative;
        background-color: $greyLight7;

        &--wrapper {
            &.react-sticky-element {
                z-index: 3;
            }
            .search-results-info--content-actions {
                padding-left: 10px;

                .search-results-info--sorting {
                    margin-left: 10px;
                }
            }
        }

        &--content {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;

            &-actions {
                align-items: center;
                display: flex;

                & > .base-checkbox {
                    bottom: 4px;
                    margin-left: -10px;
                    margin-right: -10px;
                }
            }
        }

        .sticky {
            top: $topRegionHeight !important;
            border-radius: $classicRadius;
            background: $layoutLightGrey;
            border-bottom: $classicBorder;
            z-index: 3;
        }

        p {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-family: $BrandRegular;
            color: $greyDefault;
        }

        b {
            margin-right: 5px;
            font-family: $BrandMedium;
        }
    }

    &--container, &-info {
        padding: 15px;

        &.search-results--container-list {
            max-width: 640px;
            margin: 0 auto;

            .search-results {
                .custom_asset--preview {
                    width: 100%;
                }
            }

            .base-checkbox {
                margin: 12px;
            }
        }

        .base-checkbox {
            margin: calc(#{$indentBasis} * 2);
        }
    
        .filter-tags {
            max-width: calc(#{$griItemWidth} * 2 + #{$gridItemLeftIndent});
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin-left: auto;
            .base-checkbox {
                padding: 0px;
            }
        }
    
        .base-checkbox__icon {
            width: 18px;
            height: 18px;
            margin: 0;
        }
    }

    &-sticky {
        z-index: 3;
        .search-results-controls {
            display: flex;
            flex-wrap: wrap;
            margin-top: 3*$indentBasis;
            justify-content: space-between;
            gap: 4px 0;
            .search-results-info--content-actions {
                margin: 0 !important;
                .search-results-info__count {
                    font-family: $BrandMedium;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0 $indentBasis 0.5*$indentBasis $indentBasis;
                }

                .base-checkbox {
                    margin: 12px;
                }
            }
        }
        .search-results-info {
            height: initial;
            padding-top: 3*$indentBasis;
            padding-bottom: 12px;
            margin-bottom: 1px;
        }
    }
}

.search-results--options {
    max-width: 700px;
    margin: 0 auto;
}


.preview--placeholder,
.video-preview--placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    z-index: 2;
    cursor: pointer;
    transition: visibility 0s, opacity 0.3s linear;
    visibility: hidden;
    opacity: 0;

    span {
        font-family: $BrandMedium;
        font-size: 16px;
        line-height: 24px;
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;
        top: 50%;
        color: $white;
        margin-top: 15px;
    }
}

.preview--placeholder {
    &:after {
        display: none;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -16px;
        margin-top: -20px;
        width: 32px;
        height: 32px;

        path {
            fill: $white;
        }
    }

    .material-icons {
        font-size: 3.2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -16px;
        margin-top: -16px;
        color: $white;
    }
}

.video-length--icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: $blackLight;
    color: $white;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    font-family: $BrandRegular;
    opacity: 0.8;
    border-radius: 2px;
    width: auto !important;
}

.pinned-label {
    align-items: center;
    display: flex;
    position: absolute;
    background-color: $red;
    top: 10px;
    right: 10px;
    border-radius: 2px;
    color: $white;
    padding: 3px 7px;
    z-index: 1;

    &__text {
        font-size: 12px;
        font-family: $BrandMedium;
        line-height: 17px;
        margin-left: 3px;
    }

    svg path {
        fill: $white;
    }
}
      
.grid-view {
    .search-results {
        &-info {
            &--content {
                padding-top: 10px;
            }
        }

        &--container, &-info {
            padding: 0 15px 15px;
            .filter-tags {
                margin: auto;
                padding: 0 0 10px;
                max-width: calc(#{$griItemWidth} * 2 + #{$gridItemLeftIndent});
            }
        }
    }
    .page {
        &.content-store-page {
            .full-layout--container {
                .full-layout--middle {
                    .search-results {
                        &--container {
                            margin: 0;
                        }
                    }
                }
            }
        }
        .full-layout--container {
            .full-layout--middle {
                .search-results:not(.search-results--list) {
                    &--container {
                        padding: 15px 0;
                        box-sizing: border-box;
                    }
                }
                .search-results-info {
                    justify-content: center;

                    .new-search-results--view-switcher {
                        padding-right: 0 !important;
                    }
                }

                .closeable-box {
                    margin: 0 auto 15px auto;
                }

                .other-suggestion,
                .exact-suggestion {
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 30px;
                }
            }
        }
    }
    .full-layout--container {
        .search-results--container {
            .search-results-info--content,
            .search-results-info--content-actions {
                max-width: calc(2*#{$griItemWidth} + #{$gridItemLeftIndent});
            }
        }
    }
}

.checkedFiltersCount {
    position: absolute;
    top: 14px;
    right: 32px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    border-radius: 50%;
    color: $white;
}

.filters {
    padding: 0 20px;
    border-top: $classicBorder;

    &-group {
        border-bottom: $classicBorder;
        margin: 0 -20px 0px -20px;

        .collapse {
            border: none;
            padding: 0;

            .collapse-body {
                padding: 0;
            }
        }

        .button--action.filters-group--collapseButton {
            font-size: 14px;
            font-family: $BrandMedium;
            line-height: 24px;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 0;
            width: 100%;
            color: $greyDefault;
            border: none;
            &:hover {
                background: none;
            }
            .material-icons {
                font-size: 20px;
                color: $greyDarker3
            }
            svg {
                width: 16px;
                height: 16px;
                max-width: none;
                max-height: none;
            }
            &.opened {
                svg {
                    transform: rotateX(180deg);
                }
            }
        }

        &--buttonWrapper {
            position: relative;
            padding: $indentBasis $indentBasis $indentBasis 2*$indentBasis;
            &:hover {
                background-color: $greyLight5;
            }
        }

        &--toggle {
            position: relative;
            width: 100px;
            display: block;
            margin: 0 auto 0 auto;
            font-size: 14px;
            color: $black;
            padding: 5px 10px 5px 5px;
            border-radius: 5px;
            @extend %hover-bg-annimation;

            &:after {
                position: absolute;
                content: '';
                display: inline-block;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid $black;
                top: 12px;
                right: 10px;
            }

            &:hover {
                background: $greyDarker2;
            }
        }

        .filters-group--wrapper {
            margin-right: 10px;
            &.opened {
                .filters-group--toggle {
                    &:after {
                        top: 11px;
                        border-top: 0;
                        border-bottom: 5px solid $black;
                    }
                }
            }
            &.withoutScroll {
                .filters-group-items {
                    height: auto;
                }
            }
        }

        h2 {
            font-family: $BrandMedium;
            font-size: 13px;
            text-transform: capitalize;
            margin-bottom: 10px;
            color: $blackLight;
        }

        &-items {
            height: 200px;
            .filter-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $greyDefault;
                padding: 0.5*$indentBasis 3*$indentBasis 0.5*$indentBasis 2*$indentBasis;
                line-height: 24px;
                @extend %hover-bg-annimation;

                &:hover {
                    background: $hoverBg;
                }

                .count {
                    color: $greyDefault;
                    font-family: $BrandRegular;
                    font-size: 12px;
                }
                mark {
                    background-color: rgba(0,118,203, 0.2);
                }
            }

            .base-checkbox {
                display: flex;
                align-items: center;

                &__label {
                    font-family: $BrandRegular;
                    font-size: 14px;
                    width: 110px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    color: $greyDefault;
                    text-transform: none;
                }
            }
        }
        .base-select {
            margin-bottom: 8px;
            padding: 0 22px 0 16px;

            .Select-placeholder {
                font-size: 14px;
                line-height: 31px;
                font-family: $BrandRegular;
                color: $greyDarker3;
            }
            .Select-menu-outer {
                width: 189px;
            }
        }
    }
    &-container {
        background-color: $white;
        height: 100%;
    }
}

.filters-searchInput {
    position: absolute;
    top: 55px;
    width: 205px;
    left: 17px;
    .base-text-input {
        border-radius: 24px;
        padding-right: 65px;
    }
}

.not-loaded {
    .filters {

        .base-checkbox__label,
        .count,
        .base-checkbox__icon,
        h2, .button--action
         {
            @extend %preload;
            * {
                visibility: hidden;
            }
        }

        .filter-item:hover {
            background: inherit !important;
        }
    }
    .filter-settings {display: none}
}

.filter-tags {
    margin: -10px 0 10px 0;

    & > div:last-child {
        .filter-item {
            margin-right: 0;
        }
    }

    .filter-item {
        display: inline-block;
        margin: 4px 8px 4px 0;
        position: relative;

        &:after {
            content: '';
            display: block;
            width: calc(100% - 20px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    .base-checkbox {
        margin: 0;
        outline-offset: 0;
        button {
            display: none;
        }

        &__label {
            &:after {
                content: '+';
                font-size: 24px;
                color: $greyDarker3;
                transform: rotate(45deg);
                position: absolute;
                right: 8px;
                top: 0px;
            }
        }
    }

    .count {
        display: none;
    }

    .base-checkbox .base-checkbox__label, .dropdown .button--action.dropdown-title {
        background: $greyLight2;
        padding: 0px 26px 0px 8px;
        border-radius: 4px;
        color: $greyDefault;
        font-size: 14px;
        line-height: 24px;
        position: relative;
        margin: 0;
        font-family: $BrandRegular;
        white-space: normal;
        &:hover {
            cursor: pointer;
            background-color: $greyDarker2;
        }
        &:focus {
            outline: $blue;
        }
    }

    .dropdown {
        padding: 0;
        height: initial;
        margin: 4px 8px 4px 0;

        .dropdown-body {
            padding: $indentBasis;
        }
        .dropdown-body__content__items {
            display: flex;
            flex-wrap: wrap;
            & > * {
                min-width: initial;
                .filter-item {
                    margin-right: 4px;
                }
            }

        }
        .dropdown-title {
            height: 24px;
            &:after {
                right: 9px;
            }
            .right-icon {
                height: auto;
                position: absolute;
                top: 3px;
                right: 5px;
            }
        }
    }
}

.customize-mode {
    padding: 0 0 64px;
    color: $greyDefault;

    &--container {
        display: flex;
        flex-direction: column;
    }

    &--wrapper {
        height: 100%;
        flex: 1;
        position: relative;
        background-color: $white;

        .cusomize-mode--buttonWrapper {
            padding: 2*$indentBasis 3*$indentBasis;
            border-top: $classicBorder;
            position: absolute;
            bottom: 0;
            background-color: #fff;
            width: 100%;
            .button--action {
                min-width: 60px;
            }
        }
    }

    &--header {
        padding: 2*$indentBasis 2*$indentBasis 2*$indentBasis 3*$indentBasis;
        background-color: $white;
    }

    &--description {
        margin-top: 8px;
        font-family: $BrandRegular;
        font-size: 12px;
        line-height: 18px;
    }

    .groups-container {
        .group-item {
            cursor: move;

            &:hover {
                background: $hoverBg;
            }    
        }
    }

    .group-item {
        background: $white;
        border-bottom: $classicBorder;
        padding: $indentBasis 2*$indentBasis $indentBasis 2*$indentBasis;
        color: $greyDarker3;
        font-family: $BrandRegular;
        font-size: 14px;
        line-height: 24px;
        position: relative;
        transition: background-color 0.3s ease-out;
        display: flex;
        align-items: center;

        &.isOver {
            * {visibility: hidden}
        }

        &.not-active-dragged {
            display: none;
        }

        &.group-item--active {
            color: $greyDefault;
        }

        &--drag-icon {
            height: 16px;
            width: 16px;
            margin-right: $indentBasis;
            display: flex;
            justify-content: center;

            svg {
                width: 8px;
                height: 16px;
                path {
                    fill: $greyDarker4;
                }
            }
        }

        &--name {
            text-transform: capitalize;
        }

        &--action {
            margin-left: auto;
        }
    }
}

.synonyms-container {
    padding: 0 20px;

    h4 {
        font-size: 14px;
        color: $black;
        margin-bottom: 20px;
    }

    .synonyms {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .synonym {
            margin-right: 20px;
            width: 230px;
            margin-bottom: 20px;

            button {
                text-decoration: underline;
                text-align: start;
                font-size: 14px;
                display: flex;
                vertical-align: middle;
                color: $blue;

                &:hover {
                    text-decoration: none;
                }

                svg {
                    width: 12px;
                    height: 12px;
                    margin-right: 5px;
                    margin-top: 4px;

                    path {
                        fill: $blue;
                    }
                }
            }
        }
    }
}

.closeable-box {
    border-radius: 4px;
    background: $white;
    border: $classicBorder;
    position: relative;
    padding: $indentBasis $indentBasis $indentBasis 2*$indentBasis;
    margin-bottom: 3*$indentBasis;

    h4 {
        color: $greyDefault;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0.5*$indentBasis;
        font-family: $BrandRegular;
    }

    .button--action.close {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .suggestion {
        display: block;
    }

    &.metadata-class-suggestion {
        font-size: 14px;
        line-height: 20px;
        color: $greyDefault;

        b {
            font-weight: normal;
            font-family: $BrandMedium;
        }
    }
}

.suggestions {
    margin: 3*$indentBasis 0;
    font-size: 13px;
    line-height: 16px;

    &--inner {
        margin-bottom: 3*$indentBasis;
    }

    .yes-results {
        color: $blue;
        font-style: italic;
    }

    .no-results {
        color: $red;
        font-style: italic;
    }

    .other-suggestion {
        font-size: 14px;
        line-height: 24px;
    }
}

.suggestion {
    margin-bottom: 0.5*$indentBasis;
    display: inline-block;

    &:last-child {
        margin-bottom: 0;
    }

    button.button--action {
        font-size: 14px;
        padding: 0;
        margin: 0;
        font-family: $BrandRegular;
        text-transform: none;
        text-align: left;
        height: auto;
        box-shadow: none;
        width: 100%;
        justify-content: flex-start;
        span {            
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        svg {
            flex-shrink: 0;
        }
    }
}

.exact-suggestion {
    font-family: $BrandMedium;
    font-size: 16px;
    line-height: 24px;
    color: $greyDefault;

    .suggestion {
        display: inline-block;
    }
}

.filter-settings {
    display: block;
    position: absolute;
    top: 15px;
    right: 48px;
    z-index: 3;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;

    .button--action {
        .material-icons {
            color: $greyDarker3;
        }
    }
}

.auto-hide--hidden {
    width: 0;

    .filter-settings {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s, opacity 0.5s linear;
    }
}

.auto-hide {
    padding: 0;
    position: relative;
    height: 100%;

    .auto-hide--opener {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 3;

        &.show {
            position: relative;
        }

        .button--action {
            &:disabled {
                display: none;
            }
        }
    }

    h3.customize-mode--title {
        padding: 0;
        font-size: 14px;
        line-height: 24px;
        position: static;
    }

    &--animation {
        height: 100%;
        width: 240px;

        .mobile-overlay {
            z-index: 1;
        }
    }

    &--container {
        height: 100%;
        width: 240px;
        border-left: $classicBorder;
        position: relative;
        box-sizing: border-box;
        padding-top: 110px;
        z-index: 2;
        background-color: $greyLight7;

        &.customize-mode--container {
            padding-top: 0;
        }

        h3 {
            font-family: $BrandMedium;
            color: $blackLight;
            font-size: 14px;
            line-height: 24px;
            padding: 2*$indentBasis;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.opened {
        .auto-hide--container {
            border-left: none;
            box-shadow: $grayShadow1;
            & > div {
                max-height: 100000px;
                transition: max-height 100s ease-out;
            }
        }
    }

}

.page-controls {
    display: flex;
    margin-bottom: 40px;

    &__dots {
        display: flex;
        padding: 5px 10px;
        align-items: end;
        margin-right: 5px;
        cursor: default;
    }

    &__load-more {
        width: 70%;

        .load-more--pagination {
            justify-content: flex-start;

            .load-more,
            .load-more--paging {
                text-align: center;
                padding: 0 7px;
                height: 32px;
                line-height: 32px;
                background: $white;
                color: $defaultColorText;
                border-radius: 2px;
                margin-right: 4px;
                min-width: 32px;
                font-size: 14px;
                font-family: $BrandRegular;
                text-transform: capitalize;
                @extend %hover-bg-annimation;
                flex-grow: 0;
                flex-shrink: 0;
                box-shadow: none;
                border: 1px solid $inputBorderColor;

                &.load-more--active {
                    background: $linkTextColor;
                    color: $white;
                    border-color: $greyDarker3;
                    font-size: 14px;
                }

                &:hover {
                    background: $greyDarker2;
                }
            }

            .load-more {
                margin-left: 10px;

                &.load-prev {
                    margin-right: 15px;
                    margin-left: 0;
                }
            }
        }
    }

    &__change-size {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .page_size_dropdown {
            display: flex;
            justify-content: flex-end;

            .base-input {
                width: 124px;
                height: 40px;

                .Select-control {
                    height: 40px;
                }

                .react-select__control {
                    border-color: $inputBorderColor;
                }

                .react-select__value-container {
                    padding-left: 16px;
                }
            }
        }
    }
}

.new-search-results--view-switcher {
    display: flex;
    .button--action {
        margin-left: 5px;
    }
}

.new-search-results--actions {
    display: flex;
    justify-content: flex-end;
    button {
        text-transform: none;

        .button--action__toolTip.button--action__toolTip--bottom {
            width: 105px;
            left: -43px;
            margin-right: 0;
        }
    }
}
.card-box--wrapper {
    margin-bottom: calc(#{$indentBasis} * 5);
}

.card-box {
    font-size: 14px;
    font-family: $BrandRegular;
    color: $blackLight;
    overflow: hidden;

    h4 {
        // color: $blackLight;
        font-size: 14px;
        line-height: 24px;
        font-family: $BrandMedium;
        margin-bottom: 9px;
    }

    a:hover {
        text-decoration: underline;
    }

    ul {
        line-height: 24px;
        margin: 0;
        padding-left: 15px;
        overflow: hidden;
    }
}

.search-feedback {
    font-size: 12px;
    margin-top: calc(#{$indentBasis} * 3);
    line-height: 14px;
    border-radius: 4px;
    border: $classicBorder;
    padding: $indentBasis 2*$indentBasis 2*$indentBasis 2*$indentBasis;
    color: $greyDefault;

    h4{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0.5*$indentBasis;
        font-family: $BrandRegular;
    }

    p {
        margin-bottom: 2*$indentBasis;
        line-height: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.bulk-actions {
    display: flex;
    margin-right: 5px;

    .bulk-selected-asset {
        ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            vertical-align: middle;
            align-items: center;
            padding: 0;
            max-height: 300px;
            overflow: auto;

            li {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                .button--action {
                    background: none;
                    border-radius: 3px;

                    svg {
                        path {
                            fill: $greyBlueDark;
                        }
                    }

                    &:hover {
                        background: $greyDarker2;
                    }
                }

                &.doi {
                    margin: 0 10px;
                    width: 95px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                &:last-child {
                    width: 35px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-right: 10px;
                }
            }
        }
    }

    .dropdown {
        min-width: 0;
        margin-right: 10px;

        .dropdown-title {
            background: $white;
            height: 32px;
            color: $greyDefault;
            border: 1px solid $greyDarker2;
            font-size: 14px;
            padding-right: 30px;
            padding-left: 10px;
            position: relative;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-top: 5px solid $greyBlueDark;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                position: absolute;
                top: 13px;
                right: 11px;
            }
        }

        .dropdown-body {
            top: 35px;
            left: 0;
            right: auto;

            & > div {
                margin: 0;
                padding: 0;
                cursor: default;

                &:hover {
                    background: transparent;
                }
            }

            button {
                text-align: left;
                color: $black;
                font-size: 14px;
                font-family: Arial, sans-serif;
                line-height: 25px;
                padding: 3px 10px;
                display: block;
                width: 100%;
                margin: 0;

                &:disabled {
                    color: $greyDarker2;
                    cursor: default;

                    &:hover {
                        background: transparent;
                        color: $greyDarker2 !important;
                    }
                }

                &:hover {
                    background: $greyLight7;
                }
            }
        }

        .dropdown-group {
            border-bottom: 1px solid $blueLight5;
            margin-bottom: 0;
        }
    }

    .bulk-actions {
        &--dropdown {
            margin-right: 0;
            &.opened {
                .dropdown-title {
                    background: $greyDarker2;
                }
            }
            .dropdown-body {
                right: 0;
                left: 0;
                min-width: 200px;
                button {
                    &:not(.base-checkbox__icon):not(.search-input-actions__button):not(.anime-toggle--button) {
                        font-family: $BrandRegular;
                    }
                }
                .dropdown-group, .export_to_xls--container {
                    padding: 0;
                }
            }
            button.dropdown-title {
                &:hover {
                    background: $greyDarker2;
                }
            }
            .dropdown-title:after {
                border-top-color: $greyDarker3;
            }
        }
    }

    .bulk-actions--selected-dropdown {
        .dropdown-body {
            right: -128px;
            width: 300px;
            max-height: calc(100vh - 200px);
            overflow: hidden;
            overflow-y: auto;

            &__content {
                &__items {
                    max-height: 70vh;
                    overflow-y: auto;
                    & > * {
                        cursor: auto;
                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }

        &.opened {
            .dropdown-title {
                background: $hoverBg;
            }
        }
    }
}

@mixin relatedLayout {
    .dummy:not(.isMobile) {
        .page {
            .full-layout--container {
                .full-layout--middle {
                    .search-results-info--content {
                        margin-right: auto;
                        margin-left: auto;
                        flex-wrap: wrap;
                    }

                    .page-controls {
                        justify-content: center;

                        &__load-more {
                            width: auto;
                        }
                    }
                }

                .full-layout--left,
                .full-layout--right {
                    width: auto;

                    .auto-hide {
                        position: relative;
                        top: auto;
                        right: auto;
                    }
                }
            }
        }
    }

    html:not(.grid-view) {
        .dummy:not(.isMobile) {
            .new-search-page {
                .full-layout--container {
                    .full-layout--left,
                    .full-layout--right {
                        .auto-hide {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    .new-search-page:not(.content-store-page) {
        .search-results-info--content-actions {
            margin-right: auto;
            margin-left: auto;
            flex-wrap: wrap;
        }
    }
}

@media #{$laptop} {
    @include relatedLayout;
}

@media #{$bigScreen} {
    @include relatedLayout;
}

@media #{$iPad} {
    .navigation-main-container {
        position: absolute !important;
        height: 100%;
    }

    header {
        z-index: 101;
    }

    .group-item.not-active-dragged {
        display: flex !important;
    }

    .group-item.isOver {
        * {visibility: visible !important;}
    }
}

@media only screen and (max-width: 900px) {
    .page
    .full-layout--container
    .full-layout--middle .search-results-info > .new-search-results--actions {
        width: $griItemWidth;
        padding-right: 55px;
        box-sizing: border-box;
    }
}

.mobile-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $blackLight;
    opacity: 0.6;
}

.dummy {
    height: 100vh;
}

.dummy.isMobile {
    .page {
        .full-layout--container {
            .full-layout--middle {
                width: 100%;

                .new-search-results--view-switcher {
                    display: none;
                }
            }
        }


        .placeholder__user-name {
            display: none;
        }

        .top-links {
            & > a.top-links__item {
                display: none;
            }
        }
    }
}

.dummy.isIE {
    .region--top {
        border-top: $classicBorder;
    }
}

.dummy.isMobileOnly {
    .custom_asset--preview.bulk-activated {
        margin-left: 60px !important;
        width: 330px !important;
    }

    .bulk-actions {
        margin-left: 0;
    }


    .no-result-container {
        .centered-container {
            width: auto;
        }

        ol.top-searches-list li {
            float: none;
            width: auto;
        }
    }
}


/*panels animation*/
.slide-transition-enter {
    width: 0;
    overflow: hidden;
}
.slide-transition-enter-active {
    width: 230px;
    transition: width 0.3s;
}
.slide-transition-leave {
    width: 230px;
}
.slide-transition-leave-active {
    width: 0;
    transition: width 0.3s;
    overflow: hidden;
}

//CORE Redesign
.search-text-input--wrapper {
    .search-text-input {
        padding: 0;

        .search-text-close-button {
            position: absolute;

            path {
                fill: $greyDarker
            }
        }

        .search-text-input-button {
            border: 0;
            opacity: 1;
            position: absolute;

            path {
                fill: $greyDarker3
            }

            &:focus {
                box-shadow: none;
                border: 0;
            }
        }
    }
}

html:not(.grid-view) {
    .full-layout--container {
        .search-results--container {
            max-width: 640px;
            margin: 0 auto;

            .search-results {
                .custom_asset--preview {
                    width: 100%;
                }
            }
        }
        @media #{$IE} {
            .search-results--container {
                margin: 0 auto 35px;
            }
        }
    }
}

.search-results-filter {
    .auto-hide {
        &--opener.button--action {
            position: fixed;
            right: 16px;
            top: 64px;
        }
    }
}

.assets-search__mask {
    width: 100%;
    height: calc(100vh - 48px);
    position: fixed;
    top: 48px;
    left: 0;
    z-index: 100;
    background-color: $black;
    overflow: hidden;
    transition: height 0.3s;
    opacity: 0.5;

    .close-search--box {
        padding: 9px 0 0 10px;

        .button--action:not(.dropdown-title) {
            .material-icons {
                color: $greyDarker3;
                font-size: 22px;
            }
        }
    }
}

.search-mask-filter-panel {
    .auto-hide--container {

        .filters {
            padding-top: 0;
        }
    }
}

.filters-holder {
    position: relative;
    width: 25px;

    .checkedFiltersCount {
        top: -7px;
        right: -7px;
    }
}

.content-stores-filter,
.search-results-filter {
    .filters-holder {
        right: 24px;
    }
}

.search-input--container {
    flex: 1;
    .auto-hide {
        position: fixed;
        top: 48px;
        right: 0;
        z-index: 111;
        height: calc(100% - 48px);
        background: $white;
        min-width: 60px;

        .auto-hide--opener.show {
            right: -20px;
        }
    }
}

.search-mask-transition-enter,
.search-mask-transition-leave.search-mask-transition-leave-active {
    height: 0;
}

.search-mask-transition-leave,
.search-mask-transition-enter.search-mask-transition-enter-active {
    height: 100vh;
}

.outreach-search-page {
    &.page {
        padding-top: 55px;
    }
    .main-content {
        .outreach-search-page--tabs { 
            &.search-results--container {
                ul, .search-results-info {
                    margin-bottom: 0;
                }
            }
        }
        .new-search-results--actions--right {
            display: none;
        }
        .search-results-info {
            margin-bottom: 0;
            padding: 15px 0 0;
        }
        .async-tabs-container {
            margin-bottom: 5px;
        }
    }
    .filters-searchInput {
        .search-text-input {
            width: 100%;
        }
    }
    .suggestions {
        margin: 0;
    }
}

@mixin gridWidthLayout($griItemWidth) {
    .page {
        .full-layout--container {
            .full-layout--middle {
                .closeable-box {
                    max-width: calc(2*#{$griItemWidth} + #{$gridItemLeftIndent} - 30px);
                }
            }
        }
    }
}

@include gridWidthLayout(360px);

//iPhoneS5

@media #{$iPhone5s_P} {
    $griItemWidth: 310px;
    @include gridWidthLayout($griItemWidth);
}

@import "./simple_cs_view";
@import "./style_responsive";
