.widget-teams {
    .team-list {
        display: flex;
        flex-wrap: wrap;
    }

    .team-item {
        width: 33.3%;
        min-height: 104px;
        padding: 7px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        position: relative;
        background: $white;
        border: 1px solid $white;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        transition: all .3s;

        &:hover {
            background: $greyLight6;
            border-color: $borderColor;
            border-radius: 2px;

            &:before,
            &:after,
            .divider {
                display: none;
            }

            .user-data__name {
                color: $blue;
            }

            .user-avatar {
                @extend %avatar-hover;
            }
        }

        &:before,
        &:after,
        .divider {
            content: "";
            margin: auto;
            position: absolute;
        }

        &:before {
            width: calc(100% - 32px);
            height: 0px;
            border-top: $classicBorder;
            bottom: -1px;
            left: 0;
            right: 0;
        }

        &:after {
            width: 0px;
            height: calc(100% - 32px);
            border-right: $classicBorder;
            top: 0;
            bottom: 0;
            right: -1px;
        }

        &:nth-child(3n) {
            &:after {
                display: none;
            }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            .divider {
                width: calc(100% - 32px);
                height: 1px;
                border-top: $classicBorder;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        &:focus {
            outline-offset: -4px;
        }

        .user-avatar {
            margin: 0 auto 8px;
        }

        .user-data__name {
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.my-team-table {
    font-family: $BrandRegular;

    .dt-cell {
        padding: 8px 10px;
        box-sizing: border-box;
    }

    .dt__item {
        min-height: 48px;
        height: auto;
        max-height: none;

        &:hover {
            .dt-cell--fullName {
                font-family: $BrandMedium;
                color: $blue;
            }

            .user-avatar {
                @extend %avatar-hover;
            }
        }
    }

    .dt-cell--avatar {
        max-width: 87px;
        flex-basis: 87px;
        min-width: 87px;
        padding-left: 0;
    }

    .team-item--folders {
        color: $greyDefault;
        justify-content: flex-start;

        span {
            font-family: $BrandMedium;
        }

        .material-icons {
            margin-right: 4px;
            font-size: 15px;
        }

        svg {
            max-width: 15px;
            max-height: 15px;
        }
    }
}

.team-item--folders {
    font-family: $BrandRegular;
    color: $greyDarker3;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        margin-right: 3px;
    }

    .material-icons {
        margin-right: 8px;
        color: $greyDarker;
        font-size: 13px;
    }

    svg {
        max-width: 13px;
        max-height: 13px;
        margin-right: $indentBasis;

        path {
            fill: $greyDarker;
        }
    }
}

.team-manage-permissions {
    font-family: $BrandRegular;

    .move-folder-button-wrapper {
        margin-bottom: 8px;
    }

    .team-manage-permissions-list {
        height: calc(100% - 37px);
    }

    .userName-dropdown {
        margin-top: 0;
    }

    .auto-close {
        height: 37px;
    }

    .user-details {
        padding-top: 5px;
        padding-bottom: 20px;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        border-bottom: $classicBorder;

        &--label {
            width: 40%;
            color: $greyDarker3;
        }

        &--value {
            width: 60%;
            display: block;
        }
    }

    .team-folders-tree {
        padding-top: 20px;

        .collapse {
            .tree-collapse-childrens {
                padding-left: 25px;
            }
        }

        .Select-option {
            color: $blackLight;

            &:hover {
                background-color: $greyLight2;
            }
        }

        .react-select-box-option-selected, 
        .react-select-box-option-focused {
            background-color: $greyLight2;
            color: $blackLight;
            opacity: 1;
        }

        .permission-descriptions {
            width: 100%;
            padding-left: 30px;
            margin: 0;
        }

        .permission-descriptions-item {
            font-family: $BrandRegular;
            font-size: 12px;
            line-height: 18px;
            color: $greyDarker3;
        }

        .move-folder-button-wrapper {
            display: flex;
            justify-content: space-between;

            .folder-data-wrapper {
                display: flex;
            }
        }

        .folder-for-move {
            width: 100%;
            word-break: break-all;
        }

        .base-select {
            width: $dropdownMenuWidth;
        }

        .react-select-box--opened {
            left: auto;
            right: 0;
        }
    }
}

.team-no-data {
    font-size: 20px;
    color: $greyDarker;
    text-align: center;
}

.team-folders-tree .move_collapse_title > .material-icons,
.team-folders-tree .move-folder-button-wrapper .folder-data-wrapper .material-icons {
    //need to remove inherit from common Folders Tree
    font-size: 18px;
    color: $greyDarker;
    line-height: 21px;
    margin-right: 8px;
}

.team-folders-tree .move_collapse_title > svg,
.team-folders-tree .move-folder-button-wrapper .folder-data-wrapper svg {
    //need to remove inherit from common Folders Tree
    max-height: 18px;
    max-width: 18px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 8px;

    path {
        fill: $greyDarker
    }
}

.team-folders-tree {
    .collapse.collapse--with-arrow .collapse-toggle .collapse-toggle__icon {
        margin-top: 4px;
    }

    .folder-for-move--wrapper .move_collapse_title {
        line-height: 23px;
        padding: 0 8px;
        display: flex;
    }

    .folder-data-wrapper .folder-for-move {
        padding: 0 $indentBasis 0 0;
        width: 100%;
        word-break: break-all;
        white-space: normal;
        height: auto;
        text-align: left;
        display: block;
        line-height: 23px;
        font-size: 1.4rem;
    }

    .move-folder-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@import "./style_responsive";