$upload-list-width: 150px;
$upload-list-height: 125px;

.black_undefined {
    .react-select-box, .Select-control {
        border-color: $red !important;
    }

    &:after {
        content: 'The previously chosen value conflicts with asset metadata, please select a new value among available options.';
        color: $red;
        padding-top: 5px;
        float: right;
    }
}

@mixin plusMinus($width, $lineWidth, $color) {
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: $width;
    height:0px;
    border-bottom: solid $lineWidth $color;
    position: absolute;
    bottom:$width /2 - $lineWidth/2;
    transform: rotate(90deg);
    margin: 13px 64px;
  }
  &::after {
    content: '';
    display: block;
    width: $width;
    height:0px;
    border-bottom: solid $lineWidth $color;
    position: absolute;
    bottom:$width /2 - $lineWidth/2;
    margin: 13px 64px;
  }
}

.upload-list {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    background: $yellow;
    padding: 20px 12.5px;
    min-height: $upload-list-height;
    border: 1px dashed $greyDarker4;
    border-radius: 5px;

    &--empty {
        min-height: 0;
        height: 0;
        padding: 0;
        border: 0;
        border-bottom: 3px solid $greyLight7;
    }
}

.upload-list__items {
    float: left;
    height: $upload-list-height;
}

.upload-list__item {
    text-align: left;
    width: $upload-list-width;
    height: $upload-list-height;
    background: $white;
    margin: 0 7.5px;
    position: relative;
    display: inline-block;
    &__checkbox {
        display: none;
    }
    & .spinner {
        position: absolute;
        text-align: center;
        top: 42px;
        left: 0;
        right: 0;
    }
    &:hover {
        &:before {
            content: '';
            display: inline-block;
            height: $upload-list-height;
            width: $upload-list-width;
            position: absolute;
            background: $blue;
            opacity: 0.2;
            left: 0;
            z-index: 2;
            top: 0;
        }
        cursor: pointer;
    }

    &:after {
        content: '';
        display: inline-block;
        height: $upload-list-height;
        width: $upload-list-width;
        position: absolute;
        border: 1px solid $orangeLight;
        box-sizing: border-box;
        left: 0;
    }

    &--selected {
        &:after {
            border: 4px solid $blue;
        }

        .upload-list__item__name {
            background: $blue;
            color: $white;
        }
    }

    .upload-async-state {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: normal;
        text-align: center;
        padding: 0 10px 34px 10px;

        &--error {
            color: $red
        }
        &--success {
            color: $saladGreen;
        }
    }
}

.upload-list-controls {
    margin: 0;
}

.upload-list__item__name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    padding: 10px 10px 10px 10px;
    overflow: hidden;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $black;
    background: $orangeLight;
}

.owner-name-text-input {
    .react-select-box-options {
        visibility: visible;
    }
}

.add-upload-items-modal,
.edit-upload-item-modal {
    .tabs__panel {
        height: 380px;

        .input-pair.authorisation {
            padding-top: 0;
        }

        .input-pair.description {
            align-items: flex-start;

            textarea {
                min-height: 69px;
            }
        }

        .input-pair--file {
            align-items: flex-start;
            .button--file {
                font-size: 11px;
            }
        }
    }
}

.edit-upload-item-modal {
    @include modal(600px, 35%, 65%);
    .modal__content {
        padding-right: 5px;

        .tabs__menu {
            margin-right: 20px;
        }
        .tabs__panel {
            margin-top: 15px;
            padding-top: 0;
            padding-right: 15px;
            overflow-y: scroll;

            .add-modal__c4u-checkbox-area {
                margin-left: 35%;
            }
            .add-modal__brand-private {
                width: 65%;
                margin: 10px 35%;
            }
        }

        .datepicker__input {
            height: 30px;
        }
    }

    &.gco-reuse {
        .modal__content {
            padding: 20px;
        }
    }

    .modal__footer__buttons {
        box-sizing: content-box;
    }
}

// Modal should fit on laptops
@media screen and (max-width: 900px) {
    .add-upload-items-modal .modal {
        width: 770px !important;
    }
    .add-upload-items-modal .modal__content {
        width: 770px !important;
    }
}

// Modal should fit on laptops
@media screen and (max-height: 740px) {
    .add-upload-items-modal .tabs__panel {
        height: 340px !important;
    }
}

$add-modal__head--width: 32.5842696629%;
.add-upload-items-modal {
    @include modal(890px, 42%, 58%);

    .upload-item span {
        display: block;
        padding: 8px 30px 10px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        font-family: Arial, sans-serif;
    }

    .tabs__panel {
        margin: 21px 0 0 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: scroll;

        .add-modal__fieldset {
            width: 98.8%;
        }
    }

    .personal-data__checkbox-area {
        position: relative;
        width: 42%;
        margin-right: 0;
    }

    .modal__footer__buttons {
        padding-left: 0;
    }

    .modal__footer {
        padding: 30px 20px 20px 0;
    }

    .modal__content {
        padding: 0;

        // Context for absolutely positioned head and body
        position: relative;
    }

    .owner-name-text-input {
        margin-top: 5px;
    }

    .add-modal__head {
        width: $add-modal__head--width;
        background: $greyLight7;
        position: absolute;
        z-index: 20;
        margin: 0;
        bottom: 0;
        top: 0;
        padding: 20px;
        box-sizing: border-box;
        vertical-align: top;
        border: none;
        overflow: hidden;
        overflow-y: auto;

        .upload-item {
            position: relative;
            height: 35px;
            background-color: $white;
            border: solid 2px $greyDarker4;
            color: $black;
            box-sizing: border-box;
            margin-bottom: 5px;
            font-size: 13px;
            width: 100%;
            text-align: left;

            &__final {
                position: absolute;
                right: 10px;
                top: 0;
            }

            &__icon {
                position: absolute;
                top: 8px;
                right: 10px;

                .icon-trashBin {
                    width: 11px;
                    height: 12px;
                    path {
                        fill: $greyDarker;
                    }

                    &:hover {
                        path {
                            fill: $defaultColor;
                        }
                    }
                }

                .icon-checkmark {
                    display: none;
                    width: 13px;
                    height: 11px;
                    padding-top: 2px;

                    path {
                        fill: $saladGreen;
                    }
                }

                &:focus,
                &:hover {
                    .icon-checkmark {
                        display: none;
                    }
                    .icon-trashBin {
                        display: block
                    }
                }
            }

            &--valid {
                span {
                    opacity: 0.5;
                }

                .icon-checkmark {
                    display: block;
                }

                .icon-trashBin {
                    display: none;
                }
            }

            .error-message {
                height: 41px;
                padding: 5px 50px 12px 10px;
                color: $black;
                background: $white;
                box-sizing: border-box;
                white-space: normal;
                font-size: 11px;
            }

            &--error {
                color: $white;
                height: 78px;
                border-color: $red;
                border-width: 3px;
                background: $red;

                .upload-item__icon {
                    top: auto;
                    bottom: 15px;
                }

                &.upload-item span {
                    padding: 6px 30px 9px 10px;
                }

                &:hover {
                    background: $greyDarker4;
                }

                &.upload-item--selected {
                    path {
                        fill: $yellow;
                    }
                }
            }

            &--selected {
                color: $white !important;
                background: $orange !important;
                border-color: $orange !important;
                cursor: normal;

                span {
                    opacity: 1;
                }

                .upload-item__icon {

                    path {
                        fill: $orange;
                    }
                }

                .error-message {
                    background: $orangeLight;
                }
            }

            &:hover {
                border-color: $greyDarker;
                cursor: pointer;
            }
        }
    }

    .add-modal__body {
        position: relative;
        margin-left: $add-modal__head--width;
        padding: 0 5px 0 20px;

        .modal__footer__buttons {
            .add-upload-items-modal__buttons__upload {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .tabs__menu ul {
            margin-right: -5px;
        }

        .for-sap-com__checkbox-area {
            margin: 0;
            width: 42%;
        }

        .for-partner-edge__checkbox-area {
            margin: 0;
            width: 58%;
        }

        .input-pair__upload-content-roles {
            padding: 0 25px 19px 0;
            position: relative;

            .asset-is--icon {
                position: absolute;
                top: 10px;
                left: 0;
                path {
                    fill: $blue;
                }
            }

            &--radio-group {
                .input-pair__title-box {
                    color: $blue;
                    text-transform: none;
                    font-size: 13px;
                    font-weight: normal;
                    padding: 0 10px 0 20px;
                    line-height: 27px;
                    min-height: 27px;
                }
            }
        }

        .input-pair--file {

            .button--file {
                padding: 6px 0 0 0;
                background: none;
                text-transform: none;
                font: normal 13px/15px Arial, sans-serif !important;
                color: $blue;

                &:hover {
                    text-decoration: underline;
                }
            }

            .mac-file__titles__item {
                border: none !important;
                border-bottom: 2px solid $greyDarker !important;
                background: $greyLight7;
            }

            .input-pair__input-box__error,
            .error {
                margin-top: -10px;
            }
        }
    }
}

.status-select .input-pair__input-box{
    width: 300px;
}

.input-pair-container .input-pair__title-box {
    width: 40% !important;
    vertical-align: baseline;
}

.input-pair-container .input-pair__input-box {
    width: 60% !important;
}

.add-modal__validation-message {
    margin-bottom: 5px;
}

.add-modal__head {
    border-top: 1px solid $greyDarker4;
    border-bottom: 1px solid $greyDarker4;
    margin-bottom: 20px;
}

.add-modal__footer {
    margin-top: 20px;
    background-color: $greyLight7;
}

.personal-data__checkbox-area {
    position: relative;
    width: 35%;
    margin-right: 0 !important;
}

.personal-data__checkbox-area,
.for-sap-com__checkbox-area,
.for-sf__checkbox-area,
.learning-content__checkbox-area,
.for-partner-edge__checkbox-area {
    margin: 5px 25px 0 0;
    display: inline-flex;
    position: relative;

    .base-checkbox {
        margin: 10px 0 5px;
        padding-top: 3px;

        .base-checkbox__label {
            text-transform: none;
        }
    }

    .base-checkbox__icon {
        background-color: $white;
    }

    .form-field-wrapper {
        position: relative;
    }

    .info-tooltip {
        position: absolute;
        right: -8px;
        top: 2px;
        @media #{$IE} {
            top: 10px;
            right: -15px;
        }
    }
}

.aem-fields, .pe-fields {
    position: relative;
    width: 100%;
}

.mobileapp__checkbox-area {
    width: 100%;
    display: flex;
    align-items: center;
}

.add-modal__brand-private {
    width: 58%;
    display: flex;
    align-items: center;
    margin: 10px 42%;
}

.add-modal__c4u-checkbox-area {
    flex-direction: column;
    width: 50%;
    margin-left: 42%;
    display: flex;
    min-height: 30px;
    padding-bottom: 5px;
    align-items: flex-start;
    justify-content: space-between;

    .base-checkbox {
        margin-top: 10px;
    }

    .base-checkbox__label {
        width: 53px;
        font-family: Tahoma, Arial, sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $black;
        white-space: nowrap;
    }
}
.sales-reuse-form {
    .for-mobile-app {
        .input-pair {
            margin-bottom: 0px;
        }
        .base-checkbox {
            margin-left: 35%;
            &__label{
                color: black;
            }
        }
        margin-bottom: 16px;
    }
}
.manage-asset-form__double-select-radios {
    margin-top: 10px;
}

.field-hint {
    padding-left: 11px;
    position: relative;
    line-height: 14px;

    &--required-icon {
        position: absolute;
        top: 0;
        left: 0;
    }

    &--message {
        font-style: italic;
    }
}

.button--white {
    @extend %button-white;
    background: $white;
    border: 1px solid $greyDarker4;
}

.button--white:HOVER {
    border-color: $greyDarker;
}

.react-select-box-container {
    .option-not-relevant--visible {
        opacity: 0.5;
    }

    .option-not-relevant--hidden {
        display: none;
    }
}

/* Transitions */
.upload-items-enter {
    opacity: 0.01;
}

.upload-items-enter.upload-items-enter-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.upload-items-leave {
    opacity: 1;
}

.upload-items-leave.upload-items-leave-active {
    opacity: 0.01;
    transition: opacity .5s ease-in;
}

.upload-page, .reused-assets-page {
    .breadcrumbs--current,
    .bookmark-link,
    .cabinet-header__text.visible {
        font-family: $BrandMedium;
        display: inline-block;
        padding: 5px 15px;
        cursor: pointer;
        font-size: 15px;
        min-width: 135px;
        width: auto;
        text-align: center;
    }
    .bookmark-link {
        text-decoration: none;
        opacity: 0.9;
        color: $greyBlueDark;
        font-weight: 500;
        line-height: 20px;
        border-bottom: 3px solid $greyLight7;
    }
    .bookmark-link.active {
        background: $white;
        font-weight: 500;
        line-height: 22px;
        color: $navy;
        border-bottom: 3px solid $navy;

    }
    .cabinet-header__text.visible  {
        background: $white;
        color: $greyBlueDark;
        line-height: 20px;
        border-bottom: 3px solid $greyBlueDark;

        a {
            color: $blue;
            font-family: $BrandRegular;
            font-size: 16px;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.personal-data-field {
    margin-top: 5px;

    .prefiled-smart-metadata .input-pair__input-box:before {
        bottom: -18px;
        top: auto;
    }

    .input-pair__title-box {
        padding-top: 0;
        &__text {
            text-transform: none;
            &:after {
                right: 2px;
                top: 8px;
            }
        }
    }
    .personal-data__checkbox-area {
        max-width: 130px;
        .base-checkbox {
            margin: 0;
            padding: 5px 10px 5px 0px;
            &__label {
                font-family: 'Conv_BentonSans-Regular', sans-serif;
                font-weight: normal;
                color: $defaultColor;
            }
            &--checked {
                .base-checkbox__label {
                    color: $black;
                }
            }
        }
        width: auto;
        margin: 0;
    }
}
