.clipped-text {
    position: relative;
    word-break: break-all;

    &__show {
        color: $blue;
        margin-left: 3px;
    }
    &__full-description {
        position: absolute;
        top: 40px;
        padding: 10px;
        z-index: 3;
        width: 341px;
        background-color: $white;
        box-shadow: $grayShadow1;
        font-size: 12px;
        line-height: 16px;
    }
}
