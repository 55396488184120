.input__number-box {
    display: flex;

    .base-text-input {
        width: 27px;
        height: 25px;
        text-align: center;
        padding: 0px;
        margin-top: 2px;
    }

    div, span {
        float: left;
    }

    button {
        width: 16px;
        height: 16px;
        color: $white;
        margin: 7px 5px;
        padding: 0 !important;

        &:disabled {
            cursor: default;
            path {
                fill: $greyDarker4 !important;
            }
        }
    }
}
