.search-input {
    display: inline-block;
    margin-right: 20px;
    .search-input__button {
        background-color: $blue;
        width: 38px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;

        &:after {
            content: '';
            background: url('../../styles_images/search-white.svg');
            width: 14px;
            height: 14px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .base-text-input {
        height: 30px;
    }
}
