.tooltip__open-btn {
    height: 27px;
    padding: 5px $indentBasis;
    &:focus {
        outline-offset: 2px;
    }
}

.button--action {
    .tooltip__open-btn {
        svg {
            margin-right: 0;
        }
    }
}

.input-pair.with-tooltip {
	.input-pair__title-box {
		justify-content: space-between;
	}
}

.clipped {
    position: relative;

    .clipped-tooltip {
        z-index: -10;
        opacity: 0;
        position: absolute;
        background: $white;
        padding: 5px;
        border: 1px solid $greyDarker4;
        top: 40px;
        left: 0px;
        box-shadow: $grayShadow1;
        -webkit-transition: opacity .3s ease-in-out;
        -moz-transition: opacity .3s ease-in-out;
        -ms-transition: opacity .3s ease-in-out;
        -o-transition: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out;
    }

    &:hover {
        .clipped-tooltip {
            z-index: 10;
            opacity: 1;
        }
    }
}
