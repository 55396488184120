.asset-actions-popup--mobile {
    position: fixed;
    top: auto!important;
    bottom: 0!important;
    left: 0;
    right: 0;
    width: auto;
    z-index: 1001;

    & > .tether-container {
        position: relative;
    }

    &.asset-actions-popup {
        .actions__container.actions__container--links {
            .asset-actions-popup {
                width: 100%;
            }
        }
    }

    .asset-actions-popup__close {
        position: absolute;
        top: -45px;
        right: 10px;
        background: $white;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        svg {
            path {
                fill: $blackLight;
            }
        }
    }

    .actions__container--links {
        .asset-actions-popup {
            position: static;
            width: auto;
            padding: 0;
            padding: 8px 50px 8px 0px;
            &__close {
                padding: 9px;
                border-bottom: 1px solid $greyDarker4;
            }
        }

        .link-box__wrapper {
            .button--action{
                & > span {
                    max-width: 270px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}

.dropdown {
    .dark-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: $black;
        opacity: 0.5;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .target-element {
        position: fixed;
        z-index: 11;
        overflow: visible;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;

        .dropdown-body {
            top: $indentBasis;
            left: $indentBasis;
            width: calc(100vw - 16px);
            height: calc(100vh - 16px);
            box-sizing: border-box;
            margin: 0;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}