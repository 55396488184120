.userName-dropdown,
.bp-dropdown {
    position: absolute;
    z-index: 1;
    background-color: $white;
    width: calc(100% - 2px);
    border-radius: $dropdownBorderRadius;
    border: $dropdownBorder;
    box-shadow: $dropdownShadow;
    margin-top: $indentBasis;

    &__wrapper {
        position: relative;
        padding: 8px;
        box-sizing: border-box;
        overflow: hidden;

        &:first-child {
            border-radius: $dropdownBorderRadius $dropdownBorderRadius 0 0;
        }

        &:last-child {
            border-radius: 0 0 $dropdownBorderRadius $dropdownBorderRadius;
        }

        &:hover {
            background-color: $hoverBg;
        }

        .button--action:hover {
            border-color: $hoverBg !important;
        }
    }
    & &__button {
        position: absolute;
        width: 100% !important;
        background: none;
        top: 0;
        height: 100% !important;
        box-shadow: none;
        box-sizing: border-box;

        &:hover {
            background: none;
        }
    }
    &__item {
        display: flex;
        font-size: 14px;
        line-height: 24px;
        font-family: $BrandRegular;
    }
    &__email,
    &__number {
        color: $blue;
        font-size: 12px;
        line-height: 18px;
    }
}
