html.search-input-expanded {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $black;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0.6;
    }
    overflow: hidden;
    .feedback-menu {
        z-index: -1;
    }
    .region, .region--top {
        z-index: 100;
        width: 100%!important;
        left: 0;
        right: 0;
    }
}

.search-text-input--container {
    .search-text-input--wrapper {
        .search-text-input-autocomplete {
            .button--action {
                justify-content: flex-start;
                box-shadow: none;

                .material-icons {
                    margin-right: 12px;
                    font-size: 18px;
                }
            }
            .settingsBtn {
                justify-content: center;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

.search-input-mobile {
    flex-grow: 1;
    height: 32px;
    max-width: 640px;
    margin: 0 auto;
    position: relative;
    z-index: 101;

    &--closed {
        margin-top: 9px;
        flex-grow: unset;
    }

    &--expanded {
        position: absolute;
        height: $topRegionHeight;
        left: -15px;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        background: $white;
        align-items: center;
        z-index: 11;
        width: 100vw;
        padding-right: 15px;
        padding-left: 5px;
        box-sizing: border-box;

        .search-text-input--container {
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0px);

            .search-text-input {
                .search-text-input-button {
                    svg {
                        width: 15px;
                        height: 15px;
                        path {
                            fill: $navy
                        }
                    }
                }
            }
        }
    }

    .search-text-input--container__opener {
        padding: 5px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 720px) {
    .search-input-mobile {
        max-width: none;

        &--expanded {
            padding-right: 50px;
            z-index: 101;
            .search-text-input--container {
                position: static;
                transform: none;
            }
        }
    }
}