.asset_status {
    display: flex;
    align-items: center;

    .status_icon {
        width: 7px;
        min-width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: $white;

        &.isApproved, &.isDefault {
            background-color: $saladGreen;
        }

        &.isRejected, &.isExternal {
            background-color: $red;
        }

        &.isDraft, &.isRunning {
            background-color: $greyDarker;
        }

        &.isInReview {
            background-color: $orange;
        }
    }
}