.gdpr-consent-modal {
    .gdpr-header {
        display: flex;
        align-items: center;

        img {
            width: 90px;
            flex-shrink: 0;
            margin-right: 19px;
        }
    }

    .gdpr-content {
        font-size: 16px;
    }

    .gdpr-condition-text {
        margin-bottom: 2*$indentBasis;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .gdpr-chechbox {
        margin-left: -10px;
        margin-bottom: 9px;
    }

    .base-checkbox {
        .base-checkbox__icon {
            margin: 10px;
            border-color: $greyDarker;
        }

        .base-checkbox__label {
            font-family: $BrandMedium;
        }
    }
}