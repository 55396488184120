.my-assets-page,
.semaphore-changes,
.affected-assets-modal,
.outreach-search-page,
.cvp-agencies-project-page,
.fullscreen-modal-container {
    height: 100%;
    padding: 0 15px;

    %loader {
        background: $greyDarker;
        @extend %preload;
    }

    &--progress {
        &.my-assets-page {
            .search-text-input,
            .items-counts {
                @extend %preload;
                &__text {
                    visibility: hidden;
                }
            }

            .async-tabs-container {
                ul {
                    .async-tabs-item,
                    .dropdown {
                        width: auto;
                        height: 42px;
                        margin-top: 0;
                        background-color: $greyLight2;
                        border-radius: 4px;
                        box-sizing: border-box;
                        @extend %loader;

                        .button--action {
                            visibility: hidden;
                        }
                    }

                    .dropdown {
                        width: 67px;
                        .dropdown-title {
                            display: none;
                        }
                    }
                }
            }

            .dt-grid--search,
            .search-text-input-button {
                display: none;
            }
        }
    }

    .search-text-input {
        width: 280px;
    }

    .dt {
        .assets-list__item__preview__image {
            padding-left: 0;
        }
    }
}

.fullscreen-modal,
.assets-page--controls,
.new-layout--middle {
    .search-text-input {
        width: 280px;
    }
}

.my-assets-header {
    &__buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        vertical-align: middle;

        .bulk-actions {
            &--dropdown {
                margin-right: 0;
                padding-bottom: 0;

                &.opened {
                    .dropdown-title {
                        background: $greyDarker2;
                    }
                }
                .dropdown-body {
                    right: 0;
                    left: auto;
                    button {
                        &:not(.base-checkbox__icon):not(.search-input-actions__button):not(.anime-toggle--button) {
                            font-family: $BrandRegular;
                        }
                    }
                }
                button.dropdown-title {
                    &:hover {
                        background: $greyDarker2;
                    }
                }
                .dropdown-title:after {
                    border-top-color: $greyDarker3;
                }
            }
        }
    }
    .bulk-operations {
        clear: both;
    }

    .bulk-actions {
        margin-left: 10px;
    }

    .my-assets-header__buttons-wrapper {
        .bulk-actions {
            margin-left: 0;
        }
    }

    .manage-btns-wrapper {
        display: flex;

        span {
            margin-left: calc(#{$indentBasis} * 2);


        }
    }
}

.dt--project-assets,
.my-assets-table-container {
    .dt-cell--actions__container {
        min-width: 34px;
    }
}

.my-assets-table-container {
    padding: 0 0 80px;
    .sort-controls__item {
        .sort-controls__item__label {
            color: $blackLight;
        }
    }

    .search-text-input--container {
        max-width: 100%;
    }

    .actions__container {
        width: auto;

        > button {
            svg {
                width: 14px;
                height: 14px;
                path {
                    fill: $blue;
                }
            }
        }
        .asset-actions__item__icon {
            svg {
                path {
                    fill: $blackLight;
                }
            }
        }
        .asset-actions__item--popup-mode {
            .asset-actions__item__text {
                color: $blackLight;
            }

            .pseudo-button {
                white-space: normal;
                line-height: 15px;
                height: auto;
                min-height: 32px;
            }
        }
    }

    .dt-cell {
        &.dt-cell--actions {
            min-width: 20px;
            flex-basis: 0;
            padding: 0;
        }
        &--actions__container {
            display: flex;
        }

        > span {
            color: $greyDefault;
        }
        .assets-list__item__details-link {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-family: $BrandMedium;
            color: $blue;

            svg path {
                fill: $orange;
            }
        }
        .other-info-box {
            svg, img {
                margin-right: 5px;
                flex-shrink: 0;
                flex-grow: 0;
            }

            svg {
                width: 14px;
                height: 14px;
            }

            &.type {
                font-size: 12px;
                display: flex;
                align-items: center;
                font-family: $BrandMedium;
                margin: 0 0 0 $indentBasis;
                &:after {
                    right: -8px;
                }
                &:before {
                    left: -8px;
                }
            }
        }
        .usage {
            &--container {
                span {
                    text-transform: capitalize;
                    color: $greyDefault;
                    font-family: $BrandRegular;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}

.my-assets-table-controls {
    .bulk-actions {
        margin-right: 10px;
        margin-left: 0;

        .dropdown-body {
            left: 0;
            right: auto;
        }
    }
}

/*reused and my project*/
.assets-table {
    .dt-cell {
        padding: 13px 10px;
        box-sizing: border-box;
        &--src {
            max-width: 90px;
            flex-basis: 50px;
            min-width: 50px;
        }
        &--name {
            flex-grow: 5;
            flex-shrink: 5;
            min-width: 110px;
        }
        &--actions, &--approvalStatus, &--expiresIn {
            flex-grow: 0;
            flex-shrink: 0;
        }
        &--dateImported, &--dateModified, &--expirationDate {
            flex-grow: 1;
            flex-shrink: 1;
        }
        &--owner, &--uploader {
            flex-grow: 2;
            flex-shrink: 2;
        }
        &--lob, &--salesBags, &--c4uCategory {
            flex-grow: 2;
            flex-shrink: 2;
        }
        &--archived {
            min-width: 24px;
            flex-basis: 0;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0;
        }
        &--approvalStatus {
            flex-basis: 105px;
            min-width: 105px;
        }
        &--partnerEdgeStatus {
            flex-grow: 2;
            flex-shrink: 2;
            min-width: 110px;
        }
        &--review {
            max-width: 90px;
            flex-basis: 70px;
            min-width: 70px;
        }
        &--rejection-reason,
        &--approval-reason {
            max-width: 125px;
            flex-basis: 90px;
            min-width: 90px;
        }
    }
    .dt-cell--checkbox {
        width: 28px;
        min-width: 28px;
        max-width: 28px;
        padding-left: 0;
        justify-content: flex-start;
    }
}
.my-assets-page {
    .reused-assets-controls {
        width: 100%;
        .items-counts {
            flex-grow: 1;
            margin-left: 20px;
        }
        .search-input {
            margin-right: 20px;
        }

    }
    .async-tabs-container {
        .dropdown {
            .async-tabs-item {
                .button--action {
                    text-transform: capitalize !important;
                }
            }
        }
    }
    .reused-assets-page, .project-page {
        .cabinet-header {
            display: none;
        }
        .cabinet-controls {
           flex-wrap: nowrap;
            .bulk-operations {
                display: inline-block;
            }
        }
        .search-input {
            display: inline-block !important;
        }
    }

    .bulk-actions {
        &--dropdown {
            padding-bottom: 0;
        }
    }

    .search-input-container {
        position: absolute;
        right: 10px;
    }
    .search-input {
        margin-right: 10px;
    }
    .dropdown {
        height: 32px;
        float: none;

        &.dt-grid--search {
            margin-left: -5px;

            .dropdown-title {
                background: none;
                box-shadow: none;

                &:hover {
                    background: $hoverBg;
                }

                &:after {
                    border-top-color: $black;
                }
            }
        }
    }
    .dt.expired {
        .dt-cell {
            box-sizing: border-box;
            &--name {
                flex-grow: 10;
            }
        }
    }
    .dt.expiring, .dt-grid.expiring{
        .dt-cell {
            &--actions{
                flex-grow: 2;
                text-align: center;
                min-width: 150px;
                .button-link {
                    margin-right: 5px;
                    .button--action {
                        height: 18px;
                    }
                }
            }
        }
    }

    .dt {
        .assets-list__item__preview__image {
            display: block;
            max-width: 60px;
            width: 60px;
            max-height: 45px;
            height: 45px;

            @include centeredSmallThumbnail();

            .asset-image-wrapper {
                &.is-smallIcon--wrapper {
                    justify-content: flex-start !important;
                }
            }

            .restrictedDownload,
            .restrictedContent {
                position: relative;
                &:before {
                    background-size: 58px;
                }
            }
        }
        .dt-cell--actions {
            .dropdown-title {
                margin-top: 8px;
            }
            .button--action {
                &.linkText {
                    font-family: $BrandRegular;
                }
            }
        }
        .dt__sort-controls {
            .dt-cell--checkbox {
                padding-right: 0;
            }
        }
    }
    .dt__items {
        .dt-cell {
            box-sizing: border-box;
        }
    }

    .workspace-nav {
        margin: 12px -12px 0;
        display: flex;
        flex-wrap: wrap;

        .workspace-nav-item {
            min-height: 88px;
            padding: calc(#{$indentBasis} * 2);
            border-radius: 12px;
            border: $lightBorder;
            background: $white;
            box-shadow: $grayShadow1;
            box-sizing: border-box;
            position: relative;
            text-decoration: none;
            display: block;
            transition: border-color, box-shadow .3s;

            &:hover {
                text-decoration: none;
                border-color: $blue;
                box-shadow: $grayShadow2;
            }
    
            &__title {
                margin-bottom: $indentBasis;
                font-size: 16px;
                line-height: 24px;
                color: $blue;
                display: flex;
                align-items: center;

                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }

                svg {
                    width: 16px;
                    margin-left: 4px;
                    path {
                        fill: $blue;
                    }
                }
            }

            .counter {
                padding: 3px 0;
                color: $greyDefault;
                line-height: 1.4rem;
            }

            .preload-counter {
                .counter {
                    @extend %preload;
                }
            }

            .counter_icon {
                svg {
                    width: 14px;
                    height: 14px;
                    path {
                        fill: $greyDarker3
                    }
                }
            }

            .workspace-nav-counter {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                color: $white;
                background-color: $blue;
                font-family: $BrandMedium;
                font-size: 10px;
                line-height: 24px;
                text-align: center;
                position: absolute;
                top: -8px;
                right: 8px;
            }
        }
    }
}

//temporary I suppose
.cabinet-header__dropdown.mac-dropdown.manage {
    button.mac-dropdown__trigger {
        border: 1px solid $greyLight7;
        border-radius: 3px;
        background: $white;
        box-shadow: $grayShadow1;
        font-family: $BrandRegular;
        font-size: 14px;
        line-height: 16px;
        color: $blackLight;
        &:hover {
            &:after {
                border-bottom: 5px solid $blackLight;
            }
        }
        &:after {
            border-top: 5px solid $blackLight;
        }
    }
    a {
        font-family: $BrandRegular;
    }
}

.project-workspace-controls {
    justify-content: flex-end;
    .search-input__button {
        width: 35px;
        border: 1px solid $greyLight7;
        border-radius: 0 2px 2px 0;
        background: linear-gradient(180deg, $white 0%, $greyDarker2 100%);
        box-shadow: $grayShadow1;
        svg {
            path {
                fill: $blackLight;
            }
        }
        &:after {
            content: none;
        }
        &:hover {
            background: $greyDarker2;
            border-color: $greyDarker2;
        }
    }
    .input-pair__title-box {
        display: none;
    }
    .dropdown-title {
        span {
            color: $blackLight;
            text-transform: capitalize;
            font-family: $BrandRegular;
        }
    }
    .assets_search_control {
        .bulk-actions {
            .dropdown-body {
                button {
                    font-family: $BrandRegular;
                }
            }
        }
        .dropdown-body {
            > div {
                &:hover {
                    background: transparent;
                }
            }
            label {
                 &:hover {
                     background: $greyDarker2;
                 }
            }
            .base-radio {
                display: block;
                margin: 0;
            }
            .base-radio__icon {
                display: none;
            }
        }
    }
}

.cabinet-controls {
    > div:last-child.search-input {
        flex-grow: 0;
    }
}


.content-store-search-assets-modal {
    &--table-container {
      .dt--flex-mode {
          .dt-cell {
              box-sizing: border-box;
              min-width: auto;
          }
      }
    }
}

.cabinet-header__buttons-wrapper.manage {
    .dropdown {
        a {
            margin-bottom: 0;
            &:hover {
                text-decoration: none;
            }
        }
        .copy-url-tooltip {
           top: 40px;
        }
        .dropdown-body {
            right: 0;
            left: auto;
            overflow: hidden;
        }
    }
}

.dt.expiring {
    .dt-cell--actions {
        .button-link {
            .button-link__text {
                white-space: nowrap;
            }
        }
    }
}

@media #{$iPad} {
    .reused-assets-page {
        .dt-cell--c4uCategory,
        .dt-cell--salesBags {
            display: none !important;
        }
    }

    .cabinet-controls > div:last-child.search-input .input-pair__input-box {
        width: 200px;
    }

}

.dt-cell.dt-cell--dateModified,
.dt-cell.dt-cell--dateImported {
    min-width: 140px;
}
.dt-cell.dt-cell--expirationDate {
    min-width: 120px;
}

.dt-cell.dt-cell--actions {
    .button--action.asset-links-trigger {
        svg {
            width: 18px;
        }
    }
}

.dt-cell.dt-cell--archivationDate {
    min-width: 160px;
}

div.infinite_scroll {
    color: $blackLight;
}

.empty-container {
    width: 548px;
    display: block;
    margin: 80px auto 0;
    &__title {
        font-family: $BrandMedium;
        font-size: 24px;
        color: $blackLight;
        margin-bottom: 18px;
    }
    &__description {
        color: $greyDefault;
        font-size: 13px;
        font-family: $BrandRegular;
        line-height: 23px;
    }
}

.dt--assets-table {
    .base-checkbox__icon {
        width: 18px;
        height: 18px;
        margin: 0;
    }
    &.dt {
        .assets-list__item__preview__image {
            display: block;
            max-width: 30px;
            width: 30px;
            max-height: 30px;
            height: 30px;
            margin: 0 auto;
            background: none;

            @include centeredSmallThumbnail();

            .restrictedDownload:before,
            .restrictedContent:before {
                background-size: 35px;
            }
        }
        .dt__sort-controls {
            height: 38px;
            padding: 0 21px;
        }

        .dt__items {
            padding: 0px;
        }

        .dt__item {
            padding: 0 20px 1px;
            border-top: none;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 20px;
                width: calc(100% - 40px);
                height: 1px;
                border-bottom: $classicBorder;
                box-sizing: border-box;
            }
        }

        .dt-cell--doi {
            .assets-list__item__details-link {
                color: $blue;
            }
        }
    }
}

.dt-grid {
    width: 100%;

    .dt-grid__item {
        padding-bottom: 7px;
        padding-top: 7px;
        flex-grow: 0;
        flex-shrink: 0;

        .assets-list__item__preview {
            height: 250px;
            text-align: center;

            &__image {
                height: 100%;
                width: auto;
                text-align: center;
                overflow: hidden;

                img {
                    height: 100%;
                    max-width: none;
                }
            }
        }

        .dt-cell {
            display: flex;
            justify-content: flex-start;
            vertical-align: middle;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 5px;

            .dt-grid--column_name {
                margin-right: 10px;
            }

            &.dt-cell--doi {

                &  > div {
                    display: flex;
                    flex-grow: 1;
                    flex-shrink: 1;
                    justify-content: space-between;
                    align-items: center;
                    vertical-align: middle;
                }
            }

            &.dt-cell--src {
                & > div {
                    width: 100%;

                    .assets-list__item__preview {
                        width: 100%;

                        &__image {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .actions__container {
            position: static;

            .asset-actions-popup {
                top: 0;
                right: 0;
                height: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                width: 100%;
            }
        }

        .asset-actions__item__tooltip {
            display: none !important;
        }

        .asset-actions__item--popup-mode .asset-actions__item__text {
            padding-left: 50px;
        }

        .thumbnail-replace-popup.side {
            right: 0;
        }

        .actions__container--links {
            .asset-actions-popup {
                width: 100%;
            }

            .link-box__description {
                display: none;
            }

            .asset-actions__item__icon {
                padding-left: 0;
            }

            .actions__container__set {
                display: block;

                .link-box__wrapper + .link-box__wrapper {
                    margin-left: 0;
                }
            }

            .copy-url-tooltip {
                left: 0;
            }

            .asset-actions__item--popup-mode .asset-actions__item__text {
                padding-left: 20px;
            }

            .asset-actions-popup__close {
                border-bottom: 0;
            }
        }
    }
}

.isNeo * {
    text-shadow: 0px 0px 3px $saladGreen !important;
    -webkit-text-fill-color: $saladGreen !important;
    background: $black !important;
    border-color: $saladGreen !important;
    color: $saladGreen !important;
}

.isNeo:not(i) {
    font-family: Courier, Arial, sans-serif !important;
}

.isNeo svg path:first-child {
    fill: $saladGreen !important;
}

.isNeo img {
    filter: sepia(74%) !important;
}

.dummy.isScreeWithMobileView {

    .dt-grid__items {
        margin-left: -20px;
    }

    .my-assets-table-container {
        .dt-cell {
            &--actions__container {
                margin-left: 0;
            }
        }
    }

    .my-assets-header {
        position: relative;
        background: none;
        box-shadow: none;

        .my-assets-header__buttons-wrapper {
            display: flex;
            position: relative;

            .bulk-actions {
                margin-right: 0;
            }

            & > * {
                margin-bottom: 5px;
            }
        }
    }
}

.dt__items {
    .dt__item {
        .name-ellipsis {
            @extend %ellipsis;
        }
    }
    .assets-list__item__preview__image {
        .asset-image-wrapper {
            border: $classicBorder;
            border-radius: 2px;
            box-sizing: border-box;
        }
    }
}

@import "./style_responsive";
