.form-list_field {
    .white.button--action:not(.dropdown-title) {
        color: $blackLight;
    }
    .form-list {
        margin: 16px 0;
        border-top: $classicBorder;
    }

    .list-item {
        height: 37px;
        padding: 10px;
        font-family: $BrandRegular;
        font-size: 14px;
        line-height: 21px;
        border-bottom: $classicBorder;
        display: flex;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        transition: background-color 0.2s;

        &:hover {
            background-color: $greyLight5;
            .primary_caption_field,
            .item-close {
                opacity: 1;
                visibility: visible;
            }
        }

        .item-icon {
            width: 15px;
            height: auto;
            margin-right: 10px;
        }

        .item-close {
            position: absolute;
            top: 6px;
            right: 5px;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: all .2s;
        }

        .primary_caption_field {
            position: absolute;
            top: 6px;
            right: 40px;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: all .2s;
        }

        &.subContent-item {
            >svg {
                width: 13px;
                margin-right: 8px;
            }
            .material-icons {
                color: $greyDarker3;
            }
            svg {
                path {
                    fill: $greyDarker3;
                }
            }
            .item-close {
                top: 6px;
            }
        }
    }
    .xml-error {
        color: $red;
        font-size: 12px;
        margin-top: 8px;
        display: flex;
        svg {
            width: 13px;
            margin: 1px 5px 0 0;
            path {
                fill: $red;
            }
        }
    }
}

.xml_field {
    .mac-file__titles {
        display: none;
    }
}