.dt {
    &__item {
        height: 58px;
        max-height: 58px;
    }
    &__sort-controls, &__items {
        .dt-cell--checkbox {
            flex-basis: auto;
            padding: 0;
            min-width: $DT_TINY;
            max-width: $DT_TINY;
            .base-checkbox {
                &__icon {
                    margin-right: 0;
                }
            }
        }
    }
    &__sort-controls {
        .sort-controls__item.dt-cell.dt-cell--unsearchable {
            display: flex;
            padding-bottom: 0;
            align-items: center;
        }
        .sort-controls__item__label.sort-controls__item__label--sortable {
            position: relative;
            padding-right: 20px;
        }
    }
    .assets-list__item__preview {
        height: inherit;
        position: relative;
        text-align: center;
    }
    .assets-list__item__preview__image {
        display: block;
        max-width: 60px;
        width: 60px;
        max-height: 45px;
        height: 45px;
    }
    .assets-list__item__details-link {
        display: block;
    }
    .other-info-box {
        svg, img {
            margin-right: 5px;
            flex-shrink: 0;
            flex-grow: 0;
        }
        svg {
            width: 14px;
            height: 14px;
        }
        &.type {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-family: $BrandMedium;
            &:after {
                right: -8px;
            }
            &:before {
                left: -8px;
            }
        }
    }
    .assets-list__item__type {
        margin-top: 13px;
    }
    .assets-list__item__subscription {
        position: absolute;
        margin: 0 0 0 10px;
        left: 85px;
        bottom: 39px;

        svg {
            path {
                fill: $orange;
            }
        }
    }
}

.dt-cell--doi {
    position: relative;
}

.page .dt .dt__sort-controls .dt-cell--checkbox {
    padding-left: 0;
    padding-right: 0;
}

.dt-grid__items {
    width: 100%;
}

.dt-cell--hidden {
    display: none!important;
}