.copy-link {
    margin-bottom: 15px;
    &__title {
        font-size: 14px;
        font-family: $BrandMedium;
        margin-bottom: 5px;
    }
    &__form {
        display: flex;
        &__url {
            flex-grow: 1;
            border-radius: 3px;
            border: 1px solid $greyDarker2;
            padding: 5px 10px;
            line-height: 20px;
            &:focus {
                outline: none;
            }
            width: 75%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-height: 20px;
        }
        &__submit.button--action {
            margin-left: 15px;
        }
    }

    &.error-link {
        .copy-link__form__url {
            background: $inputDisabledBg;
        }
        .button--action {
            color: $greyDarker !important;
        }
        .copy-link__title,
        .copy_link--error {
            color: $greyDarker3;
        }
    }
}