.replace-asset-modal {
    &.aside-modal {
        .aside-modal__body {
            height: calc(100vh - 52px);
        }
    }
    .upload-content {
        padding-bottom: 0;
    }
    .upload-wrapper__dnd {
        height: 100%;
    }
    .content-file-replacer {
        .file-preview {
            border: none;
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;

            &__button {
                margin-top: -3px;
            }
        }
    }
    .replace-asset-content-modal__error {
        font-size: 14px;
        color: $red;
    }
    .edit_asset_container--buttons {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0;
        button {
            width: 100%;
        }
    }
    .replace-asset-content--link {
        margin-right: 25px;
    }
    .upload-wrapper__dnd--content {
        padding: 0;
        min-height: initial;
        max-height: calc(100vh - 150px);
        overflow: auto;
    }
    .replace-asset-content-modal .compliance_indo_wrapper {
        bottom: 0;
    }
}

@import "./style_responsive";
