.export-to-xls-modal {
    opacity: 1;

    .modal {
        width: 500px;

        .input-pair__input-box {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    &__form {
        .base-checkbox {
            width: 100%;
            margin-right: 0;
            margin-top: 10px;
            &__label {
                text-transform: capitalize;
            }
        }

        .input-pair,
        .input-pair__title-box {
            padding-top: 0;
            text-transform: none;
        }
    }
}

.export-to-xls-modal__block-title {
    margin-bottom: 15px;
}
