.duplicate-preview {
    margin-top: 8px;
    padding-top: 15px;
    border-top: 1px solid $greyDarker2;
    &__title {
        font-family: $BrandMedium;
        font-size: 14px;
        color: $blackLight;
        line-height: 16px;
        margin-bottom: 5px;
    }

    &__message {
        font-size: 14px;
        color: $blackLight;
        line-height: 18px;
        &__link {
            color: $blue;
        }
    }

    &__asset-info {
        .asset-info__file {
            display: flex;
            align-items: center;
            margin-top: 15px;
            &__image {
                display: block;
                height: 50px;
                margin-right: 15px;
            }
            &__name {
                flex-grow: 1;
                font-family: $BrandMedium;
                font-size: 14px;
                color: $blackLight;
                line-height: 16px;
            }
        }

        .asset-info__data {
            padding-left: 51px;
            &__sap-id {
                &__preview {
                    color: $blue;
                    svg {
                        width: 14px;
                        height: 10px;
                        margin-right: 5px;
                        path {
                            fill: $blue;
                        }
                    }
                }

            }
            .data-pair {
                margin-bottom: 5px;
                &__title {
                    font-family: $BrandRegular;
                    text-transform: none;
                    font-size: 14px;
                    color: $greyDarker3;
                    max-width: 120px;
                    min-width: 120px;
                }
                &__value {
                    max-width: 260px;
                    font-size: 14px;
                }
            }
        }
    }
}
