.manage-relations-controls {
    margin: 15px 0 14px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        justify-content: flex-start;
    }

    .button--cancel {
        margin: 0 10px 0 0;
        padding: 0 10px;
    }
}

.aside-modal {
    &.manage-relations-modal {
        font-size: 14px;
        background-color: $greyLight7;

        .relation-gropped-assets {
            background-color: $greyLight7;
        }
        
        .relation-block__btn-wrapper {
            width: 24px;
            margin: 22px auto 0;
            display: block;
        }
    }

    &.manage-relations-modal,
    &.aside-modal-search {
        .preview-modal__body {
            width: 100%;
        }
    
        .preview-modal,
        .preview-modal__body {
            height: 100%;
        }

        .manage-relations-description,
        .assets-list__items,
        .relations-list {
            margin: 0px 4px 18px;
        }

        .assets-list__items {
            margin-top: 4px;
        }

        .outer {
            position: S;
        }
    }
}

.aside-modal.classic-header.aside-modal-search {
    .outer {
        padding-right: 25px;
    }
}

.manage-relations-assets, .relations-list {
    display: flex;
    flex-wrap: wrap;

    &.assets-list__items {
        margin: 0 -5px;
    }
}

.assets-list__item-wrapper {
    padding: 5px 5px;
    box-sizing: border-box;

    &:focus {
        outline-offset: 2px;
    }

    .assets-list__item {
        display: flex;
        position: relative;
        align-items: stretch;
        width: 171px;
        overflow: hidden;
        cursor: pointer;
        border: $classicBorder;
        border-radius: 3px;
        background-color: $white;

        &:nth-child(4n+1) {
            margin-left: 0;
        }

        .asset-usage {
            display: block;
            height: inherit;
            width: 3px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .assets-list__item-close {
            position: absolute;
            top: 5px;
            right: 5px;
            box-sizing: border-box;
        }

        .assets-list__item__info {
            width: 100%;
            padding: 9px 38px 9px 10px;
            background-color: $white;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-sizing: border-box;

            .assets-list__item__doi {
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 17px;
                color: $blue;
            }

            .assets-list__item__name {
                width: 100%;
                font-size: 14px;
                line-height: 18px;
                color: $greyDefault;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &.ready-to-drag {
            cursor: move;
            position: relative;
            border: 1px solid $orange;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: calc(100% - 4px);
                width: calc(100% - 4px);
                border: 2px solid $orange;
                background: $orange;
                opacity: 0.5;
            }
        }
    }


    .assets-list__item__name {
        word-wrap: break-word;
        padding: 0 10px 0 0;
    }

    .assets-list__item__usage {
        align-self: stretch;
        flex-basis: 10px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.relations-list {
    .relation--wrapper {
        width: 100%;
        margin: 0 0 10px;
        min-height: 58px;
        display: flex;
        align-items: flex-start;
        &.relation--invalid {
            .relation-block.relation-block-edge {
                border-color: $red;
            }
        }
    }

    .relation-error {
        width: 100%;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 18px;
        color: $red;
    }

    .relation-block {
        align-items: stretch;
        position: relative;



        .select-tree__search input {
            border: $classicBorder;
        }

        .assets-list__item-wrapper {
            width: 100%;
            padding: 0;
            margin-bottom: 3px;
        }

        .assets-list__item {
            width: 100%;
            border-right: none;
            border-left: none;
        }

        &.relation-block-edge {
            background: $white;
            border: 1px dashed $greyDarker;
            width: 31.75%;
            border-radius: 4px;
            flex-shrink: 0;
        }
        &.relation-block-mid {
            width: 35.5%;
            padding: 11px 20px;
            flex-shrink: 0;
            flex-grow: 0;
            box-sizing: border-box;

            .relation-block-inner {
                position: relative;
            }
        }
    }

    .drop-area-over {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        opacity: 0.5;
        background-color: $orangeLight;
    }

    .relation-gropped-assets {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__placeholder {
            height: 58px;
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $greyLight7;
            color: $greyDarker3;
            box-sizing: border-box;
        }

        .short-asset {
            width: 128px;
            height: 30px;
            box-shadow: $grayShadow1;
            background: $white;
            display: flex;
            margin-bottom: 5px;

            .asset-usage {
                width: 9px;
                height: calc(100% + 1px);
            }

            .doi-value {
                color: $blue;
                line-height: 34px;
                margin: 0 0 0 12px;
                width: 77px;
            }

            .remove-short-asset-button {
                cursor: pointer;
                width: 13px;
                height: 13px;
                align-self: center;
                margin-right: 10px;
                margin-left: auto;

                path {
                    fill: $greyDarker;
                }

                &:hover {
                    path {
                        fill: $greyDefault;
                    }
                }
            }
        }
    }



    .short-icon {
        background-color: $greyDefault;
        border-radius: 4px;
        display: inline-block;
        height: 8px;
        margin-right: 4px;
        width: 8px;
    }
}

.manage-relations-save-error {
    color: red;
}

.manage-relations-clear-one {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;

    svg {
        height: 17px;
        width: 17px;
    }

    path {
        fill: $blue;
    }
}

.apply-master-metadata--container {
    border: $classicBorder;
    border-radius: 3px;
    background: $greyLight7;

    p {
        font-size: 14px;
        line-height: 24px;
        font-family: $BrandRegular;
        color:$greyDefault;
        padding: 10px 15px 0 15px;
        margin-bottom: 10px;
    }

    .button--action {
        width: 100%;
        border: 0;
        border-top: $classicBorder;
        background:$greyLight2;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        color: $blackLight;
        font-family: $BrandMedium;
        box-shadow: none;

        &:hover {
            background: $hoverBg;
        }
    }
}

.manage-relations-modal,
.aside-modal-search {
    .relations-help-body,
    .relations-search-body {
        height: calc(100vh - 80px);
        padding-bottom: 20px;
    }

    .preview-modal__body {
        position: relative;

        .manage-relations-footer {
            width: 100%;
            height: 34px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 3;

            .button--action {
                width: 100%;
                border-radius: 0;
            }
        }
    }
}

.aside-modal-search {
    .aside-modal-search-wrapper {
        .search-results--container {
            padding: 0;
        }

        .search-text-input--wrapper {
            margin-bottom: 18px;
        }

        .grid-view__item {
            margin-bottom: 20px;
        }
    }
}

.manage-relations-help {
    .relations-help-block {
        margin-bottom: 20px;
        font-size: 14px;
    }

    .relations-help-title {
        margin-bottom: 5px;
        font-family: $BrandMedium;
        line-height: 17px;
    }

    .relations-help-description {
        line-height: 18px;
    }
}
