.feedback-results {
    &-controls {
        margin-top: 6px;
        margin-bottom: calc(#{$indentBasis} * 2);
        display: flex;
        justify-content: space-between;

        .search-text-input {
            input {
                width: 280px;
            }
        }
    }

    &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &-table {
        .dt-cell--summary span,
        .dt-cell--userId {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }

        .dt-cell--id {
            max-width: 90px;
            flex-basis: 90px;
            min-width: 90px;
        }

        .dt-cell--date {
            max-width: 100px;
            flex-basis: 100px;
            min-width: 100px;
        }

        .dt-cell--functionalityType {
            .dropdown-body {
                width: fit-content;
            }
        }

        .dt-cell--rate {
            max-width: 150px;
            flex-basis: 150px;
            min-width: 150px;
        }

        .dt-cell--details {
            max-width: 65px;
            flex-basis: 65px;
            min-width: 65px;

            .button--action {
                margin-left: $indentBasis;
            }
        }

        .dt-cell--status {
            max-width: 100px;
            flex-basis: 100px;
            min-width: 100px;
        }

        .checkbox-group {
            display: flex;
            flex-direction: column;

            .base-checkbox {
                padding: 9px calc(#{$indentBasis} * 2) 10px;
                display: inline-flex;

                &:hover {
                    background: $hoverBg;
                }
            }

            .base-checkbox__label {
                margin-left: $indentBasis;
                font-family: $BrandRegular;
            }

            .base-checkbox--checked {
                .base-checkbox__label {
                    font-family: $BrandMedium;
                }
            }
        }
    }
}

.feedback-score {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $greyDarker3;

    svg {
        width: 16px;
        height: 16px;
    }

    .unfavorite {
        height: 16px;
        padding-right: 6px;
        path {
            fill: $greyDarker4;
        }
    }

    .favorite {
        height: 16px;
        padding-right: 6px;
        path {
            fill: $orange;
        }
    }
}

.feedback-status {
    padding-left: 12px;
    position: relative;

    &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 5px;
    }

    &.status {
        &--new {
            &:before {
                background-color: $blue2;
            }
        }
        &--in_process {
            &:before {
                background-color: $orange;
            }
        }
        &--processed {
            &:before {
                background-color: $greyDarker2;
            }
        }
    }
}

.feedback-details {
    background-color: #fff;

    &.aside-modal {
        .aside-modal__body {
            padding-bottom: 78px;
        }
    }

    .aside-modal__footer {
        .button--action {
            text-transform: initial;
        }
    }

    .details-wrapper {
        padding-bottom: calc(#{$indentBasis} * 2);
        margin-bottom: calc(#{$indentBasis} * 2);
        border-bottom: $classicBorder;
        padding-right: 2px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    .details-item {
        margin-bottom: calc(#{$indentBasis} / 2);
        font-size: 14px;
        line-height: 24px;
        display: flex;

        .details-label {
            width: 164px;
            margin-right: 12px;
        }

        .details-value {
            flex: 1;
        }
    }

    .requestor-email {
        display: flex;

        button {
            margin-left: 2px;
        }
    }

    .feedback-status {
        padding-left: 10px;
        font-family: $BrandMedium;

        &:before {
            width: 6px;
            height: 6px;
            top: 10px;
        }
    }
}

.feedback-response-note-modal {
    .base-textarea {
        height: 210px;
        box-sizing: border-box;
    }

    .modal__footer__buttons {
        .button--action {
            text-transform: initial;
        }
    }
}

.feedback-results-export-modal {
    .base-modal {
        .base-radio__button {
            display: flex;
            align-items: center;
        }
    }
}