.content-store-groups-wrapper {
    .content-store-groups-widgets {
        margin-top: 15px;
        flex-direction: column;
    }
    .content-store-groups-widget {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .anime-toggle--button {
        margin-right: 10px;
    }
}

.folders-widget-modal.base-redux-modal .base-modal .base-modal--body {
    overflow: inherit;
}
