.asset-preview-container {
    padding: 20px;
    position: relative;

    .asset-modal__subscription {
        position: absolute;
    }

    .asset-usage {
        font: 10px/20px Arial, sans-serif;
        top: 68px;
        left: 20px;
        bottom: inherit;
        position: absolute;
        width: 62px;
    }

    .tabs__panel{
        max-width: 59%;
        padding-top: 60px;
        
        .data-pair {
            .data-pair__title {
                color: $greyDefault;
                font-size: 11px;
                line-height: 1.4em;
                @extend %tahoma-font;
            }

            .data-pair__value {
                color: $defaultColor;
                font: 13px/1.4em Arial, sans-serif;
            }
        }
    }

    .asset-detail__actions-container {
        position: absolute;
        left: 100px;
        top: 70px;
    }

    .asset-detail__notice-container {
        font: 12px Arial, sans-serif;
        top: 100px;
        left: 20px;
        bottom: inherit;
        position: absolute;
        width: 400px;
        background: $redLight;
        padding: 3px 10px;
        line-height: 15px;
    }

    &--aem-legacy {
        .tabs__panel {
            padding-top: 100px;
        }
    }

    .asset-detail__preview {
        box-sizing: border-box;
        top: 65px;
        right: 20px;
        position: absolute;
        max-width: 400px;
        width: 25%;
    }
}

.asset-preview-container,
.asset-detail-container {
    .data-pair__title {
        width: 170px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .asset-actions,
    .asset-button-actions {
        display: flex;
    }

    .data-pair__value {
        word-wrap: break-word;
    }
}

.preview-container{
    width: 100%;
    height: 100%;
    background-color: $greyLight7;

    img {
        max-height: 100%;
        max-width: 100%;
    }
    .video-tooltip {
        display: none;
    }
    &.focused {
        > div:not(.video-tooltip) {
            outline: 2px solid $blueLight !important;
            box-shadow: $whiteShadow;
        }
        .video-tooltip {
            display: block;
            width: 225px;
            background: $black;
            color: $white;
            border-radius: 4px 0 4px 4px;
            position: absolute;
            padding: 5px;
            left: -243px;
            top: 4px;
            text-align: center;
            height: auto;
            word-break: normal;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -10px;
                width: 0;
                height: 0;
                border-top: 16px solid $black;
                border-right: 16px solid transparent;
            }
        }
    }
}
.buttons-panel {
    text-align: center;
    margin-top: 5px;
    display: flex;

    .button--action {
        margin-right: 10px;
        cursor: pointer;
        flex-shrink: 0;
    }
}
