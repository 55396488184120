.playlist-page--wrapper {
    display: flex;
}
.playlists-page {
    width: 100%;
    padding: 0 15px;

    &-search {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        display: flex;
    }

    .search-text-input--container {
        max-width: 305px;
    }

    .playlists-page-header--left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-shrink: initial;
        align-items: center;

        .material-icons {
            font-size: 22px;
        }
    }

    .asset-actions-popup {
        .actions__container.actions__container--links {
            .asset-actions-popup {
                width: 332px;
                right: 206px;
                left: 100%;
            }
        }
    }

    &-items {
        .playlist-page-items--panelHeading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            border-bottom: $classicBorder;
            margin-bottom: 12px;

            .playlist-page-items--sorting {
                font-family: $BrandMedium;
                font-size: 14px;
                line-height: 21px;
                color: $greyDefault;
            }
            .playlist-page-items--count {
               font-size: 12px;
               line-height: 18px;
            }
        }
    }
}

.playlist-item-container {
    border: $classicBorder;
    border-radius: 4px;
    position: relative;
}

.playlist-relations-table {
    .dt-cell--usage {            
        text-transform: capitalize;
    }

    .dt-cell--actions {
        padding: 7px 8px 6px;

        .hovered--action {
            .my-feed-action-btn {
                margin-top: -5px;
            }
        }

        .asset_actions_dropdown {
            & > .dropdown-title {
                margin-top: 3px;
            }
        }
    }
}

.playlist-page {
    .coresap-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -20px;
    }
    .content-store-actions {
        .folder_grid_item--actions-pop-up {
            & > div,
            & > span {
                margin-left: calc(#{$indentBasis} * 2);
            }
        }
        .asset_actions_dropdown {
            .dropdown-title {
                margin-top: 0;
            }
        }
    }

    .backToButton i {
        font-size: 22px;
    }
    .content-store-header {
        background: $greyLight7;
    }
    .content-store-header--bottom {
        align-items: flex-start;
    }
    .folder_grid_item--actions-pop-up {
        display: flex;
    }
    .asset-actions__item--icon-mode {
        .white.button--action:not(.dropdown-title) {
            background-color: transparent;

            &:hover {
                background: $greyDarker2;
            }

            .material-icons {
                font-size: 22px;
            }
        }
    }
    .top-panel-container--left {
        & > div {
            width: 100%;
        }
    }
    .asset-actions-popup {
        right: 0px;
        top: 40px;
    }
    .actions__container--links {
        margin-right: 8px;
    }
    .asset-links-trigger {
        span {
            display: none;
        }
        .material-icons {
            margin-right: 0;
            font-size: 22px;
        }
    }
    .button--action__toolTip.button--action__toolTip--bottom {
        margin-right: 0;
    }
    .dashboard-widget {
        &.related-assets-widget {
            height: 248px;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            flex-direction: column;

            .widget-header {
                padding-left: 24px;
                padding-right: 24px;
            }

            .widget-content {
                flex: 1 1;
                min-height: 300px;
            }

            .my-feed-table--small {
                .dt__sort-controls {
                    display: none;
                }

                .dt-cell--checkbox {
                    display: none;
                }

                .asset-actions-popup--opened-down {
                    .asset-actions-popup {
                        position: relative;
                        width: 100% !important;
                        left: 0 !important;
                        right: auto !important;
                        box-sizing: border-box;
                    }
            
                    .actions__container--links {
                        margin: 0;
                    }
                }
            }

            .dt--assets-table {
                border-top: $classicBorder;
            }

            .nothing-found-message {
                color: $inputBorderColor;
                font-size: 20px;
                line-height: 35px;
            }
        }
    }

    .content-store__thumbnail__icon {
        padding: 0;
    }
}

.content-store-header {
    max-width: $maxLayoutWidth;
    margin: 0 auto;
    box-sizing: border-box;
}
.playlist-page--content {
    max-width: $maxLayoutWidth;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;

    .curators-dashboard-grid {
        width: 100%;
        margin: 0;

        .curators-dashboard-grid-col {
            margin: 0 12px;
        }

       .curators-dashboard-grid-col:first-child {
           width: calc(100% - 450px);
           flex: none;
           margin-left: 0;
        }

        .curators-dashboard-grid-col:last-child {
            width: 450px;
            margin-right: 0;
        }
    }
}

.playlist-preview-page {
    .full-layout--middle {
        max-width: 1075px;
        margin: 15px auto 0;
    }
}

.playlist-preview--wrapper {
    border: $classicBorder;
    border-radius: 4px;
    background-color: $white;
    margin-bottom: 24px;

    .asset-detail__preview {
        width: 100%;

        .spinner {
            width: auto;
        }
    }

    .asset-preview__thumbnail {
        min-height: 0;
    }

    .asset-preview__first {
        margin-bottom: 0;
    }

    .video-js {
        width: 100%;
        height: 100%;
    }

    .vjs-poster,
    .video-js {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .playlist-preview--details {
        padding: 20px 24px;
        background-color: $white;

        .asset-preview__tab:first-child {
            padding-top: 0;
            border: 0;
        }

        &.contentPreview {
            padding-bottom: 20px;
            margin-bottom: 15px;
        }
    }
    .asset-preview__name {
        display: flex;
        justify-content: space-between;
        .material-icons {
            color: $white;
        }
        .button--action {
            flex-shrink: 0;
        }
    }
    .top-panel-container {
        margin-bottom: 10px;
        padding: 0;

        .download-btn {
            margin-right: -5px;
        }
    }
    .playlist-preview--metadataDetails {
        padding-top: 8px;
        margin-top: 15px;
        border-top: $classicBorder;

        .playlist-preview--detailsButton {
            &.opened {
                svg {
                    transform: rotate(90deg)
                }
            }
        }
    }
}

.playlist-list--wrapper {
    max-width: 450px;
    width: 100%;
    background-color: $white;
    border: $classicBorder;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;

    .playlist-list--topSection {
        display: flex;
        align-items: center;
        padding: 10px 8px 10px 15px;
        border-bottom: $classicBorder;
        position: relative;
        z-index: 2;

        .search-results-info--sorting {
            position: relative;
            top: 3px;

            .dropdown-body {
                min-width: 200px;
            }
        }
        .base-checkbox {
            .base-checkbox__icon {
                margin-right: 0;
            }
        }
    }

    .content-preview--listHeader {
        font-size: 14px;
        line-height: 21px;
        color: $greyDefault;
        font-family: $BrandMedium;
        padding: 8px;
    }

    .playlist-list--manage {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    .videos-counter {
        margin-right: 15px;
    }

    .sort-playlist {
        .dropdown-title {
          border: none;
          box-shadow: none;
          font-family: $BrandMedium;
          color: $greyDefault;
          margin-top: 3px;
      }  
    }

    .playlist-list--container {
        margin-top: 1px;
        height: 720px;
        min-height: 400px;
    }

    .manage_order_buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 0px;
        width: 100%;

        .button--action {
            width: 100%;
            border-radius: 0 0 4px 4px;
        }
    }
}

.page--video-embed,  .page--video-embed body {
    height: 100% !important;
    background: $black;
    color: $greyDarker2;

    .playlist_videos--item .playlist_videos--item-content,
    .assets-region__nothing-found,
    i.material-icons {
        color: $greyDarker2 !important;
    }

}

.playlist_videos--dropdown {
    .playlist_videos--item .playlist_videos--item-content {
        border: 0;
    }

    .playlist_videos--item {
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            margin-right: 0;
        }

        .playlist_videos--item-preview {
            border-radius: 4px !important;
            @include centeredSmallThumbnail();

            &:before {
                border-radius: 4px !important;
            }

            img {
                border-radius: 4px !important;
            }
        }

        .top-panel-container {
            display: none;
        }

        .playlist_videos--item-content h4 {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .dropdown-body {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 451px;
        max-width: 100vw;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: none;
        border-color: $black;

        .dropdown-body__content {
            height: 100%;
        }

        .dropdown-body__content__items {
            height: 100%;

            & > * {
                padding: 0;
            }

            & > *:hover {
                background: transparent;
            }
        }

        .playlist_videos--header {
            padding: 10px;
            color: $greyDarker2;
            border-bottom: 1px solid $greyDefault;
            position: relative;

            .playlist_videos--close {
                position: absolute;
                top: 13px;
                right: 14px;
                width: 24px !important;
                height: 24px !important;
                line-height: 24px !important;
                padding: 5px !important;
                svg {
                    width: 18px !important;
                    height: 18px !important;
                    path {
                        fill: $white !important;
                    }
                }
                .material-icons {
                    font-size: 20px !important;
                }
                &:hover {
                    background-color: transparent !important;
                }
            }

            .count {
                position: absolute;
                bottom: -30px;
                right: 15px;
                font-size: 12px;

                b {
                    font-family: $BrandMedium;
                }

                span {
                    font-family: $BrandRegular;
                }
            }

            h2 {
                color: $greyDarker2;
                font-size: 18px;
                font-family: $BrandMedium;
            }
        }

        .playlist_videos--body {
            height: calc(100% - 84px);
            padding: 22px 0;
            box-sizing: border-box;
        }

    }

    &-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }
}

.playlist_videos--info {
    position: absolute;
    bottom: -10px;
    left: -10px;
    font-size: 14px;
    line-height: 14px;
    display: block;
    text-align: center;
    width: 42px;
}

.playlist-video-preview {
    width: 100%;
    height: 100%;
    position: relative;
}

.playlist_videos--item {
    padding: 8px 15px 8px 0;
    display: flex;
    align-items: center;
    height: 90px;
    margin: 0 0 2px 0;
    position: relative;
    &:hover {
        cursor: pointer;
        .playlist-actions {
            & > .actions__container {
                display: block !important;
            }
        }
    }
    &_checkbox {
        position: absolute;
        top: 18px;
        left: 40px;
        z-index: 1;
        .base-checkbox__icon {
            margin-right: 0;
        }
    }

    .playlist-actions {
        position: absolute;
        right: 25px;
        top: 16px;
        margin-top: 0px;

        * button {
            cursor: pointer;

            * {cursor: pointer}
        }
        & > .actions__container:not(.is-opened) {
            display: none;
        }
        .asset_actions_dropdown {
            .dropdown-title {
                margin-top: 0;
                .material-icons {
                    color: $greyDefault;
                }
            }
        }
    }

    &.is_playing {
        background-color: $greyLight5;

        &_embed {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .playlist_videos--item-preview {
            position: relative;
            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $blue;
                opacity: 0.5;
                content: '';
            }
        }
    }
    &.manage_item {
        .playlist_videos--item-preview:before {
            display: none;
        }
        .playlist_videos--item--index {
            width: initial;
        }
        .playlist_videos--item_checkbox {
            left: 48px;
        }
    }
    .playlist_videos--item--index {
        margin: 0 4px;
        font-size: 14px;
        width: 22px;
        text-align: center;
        display: flex;
        justify-content: center;
        color: $greyDarker;
        svg {
            width: 20px;
            height: 20px;

            path {
                fill: $blue;
            }
        }
    }
    .language {
        .material-icons {
            color: $greyDefault;
        }
    }
    .manage_order_icon {
        svg {
            width: 14px;
            path {
                fill: $greyDarker4;
            }
        }
    }
    .playlist_videos--item-preview {
        height: 100%;
        width: 160px;
        border-radius: 4px 0 0 4px;
        overflow: hidden;

        @include centeredSmallThumbnail();
        
    }
    .playlist_videos--item-content {
        padding: 12px calc(#{$indentBasis} * 2) $indentBasis;
        color: $blue;
        flex: 1;
        background-color: $white;
        height: 100%;
        border: $classicBorder;
        border-radius: 0 4px 4px 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        &.embed {
            background-color: initial;
        }

        h4 {
            font-size: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 16px;
        }
        &__description {
            color: $greyDefault;
        }

        .search-item-content--other-info {
            margin-top: calc(#{$indentBasis} * 2);
        }
    }

    &-draggable {
        & * {
            cursor: move;
        }

        &.isOver {
            * {visibility: hidden}
        }
    }
}

.add-to-playlist-modal {
    &.folders-modal {
        .folder-button-wrapper {
            position: relative;

            .material-icons {
                font-size: 17px;
            }

            &:after {
                content: '';
                position: absolute;
                height: 16px;
                left: 34px;
                border-left: 1px solid $greyDarker2;
                display: block;
                top: 23px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.playlist_videos--item .playlist-actions .asset-actions-popup--opened-down .asset-actions-popup,
.playlist-page .dashboard-widget.related-assets-widget .my-feed-table--small .asset-actions-popup--opened-down .asset-actions-popup {
    padding-left: 0;
    margin-bottom: 5px;
    box-shadow: none;
    border-bottom: $classicBorder;
}

.asset-preview__first--wrapper {
    display: flex;
}

.playlist-info-modal {
    .playlist-modal--actions {
        display: flex;
        align-items: center;
        vertical-align: center;
        margin-right: 3*$indentBasis;
    
        >.actions__container {
            margin-left: 10px;
        }
    
        .view-playlist {
            font-size: 14px;
            margin-right: calc(#{$indentBasis} * 2);
            flex: none;
            &:focus {
                outline-offset: 2px;
            }
        }
    }

    .playlist_videos--item {
        .playlist_videos--item-preview {
            border-radius: 4px;
        }


        .playlist_videos--item-content {
            height: 100%;
            display: flex;
            align-items: center;
            vertical-align: middle;
        }
    }

    .asset-preview__metadata {
        .playlist_videos--item-content {
            border: none;
        }
    }
}

.playlist-info-modal .aside-modal__header,
.playlist-info-modal .asset-preview__heading {
    display: none !important;
}

.playlist-info-modal.aside-modal .aside-modal__body {
    padding: 0 !important;
    height: 100vh;
}

@import "./style_responsive";
