.asset-preview-opened {
    .carousel-tree {
        .carousel-panels--inner {
            margin-right: 600px;
            width: calc(100vw - 600px) !important;
        }
    }
}

.content-store-header--right {
    .bulk-actions--dropdown {
        margin-top: -12px;
    }
}

@media #{$IE} {
    .add_dropdown .button--action__toolTip {
        right: 9px;
    }
}

.full-layout--middle.carousel-tree {
    position: relative;
    z-index: 3;

    .custom-carousel {
        position: relative;
        z-index: 1;
    }
    .content-store-header {
        padding-top: 7px;
        padding-bottom: 7px;
        height: 46px;
        border-bottom: 1px solid $greyDarker2;
        align-items: center;
        vertical-align: middle;
        position: relative;
        z-index: 2;

        .content-store-header--action-buttons {
            padding-top: 5px;

            & > div {
                margin-left: 5px;
            }

            .search-text-input--container,
            .button--action,
            .dropdown  {
                margin: 0 0 0 5px;
            }
            .actions-wrapper, .saved-searches-dropdown {
                .button--action {
                    margin: 0;
                }
            }
        }

        .search-text-input--container {
            margin-right: 7px;
        }

        .search-text-input-autocomplete {
            width: 300px;
            right: 0;

            .button--action {
                width: 100%;
                white-space: normal;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &--left {
            min-height: 0;
        }

        .content-store-description {
            margin-top: 0;
            align-items: center;

            .info {
                .title {
                    font-family: $BrandRegular;
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .content-store__thumbnail__icon {
                width: 32px;
                height: 32px;
            }
        }
    }

    .tabs.custom-carousel {
        height: auto;
        width: 100vw;

        .carousel-panels {
            .carousel-panel {
                background: $greyLight6;
                border-top: 0;
                border-right: 0;
                padding-top: 0;
                padding-bottom: 0;

                .infinite_scroll {
                    height: 0;
                    overflow: hidden;
                    margin-bottom: -10px;
                }
                .infinite_scroll_loader--inner {
                    height: 0;
                    text-indent: -9999px;
                }

                &:first-child {
                    border-left: 0;
                }

                &:not(.no_caruseled).active {
                    z-index: 102 !important;
                }

                .carousel-scroll  {
                    .carousel-panel-target {
                        height: 100%;
                        width: 100%;
                        box-sizing: border-box;

                        &:not(.carousel-panel-target--is-empty-space) {
                            .carousel-panel-target--text {
                                display: none;
                                color: $blue;
                                text-align: center;
                                padding-top: 35px;
                                font-size: 14px;
                            }
                        }

                        &.carousel-panel-target--is-over {
                            border: 2px dashed $blue;

                            .carousel-panel-target--text {
                                display: block;
                            }

                            .empty_folder {
                                display: none;
                            }
                        }
                    }



                    &--inner {
                        padding: 0;
                        opacity: 1;
                        position: relative;

                        .carousel-items {
                            & > * {
                                &.carousel-panel-target.carousel-panel-target--is-empty-space {
                                    height: 0;
                                    color: $blue;
                                    font-size: 14px;
                                    line-height: 70px;
                                    text-align: center;
                                    overflow: hidden;
                                    box-sizing: border-box;
                                    transition: height 0.5s;
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                    background: $greyLight6;
                                    z-index: 1;

                                    &.carousel-panel-target--is-dragging-item {
                                        height: 70px;
                                        border: 2px dashed $blue;

                                        &.carousel-panel-target--is-over {
                                            background: $hoverBg;
                                        }
                                    }

                                    &.carousel-panel-target--is-asset_on_top_folder,
                                    &.carousel-panel-target--is-from-same-deep {
                                        display: none;
                                    }
                                }
                            }
                        }

                        & > div > .react-contextmenu-wrapper {
                            height: calc(100vh - 120px);
                            position: relative;
                            z-index: 2;

                            & > div {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel_item {
        display: block;
        cursor: pointer;
        position: relative;

        &.active {
            background: $hoverBg;
        }

        &:hover {
            background: $hoverBg;
        }

        &.carousel_asset--dragging {
            background: transparent !important;

            * {
                visibility: hidden;
            }
        }

        &--is-over {
            background: $hoverBg;

            &.carousel_item--is-swiping {
                * {
                    visibility: hidden;
                }
            }
        }

        &--is-swiping {
            background: transparent !important;
        }

        &--inner {
            display: flex;
            justify-content: flex-start;
            vertical-align: top;
            padding: 10px 30px 10px 30px;
        }

        &--checkbox {
            width: 16px;
            height: 16px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 10px;
            visibility: hidden;
        }

        &.selectable-area {
            .carousel_item--checkbox {
                visibility: visible;
            }
        }

        &--thumbnail {
            width: 25px;
            height: 25px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 5px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
            svg {
                margin-top: 5px;
                @media #{$IE} {
                    margin-top: 2px;
                }
            }
        }

        &--content {
            word-break: break-all;
            overflow: hidden;
        }

        &--name {
            color: $blue;
            font-size: 16px;
            line-height: 18px;
            margin: 5px 0;
            @media #{$IE} {
                margin-top: 1px;
            }
        }

        &--description {
            font-size: 13px;
            color: $greyDefault;
            line-height: 18px;
        }

        &--children, &--child, &--assetInfo {
            display: flex;
            align-items: center;
        }

        &--children {
            margin-top: 5px;
        }

        &--child {
            svg {
                margin: 0 5px 2px 0;
                path {
                    fill: $greyDarker3;
                }
            }
            span {
                font-size: 13px;

            }
            .material-icons {
                margin-right: 4px;
                font-size: 15px;
                color: $greyDarker3;
            }
            &:last-child{
                margin-left: 20px;
            }
        }

        &--name, &--assetInfo span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &--assetInfo {
            padding: 0 12px;
            border-right: 1px solid $blackLight;
            flex-shrink: 0;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
                border-right: none;
            }
            svg, img {
                margin: 0 5px 1px 0;
            }
            img {
                width: 14px;
                height: 14px;
            }
            span {
                max-width: 58px;
                white-space: nowrap;
            }
            &.Public svg path {
                fill: #398C2B;
            }
            &.Internal svg path {
                fill: $orange;
            }
            &.Partner svg path {
                fill: $blue;
            }
            &.Customer svg path {
                fill: $blue;
            }
        }

        .not-empty {
            position: absolute;
            top: 50%;
            margin-top: -9px;
            right: 10px;

            svg {
                width: 5px;
                height: 8px;
            }
        }

        &.playlist {
            .carousel_item--inner {
                padding-left: 55px;
            }
            .carousel_item--thumbnail {
                margin-top: 5px;
                .material-icons {
                    font-size: 18px;
                    color: #3175C5;
                }
            }
        }
    }
}

.button--action.carousel-asset__detail--close {
    margin-left: auto;
    border-radius: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;

    path{
        fill: $blackLight;
    }
}

.carousel_asset_details {
    position: relative;
    z-index: 1;

    .preview-asset-modal {
        position: absolute;

        .preview-modal {
            border-top: 0;
        }

        .statistic-container {
            .statistic-container--left .other-info-box:last-child {
                display: none;
            }

            .statistics-content .statistics-chart {
                width: 380px;
            }
        }

        .video-js {
            width: 398px !important;
            height: 210px !important;
        }
    }

    .preview-modal__header__nav-tabs,
    .preview-modal__header {
        display: none;
    }

    .tabs {
        height: auto;
        margin-top: 0 !important;
    }

    .asset-preview {
        width: calc(100% - 20px);
        overflow: auto;

        .asset-preview__section:first-child {
            h2 {
                display: none;
            }
        }
    }

    .asset-preview__thumbnail {
        height: 210px;
        min-height: 210px;

        .asset-detail__preview {
            height: 210px;
        }
    }
}

.empty_folder {
    height: 100%;

    &--drop-zone {
        height: 100%;
        padding: 35px 20px 20px;
        text-align: center;
        font-size: 13px;
        border: 2px dashed transparent;
        box-sizing: border-box;

        &:hover {
            border-color: $blue;
        }

        p {
            margin-bottom: 10px;
        }

        b {
            font-size: 14px;
            font-family: $BrandMedium;

        }
    }
}

.carousel-items {
    .search-results-info--content-actions {
        padding: 3px 30px 0 30px;
        border-bottom: 1px solid $inputBorderColor;
    }
    .search-results-info--sorting {
        margin-left: 10px;
    }
}

.search-results-info--sorting .dropdown-body__content__items {
    .button--action.selected {
        background-color: $greyLight2 !important;
        font-family: $BrandMedium !important;
        color: $greyDefault !important;
    }
}

@import "./style_responsive";





