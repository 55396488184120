$assetWidth: 200px;


.asset-expandable-wrapper {
    display: inline-block;
    width: 200px;
    margin-right: 10px;
    position: relative;
    height: 184px;

    .asset-expandable {
        position: absolute;
        border: 1px dashed $greyDefault;
        background: $white;
        width: $assetWidth;
        height: 184px;
        box-sizing: border-box;

        &__expand--button {
            position: absolute;
            right: -1px;
            bottom:-1px;
            border:3px solid $greyDarker4;
            border-right-width: 2px;
            border-bottom-width: 2px;
            background-color: $greyDarker4;

            button {
                text-indent: -9999px;
                width: 100%;
                height: 100%;
            }
        }

        &__select-asset {
            position: absolute;
            bottom: 5px;
            left: 5px;
        }

        &__type {
            position: absolute;
            top: 5px;
            right: 5px;
            background: $greyLight7;
            text-transform: uppercase;
            line-height: 13px;
            padding: 0 8px;
            font-size: 10px;
        }

        &__header {
            position: absolute;
            padding: 0 10px;
            text-transform: none;
            font-size: 13px;
            line-height: 25px;
            height: 25px;
            top: -1px;
            left: -1px;
            width: 200px;
            box-sizing: border-box;
            text-align: left;
        }

        &__content {
            padding: 35px 9px 20px 9px;
            font-size: 10px;
            line-height: 12px;
            white-space: normal;
            word-break: break-all;

            &-row {
                margin-bottom: 5px;
                &--label {
                    display: inline-block;
                    text-transform: uppercase;
                    color: $greyDefault;
                    margin-right: 5px;
                    font-family: $BrandBold;
                    font-weight: bold;
                    font-size: 9px;
                }

                &--text {
                    display: inline;
                }
            }

            &-dates-row {
                padding-top: 3px;
                display: flex;
                position: absolute;
                width: 180px;
                bottom: 10px;
            }

            &__info-item {
                display: inline-block;
                width: 50%;
                font-size: 9px;

                &__title {
                    display: block;
                    text-transform: uppercase;
                    font-family: $BrandBold;
                    font-weight: bold;
                }

                &__text {
                    font: normal 11px Arial, sans-serif;
                    color: $defaultColor;
                }
            }
        }
    }

    &.dragging, &.selected {
        .asset-expandable {
            border: 4px solid $orange;
            background: $orangeLight;

            .asset-expandable__expand--button {
                right: -4px;
                bottom: -4px;
            }

            &__header {
                width: 192px;
                top: 0;
                left: 0;
                padding: 0 6px;
                line-height: 18px;
                height: 21px;
            }

            &__type {
                top: 2px;
                right: 2px;
            }

            &__content {
                padding: 32px 6px 17px 6px;

                &-dates-row {
                    bottom: 7px;
                }
            }
        }
    }
}

