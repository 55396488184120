.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;

    .auth-page-header {
        align-self: stretch;
    }
}

.page-not-found__content {
    display: flex;
    margin: 80px 0 0;
    width: 485px;
}

.page-not-found__code-number {
    margin: 0 23px 0 0;
    font-size: 60px;
    line-height: 47px;
    color: $greyDarker4;
}

.page-not-found__code-text {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    &-description {
        padding: 0 0 0 2px;
    }
}

.auth-page-header {
    display: flex;
    align-items: center;
    background: $white;
    border-bottom: 10px solid $orange;
    height: 55px;
    margin: 0 10px;

    .auth-page-header__text {
        font-size: 22px;
        color: $black;
        font-family: $BrandMedium;
    }
}

.auth-page__subheader {
    color: $red;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.auth-page__message {
    color: $greyDefault;
    font-size: 13px;

    &--highlighted {
        background: $redLight;
        font-size: 14px;
        padding: 25px 40px 29px 30px;
        line-height: 22px;
    }
}

//ACCESS DENIED PAGE
.page-access-denied {
    display: block !important;

    &__content {
        display: flex;
        justify-content: center;
    }

    &__info {
        padding: 37px 0 0;
        width: 400px;
    }

    .info__header {
        color: $red;
        font-weight: bold;
        font-size: 14px;
    }

    .info__text {
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: $greyDefault;
        padding: 30px;
        background: $redLight;
        margin: 18px 0 38px;
    }

    .info__tooltip {
        font-size: 13px;
        text-align: center;
    }
}
