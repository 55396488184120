.material-icons {
    color: $blackLight;

    &:after {
        content: attr(data-material-icon);
    }
}

@import "./colors";
@import "./sizes";
