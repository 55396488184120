@mixin previewResolution($width) {
    .video-js {
        width: $width !important;
        height: calc(#{$width} / #{$previewRatio}) !important;
    }

    .asset-preview__thumbnail .asset-detail__preview {
        width: $width;
        height: calc(#{$width} / #{$previewRatio});
    }

    .asset-content-preview-page .preview-container,
    .asset-content-preview-page .pdf-preview-container-new {
        width: calc(#{$width} - 20px);
        height: calc(#{$width} / #{$previewRatio});
    }
    .page--video-embed .video-js {
        width: 100% !important;
        height: 100% !important;
    }
}

.carousel-asset__detail {
    .asset-actions-popup {
        width: 285px;
        left: 55px;

        .button--action {
            white-space: normal;
            height: auto;
            line-height: 18px;
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

@media #{$mobile} {
    @include previewResolution($width:300px);

    .asset-preview {
        width: 100%;
        box-sizing: border-box;
        margin-top: 50px;
        padding: 5px 15px 70px 15px;
        height: calc(100% - 70px);
    }

    .page.asset-preview-page .preview-modal__body {
        padding: 0 15px;
    }

    .preview-asset-modal {
        .preview-modal__body {
            .preview-modal__header__nav-tabs {
                display: none;
            }
        }
        .asset-actions-popup {
            margin-top: 0;
        }
    }

    .asset-preview__thumbnail {
        min-height: 0;
    }

    .asset-detail__preview {
        height: auto;
    }

    .statistic-container .statistics-content {
        .dropdown-body {
            right: 0;
            z-index: 1001;
        }
        .statistics-chart {
            width: 100%;
            height: auto;
        }
        .target-element {
            overflow-y: initial;
        }
    }

    .asset-preview__title {
        width: 128px;
    }
    .preview-asset-modal .preview-modal__body .notice-container .message {
        line-height: 16px;
    }

    .preview-asset-modal .preview-modal__body .preview-modal__header.edit_asset_container--header {
        padding-left: 38px;
    }
    .page.asset-preview-page .asset_preview_page--content {
        padding-top: 0;
    }

}

@media #{$iPhone678_P} {
    @include previewResolution(375px);
}

@media #{$iPhone5s_P} {
    @include previewResolution(320px);

    .preview-asset-modal .preview-modal__body .notice-container .message {
        font-size: 12px;
        line-height: 14px;
    }

    .dropdown-body__content__items .statistics-chart {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 320px) {
    .dropdown-body__content__items .statistics-chart {
        padding-left: 0;
        padding-right: 0;
    }
    .statistic-container {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;

        .statistics-modal-link {
            .statistics-content {
                .button--action {
                    margin-left: -10px;
                }
            }
        }
    }
}

@media only screen
and (min-device-width : 411px)
and (max-device-width : 412px)
and (orientation : portrait) {
    @include previewResolution(412px);
}

@media only screen
and (min-device-width : 359px)
and (max-device-width : 360px)
and (orientation : portrait) {
    @include previewResolution(360px);
}

@media only screen
and (min-device-width : 479px)
and (max-device-width : 480px)
and (orientation : portrait) {
    @include previewResolution(480px);
}

@media only screen
and (min-device-width : 345px)
and (max-device-width : 346px)
and (orientation : portrait) {
    @include previewResolution(346px);
}

@media only screen
and (max-height: 1000px) {
    .asset-preview__content {
        min-height: 700px; //~ actions menu height
    }
}

