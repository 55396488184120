.asset-usage {
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    color: $white;
    box-sizing: border-box;
}

.asset-icon {
    display: inline-block;
    margin-right: 5px;

    svg {
        polygon,
        path {
            fill: $white;
        }
    }
}

.dt .asset-icon {
    margin-left: 5px;

    svg {
        height: 11px;
        width: 13px;

        polygon,
        path {
            fill: $greyDefault;
        }
    }
}

.assets-grid__item {
    .asset-icon {
        margin-left: 5px;
        svg {
            height: 11px;
            width: 13px;
        }
    }
}

.dt-cell--src .asset-usage .asset-icon {
    display: none;
}

.dt-cell--src {
    .asset-usage {
        position: absolute;
        font-size: 10px;
        line-height: 20px;
        top: 49px;
        margin-left: 20px;
        width: 68px;
        transform: rotate(270deg);
        transform-origin: bottom left;
        .private-icon {
            display: none;
        }
    }
}

.asset-usage--partner {
    @extend %usage--partner;
}

.asset-usage--customer {
    @extend %usage--customer;
}

.asset-usage--direct {
    @extend %usage--direct;
}

.asset-usage--public {
    @extend %usage--public;
}

.asset-usage--internal {
    @extend %usage--internal;
}

.asset-usage--brand {
    @extend %usage--brand;
}

.asset-usage--jam {
    @extend %usage--jam;
}
