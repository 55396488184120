.material-icons {
    &.md-dark {color: $blackLight}
    &.md-light {color: $white}
    &.md-gray {color: $greyDarker}
}

//Old icons support
path[fill="none"] {
    fill: none !important;
}
