.mac-dropdown {
    padding: 0 !important;
    width: 100%;
    position: relative;

    .mac-dropdown__trigger{
        color: $greyDarker;
        width: 100%;
        padding: 0 10px 0 10px;
        text-align: left;
        border: 2px solid $greyDarker4;
        height: 30px;
        font-family: $BrandRegular;
        font-size: 15px;
        border-radius: 3px;

        &:hover {
            cursor: pointer;
        }
    }

    .mac-dropdown__trigger:after {
        right: 10px;
        top: 13px;
        @extend %arrow-down;
    }

    .mac-dropdown__body {
        transition: max-height 0.2s ease-in;
        max-height: 0;
        height: 0;

        .asset-actions__item--container {
            overflow: hidden;
        }

    }
    &--opened {
        .mac-dropdown__body {
            max-height: 100vh;
            height: auto;
            overflow: hidden;
            box-shadow: $dropdownShadow;
        }
        .mac-dropdown__trigger {
            border-bottom: 1px solid $greyDarker4;
            padding-bottom: 1px;
            background: $greyLight7;
        }
        .mac-dropdown__trigger:after {
            @extend %arrow-up;
        }
    }
}

.mac-dropdown__body {
    z-index: 10;
    background: $white;
    position: absolute;
    overflow: hidden;
    top: 30px;
    left: 0;
    font: 12px/12px Arial, sans-serif;
    padding: 0 0 0 11px;
    width: 100%;
    box-sizing: border-box;
}
.mac-dropdown__body__inner {
    padding: 10px 0 10px 0;
}

.cabinet-header__dropdown.mac-dropdown {
    width: auto !important;
    z-index: 1;

    &.grouped {
        .mac-dropdown__body {
            padding: 0;

            &__inner {
                padding: 0;
            }
        }

        .cabinet-header__dropdown_group {
            border-bottom: 1px solid $greyDarker4;
            padding: 10px 15px;

            &.grey-group {
                background: $greyLight7;
            }
        }
    }

    .mac-dropdown__body {
        width: auto;
        left: auto;
        right: 0;
        padding-right: 20px;
        top: 35px;
        min-width: 160px;

        .mac-dropdown__body__inner {
            a, button {
                display: block;
                white-space: nowrap;
                line-height: 15px;
                color: $greyDefault;
                margin-bottom: 8px;
                font-size: 15px;
                font-family: $BrandRegular;
                text-transform: capitalize;

                &:hover {
                    text-decoration: underline;
                }

                &.disabled {
                    color: $greyDarker4;
                    cursor: default;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            a {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            > button {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .mac-dropdown__trigger {
        color: $white;
        background: $blue;
        width: 100px;
        border: 0;
        height: 35px;
        line-height: 35px;
        text-transform: uppercase;
        font-size: 15px;
        font-family: $BrandRegular;
    }

    &--disabled {
      .mac-dropdown__trigger {
        background: $blueLight;
      }
    }

    &.manage {
        .mac-dropdown__trigger {
            font-family: $BrandRegular;
            font-size: 15px;
            font-weight: normal;
            text-transform: none;
            padding: 0 20px;
            width: 120px;
            background: $orange;
            border-radius: 2px;

            &:after {
                top: 16px;
                right: 30px;
                border-top: 5px solid $white;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
            &:hover {
                &:after {
                    top: 16px;
                    right: 30px;
                    border-bottom: 5px solid $white;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                }
            }
        }
    }

    &--opened {
        .mac-dropdown__body {
            border-top: 1px solid $greyDarker4;
        }

        .mac-dropdown__trigger {
            background: $navy;
            padding-bottom: 0;
        }
    }
}
