.manage-playlist--aside {
    .tabs.manage-asset-form {
        .preview-modal__header__nav-tabs {
            visibility: hidden;
        }

        .asset-preview {
            overflow-x: visible;
            overflow-y: visible;
            padding-left: 0;
            padding-right: 0;
        }

        .asset-preview__section {
            .asset-preview__heading {
                display: none;
            }
        }
    }
}
