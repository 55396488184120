.page--admin {
    .global-preferences {
        padding: 0 30px 26px 30px;
        box-sizing: border-box;
        background-color: $pageBgColor;
    }

    .cabinet-header,
    .cabinet-controls {
        background-color: $pageBgColor;
    }
    .cabinet-header-title {
        font-size: 20px;
        line-height: 24px;
        color: $blackLight;
        font-family: $BrandMedium;
    }
}

.dt--user-change-requests--wrapper {
    height: 650px;
}

.system-actions-modal,
.folder-bulk-creation-modal {
    .input-pair--file {
        width: 100%;
    }

    .error-message {
        font-size: 13px;
        line-height: 18px;
        color: $red;
    }
}

.user-change-requests {
    .page-controls__load-more {
        width: auto;
    }
    .page-controls {
        justify-content: center;
    }
}

.global-preferences {
    .asset_actions_dropdown {
        & > .dropdown-title {
            margin-top: 0;
        }
    }
    .dt--content-stores {
        .asset_actions_dropdown {
            & > .dropdown-title {
                margin-top: 6px;
            }
        }
    }
    .dt-cell {
        .rejection-reason-dropdown {
            height: 24px;
        }
    }
    .notifications-container {
        padding-left: 0;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: -1%;
        .notifications--form {
            width: 45%;
            background-color: $greyLight7;
            padding: 10px;

            @media screen and (max-width: 720px) {
                width: 235px;
            }

            &-title {
                margin: 10px;
            }
            &-sub_title {
                @media screen and (max-width: 960px) {
                    display: none;
                }
            }
            &-content {
                width: 240px;
            }
            &-actions {
                display: flex;
                justify-content: space-around;
                margin: 25px 0 15px 0;
            }
        }

        .base-text-input {
            width: 220px;
            margin: 5px 10px 5px 10px;
        }

        .input-pair__input-box__error,
        .error {
            margin-top: -45px;
        }
    }
    .templates {
        .dt--templates {
            margin-top: 20px;
            .dt-cell {
                &--name {
                    width: 35%;
                    color: $black;
                }
                &--description {
                    width: 40%;
                    color: $black;
                }
                &--undefined {
                    width: 25%;
                }
            }
        }
    }
    .report_routing {
        .breadcrumbs {
            line-height: 40px;
        }
        .global-preferences-actions {
            justify-content: flex-start;
            &--button {
                margin-right: 20px;
                svg {
                    width: 15px;
                    height: 15px;
                    fill: $greyDarker4;
                }
            }
        }
        .button{
            &--action {
                float: right;
                position: relative;
                top: -40px;
                text-transform: capitalize;
            }
        }

        .cabinet-header {
            display: block;
            &:after {
                content: '';
                display: block;
                height: 4px;
                width: 108%;
                position: relative;
                top: 15px;
                left: -40px;
                background: $greyLight7;
            }
        }

        .dt--report_routing {
            .dt__items {
                padding: 0 10px;
                background: $greyLight7;
                >div:nth-child(odd) {
                    .dt__item {
                        background: $white;
                    }
                }
                >div:nth-child(even) {
                    .dt__item {
                        background: $greyDarker2;
                    }
                }
                .dt__item {
                    height: auto;
                    border: none;
                    margin-bottom: 0;
                }
            }
            .dt-cell {
                font-size: 12px;
                &--sectionValue {
                    width: 50%;
                    color: $black;
                }
                &--leaderName {
                    width: 25%;
                    color: $black;
                }
                &--leaderEmail {
                    width: 30%;
                    color: $blue;
                }
                &--undefined {
                    width: 10%;
                }
            }
            .sort-controls__item {
                background: $greyLight7;
                border: none;
                justify-content: flex-start;
                border-bottom: 1px solid $greyDarker4;
                button {
                    color: $greyDefault;
                }
                &--active {
                    &:after {
                        border-top-color: $greyDefault;
                        border-bottom-color: $greyDefault;
                    }
                }
                &--desc, &--asc {
                    &:after {
                        position: relative;
                        top: 0;
                    }
                }
            }
            .dt__sort-controls {
                margin: 0;
                background-color: $greyLight7;
                padding: 0 10px;
                button {
                    width: auto;
                }
            }
        }
    }

    .system-actions {
        &__header {
            display: flex;
            flex-wrap: nowrap;

            &-button {
                margin: 10px -10px 10px 0;

                .material-icons {font-size: 20px}
            }
            &-label {
                margin: 10px 10px 10px 0;
                align-self: center;
            }
        }

        .base-textarea, .base-text-input {
            background: $greyDarker2;
        }

        &__body {
            margin-left: -1%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &--block {
                padding: 10px;
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                &--fullsize {
                    width: 100%;
                }
                .input-pair__input-box {
                    width: 100%;
                    align-self: flex-end;
                }
                .input-pair__select-box {
                    width: 100%;
                    .input-pair__input-box {
                        margin: 0;
                    }
                }
                .button--action__container {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    button {
                        align-self: flex-end;
                    }
                }
                &__file-upload {
                    display: flex;
                    .input-pair--file {
                        padding: 0;
                        .input-pair__input-box {
                            margin: 0;
                            .button--file {
                                background: $blue;
                                color: $white;
                                width: 120px;
                            }
                        }
                    }
                    &--label {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding-left: 10px;
                    }
                }
                &__system-messages {
                    border-bottom: 3px solid $greyDarker4;
                    margin-bottom: 10px;
                    padding-bottom: 10px;

                    &:last-child {
                        border-bottom: none;
                    }

                    .input-pair {
                        display: inline-flex;
                        flex-direction: column;
                        margin-right: 50px;
                    }

                    .input-pair--compact {
                        display: inline-flex;
                        flex-direction: column;

                        &.rich-editor-column {
                            width: 490px;

                            .RichEditor-root {
                                padding-top: 5px;
                            }
                        }

                        .title-box__text{
                            margin-bottom: 7px;
                            text-transform: uppercase;
                            font-weight: bold;
                        }

                        .button--action {
                            margin-top: 40px;
                        }
                    }

                    .input-pair__title-box {
                        width: 150px;
                    }
                    .input-pair__input-box {
                        margin: 0;
                    }
                }
            }
        }
        &__modal {
            height: 0;
        }
        .metadata-values {
            &--actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 50%;
                &-separator {
                    padding: 0 10px;
                    margin: 0;
                }
            }
            &--container {
                display: flex;
                flex-wrap: wrap;
                height: 40vh;
                overflow: auto;
                &-item {
                    width: 40%;
                    padding: 5px;
                    .base-checkbox {
                        display: inline-block;
                    }
                    &-value {
                        &:nth-child(2) {
                            padding-left: 10px;
                            vertical-align: top;
                        }
                    }
                }
            }
            &-export {
                justify-self: flex-end;
                display: flex;
                justify-content: flex-end;
                width: 99%;
                span {
                    margin: 0;
                    padding-right: 10px;
                }
            }
        }
    }

    .users {
        .dt--users {
            .dt-cell {
                &--id {
                    width: 10%;
                    color: $black;
                }
                &--login {
                    width: 15%;
                    color: $black;
                }
                &--fullName {
                    width: 15%;
                    color: $black;
                }
                &--email {
                    width: 23%;
                    color: $black;
                }
                &--isBlocked {
                    width: 10%;
                    color: $black;
                }
                &--role {
                    width: 15%;
                    color: $black;
                }
                &--undefined {
                    width: 12%;
                }
            }
            .asset-actions-trigger {
                margin: 0 auto;
            }
            .user-id {
                margin-left: -15px;
            }
        }
        &__header {
            display: flex;
            flex-wrap: nowrap;
            .users__button--action {
                margin: 10px 10px 10px 0;
            }
            .insight-path-mac-search-input {
                align-self: center;
            }
        }

        .cabinet-controls {
            align-items: flex-start;

            .button--action.users__button--action {
                margin-top: 5px;
            }

            .search-input {
                flex-grow: 1;
            }
        }
        .actions__container {
            z-index: auto;
        }
    }

    .users, .user-change-requests {
        .insight-path-mac-search-input--wrapper {
            flex-grow: 1;
            .input-pair {
                margin-left: 0;
            }
            .input-pair__input-box {
                width: 320px;
            }
        }
    }

    .user-change-requests {
        .cabinet-controls {
            justify-content: flex-start;
        }
        .ownership-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            svg {
                width: 14px;
                margin: 0 15px;
                path {
                    fill: $blue;
                }
            }
        }
        .request-info {
            &--icon {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-right: 10px;
                display: inline-block;
                margin-bottom: 2px;
                &.Rejected {
                    background-color: red;
                }
                &.Awaiting {
                    background-color: $orange;
                }
                &.Approved {
                    background-color: $blue;
                }
                &.Applied {
                    background-color: $saladGreen;
                }
            }
        }
        .dt--user-change-requests {
            .dt-cell {
                color: $greyDefault;

                &.dt-cell--priority-8 {
                    min-width: 300px;
                    flex-shrink: 0;
                }

                &.sort-controls__item{
                    button {
                        white-space: pre-line;
                    }
                }
                &--undefined {
                    .modal-backdrop {
                        z-index: 1;
                    }
                }
                .view-request-details {
                    display: flex;
                    justify-content: flex-end;
                    &-button{
                        text-transform: none;
                        margin-left: -20px;
                    }
                }
            }
        }
        &__header {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;
        &--separator {
            color: $greyDarker4;
        }
        &--button {
            color: $blue;
        }
        .modal-positioner {
            z-index: 1;
        }
    }

    .user-statistics {
        display: flex;

        & > div {
            margin-right: 100px;
        }
        margin-bottom: 20px;
    }
}
.global-preferences__modal {
    .modal-positioner {
        z-index: 1;
    }
}

.user-change-requests-info-modal {
    &--header {
        margin-left: auto;
        margin-right: auto;
        width: 38%;
        font-size: 18px;
        color: coral;
    }
    &--body{
        padding: 20px 5px 2px 25%;
        .input-pair {
            padding: 0px;
            &__value-box {
                padding-top: 6px;
                width: 40%;
            }
        }
    }
    &--text {
        font-size: 12px;
        text-transform: none;
    }
}




$modalWidth: 630px;
$borderColor: $greyDarker4;


.add_new_insight_profile_modal {

    .modal__content {
        max-height: 450px;
        overflow: hidden;
        overflow-y: auto;
    }

    .input-pair {
        &__input-box {
            position: relative;

            .RichEditor-root-inner {
                width: 100%;
                .RichEditor-controls-container {
                    right: 0;
                    top: -50px;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
}

.user_profile {
    .user-preview {
        h3.user-preview-section--title {
            font-size: 15px;
            font-family: $BrandMedium;
            border-top: $classicBorder;
            color: $blackLight;
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            vertical-align: middle;
            align-items: center;

            .asset-actions__item--icon-mode {
                margin-right: -8px;
            }
        }

        .asset-preview-metadata__pair {
            margin-bottom: 15px;
        }
    }
}

.simple-modal-box {
    padding: 20px 30px;
    background: $white;
    border: $classicBorder;
    border-radius: 3px;
    margin: 0 0 1% 1%;
    width: 48%;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    position: relative;

    h3 {
        font-family: $BrandMedium;
        font-size: 14px;
        line-height: 31px;
    }

    h4 {
        font-size: 12px;
        font-family: $BrandRegular;
    }

    &--icon.button--action.noTitle {
        position: absolute;
        top: 0;
        right: 0;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        padding: 0 !important;

        .button--action__toolTip {
            margin-top: -12px;
        }

        &.yellow {
            background: $orange;
        }

        &.green {
            background: $saladGreen;
        }

        &.red {
            background: $red;
        }
    }

    &--tooltip {
        width: 6px;
        height: 6px;
        position: absolute;
        top: 5px;
        right: 5px;
        display: block;
    }

    &--content {
        flex-grow: 1;
    }

    &--controls {
        padding-left: 10%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        width: 70px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.notifications--form-content {
    .notifications--form-input-wrapper {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        >div {
            width: 100%;
        }
        .notifications--form-action {
            margin-left: 15px;
        }
    }
}

.user-assets--modal {
    .search-results--container {
        padding-left: 0;
        padding-right: 0;

        .search-item .bulk-actions-checkbox {
            display: none;
        }

        .grid-view__item {
            margin-bottom: 20px;
        }
    }
}

.service-api-page {
    .view-request-details-button {
        margin: 0 auto;
    }
    .dt {
        .dropdown {
            height: 22px;
        }
    }
}