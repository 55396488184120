.dashboard-widget {
    width: 100%;
    padding: 24px;
    margin-bottom: 16px;
    background-color: $white;
    border: $classicBorder;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;

    &:last-child {
        margin-bottom: 0px;
    }

    &.full-height {
        height: 100%;
        flex: 1;
    }

    .widget-header {
        padding-right: 35px;
        margin-bottom: 23px;
        display: flex;
        align-items: center;

        .curators-dropdown {
            margin-top: 2px;
            margin-left: 8px;
        }
    }

    .widget-title {
        font-family: $BrandMedium;
        font-size: 14px;
        line-height: 21px;
        color: $blackLight;
        white-space: nowrap;
    }

    .fullscreen-btn {
        position: absolute;
        top: 17px;
        right: 13px;
        z-index: 2;
    }

    .header-btn {
        position: absolute;
        top: 17px;
        right: 50px;
        z-index: 2;

        &.button--action:not(.dropdown-title) {
            .material-icons {
                font-size: 18px;
            }
            &:disabled {
                color: $greyDarker;
                .material-icons {
                    color: $greyDarker;
                }
            }
        }

        .button--action__toolTip {
            &.button--action__toolTip--bottom {
                margin-right: 0;
            }
        }
    }
}