@media screen and (max-width: 1000px) {
    .cs-reporting-container {
        .cs-reporting-widgets {
            width: 100%;
            flex-flow: row nowrap;
            flex-wrap: wrap;

            .stub-reporting-widget {
                display: none;
            }

            &--item {
                &:nth-child(2) {order: 3}
                &:nth-child(3) {order: 2}
            }

            .cs-reporting-widgets--item {
                width: calc(100% - 5%)
            }
        }
    }
}

// no more then 2 items in column after reload Action Map data
@media screen and (min-width: 1001px) and (max-width: 1799px) {
    .cs-reporting-container {
        .cs-reporting-widgets {
            &--item {
                &:nth-child(1) {flex: 1 1}
                &:nth-child(2) {flex: 1 1}
            }
        }
    }
}

@media screen and (min-width: 1800px) {
    .cs-reporting-container {
        .cs-reporting-widgets {
            margin-left: -1%;
            max-height: 800px; //!important, no one more in column

            & :nth-child(2) {order: 3}
            & :nth-child(3) {order: 2}

            .cs-reporting-widgets--item {
                width: 27.75%;
                margin-left: 1%;
                margin-bottom: 1%;
            }
        }
    }
    .two-widgets {
        .cs-reporting-container {
            .cs-reporting-widgets--item {
                width: 45%;
            }
        }
    }
    .one-widget {
        .cs-reporting-container {
            .cs-reporting-widgets--item {
                width: 100%;
            }
        }
    }
}

.two-widgets {
    .cs-reporting-widgets {
        > div {
            min-height: 600px;
        }
    }
}
