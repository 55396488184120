$base-margin: 1.5*$indentBasis;

.breadcrumbs {
    font-size: 12px;
    font-family: $BrandRegular;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    vertical-align: middle;
    line-height: 18px;
    padding-top: 5px;

    &--item {
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        line-height: 18px;
        color: $greyDarker3;
        font-family: $BrandRegular;
        padding: $indentBasis 0;

        &:hover {
            color: $greyDarker3;
        }

        .breadcrumbs--link-text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            i {
                color: $blue2;
            }
        }

        svg {
            width: 13px;
            height: 13px;
            margin-right: 5px;
        }
    }

    &--divider {
        margin: 0 4px;
    }

    &--info {
        color: $greyDarker3;
    }

    &--button,
    &--link {
        text-decoration: none;
    }

    .breadcrumbs-dropdown {
        .dropdown-title {
            svg {
                transition-duration: 0.3s;
                transition-property: transform;
                path {
                    fill: $greyDarker3;
                }
            }
        }
        &.opened {
            .dropdown-title {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
        .dropdown-body {
            padding: 2*$indentBasis 3*$indentBasis;
            left: 0;
            width: fit-content;
        }

        .breadcrumbs--item {
            color: $greyDarker3;
            font-size: 12px;
            min-width: initial;
            @for $i from 1 through 50 {
                &:nth-child(#{$i}) {
                  margin-left: $base-margin * ($i - 1);
                }
            }
            &.breadcrumbs--current {
                font-family: $BrandMedium;
                cursor: default;
            }
        }
    }
}

