.donut-chart {
    position: relative;
    background: $white;
    padding: 20px 0 10px;
    cursor: pointer;
    box-sizing: border-box;

    &:first-child {
        border-top: 0;
    }

    &:after {
        content: "";
        width: 1px;
        height: calc(100% - 50px);
        margin: auto;
        border-right: $classicBorder;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    .donut-custom--label {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .top--title {
            font-size: 28px;
            font-family: $BrandBold;
            line-height: 30px;
        }
        .bottom--title {
            font-size: 12px;
            font-family: $BrandRegular;
            line-height: 14px;

            b {
                font-family: $BrandMedium;
            }
        }
    }

    .donut-custom-border {
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        display: none;
    }

    &:hover {
        .donut-custom-border {
            display: block;
        }
    }

    &:focus {
        outline-offset: -3px;
    }
}

.expired-asset--charts {
    display: flex;
    justify-content: space-between;

    .donuts-separator {
        height: calc(100% - 40px);
        margin-top: 20px;
        border: 0;
        border-right: $classicBorder;
    }

    .donut-chart {
        width: 33.3%;
    }

    &.fullView {
        justify-content: flex-start;
        margin-bottom: 15px;

        .donut-chart {
            width: 165px;
            border: $classicBorder;
            border-radius: 4px;
            margin-right: 8px;

            &.active {
                box-shadow: $grayShadow1;
                border-bottom-width: 3px;
                padding-top: 18px;

                .donut-custom--label {
                    .top--title {
                        margin-top: -2px;
                    }
                }
            }

            &.disabled {
                opacity: 0.5;
            }

            &:after {
                display: none;
            }
        }
    }
}
