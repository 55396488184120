@media #{$mobile} {
    #app {
        .generate-anon-link-modal {
            .modal-positioner {
                .modal {
                    &__header {
                        background: $white;
                        border: none;
                        padding: 10px 15px 0 15px;
                        &__text {
                            font-size: 14px;
                        }
                    }
                    &__content {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}