.item-info-heading {
    display: flex;
    margin-bottom: 15px;
    border-bottom: $classicBorder;
    padding-bottom: 15px;
    img {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        border: $classicBorder;
        margin-right: 13px;
    }
}
.item-info-heading__wrapper {
    padding-top: 8px;
}
.item-info-heading__title {
    font-size: 16px;
    font-family: $BrandMedium;
    line-height: 20px;
    color: $blackLight;
    margin-bottom: 5px;
}

.item-info-heading__description {
    font-size: 14px;
    color: $blackLight;
    line-height: 20px;
}

.item-info-body {
    .asset-preview-row{
        padding-bottom: 10px;
    }
    .asset-preview__title{
        width: initial;
        word-break: keep-all;
        display: flex;
        white-space: nowrap;
    }
    .asset-preview__data{
        padding: 1px 0 10px 15px;
    }
}

.agency-member-item {
    margin-bottom: 10px;
}
