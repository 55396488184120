.download-content-files-cvp {
    .download-content {
        &__item {
            margin-top: 11px;
            display: flex;
            &__info {
                margin-left: 15px;
                flex-grow: 1;
                padding: 15px;
                border-radius: 4px;
                border: $classicBorder;
                overflow: hidden;

                .file-preview {
                    border: none;
                    padding: 0;
                    margin-bottom: 0;
                    &__info {
                        padding-top: 15px;
                    }
                }
            }
        }
    }
}