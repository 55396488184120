.page-root--loader {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 50%;
    margin-left: -50px;
}

.page {
    background-color: $pageBgColor;
    .new-layout--container {
        display: flex;
    }

    .new-layout--left,
    .new-layout--right {
        width: 0;
        transition: all 0.3s;
    }

    .new-layout--middle {
        width: calc(100% - 230px);
        min-width: 0;
        flex-grow: 1;
    }

    .new-layout--middle-center {
        width: 640px;
        margin: 0 auto;
    }

    .new-layout--left-inner,
    .new-layout--right-inner {
        width: 230px;
        height: 100%;
        position: relative;
        transition: transform 0.3s;
    }

    .new-layout--absolute {
        .new-layout--left {
            position: absolute;
            z-index: 6;
        }
    }
}

html:not(.grid-view) {
    .new-search-page {
        .new-layout--left {
            position: absolute;
            z-index: 6;
        }
    }
}

html:not(.nav-opened) {
    .new-layout--left-inner {
        transform: translateX(-100%);
    }

    .new-layout--right-inner {
        transform: translateX(100%);
    }

    .new-layout--absolute {
        .new-layout--left {
            transform: translateX(-100%);
        }
    }
}

html:not(.nav-opened):not(.grid-view) {
    .new-search-page {
        .new-layout--left {
            transform: translateX(-100%);
        }
    }
}

.nav-opened {
    .page {
        .new-layout--left,
        .new-layout--right {
            width: 230px;
        }
    }

    .isIE {
        .page {
            .new-layout--left {
                z-index: auto;
            }
        }
    }

}

.isIE {
    .page {
        .new-layout--middle {
            background: $greyLight7;
        }

        .new-layout--left {
            z-index: -1;
        }
    }
}

@media screen and (max-width: 1024px) {
    .page {
        .new-layout--left {
            position: absolute;
            z-index: 6;
        }
    }

    html:not(.nav-opened) {
        .new-layout--left {
            transform: translateX(-100%);
        }
    }
}

.cabinet-main-container {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0 33px 10px;
    z-index: 1;

    .search {
        position: relative;
        width: 400px;
    }

    .input-pair--empty {
        min-width: 260px;
    }

    .add-new-modal-link {
        text-transform: capitalize;
        margin: 5px;

        :hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 1080px) {
    .page--cabinet:before {
        width: 100%;
    }
}

.cabinet-header,
.header_navigation {
    display: flex;
    align-items: center;

    .cabinet-header__buttons-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
        .main-page-button {
            margin-left: 10px;
        }
    }
}

.header_navigation__text,
.cabinet-header__text,
.dashboard-header__text {
    font-size: 17px;
    font-family: Arial, sans-serif;
    line-height: 26px;
    color: $black;

    a {
        color: $blue;

        &:hover {
            text-decoration: underline;
        }
    }
}

.cabinet-header {
    height: 46px;
    margin: 10px 0;

    &__button {
        flex-grow: 0;
        margin: 0 0 0 10px;
    }

    &__dropdown {
        margin-left: 10px;
    }

    &__label {
        padding: 5px;
        background: $orangeLight;
        color: $black;
        font-weight: bold;
        font-size: 13px;
    }

    &__button-disabled {
        background-color: $greyLight7 !important;
        color: $greyDarker4 !important;

        &:hover {
            background-color: $greyLight7 !important;
            color: $greyDarker4 !important;
        }
    }

    a.cabinet-header__button {
        display: block;
    }

    .report-hint {
        width: 449px;
        position: absolute;
        top: 10px;
        right: 0;
        color: red;
    }

    &.modular {
        position: relative;
        z-index: 3;
    }
}

.cabinet-controls {
    padding: 15px 0;
    background: $white;
    display: flex;
    flex-wrap: wrap;
    min-height: 35px;
    align-items: center;
    justify-content: flex-end;

    .cabinet-header__buttons-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
    }

    .input-pair {
        margin: 0 0 0 15px;
    }

    .search-input {
        margin-right: 0;
    }

    .input-pair__input-box {
        width: 200px;
    }

    .input-pair__title-box {
        color: $black;
        white-space: nowrap;
        line-height: 20px;
    }

    & > div:first-child {
        .input-pair {
            margin-left: 0;
        }

        &.search-input {
            display: flex;
            justify-content: flex-start;
        }

        &.bulk-operations {
            flex-grow: 1;
            flex-shrink: 1;

            .input-pair {
                justify-content: flex-start;
            }
        }

        &.input-pair {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 0;
        }
    }

    //rewrite to simple input in cabinet controls:
    //add sepereted by coma classes like &.search-input--project-id, next, next..
    & > div:first-child,
    & > div:last-child {
        &.search-input {
            flex-grow: 1;
            flex-shrink: 1;

            .input-pair__input-box {
                width: 320px;
            }

            .input-pair__title-box:empty {
                display: none;
            }

            .input-pair {
                justify-content: flex-end;
            }

            &.search-input--project-id,
            &.search-input--sap-id,
            &.search-input--id {
                flex-grow: 0;
                flex-shrink: 0;

                .input-pair__input-box {
                    width: 200px;
                }

                .input-pair {
                    justify-content: flex-start;
                }
            }
        }
    }

    &.sameWidth {
        .search-input {
            .input-pair__input-box {
                width: 355px !important;
            }
        }
    }
}

@media (max-width: 800px) {
    .cabinet-controls {
        & > div:first-child {
            &.search-input {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
